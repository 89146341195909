import { useState, useEffect, useCallback } from "react";
import InfoBox from "../includes/InfoBox";
import FormControl from "../includes/FormControl";
import WebUserCard from "../components/WebUserCard";
import { LinearProgress } from "@mui/material";

export default function WebConfiguration(props)
{
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);

    const getUsers = useCallback(async () => {
        console.log("🚀 ~ getUsers ~ INIT")
        try
        {
            setIsLoading(true);

            const companyId = JSON.parse(localStorage.getItem('user')).iD_EMPRESA;
            console.log("🚀 ~ getUsers ~ companyId",companyId)

            const response = await fetch(`${process.env.REACT_APP_URL}/user/all?companyId=${companyId}`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            });

            if(!response.ok)
            {
                throw Error("Não foi possível obter os usuários.");
            }

            const data = await response.json();
            
            data.forEach(item => {
                item.senha = "";
            });

            setUsers([...data]);
        }
        catch (error)
        {
            console.log("🚀 ~ getUsers ~ error:", error)
        }
        finally 
        {
            setIsLoading(false);
        }
    },[])
    
    const addUser = () => {
        let user = {
            id: crypto.randomUUID(),
            iD_EMPRESA: JSON.parse(localStorage.getItem("user")).iD_EMPRESA,
            iD_INTRANET: 0,
            dS_NOME: "Novo usuário",
            email: null,
            senha: null,
            dS_CNPJ: JSON.parse(localStorage.getItem("user")).dS_CNPJ, 
            booL_MASTER: false,
            role: null,
            dS_PANEL_SERIAL: JSON.parse(localStorage.getItem("system")).serialKeys
        };

        setUsers((prevUsers) => [...prevUsers, user]);
    }

    useEffect(() => {
        getUsers();
    },[]);

    return(
        <main class="container">
            {isLoading && 
                <LinearProgress color="secondary" style={{position: "fixed", top: "66px", left: "0", width: "100%", zIndex: "1201"}} /> 
            }
            <section class="grid grid--middle mb-5">
                <div class="grid__col" data-grid-col="6">
                    <h1>Listagem de Usuários Web <span class="color-light">({users.length} / 10)</span></h1>
                </div>
                <div class="grid__col" data-grid-col="6">
                    <button type="button" class="button button--primary ml-auto" onClick={addUser} disabled={users.length >= 10}>
                        <i class={`button__icon bx bx-sm bx-user-plus`}></i>
                        <span class="button__label">Adicionar</span>
                    </button>
                </div>
            </section>
            <section class="mb-5">
            {users.map((user, i) => (
                <WebUserCard index={i} controlUser={user} controlUsersList={users} setPageLoading={setIsLoading} handleUsers={setUsers}/>
            ))}
            </section>
            {/* <pre>
                {JSON.stringify(users,null,2)}
            </pre> */}
        </main>
    );
}