import { useState, useEffect } from "react"
import { Card, CardHeader, CardContent, IconButton, Tooltip } from "@mui/material"
import { Close, Edit, Person } from '@mui/icons-material'
import EditUser from "./EditUser"

export default function UserCard(props) {
    const [edit, setEdit] = useState(false)
    const [disabled, setDisabled] = useState()

    useEffect(() => {
        setDisabled(props.user.role.notEditable)
    }, [])

    return (
        <Card sx={{marginTop: "1rem", marginBottom: "1rem", padding: "1rem"}}>
            <CardHeader
                avatar={<Person color={!!props.webMaster ? "secondary" : "primary" } />}
                action={edit
                    ?
                    <Tooltip title="Cancelar">
                        <IconButton onClick={() => setEdit(!edit)}>
                            <Close />
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title="Editar">
                        <IconButton onClick={() => setEdit(!edit)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>}
                title={!!props.webMaster ? `${props.user.dS_NOME} - Master` : props.user.dS_NOME}
            //subheader={ }
            />
            {edit ?
                <>
                    <CardContent>
                        <EditUser removeUser={props.removeUser} user={props.user} />
                    </CardContent>
                </>
                : null}
        </Card>
    )
}