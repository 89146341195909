import Helper from "./Helpers";
import GuardiaoLogo from "../assets/images/logoTray.png";
import wkhtmltopdf from "wkhtmltopdf";

const PdfViewer = {
    async PreviewAsync(action = "", method = "GET", token = "", type = "text") 
    {
        //TODO - Implementar o fileName usando o ServiceWorker e a Cache API
        try
        {
            const response = await fetch(action,{
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Expose-Headers': '*',
                    "Authorization": `Bearer ${token}`
                }
            });

            if (!response.ok) 
            {
                window.alert('Erro na resposta do servidor.');
                throw new Error('Erro na resposta da rede.');
            }

            const htmlContent = await response.text();
                
            // const wrapper = document.createElement('div');
            // wrapper.classList.add('relatory-print');
            // wrapper.innerHTML = htmlContent;

            const blob = new Blob([htmlContent], { type: 'text/html' });
            const url = window.URL.createObjectURL(blob);
            const popup = window.open('', '_blank', 'width=800,height=600');

            if (!popup) 
            {
                window.alert('O navegador bloqueou a abertura do arquivo. Por favor, permita pop-ups para visualizar o relatório.');
                return;
            }

            popup.document.write(`
                <html>
                <head>
                    <title>Guardião 2.0</title>
                </head>
                <body style="margin: 0; display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100vh;">
                    <div class="toolbar" style="margin-left: auto;">
                        <button onclick="window.print()">Imprimir</button>
                    </div>
                    <object data="${url}" type="application/xhtml+xml" width="100%" height="100%">
                        <embed src="${url}" type="application/xhtml+xml" />
                        <p>Seu navegador não suporta visualização de PDF. <a href="${url}" download>Baixe o PDF</a>.</p>
                    </object>
                </body>
                </html>
            `);

            setTimeout(() => popup.close(), 120000);
        }
        catch (error)
        {
            console.error('Erro ao gerar PDF:', error);
            window.alert("Erro ao gerar PDF.\n\nEntre em contato com o SUPORTE para analisar se o computador está com a biblioteca MSVC instalada.");
        }
    },
    async ShowAsync(action = "", method = "GET", token = "", domContainerId = "", type = "text") {
        try {
            const response = await fetch(action, {
                method: method,
                headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'Access-Control-Expose-Headers': '*',
                  "Authorization": `Bearer ${token}`
                }
            });
    
            if (!response.ok) {
                window.alert('Erro na resposta do servidor.');
                throw new Error('Erro na resposta da rede.');
            }
    
            const htmlContent = await response.text();
            const blob = new Blob([htmlContent], { type: 'text/html' });
            const url = URL.createObjectURL(blob);
    
            if (!domContainerId) {
                window.alert('O navegador bloqueou a abertura do arquivo. Por favor, permita pop-ups para visualizar o relatório.');
                return;
            }
    
            const trigger = document.querySelector(domContainerId);
            const contentContainer = document.querySelector(`${domContainerId} + .drawer .drawer__content`);
    
            // Remover todos os filhos do contentContainer
            while (contentContainer.firstChild) {
                contentContainer.removeChild(contentContainer.firstChild);
            }
    
            // Crie o elemento <object>
            const objectElement = document.createElement('object');
            objectElement.setAttribute('data', url);
            objectElement.setAttribute('type', 'text/html');
            objectElement.setAttribute('width', '100%');
            objectElement.setAttribute('height', '720px');
    
            // Crie o elemento <embed>
            const embedElement = document.createElement('embed');
            embedElement.setAttribute('src', url);
            embedElement.setAttribute('type', 'text/html');
            objectElement.appendChild(embedElement);
    
            // Crie o parágrafo de fallback
            const pElement = document.createElement('p');
            pElement.innerHTML = `Seu navegador não suporta visualização de PDF. <a href="${url}" download>Baixe o PDF</a>.`;
            objectElement.appendChild(pElement);
    
            // Anexe o elemento <object> ao contentContainer
            contentContainer.appendChild(objectElement);
    
            // Exiba o contentContainer
            trigger.checked = true;
        }
        catch (error) {
            console.error('Erro ao gerar PDF:', error);
            window.alert("Erro ao gerar PDF.\n\nEntre em contato com o SUPORTE para analisar se o computador está com a biblioteca MSVC instalada.");
        }
    },
    async PrintAsync(action = "", method = "GET", token = "", setIsLoading)
    {
        try
        {
            setIsLoading(true);
            
            const response = await fetch(action,{
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Expose-Headers': '*',
                    "Authorization": `Bearer ${token}`
                }
            });

            if (!response.ok) 
            {
                window.alert('Erro na resposta do servidor. Não foi possível gerar o PDF.');
                throw new Error('Erro na resposta da rede.');
            }

            //let styleGuardiaoLogo = ` style="background-image: url(${GuardiaoLogo});"`;
            //let target = "class='image-logo'";

            let htmlContent = await response.text();
            //htmlContent = Helper.AddFragment(htmlContent, target, styleGuardiaoLogo);

            const blob = new Blob([htmlContent], { type: 'text/html' });
            const url = window.URL.createObjectURL(blob);

            const hideFrame = document.createElement("iframe");
            hideFrame.onload = this.SetPrint;
            hideFrame.style.display = "none"; // hide iframe
            hideFrame.src = url;
            document.body.appendChild(hideFrame);
        }
        catch (error)
        {
            console.error('Erro ao gerar PDF:', error);
            window.alert("Erro ao gerar PDF.\n\nEntre em contato com o SUPORTE para analisar se o computador está com a biblioteca MSVC instalada.");
        }
        finally
        {
            setIsLoading(false);
        }
    },
    async Download(action = "", method = "GET", token = "", fileName = "", setIsLoading)
    {
        try
        {
            setIsLoading(true);
    
            await fetch(action, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            });
        }
        catch (error)
        {
            console.log("🚀 ~ error:", error);
        }
        finally
        {
            setIsLoading(false);
        }
        
        // try
        // {
        //     const response = await fetch(action, {
        //         method: method,
        //         headers: {
        //             'Content-Type': 'application/json',
        //             "Authorization": `Bearer ${localStorage.getItem("token")}`
        //         }
        //     });
    
        //     if (!response.ok) {
        //         throw new Error(`Erro na requisição: ${response.statusText}`);
        //     }
    
        //     const blob = await response.blob();

        //     const url = window.URL.createObjectURL(
        //         new Blob([blob]),
        //     );

        //     const link = document.createElement('a');
            
        //     link.href = url;
        //     link.setAttribute(
        //         'download',
        //         `${fileName}-${Helper.GenerateGUID()}.pdf`,
        //     );

        //     // Append to html link element page
        //     document.body.appendChild(link);

        //     // Start download
        //     link.click();

        //     // Clean up and remove the link
        //     link.parentNode.removeChild(link);
        // }
        // catch (error)
        // {
        //     console.error('Erro ao gerar PDF:', error);
        //     window.alert("Erro ao gerar PDF.\n\nEntre em contato com o SUPORTE para analisar se o computador está com a biblioteca MSVC instalada.");
        // }
        // finally
        // {
        //     setIsLoading(false);
        // }
    },
    Preview(data = [])
    {
        console.log("🚀 ~ data:", data)        
    },
    SetPrint() {
        const closePrint = () => {
          document.body.removeChild(this);
        };
        this.contentWindow.onbeforeunload = closePrint;
        this.contentWindow.onafterprint = closePrint;
        this.contentWindow.print();
    }
}

export default PdfViewer;