import { useState, useEffect } from "react"
import { InputLabel, OutlinedInput, FormHelperText, Card, CardHeader, CardContent } from "@mui/material"
import { cnpjMask } from './CnpjMask'
import {  Apartment } from '@mui/icons-material'
import { makeStyles } from "@mui/styles"
import { useHistory } from "react-router"

const useStyles = makeStyles({
    card: {
        padding: "1rem",
        marginTop: "1rem",
        marginBottom: "1rem"
    }
})

export default function CompanyDataWeb(props) {
    const classes = useStyles()
    const [cnpjError, setCnpjError] = useState(false)
    const [cnpjErrorText, setCnpjErrorText] = useState("")
    const [cnpj, setCnpj] = useState("")
    const [edit, setEdit] = useState(false)
    const history = useHistory()

    useEffect(() => {
        setCnpj(props.user.dS_CNPJ)
    }, [])

    const inputChange = (e) => {
        const { name, value } = e.target
        setCnpj(
            value
        )
    }

    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={
                    <Apartment color="primary" />
                }
                title="Dados da empresa"
            />

            <CardContent>
                <InputLabel htmlFor="outlined-adornment-password">CNPJ.</InputLabel>
                <OutlinedInput
                    required
                    onChange={inputChange}
                    id="cnpj"
                    value={cnpjMask(cnpj)}
                    error={cnpjError}
                    disabled={!edit}
                />
                <FormHelperText id="password">{cnpjErrorText}</FormHelperText>
            </CardContent>
        </Card>
    )
}