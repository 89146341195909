import { useState, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { Box, Paper, Grid, Typography, styled, Container, Stack } from '@mui/material'
import { Class } from "@mui/icons-material"

const useStyles = makeStyles({
    pageEvent: {
        minHeight: "100vh"
    },
    stack: {
        maxWidth: "100%"
    }
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 15
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '50px',
}));

export default function ClientsPanel(props) {
    const classes = useStyles(props)
    const [panlesList, setPanelsList] = useState([])

    useEffect(() => {
        var serialList = []
        var panelArray = []

        props.clients.forEach(element => {
            serialList.push(element.id_central)
        });

        fetch(`${process.env.REACT_APP_URL}/amplifier/coordinators`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(serialList)
        }).then(res => res.json())
            .then(data => {
                data.map(item => {
                    if (item != null) {
                        var auxPanel = {
                            "cliente": props.clients.find(c => c.id_central === item.fK_CENTRAL_AMPLIFICADOR).empresa,
                            "central": item.fK_CENTRAL_AMPLIFICADOR,
                            "status": item.dS_STATUS
                        }

                        panelArray.push(auxPanel)
                    }
                })
                setPanelsList(panelArray)
            })
    }, [])

    useEffect(() => {
        var auxPanelList = [...panlesList]
        if (props.panel !== undefined) {
            auxPanelList.filter(item => {
                if (item.central === props.panel.amplifier) {
                    item.status = props.panel.status
                }
            })

            setPanelsList(auxPanelList)
        }
    }, [props.panel])

    return (
        <>
            <center>
                <label><h3>Centrais de Clientes</h3></label>
            </center>

            <Container className={classes.pageEvent}>
                <Box sx={{ flexGrow: 1 }}>
                    <ItemHeader key={1} xs={12} md={6} lg={4}>
                        <Grid alignItems="center" container spacing={3}>
                            <Grid item key={2} xs={3} md={3} lg={3}>
                                <Class />
                            </Grid>

                            <Grid item key={3} xs={3} md={3} lg={3}>
                                <Typography variant="delta">Nome do Cliente</Typography>
                            </Grid>

                            <Grid item key={4} xs={3} md={3} lg={3}>
                                <Typography variant="delta">Série</Typography>
                            </Grid>

                            <Grid item key={5} xs={3} md={3} lg={3}>
                                <Typography variant="delta">Status</Typography>
                            </Grid>
                        </Grid>
                    </ItemHeader>
                    {
                        panlesList.length > 0 ?

                            panlesList.map(item => (
                                item == null ? <> </> :
                                <Item key={item.central}>
                                    <Grid alignItems="center" container spacing={3}>
                                        <Grid item key={2} xs={3} md={3} lg={3}>
                                            <Class color={item.status === "online" ? "success" : "error"} />
                                        </Grid>

                                        <Grid item key={3} xs={3} md={3} lg={3}>
                                            <Typography variant="delta">{item.cliente}</Typography>
                                        </Grid>

                                        <Grid item key={4} xs={3} md={3} lg={3}>
                                            <Typography variant="delta">{item.central}</Typography>
                                        </Grid>

                                        <Grid item key={5} xs={3} md={3} lg={3}>
                                            <Typography variant="delta">{item.status}</Typography>
                                        </Grid>
                                    </Grid>
                                </Item>
                            ))
                            : null
                    }

                </Box>
            </Container>
        </>
    )
}