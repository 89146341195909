import React, { useState, useEffect } from "react"
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { CardActions } from "@mui/material"
import Typography from '@mui/material/Typography'
import makeStyles from "@mui/styles/makeStyles"
import { Box } from "@mui/material"
import { Warning, Check } from "@mui/icons-material"
import { createTheme } from '@mui/material/styles'
import { textAlign } from "@mui/system"

const theme = createTheme();

theme.typography.h3 = {
    [theme.breakpoints.up('xl')]: {
        fontSize: '3rem',
        FontFace: 'Montserrat'
    },
    [theme.breakpoints.down('xl')]: {
        fontSize: '2rem',
    },
};

const useStyles = makeStyles((theme) => {
    return {
        alarmCard: {
            [theme.breakpoints.up('xl')]: {
                height: '55vh',
                display: 'flex',
                flexDirection: 'column'
            },
            [theme.breakpoints.down('xl')]: {
                height: '60vh',
                display: 'flex',
                flexDirection: 'column'
            },
        },
    }
})

export default function AlarmCard(props) {
    const classes = useStyles()

    const newColor = () => {
        if (props.row.fK_OCORRENCIA === "DESL") {
            return "#00B33C"
        }
        if (props.row.fK_OCORRENCIA === "PANI") {
            return "#F58634"
        }
        if (props.row.fK_OCORRENCIA === "SILE") {
            return "#FFEA00"
        }
        if (props.row.fK_OCORRENCIA === "LIGA") {
            return "#CA373C"
        }
    }

    return (
        <div>
            <Card className={classes.alarmCard} elevation={1} sx={{ backgroundColor: newColor }} >
                <CardHeader
                    avatar={""}
                    action={""}
                    title={"Central"}
                    subheader={<Typography variant="body2">{props.row.fK_CENTRAL}</Typography>}
                />
                <CardContent sx={{ heigth: "250px" }}>
                    <Box justifyContent="center" textAlign="center">
                        {props.row.fK_OCORRENCIA === "DESL" ? <Check sx={{ fontSize: 100 }} /> : <Warning sx={{ fontSize: 100 }} />}

                        <Typography variant='h4'>{
                            props.row.fK_OCORRENCIA === "SILE" ? "SILENCIAR ALARME" : null ||
                                props.row.fK_OCORRENCIA === "LIGA" ? "ALARME DE ABANDONO" : null ||
                                    props.row.fK_OCORRENCIA === "PANI" ? "ALARME DE BRIGADA" : null ||
                                        props.row.fK_OCORRENCIA === "DESL" ? "SISTEMA RESTAURADO" : null
                        }
                        </Typography>
                        <Typography variant='h6'>
                            Origem: {props.row.dS_ORIGEM_ALARME}
                        </Typography>
                    </Box>
                </CardContent>
                <CardActions sx={{ marginTop: 'auto' }}>
                    <Box textAlign="center" sx={{ width: "100%" }}>
                        <Typography variant='body1'>
                            {new Date(props.row.dT_EVENTO).toLocaleString().replace(',', '')}
                        </Typography>
                    </Box>
                </CardActions>
            </Card>
        </div>
    )
}