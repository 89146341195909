import React from 'react';
import PropTypes from 'prop-types';

// Classe
const InfoBox = ({ children, className = '', status = '' }) => {
  	let header = null, body = null, footer = null;

	React.Children.forEach(children, child => {
		if (child.type === InfoBox.Header) {
			header = child;
		} else if (child.type === InfoBox.Body) {
			body = child;
		} else if (child.type === InfoBox.Footer) {
			footer = child;
		}
	});

	return (
		<div className={`info-box ${className}`} data-status={`${status}`}>
			{header && <div className="info-box__header">{header}</div>}
			{body && <div className="info-box__body">{body}</div>}
			{footer && <div className="info-box__footer">{footer}</div>}
		</div>
	);
};

InfoBox.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    amplifierStatus: PropTypes.string
};

// Subcomponentes
InfoBox.Header = ({ children }) => <>{children}</>;
InfoBox.Body = ({ children }) => <>{children}</>;
InfoBox.Footer = ({ children }) => <>{children}</>;

export default InfoBox;