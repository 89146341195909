import React, { useState, useEffect } from "react"
import { Box, Grid, Typography } from '@mui/material'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => {
    return {
        containerCommandItem: {
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"commandItem1 commandItem2 commandItem3 commandItem4 commandItem5 commandItem6"`,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"commandItem1 commandItem2 commandItem3 commandItem3" 
                "commandItem4 commandItem5 commandItem6 commandItem6"`,
            }
        },
        commandItem1: {
            gridArea: "commandItem1",
        },
        commandItem2: {
            gridArea: "commandItem2",
        },
        commandItem3: {
            gridArea: "commandItem3",
        },
        commandItem4: {
            gridArea: "commandItem4",
        },
        commandItem5: {
            gridArea: "commandItem5",
        },
        commandItem6: {
            gridArea: "commandItem6",
        }
    }
})

export default function CommandItem({ commandData }) {
    const classes = useStyles()
    const [command, setCommand] = useState(commandData)
    const [commandDescription, setCommandDescription] = useState("")
    const [commandDate, setCommandDate] = useState("")
    const [commandLocal, setCommandLocal] = useState("")
    const [commandOrigin, setCommandOrigin] = useState("")
    const [serial, setSerial] = useState("")

    const handleCommand = (description) => {
        switch (description) {
            case "DESL":
                setCommandDescription("SISTEMA RESTAURADO")
                break;
            case "LIGA":
                setCommandDescription("ALARME DE ABANDONO")
                break;
            case "SILE":
                setCommandDescription("SILENCIAR ALARME")
                break;
            case "PANI":
                setCommandDescription("ALARME DE BRIGADA")
                break;
            default:
                break
        }
    }

    const newColor = (row) => {
        if (row.dS_DESCRICAO_ALARME === "DESL") {
            return "#00B33C"
        }
        if (row.dS_DESCRICAO_ALARME === "PANI") {
            return "#F58634"
        }
        if (row.dS_DESCRICAO_ALARME === "SILE") {
            return "#FFEA00"
        }
        if (row.dS_DESCRICAO_ALARME === "LIGA") {
            return "#CA373C"
        }
    }

    const handleLocal = (local) => {
        local === "0" ? setCommandLocal("Geral") : setCommandLocal(local)
    }

    useEffect(() => {
        handleLocal(commandData.dS_LOCAL_ALARME)
        handleCommand(commandData.dS_DESCRICAO_ALARME)
        setCommandDate(new Date(commandData.dT_ALARME).toLocaleString().replace(',', ''))
        setCommandOrigin(commandData.dS_ORIGEM_ALARME)
        setSerial(commandData.fK_CENTRAL)
    }, [])

    return (
        <div>
            <Box className={classes.containerCommandItem}>
                <Box className={classes.commandItem1}><VolumeUpIcon sx={{ color: newColor(command) }} /></Box>
                <Box className={classes.commandItem2}> <Typography variant="delta">{serial}</Typography></Box>
                <Box className={classes.commandItem3}><Typography variant="delta">{commandDescription}</Typography></Box>
                <Box className={classes.commandItem4}><Typography variant="delta">{commandLocal}</Typography></Box>
                <Box className={classes.commandItem5}><Typography variant="delta">{commandOrigin}</Typography></Box>
                <Box className={classes.commandItem6}><Typography variant="delta">{commandDate}</Typography></Box>
            </Box>
        </div>
    )
}