import React, { useState, useEffect, forwardRef } from 'react'
import { useHistory } from "react-router"
import IconButton from '@mui/material/IconButton'
import { Container, Button, Grid, Typography, Tooltip, Box, Snackbar } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import { Person, VpnKey, Key, Help } from '@mui/icons-material'
import { FormHelperText, FormControlLabel, Checkbox } from '@mui/material'
import { cnpjMask } from './CnpjMask'
import ApartmentIcon from '@mui/icons-material/Apartment'
import MuiAlert from "@mui/material/Alert"
import TermsModal from '../terms/TermsModal'

const useStyles = makeStyles((theme) => {
    return {
        fonts: {
            color: "#ffffff"
        }
    }
})

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ChangePassword(props) {
    const classes = useStyles()
    const [user, setUser] = useState(localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : {})
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const [nameError, setNameError] = useState(false)

    const [passwordError, setPasswordError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [passwordErrorText, setPasswordErrorText] = useState("")

    const [nameErrorText, setNameErrorText] = useState("")

    const [confirmation, setConfirmation] = useState("")
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
    const [confirmationError, setConfirmationError] = useState(false)
    const [passwordConfirmationText, setPasswordConfirmationText] = useState("")

    const [cnpj, setCnpj] = useState("")
    const [cnpjError, setCnpjError] = useState(false)
    const [cnpjErrorText, setCnpjErrorText] = useState("")

    const [userNotFound, setUserNotFound] = useState(false)

    const [checked, setChecked] = useState(false)
    const [key, setKey] = useState("")
    const [secretError, setSecretError] = useState(false)
    const [secretErrorText, setSecretErrorText] = useState("")
    const [open, setOpen] = useState(false)
    const [technicianHd, setTechnicianHd] = useState("")
    const [acceptTerm, setAcceptTerm] = useState(false)
    const [severity, setSeverity] = useState("")
    const [message, setMessage] = useState("")

    const history = useHistory()

    const inputChange = (e) => {
        const { name, value } = e.target
        setCnpj(
            value
        )
    }

    useEffect(() => {
        if (props.technician === true) {
            fetch(`${process.env.REACT_APP_URL}/account/hdd`, {
                method: "GET",
                headers: { "content-type": "application/json" },
            }).then(response => response.json())
                .then(data => setTechnicianHd(data.message),
                    console.log(technicianHd))
                
            setKey("")
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (cnpj.length < 14) {
            setCnpjError(true)
            setCnpjErrorText("Insira um CNPJ válido!")
        } else {
            setCnpjError(false)
            setCnpjErrorText("")
        }

        if (name.length < 1) {
            setNameError(true)
            setNameErrorText("Insira um novo Nome para o Usuário Administrador!")
        } else {
            setNameError(false)
            setNameErrorText("")
        }

        if (password.length < 8) {
            setPasswordError(true)
            setPasswordErrorText("Sua Senha deve conter no mínimo 8 caracteres!")
        } else {
            setPasswordError(false)
            setPasswordErrorText("")
        }

        if (confirmation !== password) {
            setConfirmationError(true)
            setPasswordConfirmationText("As Senhas devem ser iguais!")
        } else {
            setConfirmationError(false)
            setPasswordConfirmationText("")

        }

        if (password.length >= 8 && name && confirmation === password) {

            if (props.technician === true) {
                if (key.length < 25) {
                    setSecretError(true)
                    setSecretErrorText("Insira sua chave")

                    return
                } else {
                    setSecretError(false)
                    setSecretErrorText("")

                    fetch(`${process.env.REACT_APP_URL}/licences/activate/technician?key=${key}`, {
                        method: "GET",
                        headers: { "content-type": "application/json" }
                    }).then(response => response.json())
                        .then(data => {
                            if (data.message === "Success") {
                                setSeverity("success")
                                setOpen(true)
                                setMessage("Licença ativada com sucesso")

                                var roles = {
                                    "ID": user.id,
                                    "upload": true,
                                    "editar": true,
                                    "alarmes": false,
                                    "relatorio": true,
                                    "usuarios": true,
                                    "admin": true,
                                    "delta": false,
                                    "revenda": false,
                                    "tecnico": true,
                                    "light": false,
                                    "notEditable": true,
                                    "multiCentral": false
                                }

                                fetch(`${process.env.REACT_APP_URL}/user/firstlogin`, {
                                    method: "POST",
                                    headers: { "content-type": "application/json" },
                                    body: JSON.stringify({
                                        "ID": user.id,
                                        "DS_NOME": name,
                                        "SENHA": password,
                                        "ROLE": roles,
                                        "ID_INTRANET": 0,
                                        "DS_CNPJ": cnpj.replace(".", "").replace("/", "").replace("-", ""),
                                    })
                                }).then(response => response.json())
                                    .then(user => {
                                        fetch(`${process.env.REACT_APP_URL}/account/login?ds_NOME=${name}&sENHA=${password}`, {
                                            method: "GET",
                                            headers: { "content-type": "application/json" },
                                        })
                                            .then(res => {
                                                if (res.status === 404) {
                                                    setUserNotFound(true)
                                                } else {
                                                    res.json()
                                                        .then(data => {
                                                            if (data.message) {
                                                                props.token.setToken(data.message)
                                                            } else {
                                                                props.data.setToken(data.token)
                                                                props.data.setUserData(data.user)
                                                                props.data.populateData(data.user, data.token)
                                                                localStorage.setItem("user", JSON.stringify(data.user))
                                                                localStorage.setItem("token", data.token)
                                                                history.push("/central")
                                                            }
                                                        })
                                                }
                                            })
                                    })
                                    .catch(err => console.log(err))
                            }

                            if (data.message === "Used") {
                                setSeverity("error")
                                setOpen(true)
                                setMessage("Esta chave já está em uso. Adquira uma nova chave!")
                            }

                            if (data.message === "Error") {
                                setSeverity("error")
                                setOpen(true)
                                setMessage("Erro ao ativar licença!")
                            }

                            if (data.message === "NotFound") {
                                setSeverity("error")
                                setOpen(true)
                                setMessage("Chave não encontrada!")
                            }

                            if (data.message === "Invalid") {
                                setSeverity("error")
                                setOpen(true)
                                setMessage("Chave Inválida.")
                            }
                        })
                }

            } else {
                var roles = {
                    "ID": user.id,
                    "upload": true,
                    "editar": true,
                    "alarmes": true,
                    "relatorio": true,
                    "usuarios": true,
                    "admin": true,
                    "delta": false,
                    "revenda": false,
                    "tecnico": false,
                    "notEditable": true,
                    "multiCentral": false
                }

                fetch(`${process.env.REACT_APP_URL}/user/firstlogin`, {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    body: JSON.stringify({
                        "ID": user.id,
                        "DS_NOME": name,
                        "SENHA": password,
                        "ROLE": roles,
                        "ID_INTRANET": 0,
                        "DS_CNPJ": cnpj.replace(".", "").replace("/", "").replace("-", ""),
                    })
                }).then(response => response.json())
                    .then(user => {
                        fetch(`${process.env.REACT_APP_URL}/account/login?ds_NOME=${name}&sENHA=${password}`, {
                            method: "GET",
                            headers: { "content-type": "application/json" },
                        })
                            .then(res => {
                                if (res.status === 404) {
                                    setUserNotFound(true)
                                } else {
                                    res.json()
                                        .then(data => {
                                            if (data.message) {
                                                props.token.setToken(data.message)
                                            } else {
                                                props.data.setToken(data.token)
                                                props.data.setUserData(data.user)
                                                props.data.populateData(data.user, data.token)
                                                localStorage.setItem("user", JSON.stringify(data.user))
                                                localStorage.setItem("token", data.token)
                                                history.push("/central")
                                            }
                                        })
                                }
                            })
                    })
                    .catch(err => console.log(err))
            }
        }
    }

    const setVisible = () => {
        setShowPassword(!showPassword)
    }

    const setConfirmationVisible = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation)
    }

    const keyLength = (e) => {
        if (e.length >= 24) {
            setKey(e)
        }
    }

    const handleTechnicianBox = () => {
        setChecked(!checked)
        fetch(`${process.env.REACT_APP_URL}/account/hdd`, {
            method: "GET",
            headers: { "content-type": "application/json" },
        }).then(response => response.json())
            .then(data => setTechnicianHd(data.message))

        setKey("")
    }
    return (
        <Container>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(!open)}>
                <Alert severity={severity} sx={{ width: '100%' }}>{message}</Alert>
            </Snackbar>

            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                    <Grid justifyContent="center" alignItems="center" item xs={12} md={12} lg={12}>
                        <Typography className={classes.fonts} variant='h3'>Este é seu Primeiro Acesso!</Typography>
                        <Typography className={classes.fonts} variant='body1'>Para prosseguir você precisa alterar o nome e a senha do Usuário "Administrador" do Sistema Guardião 2.0.</Typography>
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <InputLabel sx={{ color: "#ffffff" }} htmlFor="usern-name">Alterar Nome do Usuário "Administrador":</InputLabel>
                        <OutlinedInput
                            autoFocus={true}
                            id="usern-name"
                            onChange={(e) => setName(e.target.value)}
                            variant="outlined"
                            color="secondary"
                            required
                            error={nameError}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Person />
                                </InputAdornment>
                            }
                        ></OutlinedInput>
                        <FormHelperText id="user-name">{nameErrorText}</FormHelperText>
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <InputLabel sx={{ color: "#ffffff" }} htmlFor="outlined-adornment-password">Insira o CNPJ da empresa:</InputLabel>
                        <OutlinedInput
                            required
                            onChange={inputChange}
                            id="cnpj"
                            value={cnpjMask(cnpj)}
                            error={cnpjError}
                            startAdornment={
                                <InputAdornment position="start">
                                    <ApartmentIcon />
                                </InputAdornment>
                            }
                        />
                        <FormHelperText id="password">{cnpjErrorText}</FormHelperText>
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <InputLabel sx={{ color: "#ffffff" }} htmlFor="outlined-adornment-password">Insira uma Nova Senha para o Usuário "Administrador":</InputLabel>
                        <OutlinedInput
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            id="password"
                            type={showPassword ? "text" : "password"}
                            error={passwordError}
                            startAdornment={
                                <InputAdornment position="start">
                                    <VpnKey />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={setVisible}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText id="password">{"Sua senha deve conter no mínimo 8 caracteres"}</FormHelperText>
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <InputLabel sx={{ color: "#ffffff" }} htmlFor="outlined-adornment-password">Confirme a Senha:</InputLabel>
                        <OutlinedInput
                            required
                            onChange={(e) => setConfirmation(e.target.value)}
                            id="confirmation"
                            type={showPasswordConfirmation ? "text" : "password"}
                            error={confirmationError}
                            startAdornment={
                                <InputAdornment position="start">
                                    <VpnKey />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={setConfirmationVisible}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText id="password">{passwordConfirmationText}</FormHelperText>
                    </Grid>

                    {/* {props.technician
                        ?
                        <Grid item xs={12} md={12} lg={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleTechnicianBox}
                                        />
                                    }
                                    label={"Habilitar licença para tecnico"}
                                />
                                <Tooltip title={"Habilite esta opção se você quiser utilizar este Guardião apensas para uso técnico"}><Help color="primary" /></Tooltip>
                            </Box>
                        </Grid>
                        : null} */}

                    {props.technician
                        ?
                        <Grid item>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "1rem" }}>
                                <Typography color="#ffffff" variant="body1" >Key {technicianHd}</Typography>
                                <Tooltip title={"Informe esta Key ao Suporte Delta Fire para Gerar a sua Licença de Técnico!"}><Help color="primary" /></Tooltip>
                            </Box>

                            <InputLabel sx={{ color: "#ffffff" }} className={classes.fonts} htmlFor="outlined-adornment-password">Insira sua Chave para Licença de Técnico:</InputLabel>
                            <OutlinedInput
                                sx={{ width: "20rem" }}
                                id="secret-key"
                                onChange={(e) => keyLength(e.target.value)}
                                variant="outlined"
                                color="secondary"
                                required
                                error={secretError}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Key />
                                    </InputAdornment>
                                }
                            ></OutlinedInput>
                            <FormHelperText id="secret-key">{secretErrorText}</FormHelperText>
                        </Grid>
                        : null}

                    <Grid item xs={12} md={12} lg={12}>
                        <TermsModal technician={checked} ressale={false} multiUser={false} setAcceptTerm={setAcceptTerm} />
                    </Grid>

                    <Grid item xs={6} md={6} lg={6} marginTop={"10px"}>
                        <Button
                            fullWidth={true}
                            type="submit"
                            color="secondary"
                            variant="contained"
                            disabled={!acceptTerm}
                        >
                            Tudo pronto! Vamos lá!
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}