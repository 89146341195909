import favicon from "../assets/favicon-v2.ico";

const Helper = {
    activeNotifications: {}, // Registro de notificações ativas
    debounceTimer: null, // Temporizador de debounce
    /* Registrar funções Helpers de acordo com a necessidade */

    TryParseJson (jsonStr) 
    {
        try
        {
            let validJsonPattern = /[{}[\]":,.\-0-9a-zA-Z\s]/g;
            let jsonObj = JSON.parse(jsonStr.match(validJsonPattern).join(''));

            if (jsonObj && typeof jsonObj === "object")
            {
                return jsonObj;
            }
        }
        catch (e)
        {
            console.log("🚀 ~ TryParseJson ~ e:", e)
            return false;
        }
    },
    Beep(type = "beep") 
    {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const oscillator = audioContext.createOscillator();
        
        oscillator.type = type; // Pode ser 'sine', 'square', 'sawtooth', 'triangle'
        oscillator.frequency.setValueAtTime(1000, audioContext.currentTime); // Frequência em Hertz (A4)
        
        oscillator.connect(audioContext.destination);
        oscillator.start();
        oscillator.stop(audioContext.currentTime + 0.1); // Para o oscilador após um tempo (exemplo: 500ms)
    },
    Notification(message = "", tag = null, debounceTime = 2000)
    {
        const triggerNotification = () => {
            switch (Notification.permission)
            {
                case "granted":
                    if (!this.activeNotifications[tag]) 
                    {
                        const notification = new Notification("Guardião 2.0", {
                            body: message,
                            icon: favicon,
                            //tag: tag
                        });
                    
                        this.activeNotifications[tag] = notification;
                    
                        // Remover a notificação do registro quando ela for fechada
                        setTimeout(() => { delete this.activeNotifications[tag] } ,60000);
                    }
                    break;
            default:
                Notification.requestPermission().then(permission => {
                    this.Notification(message, tag, debounceTime);
                });
                break;
            }
        };

        // Implementar debounce
        if (this.debounceTimer) 
        {
            clearTimeout(this.debounceTimer);
        }

        this.debounceTimer = setTimeout(() => {
            triggerNotification();
        }, debounceTime);
    },
    GenerateGUID()
    {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
        function(c) {
            var uuid = Math.random() * 16 | 0, v = c == 'x' ? uuid : (uuid & 0x3 | 0x8);
            return uuid.toString(16);
        });
    },
    HtmlStringToElement(htmlString) {
        const parser = new DOMParser();
        return parser.parseFromString(htmlString, 'text/html');
    },
    AddFragment(texto, padrao, fragmento) {
        // Cria uma expressão regular com o padrão fornecido
        const regex = new RegExp(padrao, 'g');
      
        // Usa o método replace para adicionar o fragmento onde o padrão corresponder
        return texto.replace(regex, (match) => `${match}${fragmento}`);
    }
}

export default Helper;