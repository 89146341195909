import React, { useState, useEffect } from 'react'
import { Container, Grid, Button, Box, TextField, Typography, Autocomplete, Stack } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { styled } from '@mui/material/styles'
import { Router } from '@mui/icons-material'
import RelatoryNameField from '../components/RelatoryNameField'
import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'
import Calendar from '../components/Calendar'
import PanelStatusList from '../components/PanelStatusList'
import PdfViewer from '../providers/PdfViewer'
import Drawer from '../includes/Drawer'
import { ReactComponent as IconPDF } from '../assets/icons/icon_file_pdf.svg'

const useStyles = makeStyles({
    pageAmplifierRelatory: {
        minHeight: "100vh",
        paddingTop: "4rem"
    },
    buttonRegisterRelatory: {
        marginTop: '3rem',
        marginBottom: '3rem'
    },
})

const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 15,
    width: '100%'
}));

const ItemHeader = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    height: '50px',
    width: '100%'
}));

export default function PanelStatusRelatory(props) {
    const classes = useStyles()
    const [result, setResult] = useState([])
    const [status, setStatus] = useState("Todos")
    const [relatoryName, setRelatoryName] = useState("")
    const [menuItens, setMenuItens] = useState("")
    const [firstDate, setFirstDate] = useState(format(new Date(), 'MM/dd/yyyy'), { locale: ptBR })
    const [secondDate, setSecondtDate] = useState(format(new Date(), 'MM/dd/yyyy'), { locale: ptBR })
    const [currency, setCurrency] = useState("Todos")
    const [options, setOptions] = useState(["Todos", "Modo Normal", "Modo Parcial", "Sem rede AC"])
    const [show, setShow] = useState(false);

    const gerarPdf = () => {
        let actionUrl = `${process.env.REACT_APP_URL}/relatory/panel/status?FirstDate=${firstDate}&SecondDate=${secondDate}&user=${props.user.dS_NOME}${status === '' ? '' : '&StatusType=' + status}&menuItens=${menuItens}`;

        if (process.env.REACT_APP_MODE === "desktop") {
            PdfViewer.Download(actionUrl, "GET", props.token, "relatorio-status-central", props.setIsLoading);
        } else {
            PdfViewer.PrintAsync(actionUrl, "GET", props.token, props.setIsLoading);
        }
    }


    const request = async () => {
        props.setIsLoading(true);
        fetch(`${process.env.REACT_APP_URL}/panel/status?FirstDate=${firstDate}&SecondDate=${secondDate}${status === '' ? '' : '&StatusType=' + status}&menuItens=${menuItens}`,
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Expose-Headers': '*',
                    "Authorization": "Bearer " + props.token
                }
            })
            .then(response => response.json())
            .then(data => {
                setShow(true);
                setResult({})
                setResult(data)
                props.setIsLoading(false);
            })
    }

    const handlerOperationMode = (event) => {
        setStatus(event.target.innerText === undefined ? "Todos" : event.target.innerText)
        setCurrency(event.target.innerText === undefined ? "Todos" : event.target.innerText)
    }

    useEffect(() => {
        setResult([])
        if (props.serial === undefined) {
            setMenuItens(Array.from(new Set(
                props.menuItens.map(item => {
                    return item.id_central;
                })
            )))
        } else {
            setMenuItens(props.serial)
        }
    }, [props.menuItens])

    return (
        <div class="container">
            <center>
                <h3 class="mb-5">Relatório da Situação da Central</h3>
            </center>

            <div class="filter">
                {/* <div class="filter__column filter__column--fw">
                    <RelatoryNameField changeRelatoryName={changeRelatoryName} />
                </div> */}
                <div class="filter__column">
                    <Calendar label={"Data Inicial"} setDate={setFirstDate} />
                </div>
                <div class="filter__column">
                    <Calendar label={"Data Final"} setDate={setSecondtDate} />
                </div>
                <div class="filter__column">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        defaultValue={currency}
                        options={options}
                        sx={{ width: "100%", mx: 'auto' }}
                        onChange={(event) => handlerOperationMode(event)}
                        renderInput={(params) => <TextField {...params} label="Status" />}
                    />
                </div>
            </div>

            <div class="buttons-container buttons-container--right mb-5">
                <button type="button" class="button button--md button--primary" title="Buscar" onClick={request} disabled={props.isLoading}>
                    <span class="button__label">Buscar</span>
                </button>
                <button type="button" class="button button--transparent" title="Gerar PDF" onClick={gerarPdf} disabled={props.isLoading}>
                    <IconPDF className="button__icon df-i df-i-red df-i-2xl"/>
                </button>
            </div>

            {!!show &&
                <Stack alignItems="center" spacing={3}>
                    <ItemHeader style={{"display": "flex", "alignItems": "center", "justifyContent": "center"}}>
                        <Grid alignItems="center" container spacing={3} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "marginTop": 0,"marginLeft": 0}}>
                            {/* <Grid item key={1} xs={1} md={1} lg={1}>
                                <Router />
                            </Grid> */}
                            <Grid item key={1} xs={4} md={4} lg={4} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                                <Typography variant="delta">Central</Typography>
                            </Grid>
                            <Grid item key={2} xs={4} md={4} lg={4} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                                <Typography variant="delta">Status</Typography>
                            </Grid>
                            <Grid item key={3} xs={4} md={4} lg={4} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                                <Typography variant="delta">Data</Typography>
                            </Grid>
                        </Grid>
                    </ItemHeader>
                    {result.length > 0 ? result.map(row => {
                        return <Item item key={row.id} xs={12} md={12} lg={12} sx={{ backgroundColor: '#FFFFFFF' }}> <PanelStatusList key={row.id} row={row} /> </Item>
                    }) : <Typography>Nenhum resultado encontrado!</Typography>}
                </Stack>
            }

            {/* <Drawer drawerId='panel-status-relatory-pdf' className='drawer--bottom-full'>
                <Drawer.Header>
                    <h4>Relatório para impressão</h4>
                </Drawer.Header>
                <Drawer.Body></Drawer.Body>
            </Drawer> */}
        </div>
    )
}