import React, { useState, useEffect } from 'react'
import { Button, Grid, Typography, Box, IconButton, Tooltip, Modal } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import { Person, Key, Email, AddCircle, Help } from '@mui/icons-material'
import { FormHelperText } from '@mui/material'
import { cnpjMask } from '../components/CnpjMask'
import ApartmentIcon from '@mui/icons-material/Apartment'
import validator from 'validator'
import generator from 'generate-password'
import ModalUserData from '../components/ModalUserData'

const useStyles = makeStyles((theme) => {
    return {
        key: {
            width: "25rem"
        },
        pageHomeLeft: {
            [theme.breakpoints.up('sm')]: {
                marginTop: "10%",
                paddingRight: "20%"
            },
            [theme.breakpoints.down('sm')]: {
                padding: '2rem'
            }
        },
        logoDeltaHome: {
            justifyItems: "center"
        },
        imgDiv: {
            display: "flex",
            justifyContent: "center"
        },
        pageHome: {
            [theme.breakpoints.up('sm')]: {
                marginTop: "10%",
                paddingLeft: "20%"
            },
            [theme.breakpoints.down('sm')]: {
                padding: '2rem',
            }
        },
        modal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        }
    }
})

export default function CreateMultiAccountuser(props) {
    const classes = useStyles();
    const [name, setName] = useState("")
    const [nameError, setNameError] = useState(false)


    const [nameErrorText, setNameErrorText] = useState("")


    const [cnpj, setCnpj] = useState("")
    const [cnpjError, setCnpjError] = useState(false)
    const [cnpjErrorText, setCnpjErrorText] = useState("")

    const [key, setKey] = useState("")
    const [keyError, setKeyError] = useState(false)
    const [keyErrorText, setKeyErrorText] = useState("")

    const [userNotFound, setUserNotFound] = useState(false)
    const [invalidKey, setInvalidKey] = useState(false)

    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [emailErrorText, setEmailErrorText] = useState("")

    const [serialList, setSerialList] = useState([
        {
            "id": 0,
            "serial": "",
            "error": false,
            "errorText": ""
        }
    ])
    const [serialListId, setSerialListId] = useState(1)

    const emailHelper = "Escolha um email único para esta conta. Cada série de central deve possuir seu próprio email, para poder ser acessada na versão Web do Guardião 2.0"
    const [serialError, setSerialError] = useState(false)

    const [password, setPassword] = useState(generator.generate(
        {
            length: 8,
            numbers: true
        }
    ))

    const inputChange = (e) => {
        const { name, value } = e.target
        setCnpj(
            value
        )
    }

    const [open, setOpen] = useState(false)

    const [modalError, setModalError] = useState(false)

    const clear = () => {
        setSerialList(
            [
                {
                    "id": 0,
                    "serial": "",
                    "error": false,
                    "errorText": ""
                }
            ]
        )
        setSerialListId(1)
        setEmail("")
        setCnpj("")
        setName("")
        setPassword(generator.generate(
            {
                length: 8,
                numbers: true
            }
        ))
        setKey("")
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        var tempList = [...serialList]
        var serialListError = []

        tempList.map(item => (
            item.serial.length < 12
                ?
                (
                    item.error = true,
                    item.errorText = "Digite os 12 dígitos da série"
                )
                : (
                    item.error = false,
                    item.errorText = ""
                )
        ))

        setSerialList(tempList)


        serialList.map(item => (
            item.error === true ? serialListError.push(1) : null
        ))


        if (serialListError > 0) {
            setSerialError(true)
        } else {
            setSerialError(false)
        }

        if (key.length < 24) {
            setKeyError(true)
            setKeyErrorText("Insira sua chave corretamente")
        } else {
            setKeyError(false)
            setKeyErrorText("")
        }

        if (cnpj.length < 14) {
            setCnpjError(true)
            setCnpjErrorText("Insira um cnpj válido")
        } else {
            setCnpjError(false)
            setCnpjErrorText("")
        }

        if (name.length < 1) {
            setNameError(true)
            setNameErrorText("Insira um novo nome para o usuário Administrador")
        } else {
            setNameError(false)
            setNameErrorText("")
        }

        var tempCnpj = cnpj.replace(".", "").replace(".", "").replace("/", "").replace("-", "")

        if (name && key.length >= 24 && serialError === false) {
            var serialListString = []
            serialList.map(item => (
                serialListString.push(item.serial)
            ))

            fetch(`${process.env.REACT_APP_URL}/user/multi/user`, {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({
                    "Name": name,
                    "Password": password,
                    "Email": email,
                    "Cnpj": tempCnpj,
                    "LicenceKey": key,
                    "SerialList": serialListString
                })
            }).then(res => {
                if(res.status === 404){
                    setModalError(true)
                }
                setModalError(false)
                setOpen(true)
            })
        }

    }

    const keyLength = (e) => {
        setKey(e)
    }

    const validateEmail = (e) => {
        setEmail(e)
        if (validator.isEmail(e)) {
            setEmailError(false)
            setEmailErrorText("")
        } else {
            setEmailError(true)
            setEmailErrorText("insira um email válido")
        }
    }

    const addSerialField = () => {
        var id = serialListId
        var tempList = [...serialList]
        tempList.push(
            {
                "id": id,
                "serial": "",
                "error": false,
                "errorText": ""
            }
        )
        id++
        setSerialList(tempList)
        setSerialListId(id)
    }

    const setList = (id, serial) => {
        var tempList = [...serialList]
        var field = tempList[id]
        field.serial = serial
        tempList[id] = field
        setSerialList(tempList)
    }


    return (
        <Box className={classes.container}>
            <ModalUserData modalError={modalError} setOpen={setOpen} open={open} name={name} password={password} email={email} />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <div className={classes.pageHome}>
                        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <Grid container spacing={4}>
                                <Grid justifyContent="center" alignItems="center" item xs={12} md={12} lg={12}>
                                    <Typography variant='h3'>Cadastro de usuário multi central.</Typography>
                                    <Typography variant='body1'>Preencha os campos abaixo para criar o usuário.</Typography>
                                    {/* <Typography variant='body2'>Este serviço irá enviar um email à empresa solicitando alteração de senha.</Typography> */}
                                </Grid>

                                <Grid item xs={6} md={6} lg={6}>
                                    <InputLabel htmlFor="usern-name">Nome</InputLabel>
                                    <OutlinedInput
                                        autoFocus={true}
                                        id="usern-name"
                                        onChange={(e) => setName(e.target.value)}
                                        variant="outlined"
                                        color="secondary"
                                        value={name}
                                        required
                                        error={nameError}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Person />
                                            </InputAdornment>
                                        }
                                    ></OutlinedInput>
                                    <FormHelperText id="user-name">{nameErrorText}</FormHelperText>
                                </Grid>

                                <Grid item xs={6} md={6} lg={6}>
                                    <InputLabel htmlFor="outlined-adornment-password">Insira o CNPJ da empresa.</InputLabel>
                                    <OutlinedInput
                                        required
                                        onChange={inputChange}
                                        id="cnpj"
                                        value={cnpjMask(cnpj)}
                                        error={cnpjError}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <ApartmentIcon />
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="password">{cnpjErrorText}</FormHelperText>
                                </Grid>

                                <Grid item xs={11} md={11} lg={11}>
                                    <OutlinedInput
                                        className={classes.key}
                                        id="email-field"
                                        onChange={(e) => validateEmail(e.target.value)}
                                        variant="outlined"
                                        color="secondary"
                                        required
                                        type="email"
                                        value={email}
                                        placeholder="Insira um email válido e único para este usuário"
                                        error={emailError}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Email />
                                            </InputAdornment>
                                        }
                                    ></OutlinedInput>
                                    <FormHelperText id="email-field">{emailErrorText}</FormHelperText>
                                </Grid>
                                <Grid item xs={1} md={1} lg={1}>
                                    <Tooltip title={emailHelper}><Help color="primary" /></Tooltip>
                                </Grid>

                                <Grid item xs={6} md={6} lg={6}>
                                    <InputLabel sx={{ width: '100%' }} htmlFor="secret-key">Chave da licença</InputLabel>
                                    <OutlinedInput
                                        className={classes.key}
                                        id="secret-key"
                                        onChange={(e) => keyLength(e.target.value)}
                                        variant="outlined"
                                        color="secondary"
                                        value={key}
                                        required
                                        error={keyError}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Key />
                                            </InputAdornment>
                                        }
                                    ></OutlinedInput>
                                    <FormHelperText id="password">{keyErrorText}</FormHelperText>
                                    {invalidKey
                                        ? <Typography variant="body1" color="error">* Chave inválida</Typography>
                                        : null}
                                    {userNotFound
                                        ? <Typography variant="body1" color="error">* usuário não encontrado</Typography>
                                        : null}
                                </Grid>

                                {/* {userNotFound == false ? null : <Grid item xs={12} md={12} lg={12}><Typography variant='body2' color='#d32f2f'>Usuário e/ou senha incorretos</Typography></Grid>} */}

                                <Grid item xs={12} md={12} lg={12}>
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                        <Button
                                            type="submit"
                                            color="secondary"
                                            variant="contained"
                                        >
                                            Criar
                                        </Button>

                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={clear}
                                        >
                                            Limpar
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <div className={classes.pageHomeLeft}>
                        <Typography variant="h6">Insira a série das centrais vinculadas à esta empresa</Typography>
                        <IconButton color="secondary" onClick={addSerialField}><AddCircle /></IconButton>

                        <Grid container spacing={3}>
                            {serialList.map(item => (
                                <Grid item key={item.id} xs={12} md={12} lg={12}>
                                    <InputLabel htmlFor="central-serial">Central</InputLabel>
                                    <OutlinedInput
                                        autoFocus={true}
                                        id="central-serial"
                                        onChange={(e) => setList(item.id, e.target.value)}
                                        variant="outlined"
                                        value={item.serial}
                                        color="secondary"
                                        error={item.error}
                                        required
                                    ></OutlinedInput>
                                    <FormHelperText id="user-name">{item.errorText}</FormHelperText>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </Grid>
            </Grid>

        </Box>
    );
}