import { useState, useEffect, forwardRef } from "react"
import { Card, CardContent, Box, Button, Grid, Modal, Typography, CircularProgress, Snackbar, CardHeader } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import MuiAlert from "@mui/material/Alert"
import FileUploadIcon from '@mui/icons-material/FileUpload'

const useStyles = makeStyles({
    pageCentral: {
        minHeight: "100vh",
    },
    cardCentral: {
        padding: 0,
        width: '100%', //50vw
        margin: 'auto',
        marginTop: '10px',
        marginBottom: 20
    }
})

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UploadFile(props) {
    const classes = useStyles()
    const [selectedFile, setSelectedFile] = useState(null)
    const [disabled, setDisabled] = useState(true)
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [modalMessage, setModalMessage] = useState("Carregando, por favor aguarde um instante...")
    const [portError, setPortError] = useState(null)

    useEffect(() => {
        setPortError(props.systemData.serialPortError)
    }, [])

    const submitFile = () => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("userId", props.userId);
        formData.append("backup", true);

        setOpenModal(true);

        fetch(`${process.env.REACT_APP_URL}/Register/receive`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + props.token
            },
            body: formData
        }).then(res => res.json())
            .then(data => {
                if (data.status === false) {
                    setOpenModal(false)
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Erro ao carregar o arquivo. Licença incompatível!")
                }
                if (data.status === true) {
                    props.setFileUploaded(true)
                    setOpenModal(false)
                    setSeverity("success")
                    setOpen(true)
                    setMessage("Arquivo carregado com sucesso!")
                    setSelectedFile(null)
                    setDisabled(true)
                }

            })
            .catch(res => {
                setOpenModal(false)
                setSeverity("success")
                setOpen(true)
                setMessage("Arquivo carregado com sucesso!")
                setSelectedFile(null)
                setDisabled(true)

            })
    }

    const selectFile = (e) => {
        setSelectedFile(e.target.files[0])
        setDisabled(false)
    }

    return (
        <>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box textAlign="center" sx={{ marginTop: "20%" }}>
                    <Typography color="#fff" id="modal-modal-title" variant="h6" component="h2">
                        {modalMessage}
                    </Typography>
                    <CircularProgress />
                </Box>
            </Modal>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(!open)}>

                <Alert severity={severity} sx={{ width: '100%' }}>{message}</Alert>
            </Snackbar>

            <Card className={classes.cardCentral}>
                <CardHeader
                    avatar={<FileUploadIcon color="primary" />}
                    title={"Importar Arquivo .ROM"}
                />
                <Box textAlign='center' sx={{ alignItems: 'center' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        {props.systemData.errorMessage === "Sua licença expirou. Entre em contato com seu integrador para renovar o período de uso."
                            ?
                            null
                            :
                            <form>
                                <Grid container spacing={1}>

                                    <Grid item xs={12} md={6} lg={6}>
                                        <label htmlFor="upload-file">
                                            <input type='file' style={{ display: 'none' }} name="upload-file" id="upload-file" onChange={selectFile} />
                                            <Button color='secondary' variant='contained' component="span">Carregar Arquivo</Button>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Button disabled={disabled} color='secondary' variant='contained' startIcon={<FileUploadIcon />} onClick={submitFile}>Enviar Arquivo</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        }
                    </CardContent>
                </Box>
            </Card>
        </>
    )
}