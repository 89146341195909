import { useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Doughnut } from "react-chartjs-2"
import { Chart, registerables } from "chart.js"
import { Box } from "@mui/material"
import configs from "./configs"
import { green } from '@mui/material/colors'
Chart.register(...registerables)

const useStyles = makeStyles((theme) => {
    return {
        size: {
            [theme.breakpoints.up('sm')]: {
                maxWidth: '35vw'
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '80vw'
            }
        }
    }
})

function DoughnutChart({ chart, color }) {
    const classes = useStyles()
    const theme = useTheme()
    const { data, options } = configs(chart.labels || [], chart.datasets || {})

    return (
       // <div className={classes.size}>
            <Box className={classes.size} sx={{bgcolor: color, margin: 'auto' }}>
                <Doughnut data={data} options={options} />
            </Box>
        // </div>
    )
}



export default DoughnutChart