import React, { useState, useEffect } from "react";
import { Container, Autocomplete, TextField, InputAdornment , MenuItem, List, ListItemIcon, ListItemText, ListItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Person } from "@mui/icons-material";

const useStyles = makeStyles({
    field: {
       // width: 180
    },
    container: {
        //padding: '1rem'
    }
})

export default function ClientList(props) {
    const [list, setList] = useState([])
    const classes = useStyles()

    const handleChange = (event) => {
        if (event.target.value === undefined) {
            props.filter("Todos")
        } else {
            props.filter(String(event.target.innerText).split(" - ")[1])
        }
    }

    useEffect(() => {

        var uniqueList = Array.from(new Set(props.licences.map(item => item.cnpj_cpf)))
            .map(cnpj => {
                return props.licences.find(item => item.cnpj_cpf === cnpj)
            })

        setList(Array.from(new Set(uniqueList.map(licence => licence)))
            .map(licence => {
                return {
                    label: licence.empresa + " - " + licence.cnpj_cpf
                }
            }))
            
    }, [props.licences])

    return (
            <Autocomplete
                key={props.value}
                disablePortal
                id="combo-box-demo"
                options={list}
                sx={{ width: 300 }}
                onChange={(event) => handleChange(event)}
                renderInput={(params) => <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (<InputAdornment><Person color="secondary" /></InputAdornment>)
                    }}
                    label="Filtrar por cliente"
                >
                </TextField>}
            />
    )
}