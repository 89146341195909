import React, { useState, useEffect } from "react"
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { CardActions } from "@mui/material"
import Typography from '@mui/material/Typography'
import makeStyles from "@mui/styles/makeStyles"
import { Box } from "@mui/material"
import { Warning, Check } from "@mui/icons-material"
import VolumeUpIcon from '@mui/icons-material/VolumeUp'


const useStyles = makeStyles((theme) => {
    return {
        alarmCard: {
            height: '30vh',
            display: 'flex',
            flexDirection: 'column'
        },
    }
})

export default function AlarMobileItem({ commandData }) {
    const classes = useStyles()
    const [command, setCommand] = useState({})
    const [commandDescription, setCommandDescription] = useState("")
    const [commandDate, setCommandDate] = useState("")

    useEffect(() => {
        setCommand(commandData)
        setCommandDate(new Date(commandData.dT_ALARME).toLocaleString().replace(',', ''))
    }, [])

    const newColor = () => {
        if (commandData.dS_DESCRICAO_ALARME == "DESL") {
            return "#00B33C"
        }
        if (commandData.dS_DESCRICAO_ALARME == "PANI") {
            return "#F58634"
        }
        if (commandData.dS_DESCRICAO_ALARME == "SILE") {
            return "#FFEA00"
        }
        if (commandData.dS_DESCRICAO_ALARME == "LIGA") {
            return "#CA373C"
        }
    }

    return (
        <div>
            <Card className={classes.alarmCard} elevation={1} >
                <CardHeader
                    avatar={""}
                    action={""}
                    title={"Central"}
                    subheader={<Typography variant="delta2">{command.fK_CENTRAL}</Typography>}
                />
                <CardContent>
                    <Box justifyContent="center" textAlign="center">
                        {command.dS_DESCRICAO_ALARME == "DESL" ? <Check sx={{ color: newColor, fontSize: 40 }} /> : <VolumeUpIcon sx={{ color: newColor, fontSize: 40 }} />}

                        <Typography variant='body1'>{
                            command.dS_DESCRICAO_ALARME == "SILE" ? "SILENCIAR ALARME" : null ||
                                command.dS_DESCRICAO_ALARME == "LIGA" ? "ALARME DE ABANDONO" : null ||
                                    command.dS_DESCRICAO_ALARME == "PANI" ? "ALARME DE BRIGADA" : null ||
                                        command.dS_DESCRICAO_ALARME == "DESL" ? "SISTEMA RESTAURADO" : null
                        }
                        </Typography>
                        <Typography variant='delta2'>
                            Origem: {command.dS_ORIGEM_ALARME}
                        </Typography>
                    </Box>
                </CardContent>
                <CardActions sx={{ marginTop: 'auto' }}>
                    <Box textAlign="center" sx={{ width: "100%" }}>
                        <Typography variant='delta2'>
                            {commandDate}
                        </Typography>
                    </Box>
                </CardActions>
            </Card>
        </div>
    )
}