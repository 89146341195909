import { getDaysInMonth, getDay, getMonth, getYear } from "date-fns"

const months = [
    {value: 1, name: "Janeiro"},
    {value: 2, name: "Fevereiro"},
    {value: 3, name: "Março"},
    {value: 4, name: "Abril"},
    {value: 5, name: "Maio"},
    {value: 6, name: "Junho"},
    {value: 7, name: "Julho"},
    {value: 8, name: "Agosto"},
    {value: 9, name: "Setembro"},
    {value: 10, name: "Outubro"},
    {value: 11, name: "Novembro"},
    {value: 12, name: "Dezembro"},
]

const Calendar = () => {
    var date = Date.now()
    var year = getYear(date)
    var month = getMonth(date)
    var daysInMonth = getDaysInMonth(year, month)
    var labels = []

    for(var x = 1; x <= daysInMonth; x++){
        labels.push(x)
    }

    
    const monthName = months.find(item => item.value === month+1)

    return {
        labels: labels,
        monthName: monthName.name,
        month: month+1,
        year: year
    }
}

export default Calendar