import React, { useState, useEffect } from "react";
import { Container, Autocomplete, Typography, TextField, InputAdornment, ListItemText, ListItemIcon } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Domain } from "@mui/icons-material";

const useStyles = makeStyles({
    field: {
        width: 180
    },
    container: {
        padding: '1rem'
    },
    icon: {

    }
})

export default function ResaleList(props) {
    const [list, setList] = useState([])
    const classes = useStyles()

    const handleChange = (event) => {
        if (event.target.innerText === undefined) {
            props.filterResale("Todos")
        } else {
            props.filterResale(String(event.target.innerText).split(" - ")[1])
        }
    }

    useEffect(() => {
        var uniqueList = Array.from(new Set(props.licences.map(licence => licence.id_revenda)))
            .map(id_revenda => {
                return props.licences.find(item => item.id_revenda === id_revenda)
            })

        setList(Array.from(new Set(uniqueList.map(licence => licence)))
            .map(revenda => {
                return {
                    label: revenda.revenda + " - " + revenda.id_revenda
                }
            }))
    }, [props.licences])

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={list}
            sx={{ width: 300 }}
            onChange={(event) => handleChange(event)}
            renderInput={(params) => <TextField
                {...params}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: (<InputAdornment><Domain color="secondary" /></InputAdornment>)
                }}
                label="Filtrar por revenda"
            >
            </TextField>}
        />
    )
}