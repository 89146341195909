import { useState, useEffect } from "react"
import { InputLabel, OutlinedInput, FormHelperText, Card, CardHeader, CardContent, Tooltip, IconButton } from "@mui/material"
import { cnpjMask } from './CnpjMask'
import { Edit, Save, Apartment } from '@mui/icons-material'
import { makeStyles } from "@mui/styles"
import { useHistory } from "react-router"

const useStyles = makeStyles({
    card: {
        padding: "1rem",
        marginTop: "1rem",
        marginBottom: "1rem"
    }
})

export default function CompanyData(props) {
    const classes = useStyles()
    const [cnpjError, setCnpjError] = useState(false)
    const [cnpjErrorText, setCnpjErrorText] = useState("")
    const [cnpj, setCnpj] = useState("")
    const [edit, setEdit] = useState(false)
    const history = useHistory()

    useEffect(() => {
        setCnpj(props.user.dS_CNPJ)
    }, [])

    const inputChange = (e) => {
        const { name, value } = e.target
        setCnpj(
            value
        )
    }

    const updateCnpj = () => {
        setEdit(false)
        fetch(`${process.env.REACT_APP_URL}/user/cnpj?newCnpj=${cnpj.replace(".", "").replace(".", "").replace("/", "").replace("-", "")}&oldCnpj=${props.user.dS_CNPJ}`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                //"Authorization": "Bearer " + token
              }
        }).then(response => response.json()).then(data => history.push("/"))
    }

    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={
                    <Apartment color="primary" />
                }
                title="Dados da Empresa"
                action={
                    edit ?
                        <Tooltip title="Salvar">
                            <IconButton onClick={() => updateCnpj()}>
                                <Save />
                            </IconButton>
                        </Tooltip>

                        : <Tooltip title="Editar">
                            <IconButton onClick={() => setEdit(!edit)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>

                }
            />

            <CardContent>
                <InputLabel htmlFor="outlined-adornment-password">CNPJ:</InputLabel>
                < br/>
                <OutlinedInput
                    required
                    onChange={inputChange}
                    id="cnpj"
                    value={cnpjMask(cnpj)}
                    error={cnpjError}
                    disabled={!edit}
                    fullWidth={true}
                />
                <FormHelperText id="password">{cnpjErrorText}</FormHelperText>
            </CardContent>
        </Card>
    )
}