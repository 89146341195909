import React from 'react';
import PropTypes from 'prop-types';

const Drawer = ({ children, className = '', drawerId = '',  }) => {
    let header = null, body = null;

    React.Children.forEach(children, child => {
		if (child.type === Drawer.Header) {
			header = child;
		} else if (child.type === Drawer.Body) {
			body = child;
		}
	});

    return (
        <>
            <input type="checkbox" id={drawerId} data-control="drawer"/>
            <div className={`drawer ${className}`}>
                <label for={drawerId} class="drawer__close">
                    <i class="fa fa-times fa-lg" aria-hidden="true"></i>
                </label>
                <div class="drawer__header">
                    {header}
                </div>
                <div class="drawer__content">
                    {body}
                </div>
            </div>
        </>
    );
}

Drawer.propTypes = {
    children: PropTypes.node.isRequired,
};

// Subcomponentes
Drawer.Header = ({ children }) => <>{children}</>;
Drawer.Body = ({ children }) => <>{children}</>;

export default Drawer;