import { useEffect, useState } from 'react'
import getDaysInMonth from "date-fns/getDaysInMonth"

const SetData = (origin, menuItens, month, year, row) => {
    const [menuItens2, setItens2] = useState([{ 'id_central': menuItens }])
    const [requestData, setRequestData] = useState()
    const [daysWithEvents, setDaysWithEvents] = useState()
    const [lastDayWithEvent, setLastDayWithEvent] = useState()
    const [daysList, setDaysList] = useState()
    const [labels, setDaysInMonth] = useState([])
    const [alarm, setAlarm] = useState({})
    const [ADatasetsTemplate, setTemplate] = useState({
        label: "",
        data: [],
        showLine: true,
        borderColor: "",
        backgroundColor: ""
    })

    const [PaniTemplate, setPaniTemplate] = useState({
        label: "",
        data: [],
        showLine: true,
        borderColor: "",
        backgroundColor: ""
    })

    const [SileTemplate, setSileTemplate] = useState({
        label: "",
        data: [],
        showLine: true,
        borderColor: "",
        backgroundColor: ""
    })

    const [LigaTemplate, setLigaTemplate] = useState({
        label: "",
        data: [],
        showLine: true,
        borderColor: "",
        backgroundColor: ""
    })

    const alarmTypes = ["LIGA", "PANI", "DESL", "SILE"]

    const setAlarmTypes = (alarmTypes, requestCopy, totalDaysList) => {
        if (requestCopy != undefined) {
            setSileTemplate({
                label: "CALAR SIRENES",
                data: [],
                showLine: true,
                borderColor: "#FFEA00",
                backgroundColor: "#FFEA00"
            })

            setPaniTemplate({
                label: "BRIGADA",
                data: [],
                showLine: true,
                borderColor: "#ffd600",
                backgroundColor: "#ffd600"
            })

            setLigaTemplate({
                label: "ABANDONO",
                data: [],
                showLine: true,
                borderColor: "#b71c1c",
                backgroundColor: "#b71c1c"
            })

            var pani = {
                label: "BRIGADA",
                data: [],
                showLine: true,
                borderColor: "#ffd600",
                backgroundColor: "#ffd600"
            }

            var sile = {
                label: "CALAR SIRENES",
                data: [],
                showLine: true,
                borderColor: "#FFEA00",
                backgroundColor: "#FFEA00"
            }

            var liga = {
                label: "ABANDONO",
                data: [],
                showLine: true,
                borderColor: "#b71c1c",
                backgroundColor: "#b71c1c"
            }

            alarmTypes.map(alarm => {
                var generalList = []
                var generalDaysList = []
                var generalObject = []

                requestCopy.map(event => {
                    if (event.occurrence == alarm) { generalList.push(event) }
                })

                generalDaysList = [...new Set(generalList.map(item => item.day))]
                var generalLastDay = Math.max(...generalDaysList)

                generalDaysList.map(day => generalObject.push({ day: day, occurrences: 0 }))
                generalObject.map(item => generalList.filter(requestItem => requestItem.day == item.day ? item.occurrences = item.occurrences + 1 : 0))


                if (generalObject.length > 0) {
                    for (var x = 1; x <= totalDaysList; x++) {
                        if (!(x > generalLastDay)) {
                            if (generalDaysList.includes(x)) {
                                generalObject.map(item => {
                                    if (item.day == x) {
                                        if (alarm == "LIGA") {
                                            liga.data.push(item.occurrences)
                                        }
                                        if (alarm == "SILE") {
                                            sile.data.push(item.occurrences)
                                        }
                                        if (alarm == "PANI") {
                                            pani.data.push(item.occurrences)
                                        }
                                    }
                                })
                            } else {
                                if (alarm == "LIGA") {
                                    liga.data.push(0)
                                }
                                if (alarm == "SILE") {
                                    sile.data.push(0)
                                }
                                if (alarm == "PANI") {
                                    pani.data.push(0)
                                }
                            }
                        }
                    }
                }
            })
            return [liga, sile, pani]
        }
    }

    useEffect(() => {
        if (row != null && requestData !== undefined) {
            if (row.fK_OCORRENCIA != "DESL" && row.type === "alarme") {
                var date = (row.dT_EVENTO.split("T"))[0]
                var day = (date.split("-"))[2]
                var month = (date.split("-"))[1]
                var year = (date.split("-"))[0]

                var alarmTemplate = {
                    central: row.fK_CENTRAL,
                    day: parseInt(day),
                    month: parseInt(month),
                    year: parseInt(year),
                    occurrence: row.fK_OCORRENCIA
                }

                requestData.push(alarmTemplate)

                setTemplate({
                    label: "",
                    data: [],
                    showLine: true,
                    borderColor: "",
                    backgroundColor: ""
                })

                // cria lista contendo apenas seire das centrais
                var menuList = []
                origin == "client" ? menuList = menuItens2 : menuList = menuItens

                var menuItensList = menuList.map(item => item.id_central)

                //cria lista contendo eventos que estão contidos na lista de series de central
                var requestCopy = requestData.filter(item => {
                    return menuItensList.includes(item.central) ? item : null
                })

                // cria objeto separando alarmes por tipo
                var alarmTypesList = setAlarmTypes(alarmTypes, requestCopy, daysList)

                var daysObject = []

                daysWithEvents.map(day => daysObject.push({ day: day, occurrences: 0 }))

                //cria objeto com os dias e os events ocorridos no dia
                daysObject.map(item => requestCopy.filter(requestItem => requestItem.day == item.day ? item.occurrences = item.occurrences + 1 : 0))

                if (daysObject.length > 0) {

                    ADatasetsTemplate.label = "ALARMES"

                    // para cada dia verifica se o dia possui evento e alimenta o dia com o total de eventos
                    for (var x = 1; x <= daysList; x++) {
                        if (!(x > lastDayWithEvent)) {
                            if (daysWithEvents.includes(x)) {
                                daysObject.map(item => {
                                    if (item.day == x) {
                                        ADatasetsTemplate.data.push(item.occurrences)
                                    }
                                })
                            } else {
                                ADatasetsTemplate.data.push(0)
                            }
                        }
                    }

                    setAlarm({
                        labels: labels,
                        datasets: ADatasetsTemplate,
                        general: alarmTypesList,
                    })
                }
            }
        }
    }, [row])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/command/relatory/all?occurrence=`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Expose-Headers': '*',
                // 'Authorization': 'Bearer ' + props.token
            }
        })
            .then(response => response.json())
            .then(events => {
                if (events != undefined) {
                    setRequestData(events)
                    // lista com os dias que possuem evento
                    var daysWithE = [...new Set(events.map(item => item.day))]
                    setDaysWithEvents(Array.from(daysWithE))

                    // variável contendo o último dia com evento
                    var lastDay = Math.max(...daysWithE)
                    setLastDayWithEvent(Math.max(...daysWithE))

                    // variavel contendo o total de dias no mês
                    var totalDays = getDaysInMonth(year, month)
                    setDaysList(getDaysInMonth(year, month))

                    setTemplate({
                        label: "",
                        data: [],
                        showLine: true,
                        borderColor: "",
                        backgroundColor: ""
                    })

                    // cria lista contendo apenas seire das centrais
                    var menuList = []
                    origin == "client" ? menuList = menuItens2 : menuList = menuItens

                    var menuItensList = menuList.map(item => item.id_central)

                    //cria lista contendo eventos que estão contidos na lista de series de central
                    var requestCopy = events.filter(item => {
                        return menuItensList.includes(item.central) ? item : null
                    })

                    // cria objeto separando alarmes por tipo
                    var alarmTypesList = setAlarmTypes(alarmTypes, requestCopy, totalDays)

                    var daysObject = []

                    daysWithE.map(day => daysObject.push({ day: day, occurrences: 0 }))

                    //cria objeto com os dias e os events ocorridos no dia
                    daysObject.map(item => requestCopy.filter(requestItem => requestItem.day == item.day ? item.occurrences = item.occurrences + 1 : 0))

                    if (daysObject.length > 0) {

                        ADatasetsTemplate.label = "ALARMES"

                        // para cada dia verifica se o dia possui evento e alimenta o dia com o total de eventos
                        for (var x = 1; x <= totalDays; x++) {
                            if (!(x > lastDay)) {
                                if (daysWithE.includes(x)) {
                                    daysObject.map(item => {
                                        if (item.day == x) {
                                            ADatasetsTemplate.data.push(item.occurrences)
                                        }
                                    })
                                } else {
                                    ADatasetsTemplate.data.push(0)
                                }
                            }
                        }

                        setAlarm({
                            labels: labels,
                            datasets: ADatasetsTemplate,
                            general: alarmTypesList,
                        })
                    }
                }
            })
    }, [menuItens])

    return {
        alarm: alarm
    }
}

export default SetData
