import { useState, useEffect } from "react"
import validator from 'validator'
import { Container, Checkbox, Grid, Box, FormControlLabel, Button, OutlinedInput, InputAdornment, FormHelperText, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { Email } from "@mui/icons-material"

const useStyles = makeStyles({
    key: {
        width: "25rem"
    },
    grid: {
        marginTop: "20px"
    }
})

export default function SelectUser(props) {
    const classes = useStyles()
    const [userCheckList, setUserCheckList] = useState([])
    const [counter, setCounter] = useState(1)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
    }, [props.refreshToken])

    useEffect(() => {
        setCounter(1)
    }, [props.edit])

    useEffect(() => {
        var tempList = [];

        var mainUser = props.userList.filter(user => user.role.notEditable == true)
        var otherUser = props.userList.filter(user => user.role.notEditable == false)

        var userList = mainUser.concat(otherUser)

        userList.map((user, i) => {
            if (user.email == null) {
                tempList.push(
                    {
                        "id": user.id,
                        "name": user.dS_NOME,
                        "email": "",
                        "model": user,
                        "position": i,
                        "checked": user.role.notEditable || userList.length == 1 ? true : false,
                        "disabled": user.role.notEditable || userList.length == 1 ? true : false,
                        "emailError": true,
                        "emailErrorText": "insira um email válido"
                    }
                )
            }
        })

        //Caso existe apenas um usuário na lista, ele é selecionado automaticamente
        userList.length == 1 ? setCounter(1) : setCounter(0)
        setUserCheckList(tempList)
    }, [])


    const handleChange = (user) => {
        if (counter <= props.webUsers - 2) {
            var newArr = [...userCheckList]
            newArr[user.position].checked = !newArr[user.position].checked
            setUserCheckList(newArr)

            if (newArr[user.position].checked) {
                let add = counter + 1
                setCounter(add)
            } else {
                let add = counter - 1
                setCounter(add)
            }
        }
    }

    const validateEmail = (e, user) => {
        if (validator.isEmail(e)) {
            console.log("EMAIL VÁLIDO");
            let newArr = [...userCheckList]
            newArr[user.position].emailError = false
            newArr[user.position].emailErrorText = ""
            newArr[user.position].email = e
            setUserCheckList(newArr)
        } else {
            console.log("ERRO no EMAIL");
            let newArr = [...userCheckList]
            newArr[user.position].emailError = true
            newArr[user.position].emailErrorText = "insira um email válido"
            setUserCheckList(newArr)
        }
    }

    const returnUserList = () => {
        var errorList = userCheckList.find(user => user.checked === true && (user.emailError === true || user.email === ""))

        if (errorList === undefined) {
            setIsError(false)
            var userList = userCheckList.filter(user => user.checked === true && user.email !== "")
            props.receiveCheckedUserList(userList)
        } else {
            setIsError(true)
        }
    }

    return (
        <Container>
            {userCheckList.length > 0 ?
                <>
                    <Typography variant="h6">Sua licença permite a ativação de até {props.webUsers} usuários no sistema Web.</Typography>
                    <Typography variant="body2">Você deve fornecer o email de cada usuário que selecionar.</Typography>
                    {isError ?
                        <Typography variant="body1" color="error">* Preencha corretamente os campos abaixo.</Typography>
                        : null}
                    <Grid sx={{ mt: "1rem" }} justifyContent="space-around" container spacing={3}>
                        {userCheckList.map((user, i) => (
                            <Grid alignItems="left" key={i} item xs={12} md={12} lg={12}>
                                <Box sx={{ display: "flex", flexDirection: "row", my: "auto" }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox sx={{ minWidth: "5vw" }} name={user.name} disabled={user.disabled} checked={user.checked} onChange={() => handleChange(user)} inputProps={{ 'aria-label': 'controled ' }} />
                                        }
                                        label={user.name}
                                    />
                                    {user.checked ?
                                        <div sx={{ flexDirection: "column" }}>
                                            <OutlinedInput
                                                className={classes.key}
                                                id="email-field"
                                                onChange={(e) => validateEmail(e.target.value, user)}
                                                variant="outlined"
                                                color="secondary"
                                                required
                                                type="email"
                                                placeholder="Insira um email válido para este usuário"
                                                error={user.emailError}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Email />
                                                    </InputAdornment>
                                                }
                                            ></OutlinedInput>
                                            <FormHelperText id="email-field">{user.emailErrorText}</FormHelperText>
                                        </div>
                                        : null}
                                </Box>
                            </Grid>
                        ))}

                        {counter >= 0 ?
                            <Grid item xs={12} md={12} lg={12} >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth={true}
                                    onClick={() => (returnUserList())}
                                >Ativar Licença</Button>
                            </Grid>
                            : null}
                    </Grid>
                </>
                : <Typography variant="h6">Nenhum usuário disponível. Cadastre um usuário para prosseguir.</Typography>}
        </Container>
    )
}