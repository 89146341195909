import React, { useState, useEffect } from 'react'
import { Container, Grid, Button, Box, TextField, Typography, Autocomplete, Stack } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import AmplifierList from '../components/AmplifierList'
import { styled } from '@mui/material/styles'
import { Router } from '@mui/icons-material'
import RelatoryNameField from '../components/RelatoryNameField'
import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'
import Pagination from '@mui/material/Pagination'
import AmplifierStatusList from '../components/AmplifierStatusList'
import Calendar from '../components/Calendar'
import PdfViewer from '../providers/PdfViewer'
import Drawer from '../includes/Drawer'
import { ReactComponent as IconPDF } from '../assets/icons/icon_file_pdf.svg'

const useStyles = makeStyles({
    pageAmplifierRelatory: {
        minHeight: "100vh",
        paddingTop: "4rem"
    },
    buttonRegisterRelatory: {
        marginTop: '3rem',
        marginBottom: '3rem'
    },
})

const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 15,
    width: '100%'
}));

const ItemHeader = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    height: '50px',
    width: '100%'
}));

export default function AmplifierStatusRelatory(props) {
    const classes = useStyles()
    const [nameList, setNameList] = useState([])
    const [status, setStatus] = useState([])
    const [ds, setDsList] = useState(["Todos"])
    const [groupList, setGroupList] = useState([])
    const [amplifiers, setAmplifiers] = useState([])
    const [result, setResult] = useState([])
    const [localList, setLocalList] = useState(["Todos"])
    const [amplifierId, setAmplifirId] = useState("")
    const [description, setDescription] = useState("")
    const [local, setLocal] = useState("")
    const [amplifierStatus, setAmplifierStatus] = useState(false)
    const [relatoryName, setRelatoryName] = useState("")
    const [menuItens, setMenuItens] = useState("")
    const [firstDate, setFirstDate] = useState(format(new Date(), 'MM/dd/yyyy'), { locale: ptBR })
    const [secondDate, setSecondtDate] = useState(format(new Date(), 'MM/dd/yyyy'), { locale: ptBR })
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState({})
    const [show, setShow] = useState(false);

    const changeRelatoryName = (newName) => {
        setRelatoryName(newName)
    }

    const getAll = async () => {
        props.setIsLoading(true);

        var response = await fetch(`${process.env.REACT_APP_URL}/amplifier/all`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify({
                "serialNumber": props.serial,
            })
        })

        var data = await response.json()

        setAmplifiers(data)

        var uniqueNames = Array.from(new Set(data.map(amplifier => amplifier.dS_NOME)))
            .map(dS_NOME => {
                return {
                    label: dS_NOME === null ? "Sem nome registrado" : dS_NOME
                }
            })

        setNameList(uniqueNames)

        //var uniqueGroup = Array.from(new Set(data.map(amplifier => amplifier.fK_VL_CADASTR_GRUPO)))
        //    .map(fK_VL_CADASTR_GRUPO => {
        //        return {
        //            label: fK_VL_CADASTR_GRUPO
        //        }
        //    })

        var uniqueGroup = Array.from(new Set(data.map(amplifier => amplifier.fK_VL_CADASTR_GRUPO)))
            .map(fK_VL_CADASTR_GRUPO => {
                // Encontra o primeiro objeto que corresponde ao fK_VL_CADASTR_GRUPO atual
                let amplifier = data.find(amplifier => amplifier.fK_VL_CADASTR_GRUPO === fK_VL_CADASTR_GRUPO);
                return {
                    label: `${fK_VL_CADASTR_GRUPO.substring(12)} - ${amplifier.dS_LOCALIZACAO}`
                }
            });

        uniqueGroup.unshift("Todos")

        setGroupList(uniqueGroup)

        var dressLow = Array.from(new Set(data.map(amplifier => amplifier.dS_DL)))
            .map(dS_DL => {
                return {
                    label: dS_DL.toUpperCase()
                }
            })

        setDsList(ds.concat(dressLow))

        var status = [
            {
                label: "online"
            },
            {
                label: "offline"
            }
        ]

        setStatus(status)

        var location = Array.from(new Set(data.map(amplifier => amplifier.dS_LOCALIZACAO)))
            .map((dS_LOCALIZACAO => {
                return {
                    label: dS_LOCALIZACAO === '' ? "Sem nome registrado" : dS_LOCALIZACAO
                }
            }))

        

        setLocalList(localList.concat(location))

        props.setIsLoading(false);
    }

    const gerarPdf = () => {

        if (local === '') {
            setLocal('Todos');
        }

        let actionUrl = `${process.env.REACT_APP_URL}/relatory/amplifier/status?pageNumber=1&pageSize=100&firstDate=${firstDate}&secondDate=${secondDate}&menuItens=${menuItens}&user=${props.user.dS_NOME}${amplifierId === 'Todos' ? '' : '&dsDL=' + amplifierId}${local === 'Todos' ? '' : '&local=' + local}`;

        if (process.env.REACT_APP_MODE === "desktop") {
            PdfViewer.Download(actionUrl, "GET", props.token, "relatorio-status-amplificadores", props.setIsLoading);
        } else {
            PdfViewer.PrintAsync(actionUrl, "GET", props.token, props.setIsLoading);
        }
    }

    const request = async () => {
        props.setIsLoading(true);

        if (local === '') {
            setLocal('Todos');
        }
        
        fetch(`${process.env.REACT_APP_URL}/amplifier/status/relatory?pageNumber=1&pageSize=100&firstDate=${firstDate}&secondDate=${secondDate}&menuItens=${menuItens}&name${relatoryName}${amplifierId === 'Todos' ? '' : '&dsDL=' + amplifierId}${local === 'Todos' ? '' : '&local=' + local}`,
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Expose-Headers': '*',
                    "Authorization": "Bearer " + props.token
                }
            })
            .then(response => (
                setPagination(JSON.parse(response.headers.get("X-Pagination"))),
                response.json()
            ))
            .then(data => {
                setShow(true);
                setResult({})
                setResult(data)
                props.setIsLoading(false);
            })
    }

    const pageChange = (event, value) => {
        props.setIsLoading(true);
        setPage(value)

        fetch(`${process.env.REACT_APP_URL}/amplifier/status/relatory?pageNumber=${value}&pageSize=100&firstDate=${firstDate}&secondDate=${secondDate}&menuItens=${menuItens}${ds === '' ? '' : '&dsDL=' + amplifierId}${local === '' ? '' : '&local=' + local}`,
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Expose-Headers': '*',
                    "Authorization": "Bearer " + props.token
                }
            })
            .then(response => (
                setPagination(JSON.parse(response.headers.get("X-Pagination"))),
                response.json()
            ))
            .then(data => {
                setShow(true);
                setResult({})
                setResult(data)
                props.setIsLoading(false);
            })
    }

    useEffect(() => {
        setResult([])
        if (props.serial === undefined) {
            setMenuItens(Array.from(new Set(
                props.menuItens.map(item => {
                    return item.id_central;
                })
            )))
        } else {
            setMenuItens(props.serial)
        }
    }, [props.menuItens])

    useEffect(() => {
        getAll()
    }, [])
    return (
        <div class="container">
            <center>
                <h3 class="mb-5">Relatório da Situação dos Amplificadores</h3>
            </center>

            <div class="filter">
                {/* <div class="filter__column filter__column--fw">
                    <RelatoryNameField changeRelatoryName={changeRelatoryName} />
                </div> */}
                <div class="filter__column">
                    <Calendar label={"Data Inicial"} setDate={setFirstDate} />
                </div>
                <div class="filter__column">
                    <Calendar label={"Data Final"} setDate={setSecondtDate} />
                </div>
                <div class="filter__column">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={groupList}
                        defaultValue={"Todos"}
                        sx={{ width: "100%", mx: 'auto' }}
                        onChange={(event) => setLocal(event.target.innerText === undefined ? "" : event.target.innerText)}
                        renderInput={(params) => <TextField {...params} label="Grupo" />}
                    />
                </div>
                <div class="filter__column">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={ds}
                        defaultValue={"Todos"}
                        sx={{ width: "100%", mx: 'auto' }}
                        onChange={(event) => setAmplifirId(event.target.innerText === undefined ? "" : event.target.innerText)}
                        renderInput={(params) => <TextField {...params} label="ID" />}
                    />
                </div>
            </div>

            <div class="buttons-container buttons-container--right mb-5">
                <button type="button" class="button button--md button--primary" title="Buscar" onClick={request} disabled={props.isLoading}>
                    <span class="button__label">Buscar</span>
                </button>
                <button type="button" class="button button--transparent" title="Gerar PDF" onClick={gerarPdf} disabled={props.isLoading}>
                    <IconPDF className="button__icon df-i df-i-red df-i-2xl"/>
                </button>
            </div>

            {!!show &&
                <>
                    <Stack alignItems="center" spacing={3}>
                        <ItemHeader style={{"display": "flex", "alignItems": "center", "justifyContent": "center"}}>
                            <Grid alignItems="center" container spacing={3} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "marginTop": 0,"marginLeft": 0}}>
                                <Grid item key={2} xs={2} md={2} lg={2} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                                    <Typography variant="delta">I.D.</Typography>
                                </Grid>

                                <Grid item key={3} xs={2} md={2} lg={2} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                                    <Typography variant="delta">Local</Typography>
                                </Grid>

                                <Grid item key={4} xs={2} md={2} lg={2} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                                    <Typography variant="delta">Grupo</Typography>
                                </Grid>

                                <Grid item key={5} xs={3} md={3} lg={3} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                                    <Typography variant="delta">Status</Typography>
                                </Grid>

                                <Grid item key={6} xs={3} md={3} lg={3} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                                    <Typography variant="delta">Data</Typography>
                                </Grid>
                            </Grid>
                        </ItemHeader>
                        {result.length > 0 ? result.map(row => {
                            return <Item item key={row.id} xs={12} md={12} lg={12} sx={{ backgroundColor: '#FFFFFFF' }}> <AmplifierStatusList key={row.dS_NUM_SERIE} amplifier={row} status={status} /> </Item>
                        }) : <Typography>Nenhum resultado encontrado!</Typography>}
                    </Stack>
                    <Container sx={{ p: 2 }}>
                        <Pagination
                            count={pagination.TotalPages}
                            onChange={pageChange}
                            page={page}
                        />
                    </Container>
                </>
            }
            {/* <Drawer drawerId='amplifier-status-relatory-pdf' className='drawer--bottom-full'>
                <Drawer.Header>
                    <h4>Relatório para impressão</h4>
                </Drawer.Header>
                <Drawer.Body></Drawer.Body>
            </Drawer> */}
        </div>
    )
}