import React, { useState, useEffect } from "react"
import { Router } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import makeStyles from "@mui/styles/makeStyles"
import { Grid } from "@mui/material"

const useStyles = makeStyles({

    grid: {
        maxWidth: "100%"
    },
    textField: {
        width: '15vw'
    }
})

export default function PanelStatusList(props) {
    const [panel, setPanel] = useState({})

    useEffect(() => {
        if (typeof props.row !== null) {
            setPanel(props.row)
        }
    }, [panel])

    return (
        <div>
            <Grid alignItems="center" container spacing={3} style={{"width": "100%", "display": "flex", "alignItems": "center", "justifyContent": "center", "marginTop": 0,"marginLeft": 0}}>
                {/* <Grid item key={1} xs={1} md={1} lg={1}>
                    <Router />
                </Grid> */}
                <Grid item key={2} xs={4} md={4} lg={4} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                    {typeof panel !== 'undefined' ? <Typography variant="delta">{panel.centralSerial}</Typography> : null}
                </Grid>

                <Grid item key={3} xs={4} md={4} lg={4} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                    {typeof panel !== 'undefined' ? <Typography variant="delta">{panel.status}</Typography> : null}
                </Grid>

                <Grid item key={4} xs={4} md={4} lg={4} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                    {typeof panel !== 'undefined' ? <Typography variant="delta">{new Date(panel.date).toLocaleString().replace(",", "")}</Typography> : null}
                </Grid>
            </Grid>
        </div>
    )
}