import React, { useState, useEffect, useCallback } from 'react'
import { Grid } from '@mui/material'
import { Container } from '@mui/material'
import { Box } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles"
import { Button } from '@mui/material'
import { TextField } from '@mui/material'
import { Typography } from '@mui/material'
import { MenuItem } from '@mui/material'
import { styled } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import IconReset from '../assets/icons/icon_reset.png'
import IconBrigade from '../assets/icons/icon_fireman.png'
import IconAbandon from '../assets/icons/icon_abandon.png'
import IconSilence from '../assets/icons/icon_silence.png'

const useStyles = makeStyles((theme) => {
    return {
        containerGenerateCommand: {
            //padding: '1rem'
        },
        buttonGenerateCommand: {
            [theme.breakpoints.up('sm')]: {
                fullWidth: true,
                width: "100%",
                height: 100,
                backgroundColor: '#000000'
            },
            [theme.breakpoints.down('sm')]: {
                width: "100%",
                height: 100,
                backgroundColor: '#000000'
            }
        },
        boxGenerateCommand: {
            padding: '1rem'
        },
        dialogGenerateCommand: {
            backgroundColor: '#ef5350'
        },
        dialogTitleGenerateCommand: {
            backgroundColor: '#FFF',
            padding: '2rem',
            textAlign: 'center'
        },
        buttonClose: {
            backgroundColor: '#e68a00',
            textAlign: 'center',
            fullWidth: 'true',
            width: '100%'
        }
    }
})

const defaultGroup = {
    vL_CADASTRO: 0,
    dS_LOCALIZACAO: "Geral"
}

const AlarmButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        backgroundColor: '#FF3B30',
        width: '25vw',
        height: '20vh',
        borderRadius: 100,
        color: '#000',
        '&:hover': {
            backgroundColor: '#a12b2f'
        }
    },
    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#34C759',
        width: '35vw',
        height: '20vh',
        borderRadius: 100,
        color: '#000',
        '&:hover': {
            backgroundColor: '#a12b2f'
        }
    }
}))

const ResetAlarmButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        backgroundColor: '#34C759',
        width: '25vw',
        height: '20vh',
        borderRadius: 100,
        color: '#000',
        '&:hover': {
            backgroundColor: '#2e7d32'
        }
    },
    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#00e676',
        width: '35vw',
        height: '20vh',
        borderRadius: 100,
        color: '#000',
        '&:hover': {
            backgroundColor: '#2e7d32'
        }
    }
}))

const AlarmTypeButton = styled(Button)(({ theme }) => ({

    [theme.breakpoints.up('sm')]: {
        backgroundColor: '#FF3B30',
        width: 265,
        height: 265,
        borderRadius: 30,
        '&:hover': {
            backgroundColor: '#a12b2f'
        }
    },
    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FF3B30', //#F58634
        width: 250,
        height: 200,
        borderRadius: 30,
        '&:hover': {
            backgroundColor: '#a12b2f' //#CA373C
        }
    }
}))

const ResetTypeButton = styled(Button)(({ theme }) => ({

    [theme.breakpoints.up('sm')]: {
        backgroundColor: '#34C759',
        width: 265,
        height: 265,
        borderRadius: 30,
        '&:hover': {
            backgroundColor: '#2e7d32'
        }
    },
    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#34C759', //#F58634
        width: 250,
        height: 200,
        borderRadius: 30,
        '&:hover': {
            backgroundColor: '#2e7d32' //#CA373C
        }
    }
}))

export default function GenerateCommand(props) {
    const [currency, setCurrency] = useState("Geral")
    const [actions, setActions] = useState([])
    const [icons, setIcons] = useState([])
    const [groups, setGroups] = useState(props.groupList)
    const [open, setOpen] = useState(false)
    const [buttonName, setButtonName] = useState("")
    const [groupValue, setGroupValue] = useState("")
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [user, setUser] = useState("");
    const classes = useStyles()

    const handleChange = (event) => {
        var group = props.serialNumber + '0'
        var stringArray = event.target.value.split(" valor ")

        if (event.target.value !== "Geral") {
            group = groups.find(group => group.vL_CADASTRO.slice(12) === stringArray[1]).vL_CADASTRO
        }

        setCurrency(event.target.value)
        setGroupValue(group)
    }

    const handleOpen = (action) => {        
        setButtonName(action)
        setOpen(!open)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const disableAlarmCss = useCallback(() => {
        console.dir(`🚀 ~ COMMANDS ~ disableAlarmCss ~ Controller: Current => ${props.occurrenceController.current} | Last => ${props.occurrenceController.last.dS_DESCRICAO_ALARME}`);
        
        let btns = document.querySelectorAll('[data-alarm-type]');
        
        btns.forEach(btn => {
            if (props.occurrenceController.current === props.occurrenceController.last.fK_OCORRENCIA || props.occurrenceController.current === props.occurrenceController.last.dS_DESCRICAO_ALARME)
            {
                if (btn.classList.contains('command--loading'))
                {
                    btn.classList.remove('command--loading');
                }

                if(btn.classList.contains('button--block'))
                {
                    btn.classList.remove('button--block');
                }
                if(btn.classList.contains('command--block'))
                {
                    btn.classList.remove('command--block');
                }
            }
            
            if (btn.classList.contains('command--triggered'))
            {
                btn.classList.remove('command--triggered');
            }
        });
    },[]);

    const triggerAlarmCss = (type) => {
        let btns = document.querySelectorAll(`[data-alarm-type="${type}"]`);
        console.log("triggerAlarmCss", btns);
        btns.forEach(btn => {
            if(!btn.classList.contains('command--triggered'))
            {
                btn.classList.add('command--triggered');
                btn.classList.remove('command--loading');
            }

            if(btn.classList.contains('button--block'))
            {
                btn.classList.remove('button--block');
            }

            if(btn.classList.contains('command--block'))
            {
                btn.classList.remove('command--block');
            }
        });
    }

    const SendAlarm = async (action, posGroup) => {
        //document.querySelector(`[data-alarm-type="${action}"]`).classList.add('command--loading');
        
        let btns = document.querySelectorAll(`[data-action="command"]`);

        btns.forEach(btn => {
            if (!!btn.dataset.alarmType)
            {
                if (btn.dataset.alarmType === action)
                {
                    btn.classList.add('command--loading');
                }
                else
                {
                    if (!btn.classList.contains('command--triggered'))
                    {
                        btn.classList.add('command--block');
                    }
                }
            }
            else
            {
                if (btn.title === action)
                {
                    btn.classList.add('button--loading');
                }
                else
                {
                    if (!btn.classList.contains('button--triggered'))
                    {
                        btn.classList.add('button--block');
                    }
                }
            }
        });

        let group = {
            position: !!posGroup ? posGroup : props.serialNumber + "0",
            name: !!posGroup ? currency.split(" valor ")[0] : "Geral", 
        } 

        let generatedCommandGroup = typeof groupValue === 'undefined' || groupValue === "" ? 'general' : groupValue.slice(12)
        let occurrence = props.occurrenceController;
        
        switch (action) {
            case 'Restaurar Sistema':
                occurrence.current = "DESL";
                occurrence.triggeredAt = (new Date()).toLocaleString().slice(0, 20).replace(/-/g, "/").replace("T", " ").replace(",","")
                props.setOccurrenceController(occurrence);

                let actionButton = document.querySelector('.header-action[data-action="system-alerts"]');

                if(actionButton.classList.contains("header-action--warning"))
                {
                    actionButton.classList.remove("header-action--warning");
                    actionButton.classList.remove("header-action--animation");
                }
                break;
            case 'Alarme de Abandono':
                occurrence.current = "LIGA";
                occurrence.triggeredAt = (new Date()).toLocaleString().slice(0, 20).replace(/-/g, "/").replace("T", " ").replace(",","")
                props.setOccurrenceController(occurrence);
                break;
            case 'Alarme de Brigada':
                occurrence.current = "PANI";
                occurrence.triggeredAt = (new Date()).toLocaleString().slice(0, 20).replace(/-/g, "/").replace("T", " ").replace(",","")
                props.setOccurrenceController(occurrence);
            break;
            case 'Silenciar Alarme':
                occurrence.current = "SILE";
                occurrence.triggeredAt = (new Date()).toLocaleString().slice(0, 20).replace(/-/g, "/").replace("T", " ").replace(",","")
                props.setOccurrenceController(occurrence);
                break;
            default:
                break;
        }
        
        props.generatedCommand(
            {
                "groupValue": posGroup,
                "type": occurrence.current
            }
        )
            
        console.log("🚀 ~ COMMANDS ~ sendAlarm ~ sendding! User =>", props.companyId)
        setOpen(!open)

        try
        {
            const sender = await fetch(`${process.env.REACT_APP_URL}/command/send`, {
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + props.token
                },
                body: JSON.stringify({
                    "userId": props.companyId,
                    "groupValue": group.position,
                    "groupName": group.name,
                    "command": occurrence.current,
                    "origin": "guardiao"
                })
            });

            if(!sender.ok)
            {
                throw new Error('Erro ao ENVIAR COMANDO');
            }

            const response = await sender.json();

            if (!!response && !!response.id)
            {
                CheckEventReceived(response);
            }
        }
        catch (error)
        {
            console.log("🚀 ~ sendAlarm ~ error:", error)
        }
        finally
        {
            setTimeout(GetLastAlarm,2000);
        }
    }
       
    const GetLastAlarm = useCallback(async () => {
        let isError = false;
        
        try
        {
            if (!!sessionStorage.getItem("serial"))
            {
                const response = await fetch(`${process.env.REACT_APP_URL}/command/last?serialNumber=${sessionStorage.getItem("serial")}&dateFilter=false`, { 
                    method: 'GET' ,
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer " + props.token
                    }
                });
    
                if (!response.ok)
                {
                    throw new Error('Não foi possível obter o último alarme');
                }
    
                const data = await response.json();
    
                if (!!data && !!data.id)
                {
                    console.log("🚀 ~ GetLastAlarm ~ RESPONSE:", data)
                    let occurrence = props.occurrenceController;
    
                    occurrence.last = data;
                    occurrence.triggeredIn = data.dT_EVENTO;
    
                    props.setOccurrenceController(occurrence);
                }
            }
            else
            {
                throw new Error("O Serial não foi informado. Nova tentativa será realizada!");
            }

        }
        catch (error)
        {
            console.log("🚀 ~ GetLastAlarm ~ error:", error)
            isError = true;
        }
        finally 
        {
            if (!!isError)
            {
                setTimeout(GetLastAlarm,5000);
            }
        }
    },[]);

    const CheckEventReceived = async (eventData) => {
        try 
        {
            const receiver = await fetch(`${process.env.REACT_APP_API_URL}/events/receive`, {
                method: 'POST',
                headers: { 
                    "content-type": "application/json", 
                    "Authorization": "Bearer " + props.token
                },
                body: JSON.stringify({
                    "id": eventData.id,
                    "type": "alarme",
                    "fK_OCORRENCIA": eventData.dS_DESCRICAO_ALARME,
                    "dS_LOCAL_EVENTO": eventData.dS_LOCAL_ALARME,
                    "dS_ORIGEM_ALARME": eventData.dS_ORIGEM_ALARME,
                    "fK_CENTRAL": eventData.fK_CENTRAL,
                    "fK_GRUPO": eventData.fK_GRUPO,
                    "dT_EVENTO": eventData.dT_ALARME
                })
            });

            if (!receiver.ok) 
            {
                throw new Error('Erro ao Receber resposta de registro de evento');
            }
        }
        catch (error)
        {
            console.log("🚀 ~ sendAlarm ~ error Receiver:", error)
        }
    } 

    useEffect(() => {
        if (!!props.occurrenceController.last)
        {
            console.log("🚀 ~ checkLastAlarm ~ lastAlarm:", props.occurrenceController.last, Date(Date.now()));
            
            let occurrence = !!props.occurrenceController.last.fK_OCORRENCIA ? props.occurrenceController.last.fK_OCORRENCIA : props.occurrenceController.last.dS_DESCRICAO_ALARME;
            
            //console.log("🚀 ~ useEffect ~ occurrence:", occurrence)
            
            switch(occurrence)
            {
                case "DESL":
                    disableAlarmCss();
                    break;
                case "LIGA":
                    disableAlarmCss();
                    triggerAlarmCss("Alarme de Abandono");
                    break;
                case "PANI":
                    disableAlarmCss();
                    triggerAlarmCss("Alarme de Brigada");
                    break;
                case "SILE":
                    disableAlarmCss();
                    triggerAlarmCss("Silenciar Alarme");
                    break;
                default:
                    break;
            }
        }
        else 
        {
            console.log("🚀 ~ checkLastAlarm ~ lastAlarm: NADA");
        }
    }, [props.occurrenceController.last, disableAlarmCss]);

    useEffect(() => {
        setUser(props.user);
        setGroups(props.groupList)
        
        if (props.user.role.alarmes === true) {
            setActions(["Alarme de Abandono", "Alarme de Brigada", "Silenciar Alarme", "Restaurar Sistema"])
            setIcons([
                {"Image": IconAbandon, "Alt": "Ícone Alarme de Abandono", "Color": "#FF3B30"},
                {"Image": IconBrigade, "Alt": "Ícone Alarme de Brigada", "Color": "#FF3B30" },
                {"Image": IconSilence, "Alt": "Ícone Silenciar Alarme", "Color": "#FF3B30" },
                {"Image": IconReset, "Alt": "Ícone Restaurar Sistema", "Color": "#34C759" },
            ]);
        }
        else 
        {
            setActions(["Restaurar Sistema"])
            setIcons([{"Image": IconReset, "Alt": "Ícone Restaurar Sistema"}]);
        }
        
    }, [props.groupList, props.user]);

    useEffect(GetLastAlarm,[]);

    return (
        <Container className={classes.containerGenerateCommand}>
            <Box textAlign="center" className={classes.boxGenerateCommand}>
                <Typography variant="h5" color="textSecondary" width="100%">
                    Selecione um Grupo para Enviar o Comando:
                </Typography>

                <br />

                <TextField
                    className='mb-5'
                    fullWidth={true}
                    select
                    value={currency}
                    onChange={handleChange}>

                    <MenuItem key={defaultGroup.vL_CADASTRO} value={defaultGroup.dS_LOCALIZACAO}>
                        {defaultGroup.dS_LOCALIZACAO}
                    </MenuItem>

                    {groups.map((option) => (
                        <MenuItem key={option.vL_CADASTRO} value={option.dS_LOCALIZACAO === "" ? "Sem Nome valor " + option.vL_CADASTRO.slice(12) : option.dS_LOCALIZACAO + " valor " + option.vL_CADASTRO.slice(12)}>
                            {option.dS_LOCALIZACAO === "" ? option.vL_CADASTRO.slice(12) + " - Sem Nome Regist." : option.vL_CADASTRO.slice(12) + " - " + option.dS_LOCALIZACAO}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>

            <Grid container spacing={3} justifyContent="center">
                {
                    actions.length > 0
                        ?
                        actions.map((action,index) => (
                            action === "Restaurar Sistema" ?
                            <ResetTypeButton 
                                className='command command--reset' 
                                data-action="command"
                                data-alarm-type={action} 
                                data-alarm-color={icons[index].Color} 
                                variant='contained' 
                                disabled={!props.modemStatus.status ? true : false}
                                onClick={() => { handleOpen(action) }}
                            >
                                <h3 class="command__title">{action}</h3>
                                <figure class="command__icon">
                                    <img class="command__icon__image" src={icons[index].Image} alt={icons[index].Alt}/>
                                    <i class="command__icon__loading fa fa-spinner fa-pulse fa-5x fa-fw"></i>
                                </figure>
                                <p class="command__local">{currency === "Geral" ? "" : "Grupo"} {currency}</p>
                            </ResetTypeButton>
                            :
                            <AlarmTypeButton 
                                className='command command--alarm' 
                                data-action="command"
                                data-alarm-type={action} 
                                data-alarm-color={icons[index].Color} 
                                variant='contained' 
                                disabled={!props.modemStatus.status ? true : false}
                                onClick={() => { handleOpen(action) }}
                            >
                                <h3 class="command__title">{action}</h3>
                                <figure class="command__icon">
                                    <img class="command__icon__image" src={icons[index].Image} alt={icons[index].Alt}/>
                                    <i class="command__icon__loading fa fa-spinner fa-pulse fa-5x fa-fw"></i>
                                </figure>
                                <p class="command__local">{currency === "Geral" ? "" : "Grupo"} {currency}</p>
                            </AlarmTypeButton>
                    ))
                    : null
                }
            </Grid>

            <Dialog
                open={open}
                onClose={() => handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle className={classes.dialogTitleGenerateCommand} id="Alarm">
                    <Typography color="black" component={'span'} variant='h5'>Você deseja gerar {buttonName} {currency === "Geral" ? "" : "no Grupo"} {currency}?</Typography>
                </DialogTitle>

                <DialogContent className={classes.dialogTitleGenerateCommand}>
                    <Box textAlign='center'>
                        {buttonName === 'Restaurar Sistema'
                            ?
                            <ResetAlarmButton disabled={buttonDisabled} onClick={() => { SendAlarm(buttonName, groupValue) }} autoFocus>{buttonDisabled ? "Enviando Comando " + buttonName : "Gerar " + buttonName}</ResetAlarmButton>
                            :
                            <AlarmButton disabled={buttonDisabled} onClick={() => { SendAlarm(buttonName, groupValue) }} autoFocus>{buttonDisabled ? "Enviando Comando " + buttonName : "Gerar " + buttonName}</AlarmButton>
                        }
                    </Box>
                    <DialogContentText>

                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button className={classes.buttonClose} onClick={handleClose}>Sair</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}