import { React, useState, useEffect } from "react";
import InfoBox from "../includes/InfoBox";
import FormControl from "../includes/FormControl";
import { useSnackbar } from "../providers/SnackbarProvider";

export default function WebUserCard({ index, controlUser, controlUsersList, handleUsers, setPageLoading })
{
    const { openSnackbar } = useSnackbar();
    
    const [isLoading, setIsLoading] = useState(false);
    const [onEdit, setOnEdit] = useState(false);
    const [master, setMaster] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");
    const [pswValidation, setPswValidation] = useState({content: "A senha deve ter no mínimo 8 caracteres", color: 'color-light'});

    //const [toast, setToast] = useState({ open: false, message: ""})

    const checkEmail = async () => {
        try
        {
            const request = await fetch(`${process.env.REACT_APP_URL}/user/check-email?email=${email}`,{
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            });

            if (!request.ok)
            {
                throw new Error("Não foi possível finalizar a verificação!");
            }

            const response = await request.json();

            return response;
        }
        catch (error)
        {
            console.log("🚀 ~ checkEmail ~ error:", error)
            openSnackbar(error.message, "error");
        }
    }

    const deleteUser = async () => {
        try 
        {
            setPageLoading(true);

            if (!!controlUser.booL_MASTER) 
            {
                throw Error("Você NÃO pode EXCLUIR o usuário MASTER!");
            }
            
            if(window.confirm("Você realmente quer excluir esse usuário?"))
            {
                if (!!controlUser.role)
                {
                    const request = await  fetch(`${process.env.REACT_APP_URL}/deltasystem/delete/user?userId=${controlUser.id}&serialNumber=${JSON.parse(localStorage.getItem("system")).serialKeys}`,{
                        method: 'GET',
                        headers: {
                            "content-type": "application/json",
                            "Authorization": "Bearer " + localStorage.getItem("token")
                        }
                    });
                    
                    if (!request.ok)
                    {
                        throw Error("Não foi possível excluír o usuário.");
                    }
                }

                let handleList = controlUsersList.filter(user => user.id !== controlUser.id);
        
                handleUsers(handleList);
                
                openSnackbar("Usuário excluído", "success");
            }
        }
        catch (error)
        {
            console.log("🚀 ~ deleteUser ~ error:", error)
            openSnackbar(error.message, "error");
        }
        finally 
        {
            setPageLoading(false);
        }
    }

    const submitChanges = async (e) => {
        e.preventDefault();

        try
        {
            setPageLoading(true);

            //TODO - Método HELPER para validar senha
            if (!!password && !!confirm) 
            {
                if (password !== confirm)
                {
                    setPswValidation({content: "As senhas não são iguais", color: 'color-danger'})
                    throw new Error("As senhas informadas não são iguais.");
                }
                else 
                {
                    setPswValidation({content: "A senha deve ter no mínimo 8 caracteres", color: 'color-light'})
                }
            }

            if (email !== controlUser.email)
            {
                //TODO - Método HELPER para verificar uso do E-mail
                const emailCheck = await checkEmail();
    
                if(!emailCheck.used)
                {
                    throw new Error(emailCheck.message);
                }
            }

            let user = controlUser;

            user.dS_NOME = name;
            user.email = email;
            user.senha = !!password ? password : "";
            //TODO - Set booL_MASTER

            //Request
            const request = await fetch(`${process.env.REACT_APP_URL}/user/create-or-update`,{
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(user)
            });

            if (!request.ok)
            {
                throw new Error("Não foi possível finalizar as alterações!");
            }

            const response = await request.json();

            if (!response.user)
            {
                throw Error(response);
            }

            let controlList = controlUsersList;

            controlList.forEach(control => {
                if (control.id === user.id)
                {
                    control.id = response.user.id
                    control.dS_NOME = response.user.name;
                    control.email = response.user.email;
                    control.booL_MASTER = response.user.booL_MASTER;
                    control.role = response.user.role;

                    return;
                }
            });

            handleUsers(controlList);
            setOnEdit(false);

            openSnackbar("Alterações salvas", "success")
        }
        catch (error)
        {
            console.log("🚀 ~ submitChanges ~ error:", error)
            openSnackbar(error.message, "error");
            setOnEdit(true);
        }
        finally
        {
            setPageLoading(false);
        }
    }

    useEffect(() => {
        setName(controlUser.dS_NOME);
        setEmail(controlUser.email);
        setMaster(controlUser.booL_MASTER);
        setPassword("");
        setConfirm("");
        setOnEdit(false);
    },[controlUser])

    return (
        <InfoBox className={`info-box--full info-box--curved ${master ? "info-box--primary" : ""} mx-0 mb-5`}>
            <InfoBox.Header>
                <div class="w-100 grid grid--middle grid--nowrap d-flex--row">
                    <div class="grid__col d-flex d-flex--align-items-center" data-grid-col="8">
                        <i class={`bx bx-md bx-user-circle mr-2 ${master ? "color-info" : ""}`}></i>
                        <h2 class="h5 my-0">{name} {master ? <span class="color-light">- Master</span> : null }</h2>
                    </div>
                    <div class="grid__col" data-grid-col="8">
                        <div class="buttons-container buttons-container--right">
                            <button for={`edit-user-${index}`} class="button" onClick={() => setOnEdit(!onEdit)}>
                                <i class='button__icon bx-sm bx bxs-pencil'></i>
                            </button>
                            <button class="button button--danger" onClick={() => deleteUser()}>
                                <i class='button__icon bx bx-sm bxs-trash'></i>
                            </button>
                        </div>
                    </div>
                </div>
            </InfoBox.Header>
            <InfoBox.Body>
                <div class="collapsable">
                    <input type="checkbox" id={`edit-user-${index}`} class="collapsable__toggle" checked={onEdit}/>
                    <form id={`web-user-${index}`} class="collapsable__content" onSubmit={(e) => submitChanges(e)}>
                        <FormControl 
                            controlType="text"
                            controlId={`web-user-name-${index}`}
                            controlName="name"
                            controlLabel="Nome"
                            controlLabelPosition="vertical"
                            controlPlaceholder="Digite o nome"
                            controlValue={name}
                            controlRequired={true}
                            controlDisabled={false}
                            controlMinLength={3}
                            controlMaxLength={50}
                            onChange={(e) => setName(e.target.value.trimStart())}
                        />
                        <FormControl 
                            controlType="email"
                            controlId={`web-user-email-${index}`}
                            controlName="email"
                            controlLabel="E-mail"
                            controlLabelPosition="vertical"
                            controlPlaceholder="Digite um e-mail válido"
                            controlValue={email}
                            controlRequired={true}
                            controlDisabled={false}
                            controlMaxLength={50}
                            onChange={(e) => setEmail(e.target.value.trim())}
                        />
                        <FormControl 
                            controlType="password"
                            controlId={`web-user-password-${index}`}
                            controlName="password"
                            controlLabel="Senha"
                            controlLabelPosition="vertical"
                            controlPlaceholder="Digite uma senha"
                            controlValue={password}
                            controlRequired={password.length > 0 || !controlUser.role ? true : false}
                            controlDisabled={false}
                            controlMinLength={8}
                            controlMaxLength={50}
                            controlMessage={pswValidation}
                            controlPattern={"^\\S+$"}
                            onChange={(e) => setPassword(e.target.value.trim())}
                        />
                        <FormControl 
                            controlType="password"
                            controlId={`web-user-confirm-${index}`}
                            controlName="confirm"
                            controlLabel="Confirme a senha"
                            controlLabelPosition="vertical"
                            controlPlaceholder="Repita a senha"
                            controlValue={confirm}
                            controlRequired={password.length > 0 ? true : false}
                            controlDisabled={false}
                            controlMinLength={8}
                            controlMaxLength={50}
                            controlMessage={pswValidation}
                            controlPattern={"^\\S+$"}
                            onChange={(e) => setConfirm(e.target.value.trim())}
                        />
                        <div class="buttons-container buttons-container--right mt-3">
                            <button 
                                type="submit" 
                                class="button button--primary"
                            >
                                <i class='button__icon bx bx-sm bxs-save'></i>
                                <span class="button__label">Salvar</span>
                            </button>
                        </div>
                    </form>
                </div>
            </InfoBox.Body>
        </InfoBox>
    );
}