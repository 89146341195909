import LineChart from "./LineChart"

function LineDashboard({ darkMode, data }) {

    return (
        <>
            {data.labels !== undefined
                ? <LineChart
                    darkMode={darkMode}
                    chart={data}
                    backgroundColor="FFFFFF"
                /> : null}

        </>
    )
}

export default LineDashboard