import React, { useState, useEffect } from 'react'
import { Container, Typography, TextField, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { parseWithOptions } from 'date-fns/fp'

const useStyles = makeStyles({
    fieldLocal: {
        width: '100%'
    }
})

export default function Local(props) {
    const classes = useStyles()
    const [local, setLocal] = useState([])
    const [currency, setCurrency] = useState("Todos")

    const handleChange = (event) => {
        let group = local.find(item => item.dS_LOCALIZACAO === event.target.value)
        props.changeGroupValue(group.vL_CADASTRO)
        setCurrency(event.target.value)
        props.setLocal(event.target.value)
    }

    async function populateData() {
        const response = await fetch(`${process.env.REACT_APP_URL}/group/groups`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify({
                "serialNumber": props.serial,
            })
        })

        const json = await response.json()
        return json
    }

    useEffect(() => {
        populateData().then(json => {
            setLocal(json)
            setLocal(local => [...local, { id: 0, dS_LOCALIZACAO: "Todos", vL_CADASTRO: "" }].reverse())
        })
    }, []);

    useEffect(() => {
        if (local.length > 0)
        {
            props.setControlLoading(props.controlLoading.map(item => {
                if (item.type === "local") 
                {
                    return { ...item, status: true };
                }
                return item;
            }));
        }
    },[local]);

    return (
        <>
            <TextField
                id="outlined-select-currency"
                select
                label="Grupo"
                value={currency}
                onChange={handleChange}
                className={classes.fieldLocal}
            >
                {local.map((option) => (
                    <MenuItem key={option.id} value={option.dS_LOCALIZACAO}>
                        {option.dS_LOCALIZACAO == "Todos" ? null : option.vL_CADASTRO.slice(12) + " - "} {option.dS_LOCALIZACAO ? option.dS_LOCALIZACAO : "Sem nome registrado"}
                    </MenuItem>
                ))}
            </TextField>
        </>
    )
}