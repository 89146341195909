import React, { useState, useEffect } from "react"
import { FormatListBulleted } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import { Grid, Box } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => {
    return {
        containerRegisterItem: {
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                // gridTemplateAreas: `"registerItem1 registerItem2 registerItem3 registerItem4 registerItem5 registerItem6"`,
                gridTemplateAreas: `"registerItem2 registerItem3 registerItem4 registerItem5"`,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                // gridTemplateAreas: `"registerItem1 registerItem2 registerItem3 registerItem5 registerItem4 registerItem6" 
                gridTemplateAreas: `"registerItem2 registerItem3 registerItem5 registerItem4"`,
            }
        },
        // registerItem1: {
        //     gridArea: "registerItem1",
        // },
        registerItem2: {
            gridArea: "registerItem2",
            paddingTop: "5px",
            paddingBottom: "5px",
        },
        registerItem3: {
            gridArea: "registerItem3",
            paddingTop: "5px",
            paddingBottom: "5px",
        },
        registerItem4: {
            gridArea: "registerItem4",
            paddingTop: "5px",
            paddingBottom: "5px",
        },
        registerItem5: {
            gridArea: "registerItem5",
            paddingTop: "5px",
            paddingBottom: "5px",
        },
        // registerItem6: {
        //     gridArea: "registerItem6",
        // }
    }
})

export default function RegisterItem({ register }) {
    const classes = useStyles()

    return (
        <Box className={classes.containerRegisterItem}>
            {/* <Box className={classes.registerItem1}><FormatListBulleted /></Box> */}
            <Box className={classes.registerItem2}><Typography variant="delta">{register.vL_MEM}</Typography></Box>
            <Box className={classes.registerItem3}><Typography variant="delta">{register.dS_NUM_SERIE}</Typography></Box>
            <Box className={classes.registerItem4}><Typography variant="delta">{register.dS_DESCRICAO}</Typography></Box>
            <Box className={classes.registerItem5}><Typography variant="delta">{`${register.fK_GRUPO.slice(12)} - ${register.dS_LOCALIZACAO}`}</Typography></Box>
            {/* <Box className={classes.registerItem6}><Typography variant="delta">{register.dS_LOCALIZACAO_EXT}</Typography></Box> */}
        </Box>
    )
}