import { useState, useEffect } from "react"
import { Container, Grid } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import UserCardWeb from "../components/UserCardWeb"
import CompanyDataWeb from "../components/CompanyDataWeb"
import UserLicences from "../components/UserLicences"

const useStyles = makeStyles({
    pageWebUser: {
        minHeight: "100vh",
    },
    card: {
        padding: "3rem"
    }
})

export default function UserWeb(props) {
    const classes = useStyles()
    const [userList, setUserList] = useState([])
    const [update, setUpdate] = useState(0)
    const [cloudLicence, setCloudLicence] = useState("")

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/user/all?companyId=${props.user.iD_EMPRESA}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            }
        })
            .then(response => response.json())
            .then(data => setUserList(data))

        fetch(`${process.env.REACT_APP_API_URL}/licences/desktop/key?serialNumber=${props.systemData.serialKeys}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            }
        })
            .then(response => {
                if (response.status === 202) {
                    response.json()
                        .then(data => {
                            setCloudLicence(data.licence)
                        })
                }
                if (response.status === 404) {
                    setCloudLicence("chave não encontrada")
                }
            }).catch((error) => { })

    }, [])

    useEffect(() => {

    }, [update])

    const removeUser = (user) => {
        var tempUserList = userList.filter(item => item.id !== user.id)
        setUserList(tempUserList)
        setUpdate(Math.random)
    }

    return (
        <Container className={classes.pageWebUser}>
            <CompanyDataWeb user={props.user} />
            <UserLicences serialKeys={props.systemData.serialKeys} token={props.token} />
            <Grid container spacing={3}>
                {userList.map(user => (
                    <Grid key={user.id} item xs={12} md={12} lg={6}>
                        <UserCardWeb removeUser={removeUser} key={user.id} user={user} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}