import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { Warning } from "@mui/icons-material"

export default function EventAlert(data) {
    const [row, setRow] = useState({})

    useState(() => {
        setRow(data.row)
    }, [])

    const newColor = (row) => {
        if (row.dS_DESCRICAO_EVENTO === "Sub Central") {
            if (row.fK_OCORRENCIA === "ALARME DE RETORNO" ||
                row.fK_OCORRENCIA === "ALARME EXTRA" ||
                row.fK_OCORRENCIA === "ALARME VISUAL") {
                return "#FFEA00"
            }
            if (row.fK_OCORRENCIA === "SISTEMA RESTAURADO") {
                return "#00B33C";
            }
            if (row.fK_OCORRENCIA === "ALARME DE BRIGADA") {
                return "#F58634";
            }
            if (row.fK_OCORRENCIA === "ALARME DE ABANDONO") {
                return "#CA373C";
            }
        }
        if (row.fK_OCORRENCIA === "BATERIA BAIXA" ||
            row.fK_OCORRENCIA === "SINALIZAÇAO VISUAL ATIVA" ||
            row.fK_OCORRENCIA === "REMOVIDO" ||
            row.fK_OCORRENCIA === "SILENCIAR ALARME" ||
            row.fK_OCORRENCIA === "CONTROLE AMBIENTAL" ||
            row.fK_OCORRENCIA === "SEM REDE AC"
        ) {
            return "#FFEA00"
        } else {
            return "#CA373C"
        }
    }

    return (
        <div>
            <Grid alignItems="center" container spacing={3}>
                <Grid item key={1} xs={1} md={1} lg={1}>
                    <Warning sx={{ color: newColor(row) }} />
                </Grid>

                <Grid item key={3} xs={2} md={2} lg={2}>
                    <Typography variant="delta">{row.fK_OCORRENCIA}</Typography>
                </Grid>

                <Grid item key={4} xs={3} md={3} lg={3}>
                    <Typography variant="delta">{row.dS_DESCRICAO_EVENTO}</Typography>
                </Grid>

                <Grid item key={5} xs={2} md={2} lg={2}>
                    <Typography variant="delta">{row.dS_ID_PERIFERICO ? row.dS_ID_PERIFERICO : row.iD_PERIFERICO}</Typography>
                </Grid>

                <Grid item key={6} xs={2} md={2} lg={2}>
                    <Typography variant="delta">{row.dS_LOCAL_EVENTO}</Typography>
                </Grid>

                <Grid item key={7} xs={2} md={2} lg={2}>
                    <Typography variant="delta">{new Date(row.dT_EVENTO).toLocaleString().replace(',', '')}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}