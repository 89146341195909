import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router"
import { Container, Card, Grid, Typography, Checkbox, FormControlLabel, FormHelperText} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import { Person, VpnKey, Email } from '@mui/icons-material'

const useStyles = makeStyles({

})

export default function ViewWebUser(props) {
    const classes = useStyles();
    const [name, setName] = useState()
    const [nameError, setNameError] = useState(false)
    const [nameErrorText, setNameErrorText] = useState("")
    const [email, setEmail] = useState(props.user.email === "" ? "" : props.user.email)
    const [emailError, setEmailError] = useState(false)
    const [emailErrorText, setEmailErrorText] = useState("")
    const [open, setOpen] = useState(false)
    const [userRolesList, setUserRolesList] = useState(props.user.role)
    const history = useHistory()
    const [disabled, setDisabled] = useState()
    const { upload, editar, alarmes, relatorio, usuarios, admin, revenda, delta } = userRolesList

    const handleRoles = (event) => {
        setUserRolesList({
            ...userRolesList,
            [event.target.name]: event.target.checked,
        })
    }

    useEffect(() => {
        setName(props.user.dS_NOME)
        setDisabled(props.user.role.notEditable)
    }, [])

    return (
        <Container>
            <form noValidate autoComplete="off">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={12} lg={12}>
                        <InputLabel htmlFor="usern-name">Nome do usuário</InputLabel>
                        <OutlinedInput
                            id="usern-name"
                            onChange={(e) => setName(e.target.value)}
                            variant="outlined"
                            color="secondary"
                            disabled
                            value={name}
                            error={nameError}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Person />
                                </InputAdornment>
                            }
                        ></OutlinedInput>
                        <FormHelperText id="user-name">{nameErrorText}</FormHelperText>
                    </Grid>

                    {props.user.email != null ?
                        <Grid item xs={12} md={12} lg={12}>
                            <OutlinedInput
                                className={classes.key}
                                id="email-field"
                                variant="outlined"
                                color="secondary"
                                disabled
                                type="email"
                                value={email}
                                placeholder="Insira um email válido para este usuário"
                                error={emailError}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Email />
                                    </InputAdornment>
                                }
                            ></OutlinedInput>
                            <FormHelperText id="email-field">{emailErrorText}</FormHelperText>
                        </Grid>
                        : null}
                </Grid>

                <Grid sx={{ marginTop: "20px", marginBottom: "20px" }} container spacing={4}>
                    <Grid justifyContent="center" alignItems="center" item xs={12} md={12} lg={12}>
                        <Typography sx={{ fontSize: '12px' }}>Permissões.</Typography>
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <FormControlLabel
                            control={
                                <Checkbox label="Top" disabled={disabled} checked={upload} onChange={handleRoles} name="upload" />
                            }
                            label="Upload rom"
                        />
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <FormControlLabel
                            control={
                                <Checkbox label="Top" disabled={disabled} checked={usuarios} onChange={handleRoles} name="usuarios" />
                            }
                            label="Criar usuários"
                        />
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <FormControlLabel
                            control={
                                <Checkbox label="Top" disabled={disabled} checked={alarmes} onChange={handleRoles} name="alarmes" />
                            }
                            label="Gerar alarmes"
                        />
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <FormControlLabel
                            control={
                                <Checkbox label="Top" disabled={disabled} checked={relatorio} onChange={handleRoles} name="relatorio" />
                            }
                            label="Gerar relatorio"
                        />
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <FormControlLabel
                            control={
                                <Checkbox label="Top" disabled={disabled} checked={editar} onChange={handleRoles} name="editar" />
                            }
                            label="Editar grupos/amplificadores"
                        />
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}