import React, { useState, useEffect } from "react"
import { Autocomplete, InputAdornment, TextField, Icon, Typography, ListItemButton, Box } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import MuiAppBar from "@mui/material/AppBar"
import MuiDrawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { styled, useTheme } from "@mui/material/styles"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { Class, Search } from "@mui/icons-material";
import { useHistory, useLocation } from "react-router"
import ToolBar from "@mui/material/Toolbar"
import { Grid } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu'
import { Menu } from "@mui/material"
import { MenuItem } from "@mui/material"
import LogoGuardiao from '../assets/logo-integrador-v2.png'
import LogoCliente from '../assets/logo-guardiao-2-h.png'
import IconButton from '@mui/material/IconButton'
import ClientList from "./ClientList"
import ResaleList from "./ResaleList"
import { InsertDriveFile, Dashboard, Warning, Person } from "@mui/icons-material";
import bkImage from "../assets/logoDelta2.png"
import bkImageDark from "../assets/logoDeltaOpaco.png"
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import { propsToClassKey } from "@mui/styles"

var drawerWidth = 350

const drawerItens = [
    {
        text: "Dashboard",
        icon: <Dashboard color="secondary" />,
        path: '/painel+dashboard'
    },
    {
        text: "Monitoramento",
        icon: <Warning color="secondary" />,
        path: '/painel'
    },
    {
        text: 'Relatórios',
        icon: <InsertDriveFile color="secondary" />,
        path: '/painel+relatorio'
    },
    {
        text: 'Configurações',
        icon: <Person color="secondary" />,
        path: '/usuario+integrador'
    },
    {
        text: 'Todas as Centrais',
        icon: <Class color="secondary" />,
        path: '/clients+panel'
    }
]

const drawerItensDelta = [
    {
        text: "Dashboard",
        icon: <Dashboard color="secondary" />,
        path: '/painel+dashboard'
    },
    {
        text: "Monitoramento",
        icon: <Warning color="secondary" />,
        path: '/painel'
    },
    {
        text: 'Licenças Integrador',
        icon: <Class color="secondary" />,
        path: '/licencas+integrador'
    },
    {
        text: 'Relatórios',
        icon: <InsertDriveFile color="secondary" />,
        path: '/painel+relatorio'
    }
]

const useStyles = makeStyles((theme) => {
    return {
        logoDeltaMaster: {
            [theme.breakpoints.up('sm')]: {
                maxWidth: '100px',
                maxHeight: '50px',
                //marginTop: '0.5rem'
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '90px',
                maxHeight: '40px',
                //marginTop: '0.5rem'
            }
        },
        pageLayoutMaster: {
            backgroundImage: `url(${bkImage})`,
            border: "1px solid black",
            backgroundPosition: 'center center',
            [theme.breakpoints.up('sm')]: {
                backgroundSize: 'auto'
            },
            [theme.breakpoints.down('sm')]: {
                backgroundSize: '80vw'
            },

            backgroundRepeat: 'no-repeat',
            backgroundAttachment: "scroll",
            //backgroundColor: '#f9f9f9',
            backgroundColor: '#FFFFFF',
            width: '100vw',
            //height: '100vh',
            padding: theme.spacing(1)
        },
        drawerLayoutMaster: {
            display: (open) => {
                if (!open) {
                    return "none"
                } else {
                    return "flex"
                }
            },
            [theme.breakpoints.up('sm')]: {
                overflow: "hidden",
            },
            [theme.breakpoints.down('sm')]: {
                display: "none"
            }
        },
        pageLayoutMasterDark: {
            backgroundImage: `url(${bkImageDark})`,
            border: "1px solid black",
            backgroundPosition: 'center center',
            [theme.breakpoints.up('sm')]: {
                backgroundSize: 'auto'
            },
            [theme.breakpoints.down('sm')]: {
                backgroundSize: '80vw'
            },
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: "scroll",
            backgroundColor: '#212121',
            width: '100vw',
            paddingTop: theme.spacing(1)
        },
        toolbarLayoutMaster: theme.mixins.toolbar,
        appBarLayout: {
            backgroundColor: '#212121',
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '0.2fr 1fr 3fr 2fr 0.5fr',
            gridTemplateAreas: `"item1 logoDeltaMaster item3 item4 item5"`,
        },
        toolbarLayoutMaster: theme.mixins.toolbar,
        appBarLayoutDark: {
            backgroundColor: '#5E5E5E',
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '0.2fr 1fr 3fr 2fr 0.5fr',
            gridTemplateAreas: `"item1 logoDeltaMaster item3 item4 item5"`,
        }
    }
}, { index: 1 })

// novo menu
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    border: '1px solid black',
    //borderColor: '#9e9e9e',
    borderColor: '#212121',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
//novo menu

export default function LayoutMaster({ setDarkMode, darkMode, setAppMenuItens, userRole, clients, setClients, setSystemData, serialKeys, children, logout, user, token, rows, clearState }) {
    const theme = useTheme()
    const [open, setOpen] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorUserEl, setAnchorUserEl] = useState(null)
    const mobileMenuOpen = Boolean(anchorEl)
    const classes = useStyles(open)
    const history = useHistory()
    const location = useLocation()
    const [menuItens, setMenuItens] = useState([])
    const [licencesList, setLicencesList] = useState([])
    const [autocompleteList, setAutocompleteList] = useState([])
    const [resaleValue, setResaleValue] = useState(0)
    const [clientKey, setClientKey] = useState(0)

    const handleDrawerOpen = () => {
        setOpen(!open)
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseUser = (path) => {
        setAnchorUserEl(null)
    }

    const handleClose = (serial) => {
        setAnchorEl(null)
        setSystemData(
            {
                listPorts: null,
                serialKeys: serial
            }
        )
        //history.push("/central")
    }

    const handleMenu = (event) => {
        setAnchorUserEl(event.currentTarget);
    };

    const handlePanel = (serial, name) => {
        setSystemData(
            {
                listPorts: null,
                serialKeys: serial,
                name: name
            }
        )
        history.push("/central")
    }

    const filterResale = (resale) => {
        setClientKey(resale)
        setResaleValue(resale)
        if (resale == "Todos") {
            setLicencesList(serialKeys)
            setMenuItens(serialKeys)
            setAppMenuItens(serialKeys)
            setClients(serialKeys)

            var autocompleteItem = serialKeys.map(item => {
                return {
                    label: item.id_central
                }
            })

            setAutocompleteList(autocompleteItem)
        } else {
            var filtered = serialKeys.filter(serial => {
                return serial.id_revenda == resale ? serial.cnpj_cpf : null
            })

            var clients = licencesList.filter(serial => {
                return serial.id_revenda == resale ? serial : null
            })
            setLicencesList(filtered)
            setMenuItens(filtered)
            setAppMenuItens(filtered)
            setClients(clients)
            var autocompleteItem = filtered.map(item => {
                return {
                    label: item.id_central
                }
            })

            setAutocompleteList(autocompleteItem)
        }
    }

    const autocomplete = (event) => {
        if (event.target.innerText === undefined) {
            setMenuItens(licencesList)
            setAppMenuItens(licencesList)
        } else {
            var autocomplete = licencesList.find(item => item.id_central === event.target.innerText)
            setMenuItens(Array(autocomplete))
            setAppMenuItens(Array(autocomplete))
        }
    }

    const filter = (client) => {
        setClientKey(client)
        if (client == "Todos") {
            setMenuItens(licencesList)
            setAppMenuItens(licencesList)
            setClients(licencesList)

            var autocompleteItem = licencesList.map(item => {
                return {
                    label: item.id_central
                }
            })

            setAutocompleteList(autocompleteItem)
        } else {
            var filtered = licencesList.filter(serial => {
                return serial.cnpj_cpf == client ? serial.cnpj_cpf : null
            })

            var clients = licencesList.filter(serial => {
                return serial.cnpj_cpf == client ? serial : null
            })

            var autocompleteItem = filtered.map(item => {
                return {
                    label: item.id_central
                }
            })

            setAutocompleteList(autocompleteItem)
            setMenuItens(filtered)
            setAppMenuItens(filtered)
            setClients(clients)
        }
    }

    useEffect(() => {
        setLicencesList(serialKeys)
        setMenuItens(serialKeys)
        setAppMenuItens(serialKeys)
        setClients(serialKeys)

        var autocompleteItem = serialKeys.map(item => {
            return {
                label: item.id_central
            }
        })
        setAutocompleteList(autocompleteItem)
    }, [serialKeys])

    const handleDarkMode = () => {
        setDarkMode(!darkMode)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar className="app-header" position="fixed" open={open} elevation={0}>
                <ToolBar className="app-header__container" elevation={0}>
                    <div class="app-header__container__wrap app-header__container__wrap--left">
                        <Box className="item1" sx={{ my: 'auto' }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginRight: 5,
                                    [theme.breakpoints.down('sm')]: {
                                        display: 'none'
                                    },
                                    ...(open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <IconButton
                                id="basic-button"
                                aria-controls="basic-menu"
                                aria-haspopup="true"
                                aria-expanded={mobileMenuOpen ? 'true' : undefined}
                                onClick={handleClick}
                                edge="start"
                                sx={{
                                    marginRight: 5,
                                    [theme.breakpoints.up('sm')]: {
                                        display: 'none'
                                    },
                                    // ...(open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>

                        <figure class="logo">
                            {userRole.revenda === true
                                ? <img src={LogoGuardiao} alt={"Guardião Delta Fire"} className={classes.logoDeltaMaster} />
                                : <img src={LogoCliente} alt={"Guardião Delta Fire"} className={classes.logoDeltaMaster} />
                            }
                        </figure>

                        <Box className="item3">

                        </Box>
                        
                    </div>

                    <div class="app-header__container__wrap app-header__container__wrap--right">
                        <Box sx={{ width: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton
                                className="item4"
                                sx={{ height: '100%' }}
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <i class="fa fa-suitcase" aria-hidden="true" style={{"font-size": "20px", "margin-right": "5px"}}></i>
                                <Typography variant="body1">{user}</Typography>
                            </IconButton>
                        </Box>


                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorUserEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorUserEl)}
                            onClose={handleCloseUser}
                        >
                            <MenuItem onClick={() => {
                                history.push("/")
                                //logout()
                            }}>Sair</MenuItem>
                        </Menu>

                        {/* <IconButton className="item5" onClick={handleDarkMode}>
                            {darkMode
                                ?
                                <LightModeIcon />
                                :
                                <DarkModeIcon />
                            }

                        </IconButton> */}
                    </div>


                    <Menu
                        anchorEl={anchorEl}
                        open={mobileMenuOpen}
                        onClose={handleClose}
                        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        {userRole.delta == true
                            ? <ListItem>
                                <ResaleList filterResale={filterResale} licences={serialKeys} />
                            </ListItem>
                            : null
                        }
                        <ListItem key="0">
                            <ClientList filter={filter} licences={licencesList} value={resaleValue} ></ClientList>
                        </ListItem>
                        <ListItem>
                            <Autocomplete
                                key={clientKey}
                                disablePortal
                                id="combo-box-demo"
                                options={autocompleteList}
                                sx={{ width: 300 }}
                                onChange={(event) => autocomplete(event)}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (<InputAdornment><Class color="secondary" /></InputAdornment>)
                                        }}
                                        label="Pesquisar por Série" />
                                }
                            />
                        </ListItem>

                        {userRole.delta === true ?

                            drawerItensDelta.map(item => (
                                <ListItem key={item.text}>

                                    <ListItemButton
                                        onClick={() => history.push(item.path)}
                                        selected={location.pathname == item.path ? true : false}
                                    >
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText>{item.text}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            ))

                            :
                            drawerItens.map(item => (
                                <ListItem key={item.text}>

                                    <ListItemButton
                                        onClick={() => history.push(item.path)}
                                        selected={location.pathname == item.path ? true : false}
                                    >
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText>{item.text}</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            ))
                        }

                        {menuItens.map(item => (
                            <ListItem
                                key={item.id_central}
                            >
                                <ListItemButton
                                    onClick={() => handlePanel(item.id_central, item.empresa)}
                                    selected={location.pathname == item.id_central ? true : false}>
                                    <ListItemIcon>{<Class color="primary" />}</ListItemIcon>
                                    <ListItemText primary={item.id_central}></ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </Menu>

                </ToolBar>
            </AppBar>

            <Drawer
                className={classes.drawerLayoutMaster}
                variant='permanent'
                anchor="left"
                open={open}
                overflow="hidden"
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerOpen}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>

                <List>
                    {userRole.delta == true
                        ? <ListItem>
                            <ResaleList filterResale={filterResale} licences={serialKeys} />
                        </ListItem>
                        : null
                    }
                    <ListItem key="0">
                        <ClientList filter={filter} licences={licencesList} value={resaleValue} ></ClientList>
                    </ListItem>
                    <ListItem>
                        <Autocomplete
                            key={clientKey}
                            disablePortal
                            id="combo-box-demo"
                            options={autocompleteList}
                            sx={{ width: 300 }}
                            onChange={(event) => autocomplete(event)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (<InputAdornment><Class color="secondary" /></InputAdornment>)
                                    }}
                                    label="Pesquisar por Série" />
                            }
                        />
                    </ListItem>
                    {userRole.delta == true
                        ?
                        drawerItensDelta.map(item => (
                            <ListItem key={item.text}>
                                <ListItemButton
                                    onClick={() => history.push(item.path)}
                                    selected={location.pathname == item.path ? true : false}
                                >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText>{item.text}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))
                        :
                        drawerItens.map(item => (
                            <ListItem key={item.text}>
                                <ListItemButton
                                    onClick={() => history.push(item.path)}
                                    selected={location.pathname == item.path ? true : false}
                                >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText>{item.text}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))
                    }



                    {menuItens.map(item => (
                        <ListItem
                            key={item.id_central}
                        >
                            <ListItemButton
                                onClick={() => handlePanel(item.id_central, item.empresa)}
                                selected={location.pathname == item.id_central ? true : false}>
                                <ListItemIcon>{<Class color="primary" />}</ListItemIcon>
                                <ListItemText sx={{ fontSize: '0.8rem !important' }} primary={item.empresa}></ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <div className={darkMode ? classes.pageLayoutMasterDark : classes.pageLayoutMaster}>
                <div className={classes.toolbarLayoutMaster}></div>
                {children}
            </div>
        </Box>
    )
}