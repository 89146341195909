import { useState, useEffect } from 'react'

const DoughnutData = (batery, removed, shot, alarm) => {
    var doughnutData = []

    if(batery != 0){doughnutData.push(batery.reduce((a, b) => a + b, 0))} else {doughnutData.push(0)}
    if(removed != 0) {doughnutData.push(removed.reduce((a, b) => a + b, 0))} else {doughnutData.push(0)}
    if(shot != 0) {doughnutData.push(shot.reduce((a, b) => a + b, 0))} else {doughnutData.push(0)}
    if(alarm != 0) {doughnutData.push(alarm.reduce((a, b) => a + b, 0))} else {doughnutData.push(0)}

    return {
        doughnut: {
            labels: ["BATERIA BAIXA", "REMOVIDO", "DISPAROS", "ALARMES"],
            datasets: { label: "Resumo", data: doughnutData },
        }
    }
}

export default DoughnutData