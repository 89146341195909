import { Card, CardHeader, CardContent, Typography, Button, CardActions, Snackbar, OutlinedInput, InputAdornment } from "@mui/material"
import { Computer, Key } from '@mui/icons-material'
import { useState, forwardRef } from "react"
import { makeStyles } from "@mui/styles"
import { useHistory } from "react-router-dom"
import MuiAlert from "@mui/material/Alert"

const useStyles = makeStyles({
    card: {
        padding: "1rem",
        marginTop: "1rem",
        marginBottom: "1rem"
    },
    key: {
        width: "20rem",
        marginBottom: "3rem"
    }
})

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function TechnicianLicence(props) {
    const classes = useStyles()
    const history = useHistory()
    const [key, setKey] = useState()
    const [activeButton, setActiveButton] = useState(true)
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    const keyLength = (e) => {
        if (e.length >= 24) {
            setKey(e)
            setActiveButton(false)
        }
    }

    const ActivateLicence = (e) => {
        fetch(`${process.env.REACT_APP_URL}/licences/renew/technician?key=${key}`, {
            method: "GET",
            headers: { "content-type": "application/json" }
        }).then(response => response.json())
            .then(data => {
                if (data.message === "Success") {
                    setSeverity("success")
                    setOpen(true)
                    setMessage("Licença renovada com sucesso")
                }
                if (data.message === "Used") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Esta chave já está em uso. Adquira uma nova chave.")
                }
                if (data.message === "Error") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Erro ao ativar licença.")
                }
                if (data.message === "NotFound") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Chave não encontrada.")
                }
                if (data.message === "Invalid") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Chave inválida.")
                }
            })
    }

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(!open)}
            >
                <Alert severity={severity} sx={{ width: '100%' }}>{message}</Alert>
            </Snackbar>
            <Card className={classes.card}>
                <CardHeader
                    avatar={<Computer color="primary" />}
                    title={"Renovar licença de técnico"}
                />
                <CardContent>
                    {/* <InputLabel htmlFor="secret-key">Insira sua chave</InputLabel> */}
                    <OutlinedInput
                        className={classes.key}
                        autoFocus={true}
                        id="secret-key"
                        onChange={(e) => keyLength(e.target.value)}
                        variant="outlined"
                        color="secondary"

                        required
                        //error={secretError}
                        startAdornment={
                            <InputAdornment position="start">
                                <Key />
                            </InputAdornment>
                        }
                    ></OutlinedInput>
                    {/* <FormHelperText id="secret-key">{secretError}</FormHelperText> */}
                </CardContent>
                <CardActions>
                    <Button
                        disabled={activeButton}
                        variant="contained"
                        color="secondary"
                        onClick={() => (ActivateLicence())}>Renovar licença</Button>
                </CardActions>

            </Card >
        </>
    )
}