import { useState, useEffect, forwardRef } from 'react'
import { Snackbar, Box, Typography, Container, Card, CardHeader, CardContent, CardActions, Checkbox, IconButton, Tooltip, Autocomplete, TextField, InputAdornment, Grid, Button } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles"
import { Search, ExpandMore, ExpandLess, Cloud } from '@mui/icons-material'
import { useHistory } from "react-router"
import MuiAlert from "@mui/material/Alert"


const useStyles = makeStyles({
    pageResalesLicences: {
        minHeight: "100vh"
    },
    cardResaleLicence: {
        padding: '1rem',
        marginTop: '2rem'
    }
})

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ResalesLicences(props) {
    const history = useHistory()
    const classes = useStyles()
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [update, setUpdate] = useState(0)
    const [licences, setLicences] = useState([])
    const [auxLicences, setAuxLicences] = useState([...licences])
    const [autocompleteList, setAutocompleteList] = useState([])


    const autocomplete = (event) => {
        if (event.target.innerText === undefined || event.target.innerText === "") {
            setAuxLicences(licences.map(item => { return item }))
        } else {
            var autocomplete = licences.filter(item => { return item.licence.revenda === event.target.innerText ? item : null })
            setAuxLicences(autocomplete.map(item => { return item }))
        }
    }

    const openLicenceCard = (itemPos) => {
        var newArr = [...auxLicences]
        newArr[itemPos].open = !newArr[itemPos].open
        setAuxLicences(newArr)
    }

    const closeLicenceCard = (itemPos) => {
        var newArr = [...auxLicences]
        newArr[itemPos].open = !newArr[itemPos].open
        setAuxLicences(newArr)
    }

    async function requestList() {
        var request = []

        await fetch(`${process.env.REACT_APP_API_URL}/licences/resales/licences`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                //"Authorization" : "Bearer "+props.token
            }
        }).then(res => res.json())
            .then(data => request = data).catch((error) => { })

        var tempList = []


        await Promise.all(
            request.map(async (item, i) => {

                var freeClientList = []

                await fetch(`${process.env.REACT_APP_API_URL}/licences/free/serials?id_revenda=${item.id_revenda}`, {
                    method: 'GET',
                    headers: {
                        "content-type": "application/json",
                        //"Authorization" : "Bearer "+props.token
                    }
                }).then(res => res.json())
                    .then(data => {
                        data.map((serial, i) => {
                            var freeClientTemplate = {
                                "position": i,
                                "isChecked": false,
                                "serial": ""
                            }

                            freeClientTemplate.serial = serial
                            freeClientList.push(freeClientTemplate)
                        })

                    }).catch((error) => { })

                var templateList = []

                await fetch(`${process.env.REACT_APP_API_URL}/licences/client/list?id_licenca=${item.id}`, {
                    method: 'GET',
                    headers: {
                        "content-type": "application/json",
                        //"Authorization" : "Bearer "+props.token
                    }
                }).then(res => res.json())
                    .then(data => {
                        data.serialNumbers.map((item, i) => {
                            var clientListTemplate = {
                                "position": i,
                                "isChecked": true,
                                "serial": ""
                            }
                            clientListTemplate.serial = item
                            templateList.push(clientListTemplate)
                        })
                    }).catch((error) => { })

                tempList.push(
                    {
                        "id": item.id,
                        "position": i,
                        "open": false,
                        "licence": item,
                        "freeClientList": freeClientList,
                        "clientList": templateList
                    }
                )
            })
        )
        setLicences(tempList)
        setAuxLicences([...new Set(Array.from(tempList.map(item => { return item })))])
        setAutocompleteList([...new Set(Array.from(tempList.map(item => { return item.licence.revenda })))])
    }

    const handleCheck = (itemPos, clientPosition) => {
        var newArr = [...auxLicences]
        newArr[itemPos].clientList[clientPosition].isChecked = !newArr[itemPos].clientList[clientPosition].isChecked
        setAuxLicences(newArr)
    }

    const handleFreeClientCheck = (itemPos, clientPosition) => {
        var newArr = [...auxLicences]
        newArr[itemPos].freeClientList[clientPosition].isChecked = !newArr[itemPos].freeClientList[clientPosition].isChecked
        setAuxLicences(newArr)
    }

    const updateLicence = (client) => {
        var updateTemplate = {
            "licenceId": "",
            "serialNumbers": []
        }

        var id_revenda = client.licence.id_revenda

        updateTemplate.licenceId = client.id

        client.clientList.map(item => {
            updateTemplate.serialNumbers.push(item)
        })

        client.freeClientList.map(item => {
            updateTemplate.serialNumbers.push(item)
        })

        var serialCount = []

        updateTemplate.serialNumbers.filter(item => (
            item.isChecked ? serialCount.push(item) : null
        ))

        if (serialCount.length > 5) {
            setOpenSnackBar(true)
            setMessage("Esta licença comporta apenas 5 séries de central")
            setSeverity("error")
        } else {

            fetch(`${process.env.REACT_APP_API_URL}/licences/update/list?id_revenda=${id_revenda}`, {
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    //"Authorization" : "Bearer " + props.token
                },
                body: JSON.stringify({
                    "licenceId": updateTemplate.licenceId,
                    "serialNumbers": updateTemplate.serialNumbers
                })
            }).then(res => res.json())
                .then(data => {
                    if (data === true) {
                        setOpenSnackBar(true)
                        setMessage("Licença alterada com sucesso")
                        setSeverity("success")
                    } else {
                        setOpenSnackBar(true)
                        setMessage("Erro ao alterar licença. Tente novamente")
                        setSeverity("error")
                    }
                    setUpdate(Math.random)
                }).catch((error) => { })
        }
    }

    useEffect(() => {
        requestList()
    }, [update])

    return (
        <Container className={classes.pageResalesLicences}>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={4000}
                onClose={() => setOpenSnackBar(!openSnackBar)}
            >
                <Alert severity={severity} sx={{ width: '100%' }}>{message}</Alert>
            </Snackbar>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={autocompleteList}
                onChange={(event) => autocomplete(event)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        placeholder="Pesquisar por Integrador"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (<InputAdornment position="start"><Search color="secondary" /></InputAdornment>)
                        }}
                    />
                }
            />

            {
                auxLicences.map((item, itemPos) => {
                    return <Card className={classes.cardResaleLicence} key={item.id}>
                        <CardHeader
                            action={item.open
                                ?
                                <Tooltip title="Fechar">
                                    <IconButton onClick={() => closeLicenceCard(itemPos)}>
                                        <ExpandLess />
                                    </IconButton>
                                </Tooltip>
                                :
                                <Tooltip title="Abrir">
                                    <IconButton onClick={() => openLicenceCard(itemPos)}>
                                        <ExpandMore />
                                    </IconButton>
                                </Tooltip>}
                            // format(new Date(date), 'dd/MM/yyyy HH:mm')
                            title={
                                <Grid container spacing={3}>
                                    <Grid item xs={4} md={4} lg={4}>
                                        <Typography>{item.licence.revenda}</Typography>
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={4}>
                                        <Typography>{item.licence.chave}</Typography>
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={4}>
                                        <Typography>{"Validade" + "    " + new Date(item.licence.expiracao_licenca).toLocaleDateString()}</Typography>
                                    </Grid>
                                </Grid>
                            }
                        />
                        {item.open
                            ?
                            <>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} md={6} lg={6}>
                                            <Typography variant="h6">Centrais associadas a esta licença</Typography>
                                            {item.clientList.map(client => {
                                                return <Box sx={{ display: 'flex', alignItems: 'center' }} key={client.serial}>
                                                    <Checkbox
                                                        name={client.serial}
                                                        checked={client.isChecked}
                                                        onChange={() => handleCheck(itemPos, client.position)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                    <Typography>{client.serial}</Typography>
                                                </Box>
                                            })}
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}>
                                            <Typography variant="h6">Centrais Livres</Typography>
                                            {item.freeClientList.map(freeClient => {
                                                return <Box sx={{ display: 'flex', alignItems: 'center' }} key={freeClient.serial}>
                                                    <Checkbox
                                                        name={freeClient.serial}
                                                        checked={freeClient.isChecked}
                                                        onChange={() => handleFreeClientCheck(itemPos, freeClient.position)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                    <Typography>{freeClient.serial}</Typography>
                                                </Box>
                                            })}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" color="secondary" onClick={() => updateLicence(item)}>Alterar Licença</Button>
                                </CardActions>
                            </>
                            : null}
                    </Card>
                })
            }
        </Container >
    )
}