import React, { useState, useEffect } from "react"
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import IconButton from '@mui/material/IconButton'
import { Domain, Edit, InvertColors, Save, TouchApp } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import makeStyles from "@mui/styles/makeStyles"
import { TextField } from "@mui/material"
import { Tooltip } from "@mui/material"
import { Button } from "@mui/material"
import RegisterModal from "./RegisterModal"
import AmplifierModal from "./AmplifiersModal"
import CommandModal from "./CommandModal"
import EventModal from "./EventModal"
import { Grid } from "@mui/material"
import { Box } from "@mui/material"
import InfoBox from '../includes/InfoBox'
import GroupCommandDisplay from "./GroupCommandDisplay"

const useStyles = makeStyles({
    textGroupCard: {
        color: "#dbdbdb"
    },
    statusGroupCard: {
        width: 'auto',
        height: 'auto',
        color: 'red'
    },
    btnTitleGroupCard: {
        padding: "0.3rem"
    },
    headerGroupCard: {
        padding: "1rem",
        minHeight: 100
    },
    cardCounterGroupCard: {
        marginLeft: "90%"
    }
})

export default function GroupCard({ role, token, group, groupIndex, handleUpdate, length, counter, command, rows }) {
    const classes = useStyles()
    const [groupsLengh, setGroupsLength] = useState(0)
    const [edit, setEdit] = useState(true)
    const [registers, setRegisters] = useState({})
    const [amplifiers, setAmplifiers] = useState({})

    const [title, setTitle] = useState("")
    const [label, setLabel] = useState("")
    const [index, setIndex] = useState(counter)

    useEffect(() => {
        setRegisters(group.register)
        setAmplifiers(group.amplifier)

        var localization = group.dS_LOCALIZACAO !== "" ? group.dS_LOCALIZACAO : "Sem Nome"

        setLabel(localization)
        setGroupsLength(length)
    }, [groupsLengh])

    useEffect(() => {
        console.log("🚀 ~ GroupCard ~ command:", command)
        
    }, [command])

    const editTitle = () => {
        setEdit(!edit)
    }

    const updateTitle = () => {
        handleUpdate(title)
        setEdit(!edit)
    }

    const changeGroup = (e, group) => {
        group.dS_LOCALIZACAO = group.dS_LOCALIZACAO !== "" ? e : "Sem Nome"
        setTitle(group)
        setLabel(e)
    }

    return (
        <InfoBox className={"info-box--full info-box--curved m-0"} status={!!command ? command.type : ""}>
            <InfoBox.Header>
                <div class="amplifier-card--row">
                    <figure class="amplifier-card__icon">
                        <i class="df-i df-i-2xl df-i-groups df-i-white"></i>
                    </figure>
                    <div class="amplifier-card__content">
                        <TextField fullWidth disabled={edit} id="standard-basic" value={label} variant="standard" onChange={(e) => { changeGroup(e.target.value, group) }}></TextField>
                        <Typography variant="body2" color="textSecondary"> Grupo: {group.vL_CADASTRO.slice(12)}</Typography>
                    </div>
                    {process.env.REACT_APP_MODE === 'desktop' && role.editar && !role.tecnico &&
                    <div class="amplifier-card__actions">
                        {edit ? 
                            (<Tooltip title="Editar">
                                <IconButton onClick={() => editTitle(group)}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>)
                            :
                            (<Tooltip title="Salvar">
                                <IconButton onClick={() => updateTitle(group)}>
                                    <Save />
                                </IconButton>
                            </Tooltip>)
                        }
                    </div>
                    }
                </div>
            </InfoBox.Header>
            <InfoBox.Body>
                <Grid container spacing={1}>
                    <Grid className="mt-5" key={0} item xs={12} md={12} lg={12}>
                        <GroupCommandDisplay command={command} groupValue={group.vL_CADASTRO} />
                    </Grid>
                    <Grid key={1} item xs={6} md={6} lg={6}>
                        <Box textAlign="center">
                            <Typography className={classes.btnTitleGroupCard} variant="body2" color="textSecondary">Periféricos</Typography>
                            <RegisterModal index={groupIndex} data={registers} title={`Periféricos - Grupo ${label}`} />
                            {/* TODO: BUSCAR UMA UMA FORMA DE UNIR RegisterModal, CommandModal, EventModal e AmplifierModal  */}
                        </Box>
                    </Grid>

                    <Grid key={2} item xs={6} md={6} lg={6}>
                        <Box textAlign="center">
                            <Typography className={classes.btnTitleGroupCard} variant="body2" color="textSecondary">Alarmes</Typography>
                            <CommandModal index={groupIndex} title={`Alarmes - Grupo ${label}`} token={token} groupId={group.vL_CADASTRO} rows={rows} />
                        </Box>
                    </Grid>

                    <Grid key={3} item xs={6} md={6} lg={6}>
                        <Box textAlign="center">
                            <Typography className={classes.btnTitleGroupCard} variant="body2" color="textSecondary">Eventos</Typography>
                            <EventModal index={groupIndex} title={`Eventos - Grupo ${label}`} token={token} groupId={group.vL_CADASTRO} rows={rows} />
                        </Box>
                    </Grid>

                    <Grid key={4} item xs={6} md={6} lg={6}>
                        <Box textAlign="center">
                            <Typography className={classes.btnTitleGroupCard} variant="body2" color="textSecondary">Rede</Typography>
                            <AmplifierModal index={groupIndex} title={`Rede - Grupo ${label}`} token={token} data={amplifiers} groupName={group.dS_LOCALIZACAO} />
                        </Box>
                    </Grid>
                </Grid>
            </InfoBox.Body>
            <InfoBox.Footer>
                <span class="ml-auto">{index}/{groupsLengh}</span>
            </InfoBox.Footer>
        </InfoBox>
    )
}