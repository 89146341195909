import { useState, useEffect } from "react"
import { Box, typography } from "@mui/system"
import { Typography } from "@mui/material"
import VolumeUpIcon from '@mui/icons-material/VolumeUp'

export default function GroupCommandDisplay(props) {
    const [groupValue, setGroupValue] = useState("")
    const [groupCommand, setGroupCommand] = useState("")
    const [commandColor, setCommandColor] = useState("")

    useEffect(() => {
        if (typeof props.command !== 'undefined') {
            handleCommand(props.command.type)
            setGroupValue(props.command.groupValue)
        } else {
            setGroupValue("")
            setGroupCommand("")
            setCommandColor("")
        }
    }, [props.command])

    const handleCommand = (commandType) => {
        console.log("🚀 ~ handleCommand ~ commandType:", commandType)
        
        switch (commandType) {
            case "DESL":
                setGroupCommand(null)
                setCommandColor("#00B33C")
                break;
            case "LIGA":
                setGroupCommand("ALARME DE ABANDONO")
                setCommandColor("#FF3B30")
                break;
            case "SILE":
                setGroupCommand("SILENCIAR ALARME")
                setCommandColor("#FFCC00")
                break;
            case "PANI":
                setGroupCommand("ALARME DE BRIGADA")
                setCommandColor("#FF9500")
                break;
            default:
                break
        }
    }

    return (
        <>
            {props.groupValue === groupValue || groupCommand !== 'general' && typeof props.command !== 'undefined'
                ? <Box sx={{ marginTop: '-20px', backgroundColor: commandColor, justifyContent: 'space-evenly', display: 'flex', alignItems: 'center' }}>
                    <VolumeUpIcon />
                    <Typography>{groupCommand}</Typography>
                </Box >
                : null
            }
        </>

    )
}