import React, { useState, useEffect } from "react"
import { Button, Typography, Modal, Badge, Grid, Container, IconButton, Stack, Paper, styled, Box } from "@mui/material"
import { Cancel } from "@mui/icons-material"
import CommandItem from "./CommandItem"
import makeStyles from "@mui/styles/makeStyles"
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import Drawer from "../includes/Drawer"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#5E5E5E",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
    marginTop: "0px !important",
    marginBottom: "2px !important"
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: "#B4B4B4",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    width: '100%',
    marginBottom: "10px !important"
}));

const useStyles = makeStyles((theme) => {
    return {
        modalCommand: {
            position: 'absolute',
            top: '10%',
            left: '10%',
            overflow: 'scroll',
            height: '100%',
            display: 'block'
        },
        containerCommand: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        },
        closeCommand: {
            color: '#f44336',
            padding: '2rem'
        },
        messageCommand: {
            borderRadius: 10,
            width: "auto",
            height: "auto",
            position: "absolute",
            backgroundColor: "#FFFFFF",
            padding: "2rem"
        },
        containerCommandItem: {
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"commandModalItem1 commandModalItem2 commandModalItem3 commandModalItem4 commandModalItem5 commandModalItem6"`,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"commandModalItem1 commandModalItem2 commandModalItem3 commandModalItem3" 
                "commandModalItem4 commandModalItem5 commandModalItem6 commandModalItem6"`,
            }
        },
        commandModalItem1: {
            gridArea: "commandModalItem1",
        },
        commandModalItem2: {
            gridArea: "commandModalItem2",
        },
        commandModalItem3: {
            gridArea: "commandModalItem3",
        },
        commandModalItem4: {
            gridArea: "commandModalItem4",
        },
        commandModalItem5: {
            gridArea: "commandModalItem5",
        },
        commandModalItem6: {
            gridArea: "commandModalItem6",
        }
    }
})

export default function CommandModal(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [commands, setCommands] = useState([])

    const handleOpen = () => {
        setOpen(!open)
    }

    const populate = () => {
        fetch(`${process.env.REACT_APP_URL}/command/get`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify({ "groupValue": props.groupId })
        })
            .then(res => res.json())
            .then(data => setCommands(data))
    }

    useEffect(() => {
        if (typeof props.rows !== 'undefined' && props.rows !== null && props.rows.fK_GRUPO === props.groupId && props.rows.type === 'alarme') {
            var tempCommand = [...commands]
            tempCommand.push(props.rows)
            setCommands(tempCommand)
        }
    }, [props.rows])

    useEffect(() => {
        populate()
    }, [])

    return (
        <div>
            <label for={`drawer-alarms-group-${props.index}`} className="button button--xs mx-auto">
                <Badge
                    badgeContent={commands.length > 0 ? commands.length : 0}
                    color="secondary"
                    showZero={true}
                >
                    <VolumeUpIcon fontSize='large' />
                </Badge>
            </label>

            <Drawer className="drawer--bottom-full" drawerId={`drawer-alarms-group-${props.index}`}>
                <Drawer.Header>
                    <h3>{props.title}</h3>
                </Drawer.Header>
                <Drawer.Body>
                    <Container>
                        <Stack alignItems="center" spacing={2}>
                            <ItemHeader item key={0} xs={12} md={6} lg={4}>
                                <Box className={classes.containerCommandItem}>
                                    <Box className={classes.commandModalItem1}><VolumeUpIcon /></Box>
                                    <Box className={classes.commandModalItem2}><Typography variant="body2">Central</Typography></Box>
                                    <Box className={classes.commandModalItem3}><Typography variant="body2">Descrição</Typography></Box>
                                    <Box className={classes.commandModalItem4}><Typography variant="body2">Local</Typography></Box>
                                    <Box className={classes.commandModalItem5}><Typography variant="body2">Origem</Typography></Box>
                                    <Box className={classes.commandModalItem6}><Typography variant="body2">Data</Typography></Box>
                                </Box>
                            </ItemHeader>
                            {commands.length > 0 ?
                                commands.map(command => (
                                    <Item item key={command.id} xs={12} md={6} lg={4}>
                                        <CommandItem commandData={command} />
                                    </Item>
                                ))
                                : 
                                <h4 style={{textAlign: "center"}}>Este grupo não possui comandos.</h4>
                            }
                        </Stack>
                    </Container>
                </Drawer.Body>
            </Drawer>
        </div>

    )
}