import { useState, useEffect } from 'react'
import { TextField, Box, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

export default function NumberSelector(props) {
    const [value, setValue] = useState(props.time)

    const AddValue = () => {
        var tempValue = value + 1
        if (tempValue >= 60) {
            tempValue = 60
        }
        setValue(tempValue)
        props.setValue(tempValue)
    }

    const RemoveValue = () => {
        var tempValue = value - 1
        if (tempValue <= 3) {
            tempValue = 3
        }
        setValue(tempValue)
        props.setValue(tempValue)
    }

    // useEffect(() => {
    // }, [])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton onClick={RemoveValue}> <RemoveIcon /> </IconButton>
            <TextField disabled="false" sx={{ maxWidth: '50px' }} value={value}></TextField>
            <IconButton onClick={AddValue}> <AddIcon /> </IconButton>
        </Box>
    )
}