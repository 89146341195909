import React, { useState, useEffect } from 'react'
//import { DatePicker, LocalizationProvider } from '@mui/lab'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
//import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Container, Typography, TextField } from '@mui/material'
import format from 'date-fns/format';
import parse from 'date-fns/parse'; 
import ptBR from 'date-fns/locale/pt-BR'
import dayjs from 'dayjs';


import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    fieldCalendar: {
        width: '100%'
    }
})

export default function Calendar(props) {
    const classes = useStyles()
    const today = new Date();
    const [date, setDate] = useState(dayjs(today).toDate())
    const [label, setLabel] = useState(props.label)

    const parseDate = (dateString) => {
        return parse(dateString, 'MM/dd/yyyy', new Date());
    };

    const handleDate = (newDate) => {
        if (newDate && !isNaN(newDate.getTime())) 
        {
             // Formatar para 'MM/dd/yyyy' para armazenamento
             const formattedDate = format(newDate, 'MM/dd/yyyy');
             props.setDate(formattedDate);
             setDate(newDate);
        }
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                <DatePicker
                    label={label}
                    inputFormat="dd/MM/yyyy"
                    value={date}
                    onChange={(newValue) => { handleDate(newValue) }}
                    className={classes.fieldCalendar} 
                    renderInput={(params) => <TextField {...params} className={classes.fieldCalendar} />}
                />
            </LocalizationProvider>
        </>
    )
}