import React, { useState, useEffect } from "react"
import { Button, Typography, Modal, Badge, styled, Stack, Paper } from "@mui/material"
import { Router, Cancel } from "@mui/icons-material"
import { Grid } from "@mui/material"
import { Container } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles"
import { IconButton } from "@mui/material"
import AmplifierList from "./AmplifierList"
import Drawer from "../includes/Drawer"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#5E5E5E",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
    marginTop: "0px !important",
    marginBottom: "2px !important"
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: "#B4B4B4",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    width: '100%',
    marginBottom: "10px !important"
}));

const useStyles = makeStyles({
    modalAmplifier: {
        position: 'absolute',
        top: '10%',
        left: '10%',
        overflow: 'scroll',
        height: '100%',
        display: 'block'
    },
    containerAmplifier: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    closeAmplifier: {
        color: '#f44336',
        padding: '2rem'
    },
    messageAmplifier: {
        borderRadius: 10,
        width: "auto",
        height: "auto",
        position: "absolute",
        backgroundColor: "#FFFFFF",
        padding: "2rem"
    }
})

export default function AmplifierModal({data, index, title, token, groupName}) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [amplifiers, setAmplifiers] = useState({})
    const [length, setLength] = useState([])

    const handleOpen = () => {
        setOpen(!open)
    }

    useEffect(() => {
        if (!!data)
        {
            setAmplifiers(data)
            setLength(data.length)
        }
    }, [data])

    return (
        <div>
            <label for={`drawer-amplifiers-group-${index}`} className="button button--xs mx-auto">
                <Badge
                    badgeContent={length > 0 ? length : 0}
                    color="secondary"
                    showZero={true}
                >
                    <i class="df-i df-i-2xl df-i-network df-i-white"></i>
                </Badge>
            </label>
            <Drawer className="drawer--bottom-full" drawerId={`drawer-amplifiers-group-${index}`}>
                <Drawer.Header>
                    <h3>{title}</h3>
                </Drawer.Header>
                <Drawer.Body>
                    <Container>
                        <Stack alignItems="center" spacing={3}>
                            <ItemHeader>
                                <Grid alignItems="center" container spacing={3}>
                                    <Grid item key={1} xs={1} md={1} lg={1}>
                                        <Router />
                                    </Grid>

                                    <Grid item key={2} xs={3} md={3} lg={3}>
                                        Id
                                    </Grid>

                                    <Grid item key={3} xs={1} md={1} lg={1}>
                                        Status
                                    </Grid>

                                    <Grid item key={4} xs={4} md={4} lg={4}>
                                        Grupo
                                    </Grid>

                                    <Grid item key={5} xs={3} md={3} lg={3}>
                                        Local
                                    </Grid>
                                </Grid>
                            </ItemHeader>
                            {length > 0 ?
                                amplifiers.map(r => (
                                    <Item item key={r.id} xs={12} md={12} lg={12}>
                                        <AmplifierList token={token} amplifier={r} />
                                    </Item>
                                ))
                                : 
                                <h4 style={{textAlign: "center"}}>Este grupo não possui amplificadores.</h4>
                            }
                        </Stack>
                    </Container>
                </Drawer.Body>
            </Drawer>
        </div>
    )
}