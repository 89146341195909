function configs(labels, datasets, darkMode ) {
    return {
      data: {
        labels,
        datasets: [
          {
            label: datasets.label,
            tension: 0,
            pointRadius: 5,
            pointBorderColor: "transparent",
            //cor dos pontos
            pointBackgroundColor: "#ffd600",
            //cor da linha
            borderColor: "#ffd600",
            borderWidth: 4,
            backgroundColor: "transparent",
            fill: true,
            data: datasets.data,
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              //cor do pontilhado
              color: darkMode ? "#fff" : 'primary',
            },
            ticks: {
              display: true,
              // cor eixo y
              color: darkMode ? "#fff" : 'primary',
              padding: 10,
              font: {
                size: 10,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              //cor eixo x
              color: darkMode ? "#fff" : 'primary',
              padding: 10,
              font: {
                size: 10,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    };
  }
  
  export default configs;