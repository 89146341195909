import React from 'react';
import PropTypes from 'prop-types';

const OccurenceCard = ({ children }) => {
    let header = null, body = null, footer = null;

    React.Children.forEach(children, child => {
		if (child.type === OccurenceCard.Header) {
			header = child;
		} else if (child.type === OccurenceCard.Body) {
			body = child;
		} else if (child.type === OccurenceCard.Footer) {
			footer = child;
		}
	});

    return (
        <div className="occurence-card">
            <div className="occurence-card__header">
                {header}
            </div>
            <div className="occurence-card__body">
                {body}
            </div>
            <div className="occurence-card__footy">
                {footer}
            </div>
        </div>
    );
}

OccurenceCard.propTypes = {
    children: PropTypes.node.isRequired,
};

// Subcomponentes
OccurenceCard.Header = ({ children }) => <>{children}</>;
OccurenceCard.Body = ({ children }) => <>{children}</>;
OccurenceCard.Footer = ({ children }) => <>{children}</>;

export default OccurenceCard;