import React, { useState, useEffect } from "react"
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import makeStyles from "@mui/styles/makeStyles"
import { Button, Grid, Box, styled } from "@mui/material"

const useStyles = makeStyles((theme) => {
    return {
        eventCard: {
            [theme.breakpoints.up('xl')]: {
                height: '55vh',
                display: 'flex',
                flexDirection: 'column'
            },
            [theme.breakpoints.down('xl')]: {
                height: '60vh',
                display: 'flex',
                flexDirection: 'column'
            },
            backgroundColor: "#00B33C"
        },
        actionsEvent: {
            justifyContent: "center"
        }
    }
})

const AlarmButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        backgroundColor: '#F58634',
        width: '10vw',
        height: '8vh',
        borderRadius: 100,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#b71c1c'
        }
    },
    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#F58634',
        width: '10vw',
        height: '10vh',
        borderRadius: 100,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#b71c1c'
        }
    }
}))

export default function EventCard(props) {
    const classes = useStyles()
    const [groupValue, setGroupValue] = useState(props.row.fK_GRUPO.slice(12))
    const [groupName, setGroupName] = useState(props.row.ds_GRUPO)
    const [command, setCommand] = useState()

    useEffect(() => { }, [])

    const newColor = () => {
        if (props.row.dS_DESCRICAO_EVENTO === "Sub Central") {
            if (props.row.fK_OCORRENCIA === "ALARME DE RETORNO" ||
                props.row.fK_OCORRENCIA === "ALARME EXTRA" ||
                props.row.fK_OCORRENCIA === "ALARME VISUAL") {
                return "#FFEA00"
            }
            if (props.row.fK_OCORRENCIA === "SISTEMA RESTAURADO") {
                return "#00B33C";
            }
            if (props.row.fK_OCORRENCIA === "ALARME DE BRIGADA") {
                return "#F58634";
            }
            if (props.row.fK_OCORRENCIA === "ALARME DE ABANDONO") {
                return "#CA373C";
            }
        }
        if (props.row.fK_OCORRENCIA === "BATERIA BAIXA" ||
            props.row.fK_OCORRENCIA === "SINALIZAÇAO VISUAL ATIVA" ||
            props.row.fK_OCORRENCIA === "REMOVIDO" ||
            props.row.fK_OCORRENCIA === "SILENCIAR ALARME" ||
            props.row.fK_OCORRENCIA === "CONTROLE AMBIENTAL" ||
            props.row.fK_OCORRENCIA === "SEM REDE AC"
        ) {
            //return "#FFEA00"
            return "#FFCC00"
        } else {
            return "#CA373C"
        }
    }

    const sendCommand = (command) => {
        var name = ""
        if (groupName === undefined) {
            name = "Sem nome regist."
        } else {
            name = groupName
        }
        fetch(`${process.env.REACT_APP_URL}/command/send`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify({
                "userId": props.userId,
                "groupValue": `${props.serialNumber}${groupValue}`,
                "groupName": name,
                "command": command
            })
        })
            .then(res => res.json())
            .then(data => {
                if (command === "DESL") {
                    props.removeGroup(props.row.fK_GRUPO)
                }

                if (process.env.REACT_APP_MODE === 'desktop') {
                    fetch(`${process.env.REACT_APP_API_URL}/Events/receive`, {
                        method: 'POST',
                        headers: {
                            "content-type": "application/json",
                            "Authorization": "Bearer " + props.token
                        },
                        body: JSON.stringify({
                            "id": data.id,
                            "type": "alarme",
                            "dT_EVENTO": data.dT_ALARME,
                            "fK_CENTRAL": data.fK_GRUPO.slice(0,12),
                            "fK_OCORRENCIA": data.dS_DESCRICAO_ALARME,
                            "fK_GRUPO": data.fK_GRUPO                            ,
                        })
                    })
                }
            })
    }



    return (
        <div>
            <Card className={classes.eventCard} elevation={1} sx={{ backgroundColor: newColor }} >
                <CardHeader
                    avatar={""}
                    action={""}
                    title={"GRUPO"}
                    subheader={<Typography variant="body2">{props.row.ds_GRUPO == null ? props.row.fK_GRUPO.slice(12) : props.row.ds_GRUPO}</Typography>}
                />
                <CardContent>
                    <Box textAlign="center">
                        <Typography variant='h6'>{props.row.dS_DESCRICAO_EVENTO}</Typography>
                        <Typography variant="delta2">{props.row.dS_ID_PERIFERICO}</Typography>
                        <Typography variant='h5'>{props.row.fK_OCORRENCIA}</Typography>
                        <Typography variant='h5'>{props.row.dS_LOCALIZACAO}</Typography>
                        <Typography variant='h6'>{props.row.dS_LOCAL_EVENTO}</Typography>
                    </Box>

                    {process.env.REACT_APP_MODE === 'desktop'
                        ? props.userRole.alarmes === true && props.row.fK_OCORRENCIA === "BATERIA BAIXA" ||
                            props.row.fK_OCORRENCIA === "SINALIZAÇÃO VISUAL ATIVA" ||
                            props.row.fK_OCORRENCIA === "REMOVIDO" ||
                            props.row.fK_OCORRENCIA === "CONTROLE AMBIENTAL" ||
                            props.row.fK_OCORRENCIA === "SEM REDE AC" ||
                            props.row.dS_DESCRICAO_EVENTO === "Sub Central"
                            ?
                            null
                            :
                            <Grid container spacing={2}>
                                <Grid textAlign="center" item xs={6}>
                                    {props.userRole.tecnico === false ? <AlarmButton variant="body1" color="textSecondary" onClick={() => sendCommand("LIGA")}>Alarme de Abandono</AlarmButton> : null}
                                </Grid>

                                <Grid textAlign="center" item xs={6}>
                                    {props.userRole.tecnico === false ? <AlarmButton variant="body2" color="textSecondary" onClick={() => sendCommand("PANI")}>Alarme de Brigada</AlarmButton> : null}

                                </Grid>
                                <Grid textAlign="center" item xs={6}>
                                    <AlarmButton variant="body2" color="textSecondary" onClick={() => sendCommand("DESL")}>Restaurar Grupo</AlarmButton>
                                </Grid>
                                <Grid textAlign="center" item xs={6}>
                                    {props.userRole.tecnico === false ? <AlarmButton variant="body2" color="textSecondary" onClick={() => sendCommand("SILE")}>Silenciar Alarme</AlarmButton> : null}

                                </Grid>
                            </Grid>
                        : null
                    }
                </CardContent>
                <CardActions sx={{ marginTop: 'auto' }}>
                    <Box textAlign="center" sx={{ width: "100%" }}>
                        <Typography variant='body1'>
                            {new Date(props.row.dT_EVENTO).toLocaleString().replace(',', '')}
                        </Typography>
                    </Box>
                </CardActions>
            </Card>
        </div>
    )
}