import React, { useState, useEffect } from "react";
import { Grid, Typography } from '@mui/material';
import format from "date-fns/format"
import VolumeUpIcon from '@mui/icons-material/VolumeUp'

export default function CommandItemRemote({ commandData }) {
    const [command, setCommand] = useState(commandData)
    const [commandDescription, setCommandDescription] = useState("")
    const [commandDate, setCommandDate] = useState("")

    const handleCommand = (description) => {
        switch (description) {
            case "A licença Desktop expirou":
                setCommandDescription("A licença Desktop expirou")
                break;
            case "DESL":
                setCommandDescription("SISTEMA RESTAURADO")
                break;
            case "LIGA":
                setCommandDescription("ALARME DE ABANDONO")
                break;
            case "SILE":
                setCommandDescription("SILENCIAR ALARME")
                break;
            case "PANI":
                setCommandDescription("ALARME DE BRIGADA")
                break;
            default:
                break;
        }
    }

    const newAlarmColor = (row) => {
        if (row.fK_OCORRENCIA === "DESL") {
            return "#00B33C"
        }
        if (row.fK_OCORRENCIA === "PANI") {
            return "#F58634"
        }
        if (row.fK_OCORRENCIA === "SILE") {
            return "#FFEA00"
        }
        if (row.fK_OCORRENCIA === "LIGA") {
            return "#CA373C"
        }
    }

    const handleDate = (date) => {
        setCommandDate(format(new Date(date), 'dd/MM/yyyy HH:mm'))
    }

    useEffect(() => {
        handleCommand(command.fK_OCORRENCIA)
        handleDate(command.dT_EVENTO)
    }, [])

    return (
        <div>
            <Grid alignItems="center" container spacing={3}>
                <Grid item key={1} xs={1} md={1} lg={1}>
                    <VolumeUpIcon sx={{ color: newAlarmColor(command) }} />
                </Grid>

                <Grid item key={2} xs={2} md={2} lg={2}>
                    <Typography variant="delta">{command.fK_CENTRAL}</Typography>
                </Grid>

                <Grid item key={3} xs={4} md={4} lg={4}>
                    <Typography variant="delta">{commandDescription}</Typography>
                </Grid>

                <Grid item key={4} xs={3} md={3} lg={3}>
                    <Typography variant="delta">{command.dS_LOCAL_EVENTO === null ? "Em todo o sistema" : command.dS_LOCAL_EVENTO}</Typography>
                </Grid>

                <Grid item key={5} xs={2} md={2} lg={2}>
                    <Typography variant="delta">{new Date(command.dT_EVENTO).toLocaleString().replace(',', '')}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}