import React, { useState, useEffect } from 'react'
import { Container, Grid, Button, Box, TextField, Typography, Autocomplete, Stack } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import AmplifierList from '../components/AmplifierList'
import { styled } from '@mui/material/styles'
import { Router } from '@mui/icons-material'
import RelatoryNameField from '../components/RelatoryNameField'
import PdfViewer from '../providers/PdfViewer'
import Drawer from '../includes/Drawer'
import { ReactComponent as IconPDF } from '../assets/icons/icon_file_pdf.svg'

const useStyles = makeStyles({
    pageAmplifierRelatory: {
        minHeight: "100vh",
        paddingTop: "4rem"
    },
    buttonRegisterRelatory: {
        marginTop: '3rem',
        marginBottom: '3rem'
    },
})

const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 15,
    width: '100%'
}));

const ItemHeader = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    height: '50px',
    width: '100%'
}));

export default function AmplifierRelatory(props) {
    const classes = useStyles()
    const [nameList, setNameList] = useState([])
    const [status, setStatus] = useState([])
    const [ds, setDsList] = useState(["Todos"])
    const [groupList, setGroupList] = useState([])
    const [amplifiers, setAmplifiers] = useState([])
    const [result, setResult] = useState([])
    const [localList, setLocalList] = useState(["Todos"])
    const [amplifierId, setAmplifirId] = useState("")
    const [description, setDescription] = useState("")
    const [local, setLocal] = useState("")
    const [amplifierStatus, setAmplifierStatus] = useState(false)
    const [relatoryName, setRelatoryName] = useState("")
    const [show, setShow] = useState(false);

    const IntegrityData = (workData) => {
        if (!!sessionStorage.getItem('amplifiers'))
        {
            console.log("🚀 ~ IntegrityData ~ workData ~ ENTRY:", workData)
            let controlAmplifiers = JSON.parse(sessionStorage.getItem('amplifiers'));
            
            workData.forEach(item => {
                item = controlAmplifiers.all.filter(a => a.dS_DL === item.dS_DL);
            });
    
            console.log("🚀 ~ IntegrityData ~ workData ~ OUT:", workData)
    
            return workData;
        }
        else
        {
            setTimeout(IntegrityData(workData),2000);
        }
    }

    const request = async () => {
        props.setIsLoading(true);
        var response = await fetch(`${process.env.REACT_APP_URL}/amplifier/all`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify({
                "serialNumber": props.serial,
            })
        })
        var data = await response.json()

        setAmplifiers(data)

        var uniqueNames = Array.from(new Set(data.map(amplifier => amplifier.dS_NOME)))
            .map(dS_NOME => {
                return {
                    label: dS_NOME === null ? "Sem nome registrado" : dS_NOME
                }
            })

        setNameList(uniqueNames)

        var uniqueGroup = Array.from(new Set(data.map(amplifier => amplifier.fK_VL_CADASTR_GRUPO)))
            .map(fK_VL_CADASTR_GRUPO => {
                // Encontra o primeiro objeto que corresponde ao fK_VL_CADASTR_GRUPO atual
                let amplifier = data.find(amplifier => amplifier.fK_VL_CADASTR_GRUPO === fK_VL_CADASTR_GRUPO);
                return {
                    label: `${fK_VL_CADASTR_GRUPO.substring(12)} - ${amplifier.dS_LOCALIZACAO}`
                }
            });

        uniqueGroup.unshift("Todos")

        setGroupList(uniqueGroup)

        var dressLow = Array.from(new Set(data.map(amplifier => amplifier.dS_DL)))
            .map(dS_DL => {
                return {
                    label: dS_DL.toUpperCase()
                }
            })

        setDsList(ds.concat(dressLow))

        var status = [
            {
                label: "online"
            },
            {
                label: "offline"
            }
        ]

        setStatus(status)

        var location = Array.from(new Set(data.map(amplifier => amplifier.dS_LOCALIZACAO)))
            .map((dS_LOCALIZACAO => {
                return {
                    label: dS_LOCALIZACAO === '' ? "Sem nome registrado" : dS_LOCALIZACAO
                }
            }))

        setLocalList(localList.concat(location))
        props.setIsLoading(false);
    }

    const gerarPdf = async () => {
        var filterParameters = {
            fK_CENTRAL_AMPLIFICADOR: props.serial === "Todos" ? "" : props.serial,
            dS_DL: amplifierId === "Todos" ? "" : amplifierId,
            dS_NOME: description === "Todos" ? "" : description,
            dS_LOCALIZACAO: local === "Todos" || local === "Sem nome registrado" ? "" : local,
            dS_STATUS: amplifierStatus === "Todos" ? "" : amplifierStatus,
        }
        let actionUrl = `${process.env.REACT_APP_URL}/relatory/amplifier?FK_CENTRAL_AMPLIFICADOR=${props.serial}&DS_DL=${filterParameters.dS_DL}&DS_LOCALIZACAO=${filterParameters.dS_LOCALIZACAO}&DS_NOME=${filterParameters.dS_NOME}&BL_STATUS=${filterParameters.dS_STATUS}&user=${props.user.dS_NOME}&name${relatoryName}`;

        if (process.env.REACT_APP_MODE === "desktop") {
            PdfViewer.Download(actionUrl, "GET", props.token, "relatorio-amplificadores", props.setIsLoading);
        } else {
            PdfViewer.PrintAsync(actionUrl, "GET", props.token, props.setIsLoading);
        }
    }

    const filter = async () => {
        var filterParameters = {
            fK_CENTRAL_AMPLIFICADOR: amplifierId === "Todos" ? "" : props.serial,
            dS_DL: amplifierId === "Todos" ? "" : amplifierId,
            dS_NOME: description === "Todos" ? "" : description,
            dS_LOCALIZACAO: local === "Todos" || local === "Sem nome registrado" ? "" : local,
            dS_STATUS: amplifierStatus === "Todos" ? "" : amplifierStatus,
        }
        console.log("🚀 ~ filter ~ filterParameters:", filterParameters);

        if (filterParameters.dS_DL === "" && filterParameters.dS_LOCALIZACAO === "") 
        {
            setShow(true);
            setResult(amplifiers);
        } 
        else 
        {
            props.setIsLoading(true);
            const response = await fetch(`${process.env.REACT_APP_URL}/amplifier/relatory?FK_CENTRAL_AMPLIFICADOR=${props.serial}&DS_DL=${filterParameters.dS_DL}&DS_LOCALIZACAO=${filterParameters.dS_LOCALIZACAO}&DS_NOME=${filterParameters.dS_NOME}&BL_STATUS=${filterParameters.dS_STATUS}`,
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Expose-Headers': '*',
                    "Authorization": "Bearer " + props.token
                }
            });

            const data = await response.json();
            
            console.log("🚀 ~ filter ~ data:", data)
            setShow(true);
            setResult(IntegrityData(data));
            props.setIsLoading(false);
        }
    }

    useEffect(() => {
        request()
    }, [])

    return (
        <div class="container">
            <center>
                <h3 class="mb-5">Relatório de Amplificadores Registrados</h3>
            </center>

            <div class="filter">
                {/* <div class="filter__column filter__column--fw">
                    <RelatoryNameField changeRelatoryName={changeRelatoryName} />
                </div> */}
                <div class="filter__column">
                    <Autocomplete
                        disablePortal
                        id="combo-box-groups"
                        options={localList}
                        defaultValue={"Todos"}
                        sx={{ width: "100%", mx: 'auto' }}
                        onChange={(event) => setLocal(event.target.innerText === undefined ? "" : event.target.innerText)}
                        renderInput={(params) => <TextField {...params} label="Grupo" />}
                    />
                </div>
                <div class="filter__column">
                    <Autocomplete
                        disablePortal
                        id="combo-box-ids"
                        options={ds}
                        defaultValue={"Todos"}
                        sx={{ width: "100%", mx: 'auto' }}
                        onChange={(event) => setAmplifirId(event.target.innerText === undefined ? "" : event.target.innerText)}
                        renderInput={(params) => <TextField {...params} label="ID" />}
                    />
                </div>
            </div>

            <div class="buttons-container buttons-container--right mb-5">
                <button type="button" class="button button--md button--primary" title="Buscar" onClick={filter} disabled={props.isLoading}>
                    <span class="button__label">Buscar</span>
                </button>
                <button type="button" class="button button--transparent" title="Gerar PDF" onClick={gerarPdf} disabled={props.isLoading}>
                    <IconPDF className="button__icon df-i df-i-red df-i-2xl"/>
                </button>
            </div>
                    
            {!!show &&
                <Stack alignItems="center" spacing={3}>
                    <ItemHeader style={{"display": "flex", "alignItems": "center", "justifyContent": "center"}}>
                        <Grid container spacing={3} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "marginTop": 0,"marginLeft": 0}}>
                            <Grid item key={1} xs={1} md={1} lg={1} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                                <Router />
                            </Grid>

                            <Grid item key={2} xs={2} md={2} lg={2} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                                <Typography variant="delta">I.D.</Typography>
                            </Grid>

                            <Grid item key={3} xs={2} md={2} lg={2} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                                <Typography variant="delta">Status</Typography>
                            </Grid>

                            <Grid item key={4} xs={4} md={4} lg={4} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                                <Typography variant="delta">Grupo</Typography>
                            </Grid>

                            <Grid item key={5} xs={3} md={3} lg={3} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                                <Typography variant="delta">Local</Typography>
                            </Grid>
                        </Grid>
                    </ItemHeader>
                    {result.length > 0 ? result.map(row => {
                        return (
                            <Item item key={row.id} xs={12} md={12} lg={12}> 
                                <AmplifierList key={row.dS_NUM_SERIE} amplifier={row} /> 
                            </Item> 
                        )
                    }) : <Typography>Nenhum resultado encontrado!</Typography>}
                </Stack>
            }
            {/* <Container sx={{ p: 2 }}>
                <Pagination
                    count={pagination.TotalPages}
                    onChange={pageChange}
                    page={page}
                />
            </Container> */}
        </div>
    )
}