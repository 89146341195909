import { Card, CardHeader, CardContent, Typography, Button, CardActions, Snackbar, OutlinedInput, InputAdornment } from "@mui/material"
import { Computer, Key } from '@mui/icons-material'
import { useState, forwardRef } from "react"
import { makeStyles } from "@mui/styles"
import { useHistory } from "react-router-dom"
import MuiAlert from "@mui/material/Alert"

const useStyles = makeStyles({
    card: {
        padding: "1rem",
        marginTop: "1rem",
        marginBottom: "1rem"
    },
    key: {
        width: "20rem",
        marginBottom: "0rem"
    }
})

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function DesktopLicence(props) {
    const classes = useStyles()
    const history = useHistory()
    const [key, setKey] = useState()
    const [activeButton, setActiveButton] = useState(true)
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [actionType, setActionType] = useState("")

    const keyLength = (e) => {
        if (e.length >= 24) {
            setKey(e)
            setActiveButton(false)
        }
    }

    const RenewLicence = (e) => {
        fetch(`${process.env.REACT_APP_URL}/licences/renew/desktop?key=${key}&serialNumber=${props.systemData.serialKeys}`, {
            method: "GET",
            headers: { "content-type": "application/json" },
        })
            .then(response => response.json())
            .then(data => {
                if (data.message === "Success") {
                    fetch(`${process.env.REACT_APP_URL}/user/light?userId=${props.user.id}&companyId=${props.user.iD_EMPRESA}&key=${key}`, {
                        method: "GET",
                        headers: { "content-type": "application/json" }
                    }).then(res => {
                        setSeverity("success")
                        setOpen(true)
                        setMessage("Sua licença PRO Local foi ativada com sucesso")
                        history.push("/")
                    })
                }
                if (data.message === "Error") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Erro ao ativar licença.")
                }
                if (data.message === "Invalid") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Chave inválida.")
                }



                if (data.message === "NotFound") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Chave não encontrada.")
                }
                if (data.message === "Used") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Esta chave já está em uso. Adquira uma nova chave.")
                }
            })
    }

    const ActivateLicence = (e) => {
        props.handleOpenModal(true)

        fetch(`${process.env.REACT_APP_URL}/licences/activate/desktop?key=${key}&serialNumber=${props.systemData.serialKeys}&userId=${props.user.id}`, {
            method: "GET",
            headers: { "content-type": "application/json" },
        })
            .then(response => response.json())
            .then(data => {
                props.handleOpenModal(false)
                if (data.message === "Success") {
                    fetch(`${process.env.REACT_APP_URL}/user/light?userId=${props.user.id}&companyId=${props.user.iD_EMPRESA}&key=${key}`, {
                        method: "GET",
                        headers: { "content-type": "application/json" }
                    }).then(res => {
                        setSeverity("success")
                        setOpen(true)
                        setMessage("Sua licença PRO Local foi ativada com sucesso")
                        history.push("/")
                    })
                }
                if (data.message === "Error") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Erro ao ativar licença. Verifique se a chave foi inserida corretamente")
                }
                if (data.message === "Invalid") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Chave inválida.")
                }



                if (data.message === "Used") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Esta chave já está em uso. Adquira uma nova chave.")
                }
                if (data.message === "NotFound") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Chave não encontrada.")
                }
            })
    }

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(!open)}>

                <Alert severity={severity} sx={{ width: '100%' }}>{message}</Alert>
            </Snackbar>

            <Card className={classes.card}>
                <CardHeader
                    avatar={<Computer color="primary" />}
                    title={props.actionType === "active" ? "Ativar licença PRO Local" : "Renovar licença PRO Local"} />

                {props.systemData.errorMessage === "Nenhuma central cadastrada. Faça o upload do arquivo ROM."
                    ?
                    <Typography variant="body1" color="error">* Nenhuma Central Cadastrada.</Typography>
                    :
                    <>
                        <CardContent>
                            {/* <InputLabel htmlFor="secret-key">Insira sua chave</InputLabel> */}
                            <OutlinedInput
                                className={classes.key}
                                id="secret-key"
                                onChange={(e) => keyLength(e.target.value)}
                                variant="outlined"
                                color="secondary"
                                fullWidth={true}
                                required
                                //error={secretError}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Key />
                                    </InputAdornment>
                                }
                            ></OutlinedInput>
                            {/* <FormHelperText id="secret-key">{secretError}</FormHelperText> */}
                        </CardContent>
                        <CardActions>
                            {props.actionType === "active"
                                ?
                                <Button
                                    disabled={activeButton}
                                    variant="contained"
                                    color="secondary"
                                    fullWidth={true}
                                    onClick={() => (ActivateLicence())}>Ativar Licença</Button>
                                :
                                <Button
                                    disabled={activeButton}
                                    variant="contained"
                                    color="secondary"
                                    fullWidth={true}
                                    onClick={() => (RenewLicence())}>Renovar Licença</Button>
                            }

                        </CardActions>
                    </>
                }

            </Card >
        </>
    )
}