import React, { useState, useEffect, forwardRef } from 'react'
import { useHistory } from "react-router"
import IconButton from '@mui/material/IconButton'
import { Button, Grid, Typography, Box, Snackbar } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import { Person, VpnKey, Key } from '@mui/icons-material'
import { FormHelperText } from '@mui/material'
import { cnpjMask } from '../components/CnpjMask'
import ApartmentIcon from '@mui/icons-material/Apartment'
import LogoDelta from '../assets/logoDelta.png'
import LogoGuardiao from '../assets/logoPequeno.png'
import TermsModal from '../terms/TermsModal'
import MuiAlert from "@mui/material/Alert"

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => {
    return {
        ressaleHomeKey: {
            width: "25rem"
        },
        ressalePageHomeLeft: {
            [theme.breakpoints.up('sm')]: {
                marginTop: "10%",
                paddingRight: "20%"
            },
            [theme.breakpoints.down('sm')]: {
                padding: '2rem'
            }
        },
        ressaleLogoDeltaHome: {
            justifyItems: "center"
        },
        ressaleHomeImgDiv: {
            display: "flex",
            justifyContent: "center"
        },
        ressalePageHome: {
            [theme.breakpoints.up('sm')]: {
                marginTop: "10%",
                paddingLeft: "20%"
            },
            [theme.breakpoints.down('sm')]: {
                padding: '2rem',
            }
        },
    }
})

export default function ChangePassword(props) {
    const classes = useStyles();
    const [user, setUser] = useState(localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : {})
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const [nameError, setNameError] = useState(false)

    const [passwordError, setPasswordError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [passwordErrorText, setPasswordErrorText] = useState("")

    const [nameErrorText, setNameErrorText] = useState("")

    const [confirmation, setConfirmation] = useState("")
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
    const [confirmationError, setConfirmationError] = useState(false)
    const [passwordConfirmationText, setPasswordConfirmationText] = useState("")

    const [cnpj, setCnpj] = useState("")
    const [cnpjError, setCnpjError] = useState(false)
    const [cnpjErrorText, setCnpjErrorText] = useState("")

    const [key, setKey] = useState("")
    const [keyError, setKeyError] = useState(false)
    const [keyErrorText, setKeyErrorText] = useState("")

    const [userNotFound, setUserNotFound] = useState(false)
    const [invalidKey, setInvalidKey] = useState(false)

    const [accepTerm, setAcceptTerm] = useState(false)

    const history = useHistory()

    const [open, setOpen] = useState(false)
    const [severity, setSeverity] = useState("")
    const [message, setMessage] = useState("")

    const inputChange = (e) => {
        const { name, value } = e.target
        setCnpj(
            value
        )
    }

    useEffect(() => {
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (key.length < 24) {
            setKeyError(true)
            setKeyErrorText("Insira a Chave corretamente!")
        } else {
            setKeyError(false)
            setKeyErrorText("")
        }

        if (cnpj.length < 14) {
            setCnpjError(true)
            setCnpjErrorText("Insira um CNPJ válido!")
        } else {
            setCnpjError(false)
            setCnpjErrorText("")
        }

        if (name.length < 1) {
            setNameError(true)
            setNameErrorText("Insira um novo nome para o Usuário Administrador!")
        } else {
            setNameError(false)
            setNameErrorText("")
        }

        if (password.length < 8) {
            setPasswordError(true)
            setPasswordErrorText("Sua senha deve conter no mínimo 8 caracteres!")
        } else {
            setPasswordError(false)
            setPasswordErrorText("")
        }

        if (confirmation != password) {
            setConfirmationError(true)
            setPasswordConfirmationText("As senhas devem ser iguais!")
        } else {
            setConfirmationError(false)
            setPasswordConfirmationText("")

        }

        if (password.length >= 8 && name && confirmation == password && key.length >= 24) {

            fetch(`${process.env.REACT_APP_URL}/resale/create`, {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({
                    "DS_NOME": name,
                    "SENHA": password,
                    "ID_INTRANET": 0,
                    "DS_CNPJ": cnpj.replace(".", "").replace(".", "").replace("/", "").replace("-", ""),
                    "KEY": key
                })
            }).then(response => {
                if (response.status === 404) {
                    setInvalidKey(true)
                } else {
                    response.json()
                        .then(data => {
                            if (data.message === "Used") {
                                setSeverity("error")
                                setOpen(true)
                                setMessage("Esta chave já está em uso. Adquira uma nova chave.")
                            }
                            if (data.message === "Error") {
                                setSeverity("error")
                                setOpen(true)
                                setMessage("Erro ao ativar licença.")
                            }
                            if (data.message === "NotFound") {
                                setSeverity("error")
                                setOpen(true)
                                setMessage("Chave não encontrada.")
                            }
                            if (data.message === "Invalid") {
                                setSeverity("error")
                                setOpen(true)
                                setMessage("Chave inválida.")
                            }
                            if (data.message === "Success") {
                                setSeverity("success")
                                setOpen(true)
                                setMessage("Usuário cadastrado com sucesso.")
                                const uri = `${process.env.REACT_APP_URL}/account/login?ds_NOME=${data.user.dS_NOME}&sENHA=${password}`
                                const encoded = encodeURI(uri)
                                fetch(encoded, {
                                    method: "GET",
                                    headers: { "content-type": "application/json" },
                                })
                                    .then(res => res.json())
                                    .then(data => {
                                        if (data.message) {
                                            props.token.setToken(data.message)
                                        } else {
                                            props.setToken(data.token)
                                            props.setUserData(data.user)
                                            props.populateData(data.user, data.token)
                                            localStorage.setItem("user", JSON.stringify(data.user))
                                            localStorage.setItem("token", data.token)
                                            //sessionStorage.setItem("serialConnected", "conected")
                                            history.push("/painel")
                                        }
                                    })
                            }

                        })
                        .catch(err => console.log(err))
                }
            })
        }
    }

    const setVisible = () => {
        setShowPassword(!showPassword)
    }

    const setConfirmationVisible = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation)
    }

    const keyLength = (e) => {
        if (e.length >= 24) {
            setKey(e)
            //setActiveButton(false)
        }
    }


    return (
        <Box className={classes.container}>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(!open)}
            >
                <Alert severity={severity} sx={{ width: '100%' }}>{message}</Alert>
            </Snackbar>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <div className={classes.ressalePageHome}>
                        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <Grid container spacing={4}>
                                <Grid justifyContent="center" alignItems="center" item xs={12} md={12} lg={12}>
                                    <Typography variant='h3'>Cadastro de Integrador</Typography>
                                    <Typography variant='body1'>Preencha os campos abaixo para criar a sua conta de Integrador!</Typography>
                                </Grid>

                                <Grid item xs={6} md={6} lg={6}>
                                    <InputLabel htmlFor="usern-name">Nome:</InputLabel>
                                    <OutlinedInput
                                        autoFocus={true}
                                        id="usern-name"
                                        onChange={(e) => setName(e.target.value)}
                                        variant="outlined"
                                        color="secondary"
                                        required
                                        error={nameError}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Person />
                                            </InputAdornment>
                                        }
                                    ></OutlinedInput>
                                    <FormHelperText id="user-name">{nameErrorText}</FormHelperText>
                                </Grid>

                                <Grid item xs={6} md={6} lg={6}>
                                    <InputLabel htmlFor="outlined-adornment-password">Insira o CNPJ da sua empresa:</InputLabel>
                                    <OutlinedInput
                                        required
                                        onChange={inputChange}
                                        id="cnpj"
                                        value={cnpjMask(cnpj)}
                                        error={cnpjError}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <ApartmentIcon />
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="password">{cnpjErrorText}</FormHelperText>
                                </Grid>

                                <Grid item xs={6} md={6} lg={6}>
                                    <InputLabel htmlFor="outlined-adornment-password">Senha:</InputLabel>
                                    <OutlinedInput
                                        required
                                        onChange={(e) => setPassword(e.target.value)}
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        error={passwordError}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <VpnKey />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={setVisible}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="password">{"Sua senha deve conter no mínimo 8 caracteres!"}</FormHelperText>
                                </Grid>

                                <Grid item xs={6} md={6} lg={6}>
                                    <InputLabel htmlFor="outlined-adornment-password">Confirme a Senha:</InputLabel>
                                    <OutlinedInput
                                        required
                                        onChange={(e) => setConfirmation(e.target.value)}
                                        id="confirmation"
                                        type={showPasswordConfirmation ? "text" : "password"}
                                        error={confirmationError}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <VpnKey />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={setConfirmationVisible}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="password">{passwordConfirmationText}</FormHelperText>
                                </Grid>

                                <Grid item xs={6} md={6} lg={6}>
                                    <InputLabel sx={{ width: '100%' }} htmlFor="secret-key">Chave da Licença:</InputLabel>
                                    <OutlinedInput
                                        //className={classes.ressaleHomeKey}
                                        sx={{ width: "25rem" }}
                                        id="secret-key"
                                        onChange={(e) => keyLength(e.target.value)}
                                        variant="outlined"
                                        color="secondary"

                                        required
                                        error={keyError}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Key />
                                            </InputAdornment>
                                        }
                                    ></OutlinedInput>
                                    <FormHelperText id="password">{keyErrorText}</FormHelperText>
                                    {invalidKey
                                        ? <Typography variant="body1" color="error">* Chave Inválida!</Typography>
                                        : null}
                                    {userNotFound
                                        ? <Typography variant="body1" color="error">* usuário não encontrado!</Typography>
                                        : null}
                                </Grid>

                                {/* {userNotFound == false ? null : <Grid item xs={12} md={12} lg={12}><Typography variant='body2' color='#d32f2f'>Usuário e/ou senha incorretos</Typography></Grid>} */}

                                <Grid item xs={12} md={12} lg={12}>
                                    <TermsModal technician={false} ressale={true} multiUser={false} setAcceptTerm={setAcceptTerm} />
                                </Grid>

                                <Grid item xs={12} md={12} lg={12} marginTop={"5px"}>
                                    <Button
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                        fullWidth={true}
                                        disabled={!accepTerm}
                                    >
                                        Criar
                                    </Button>
                                </Grid>

                            </Grid>
                        </form>
                    </div>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <div className={classes.ressalePageHomeLeft}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={12} lg={12}>
                                <div className={classes.ressaleHomeImgDiv}>
                                    <img width={300} height={300} src={LogoGuardiao} alt={"Logo Guardião"} className={classes.ressaleLogoDeltaHome} />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                                <div className={classes.ressaleHomeImgDiv}>
                                    <img width={300} height={120} src={LogoDelta} alt={"Delta Fire"} className={classes.ressaleLogoDeltaHome} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

        </Box>
    );
}