import React, { useEffect, useState } from 'react'
import makeStyles from "@mui/styles/makeStyles"
import { Box, Stack, Paper, Grid, Typography, styled, IconButton, Tooltip, LinearProgress } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Container } from '@mui/material'
import format from "date-fns/format"
import EventAlert from '../components/EventAlert'
import EventMobileItem from '../components/EventMobileItem'
import { Warning } from "@mui/icons-material"
import RefreshIcon from '@mui/icons-material/Refresh'

const useStyles = makeStyles({
    pageEvent: {
        minHeight: "100vh"
    },
    pageEventGrid: {
        width: "calc(100% + 48px) !important"
    },
    stack: {
        maxWidth: "100%"
    }
});

const EventDesktopContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
    [theme.breakpoints.down('xl')]: {
        "max-width": "1090px"
    },
    [theme.breakpoints.down('lg')]: {
        "max-width": "820px"
    },
    [theme.breakpoints.down('md')]: {
        "max-width": "660px"
    },
}))

const EventMobileContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        display: 'none'
    }
}))

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 15
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    height: '50px',
}));

const ReceiveData = (props) => {
    const history = useHistory()
    const classes = useStyles(props)
    const [rows, setRows] = useState([])
    const [serialKey, setSerialKey] = useState()
    const [eventDate, setEventDate] = useState("")
    const [isLoading, setIsLoading] = useState(false);

    const handleDate = (date) => {
        var date = format(new Date(date), 'dd/MM/yyyy HH:mm')
        return date
    }

    const populateData = () => {
        setIsLoading(true);

        fetch(`${process.env.REACT_APP_URL}/UserFetchData/remote?serialKey=${props.systemData.serialKeys}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            }
        })
        .then(response => response.json())
        .then(data => {
            setRows(data);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        populateData()
    }, []);

    useEffect(() => {
        //setIsLoading(true);

        setRows((currentRows) => [...currentRows, props.streaming])
        if (rows.length > 99) 
        {
            rows.shift()
        }
        setSerialKey(props.systemData.serialKeys);
        //setIsLoading(false);
    }, [props.streaming])

    return (
        <>
            {isLoading && 
                <LinearProgress color="secondary" style={{position: "fixed", top: "66px", left: "0", width: "100%", zIndex: "1201"}} /> 
            }
            <center>
                <label><h3>Eventos dos Equipamentos - Hoje</h3></label>
            </center>

            <EventDesktopContainer className={classes.pageEvent}>
                <Box sx={{ flexGrow: 1 }}>
                    <Tooltip title="Recarregar Eventos">
                        <IconButton onClick={() => populateData()}><RefreshIcon/></IconButton>
                    </Tooltip>

                    <ItemHeader key={1} xs={12} md={6} lg={4}>
                        <Grid alignItems="center" container spacing={3}>
                            <Grid item key={2} xs={1} md={1} lg={1}>
                                <Warning />
                            </Grid>

                            <Grid item key={3} xs={2} md={2} lg={2}>
                                <Typography variant="delta">Ocorrência</Typography>
                            </Grid>

                            <Grid item key={4} xs={3} md={3} lg={3}>
                                <Typography variant="delta">Descrição</Typography>
                            </Grid>

                            <Grid item key={5} xs={2} md={2} lg={2}>
                                <Typography variant="delta">I.D.</Typography>
                            </Grid>

                            <Grid item key={6} xs={2} md={2} lg={2}>
                                <Typography variant="delta">Local</Typography>
                            </Grid>

                            <Grid item key={7} xs={2} md={2} lg={2}>
                                <Typography variant="delta">Data</Typography>
                            </Grid>
                        </Grid>
                    </ItemHeader>
                    {rows.length > 0
                    ?
                    <>
                    {
                        rows.slice(0).reverse().map(row => {
                            if (row) {
                                return row.fK_CENTRAL === serialKey ? row.type === "alarme"
                                    ?
                                    null
                                    :
                                    <div>
                                        <Stack>
                                            <Item key={0} xs={12} md={6} lg={4}>
                                                <EventAlert key={row.id} row={row} />
                                            </Item>
                                        </Stack>
                                    </div>
                                    : null
                            }
                        })
                    }   
                    </>
                    :
                    <Box key={1} xs={12} md={12} lg={12}>
                        <center>
                            <h4>Sem Eventos Registrados na Data de Hoje!</h4>
                        </center>
                    </Box>
                    }

                </Box>
            </EventDesktopContainer>

            <EventMobileContainer className={classes.pageEvent}>
                <Tooltip title="Recarregar Eventos">
                    <IconButton onClick={() => populateData()}><RefreshIcon /></IconButton>
                </Tooltip>
                <Grid alignItems="center" container spacing={3} className={classes.pageEventGrid}>
                {rows.length > 0
                ?
                <>
                    {rows.slice(0).reverse().map(row => {
                        if (row) {
                            return row.fK_CENTRAL === serialKey ? row.type === "alarme"
                                ?
                                null
                                :
                                <Grid item key={row.id} xs={12} md={12} lg={12}>
                                    <EventMobileItem row={row} />
                                </Grid>
                                : null
                        }
                    })}
                </>
                        :
                <Grid key={1} xs={12} md={12} lg={12}>
                    <center>
                        <h4>Sem Eventos Registrados na Data de Hoje!</h4>
                    </center>
                </Grid>
                }
                </Grid>
            </EventMobileContainer>
        </>
    );
}

export default ReceiveData;