import React, { useEffect, useState } from 'react'
import makeStyles from "@mui/styles/makeStyles"
import { Box, Stack, Paper, Grid, Typography, styled, IconButton, Tooltip, LinearProgress } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Container } from '@mui/material'
import CommandItem from "../components/CommandItem"
import AlarMobileItem from '../components/AlarmMobileItem'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import RefreshIcon from '@mui/icons-material/Refresh'

const useStyles = makeStyles((theme) => {
    return {
        pageEvent: {
            minHeight: "100vh"            
        },
        pageEventGrid: {
            width: "calc(100% + 48px) !important"
        },
        stack: {
            maxWidth: "100%"
        },
        alarmPageHeader: {
            height: "100%",
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"alarmPageItem1 alarmPageItem2 alarmPageItem3 alarmPageItem4 alarmPageItem5 alarmPageItem6"`,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"alarmPageItem1 alarmPageItem2 alarmPageItem3 alarmPageItem3" 
                "alarmPageItem4 alarmPageItem5 alarmPageItem6 alarmPageItem6"`,
            }
        },
        alarmPageItem1: {
            gridArea: "alarmPageItem1",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        alarmPageItem2: {
            gridArea: "alarmPageItem2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        alarmPageItem3: {
            gridArea: "alarmPageItem3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        alarmPageItem4: {
            gridArea: "alarmPageItem4",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        alarmPageItem5: {
            gridArea: "alarmPageItem5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        alarmPageItem6: {
            gridArea: "alarmPageItem6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    }
});

const AlarmDesktoContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
    [theme.breakpoints.down('xl')]: {
        "max-width": "1090px"
    },
    [theme.breakpoints.down('lg')]: {
        "max-width": "820px"
    },
    [theme.breakpoints.down('md')]: {
        "max-width": "660px"
    },
}))

const AlarmMobileContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        display: 'none'
    }
}))

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 15,
    width: '100%'
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    height: '50px',
    width: '100%'
}));

export default function Alarms(props) {
    const history = useHistory()
    const classes = useStyles(props)
    const [rows, setRows] = useState([])
    const [serialKey, setSerialKey] = useState()
    const [commands, setCommands] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const populateData = () => {
        setIsLoading(true);

        fetch(`${process.env.REACT_APP_URL}/command/all`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify({
                "serialNumber": props.systemData.serialKeys,
            })
        })
        .then(res => res.json())
        .then(data => { 
            setCommands(data);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        populateData()
    }, []);

    useEffect(() => {
        setRows((currentRows) => [...currentRows, props.streaming])
        if (rows.length > 99) {
            rows.shift()
        }
        setSerialKey(props.systemData.serialKeys)
    }, [props.streaming])

    return (
        <>
            {isLoading && 
                <LinearProgress color="secondary" style={{position: "fixed", top: "66px", left: "0", width: "100%", zIndex: "1201"}} /> 
            }
            <center>
                <label><h3>Comandos de Alarmes - Hoje</h3></label>
            </center>

            <AlarmDesktoContainer className={classes.pageEvent}>

                <Box sx={{ flexGrow: 1 }}>
                    <Tooltip title="Recarregar Alarmes">
                        <IconButton onClick={() => populateData()}><RefreshIcon /></IconButton>
                    </Tooltip>

                    <Stack className={classes.stack} alignItems="center" spacing={2} marginTop={1}>
                        <ItemHeader key={0} xs={12} md={6} lg={4}>
                            <Box className={classes.alarmPageHeader}>
                                <Box className={classes.alarmPageItem1}><VolumeUpIcon /></Box>
                                <Box className={classes.alarmPageItem2}> <Typography variant="delta">Central</Typography></Box>
                                <Box className={classes.alarmPageItem3}><Typography variant="delta">Descrição</Typography></Box>
                                <Box className={classes.alarmPageItem4}><Typography variant="delta">Local</Typography></Box>
                                <Box className={classes.alarmPageItem5}><Typography variant="delta">Origem</Typography></Box>
                                <Box className={classes.alarmPageItem6}><Typography variant="delta">Data</Typography></Box>
                            </Box>
                        </ItemHeader>
                        {commands.length > 0
                        ?
                        <>
                        {commands.slice(0).reverse().map(command => (
                            <Item key={command.id} xs={12} md={6} lg={4}>
                                <CommandItem commandData={command} />
                            </Item>
                        ))}
                        </>
                        :
                        <>
                            <Box key={1} xs={12} md={6} lg={4}>
                                <center>
                                    <h4>Sem Alarmes Registrados na Data de Hoje!</h4>
                                </center>
                            </Box>
                        </>
                        }
                    </Stack>
                </Box>
            </AlarmDesktoContainer>

            <AlarmMobileContainer className={classes.pageEvent}>
                <Tooltip title="Recarregar Alarmes">
                    <IconButton onClick={() => populateData()}><RefreshIcon /></IconButton>
                </Tooltip>

                <Grid alignItems="center" container spacing={3} className={classes.pageEventGrid}>
                    {commands.length > 0
                    ?
                    <>
                    {commands.slice(0).reverse().map(command => (
                        <Grid item key={command.id} xs={12} md={12} lg={12}>
                            <AlarMobileItem key={command.id} commandData={command} />
                        </Grid>
                    ))}
                    </>
                    :
                        <>
                        <Grid key={1} xs={12} md={12} lg={12}>
                            <center>
                                <h4>Sem Alarmes Registrados na Data de Hoje!</h4>
                            </center>
                        </Grid>
                    </>
                    }
                </Grid>
            </AlarmMobileContainer>
        </>
    );
}