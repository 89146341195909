import { useState, useEffect } from "react"
import { Card, Typography, Container, Grid } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import UserCard from "../components/UserCard"

const useStyles = makeStyles({
    pageUser: {
        minHeight: "100vh",
    },
    card: {
        padding: "3rem"
    },
    userLicenceCard: {
        padding: "1rem",
        marginTop: "1rem",
        marginBottom: "1rem"
    }
})

export default function ResaleUser(props) {
    const classes = useStyles()
    const [userList, setUserList] = useState([])
    const [licences, setLicences] = useState([])

    const removeUser = () => {

    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/user/all?companyId=${props.user.iD_EMPRESA}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            }
        })
            .then(response => response.json())
            .then(data => setUserList(data))

        if (props.user.role.revenda === true) {
            fetch(`${process.env.REACT_APP_API_URL}/licences/resale/licence?id_intranet=${props.user.iD_INTRANET}`, {
                method: 'GET',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + props.token
                }
            }).then(response => response.json())
                .then(data => setLicences(data)).catch((error) => { })
        }

        if (props.user.role.multiCentral === true) {
            fetch(`${process.env.REACT_APP_API_URL}/licences/multi/licence?id_intranet=${props.user.iD_INTRANET}`, {
                method: 'GET',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + props.token
                }
            }).then(response => response.json())
                .then(data => setLicences(data)).catch((error) => { })
        }

    }, [])

    return (
        <Container className={classes.pageUser}>
            {licences.map(item => {
                return <Card className={classes.userLicenceCard} key={item.chave}>
                    <Grid container spacing={3}>
                        <Grid item xs={3} md={3} lg={3}>
                            <Typography variant="body2">Licença {item.tipo_licenca}</Typography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <Typography variant="body2">Chave {item.chave}</Typography>
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                            <Typography variant="body2">Validade {new Date(item.expiracao_licenca).toLocaleDateString()}</Typography>
                        </Grid>
                        <Grid item xs={2} md={2} lg={2}>
                            <Typography variant="body2">{item.chave_utilizada ? "Ativada" : "Não Ativada"}</Typography>
                        </Grid>
                    </Grid>
                </Card>
            })}

            <Grid container spacing={3}>
                {userList.map(user => (
                    <Grid key={user.id} item xs={12} md={12} lg={6}>
                        <UserCard removeUser={removeUser} key={user.id} user={user} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}