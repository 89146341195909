import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Classe
const FormControl = ({ children, onChange, controlId = '', controlName = null,  controlLabelPosition = '', controlLabel = '', controlMessage = {content: null, color: null}, controlRequired = false, controlType = "", controlRegex = null, controlPlaceholder = null, controlMin = null, controlMax = null, controlMinLength = null, controlMaxLength = null, controlValue = null, controlDisabled = null, controlPattern = null }) => {
    let response = null;
    let wrapClass = "";
    
    let options = null;

    const [inputType, setInputType] = useState(controlType);

    React.Children.forEach(children, child => {
        if (child.type === FormControl.Options) 
        {
            options = child;
        }
    });

    const inputAttributes = {
        type: inputType,
        id: controlId,
        class: "form__item__control",
        ...(controlName && {name: controlName}),
        ...(controlRegex && {pattern: controlRegex}),
        ...(controlPlaceholder && {placeholder: controlPlaceholder}),
        ...(controlMin && { min: controlMin}),
        ...(controlMax && { max: controlMax}),
        ...(controlMinLength && { minlength: controlMinLength }),
        ...(controlMaxLength && { maxlength: controlMaxLength }),
        ...(controlValue && { value: controlValue }),
        ...(controlRequired && { required: true }),
        ...(controlDisabled && { disabled: true }),
        ...(controlPattern && { pattern: controlPattern})
    };

    switch (controlLabelPosition)
    {
        case "horizontal":
            wrapClass = "form__item--label-horizontal";
            break;
        case "float":
            wrapClass = "form__item--float";
            break;
        default:
            wrapClass = "form__item--label-vertical";
            break;
    }

    const togglePasswordVisibility = () => {
        // Alterna entre "password" e "text"
        setInputType(prevType => prevType === 'password' ? 'text' : 'password');
    };

    switch (controlType)
    {
        case "select":
            response = (
                <div className={`form__item ${wrapClass} form__item--select`}>
                    <label for={controlId}>{controlLabel}</label>
                    <select {...inputAttributes} onChange={onChange}>
                        {options}
                    </select>
                </div>
            );
            break;
        case "textarea":
            break;
        default:
            response = (
                <div className={`form__item ${wrapClass}`}>
                    <label for={controlId}>{controlLabel}</label>
                    <input {...inputAttributes} onChange={onChange}/>
                    {controlType === "password" && (
                        <button type="button" class="button button--transparent form__item__spy" onClick={togglePasswordVisibility}>
                            <i className={`button__icon bx bx-sm ${inputType === 'password' ? 'bx-show' : 'bx-hide'}`}></i>
                        </button>
                    )}
                    {controlMessage.content &&
                        <p class="my-0"><small class={controlMessage.color}>{controlMessage.content}</small></p>
                    }
                </div>
            )
            break;
    }

    return (response);
  };
  
  FormControl.propTypes = {
      children: PropTypes.node.isRequired,
      className: PropTypes.string,
      controlId: PropTypes.string,
      controlName: PropTypes.string,
      controlType: PropTypes.oneOf(['text', 'textarea', 'email', 'number', 'password', 'select', 'url']), // TODO file, radio, checkbox, range, image, search, color
      controlLabelPosition: PropTypes.oneOf(['vertical', 'horizontal', 'float']),
      controlLabel: PropTypes.string,
      controlRegex: PropTypes.string,
      controlPlaceholder: PropTypes.string, 
      controlMin: PropTypes.number, 
      controlMax: PropTypes.number, 
      controlMinLength: PropTypes.number,
      controlMaxLength: PropTypes.number,
      controlValue: PropTypes.any,
      controlRequired: PropTypes.bool,
      controlDisabled: PropTypes.bool,
      controlPattern: PropTypes.string
  };

  //Subcomponentes
  FormControl.Options = ({ children }) => <>{children}</>;
  
  export default FormControl;