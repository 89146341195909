import React, { useState, useEffect } from "react"
import { Box } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import ChangePassword from "../components/ChangePassword"

const useStyles = makeStyles((theme) => {
    return {
        grid: {
            justifyContent: "center"
        },
        container: {
            backgroundColor: '#f9f9f9',
            width: '100%',
            //height: '100% !important',
        },
        pageHome: {
            [theme.breakpoints.up('sm')]: {
                marginTop: "auto",
                paddingLeft: "auto"
            },
            [theme.breakpoints.down('sm')]: {
                padding: '2rem',
            }
        },
        pageHomeLeft: {
            [theme.breakpoints.up('sm')]: {
                marginTop: "20%",
                paddingRight: "20%"
            },
            [theme.breakpoints.down('sm')]: {
                padding: '2rem'
            }
        },
        logoDeltaHome: {
            justifyItems: "center"
        },
        imgDiv: {
            display: "flex",
            justifyContent: "center"
        },
    }
})

export default function TechnicianFirstLogin(props) {
    const classes = useStyles()

    return (
        <Box sx={{ py: "10vw" }}>
            <ChangePassword technician={true} populateData={props.populateData} data={props} />
        </Box>
    )
}