export default function UserTermText() {
    return (
        <>
            <p>
                DELTA FIRE LTDA., na forma de seus atos constitutivos, aqui denominada DELTA FIRE e/ou
                LICENCIANTE, na qualidade de produtora dos softwares DELTA FIRE abaixo relacionados e
                prestadora de serviços e, do outro lado, na condição de USUÁRIO dos softwares e programas
                DELTA FIRE e CONTRATANTE dos serviços prestados, aqui denominado CLIENTE ou USUÁRIO
                ou, ainda, LICENCIADA, este qualificado na NOTA FISCAL RESPECTIVA, contratam entre si, nos
                termos das condições a seguir aduzidas.
            </p>
            <p>
                <strong>1. DO OBJETO:</strong> Constitui objeto do presente termo a concessão de licença de uso, em favor do
                CLIENTE, sem exclusividade, do(s) software(s) objeto(s) da nota fiscal de compra e venda em
                anexo, conforme o disposto no art. 9º da Lei 9.609/98, bem como, a prestação dos serviços a
                ele correlatos, estes nos termos do art. 8º da Lei 9.609/98, que também poderão ser prestados
                por meio de representantes credenciados pela LICENCIANTE.
            </p>
            <p>
                <strong>2. DO USO:</strong> A LICENCIANTE, neste ato, cede à LICENCIADA, o direito de uso de uma cópia do(s)
                programa(s) de computador licenciado(s), na forma e no tempo determinado na nota fiscal de
                venda e/ou serviços respectiva, para que seja instalado em 1 (um) único computador. O direito
                de uso de software que ora se contrata (LICENÇA DE USO) é licenciado para utilização apenas na
                sede da CONTRATANTE/LICENCIADA. A DELTA FIRE colocará à disposição do CLIENTE os serviços
                de manutenção e suporte que serão prestados mediante demanda do CLIENTE, conforme
                valores, níveis, procedimentos, horários e demais condições praticadas pela DELTA FIRE no
                momento da prestação dos serviços, os quais serão oportunamente informados ao Cliente
                quando da solicitação do serviço. Os serviços de manutenção e suporte serão oferecidos por
                pessoal da DELTA FIRE, de forma remota, nas instalações da própria LICENCIANTE. Caso o Cliente
                necessite que os serviços de manutenção e suporte sejam realizados localmente em seus
                estabelecimentos ou que seja necessário realizar serviços adicionais específicos, as Partes
                poderão negociar por uma contratação específica por meio de apresentação de proposta e
                formalização de instrumento particular. A LICENCIADA afirma que teve a oportunidade de
                avaliar o uso e características do referido software, tendo conhecimento de suas
                funcionalidades, assim como eventuais limitações, caso existentes.
            </p>
            <p>
                <strong>3. DO HARDWARE:</strong> A LICENCIADA se responsabiliza pelo hardware que se faz necessário para a
                correta execução do software ora licenciado, assim como pela correta utilização e manutenção
                do mesmo. Caso A LICENCIADA opte pela aquisição do hardware (PC) por outros meios, deverá
                solicitar à LICENCIANTE os requisitos mínimos de hardware para o correto funcionamento do
                software Delta Fire. Se o hardware for adquirido através da LICENCIANTE, a cobertura de
                garantia é oferecida conforme Manual de Garantia Delta Fire. Vide www.deltafire.com.br/mg
            </p>
            <p>
                <strong>4. DA PIRATARIA:</strong> Em sendo detectado que o(s) Software(s), o(s) Programa(s) e acessórios
                esteja(m) sendo utilizado(s) de forma diversa daquela expressamente contratada, restará
                interpretado que a LICENCIADA agirá ilegalmente, considerando-se “PIRATARIA”. Neste caso,
                em sendo realmente comprovado o delito, arcará, a LICENCIADA/USUÁRIO, com uma multa
                penal a ser paga à LICENCIANTE no valor equivalente ao valor do objeto/software na sua versão
                paga, ou seja, neste caso, ao valor do software DELTA FIRE equivalente não gratuito mais o
                período identificado de uso.

            </p>
            <p>
                <strong>5. DA MANUTENÇÃO CORRETIVA:</strong> O CLIENTE declara e dá plena ciência, ainda, que a DELTA FIRE
                poderá, a qualquer tempo, utilizar informações de uso do programa para a manutenção
                preventiva, corretiva e planejamento de melhorias nos equipamentos da LICENCIADA e
                monitorando rotinas de acesso e uso, sem, contudo, implicar em permissão ao acesso aos dados.
            </p>
            <p>
                <strong>6. DA PROPRIEDADE PATRIMONIAL E INTELECTUAL:</strong> O licenciamento que ora se contrata por
                meio do presente instrumento não constitui qualquer espécie de cessão ou transferência de
                direitos patrimoniais ou autorais sobre os programas de computador, sistemas e/ou módulos
                licenciados, seja em sua versão original ou mesmo cópia, mas tão somente formaliza uma
                outorga à LICENCIADA do direito de uso do mesmo. Em assim sendo e, de acordo com a
                legislação aplicável, anui e concorda expressamente, o CLIENTE, que é absolutamente proibida
                qualquer forma de engenharia reversa, descompilação, desmonte do software ou de outra
                forma tentar fazê-lo. O licenciamento que ora se contrata por meio do presente instrumento
                EXCLUI DE MANEIRA EXPRESSA qualquer propriedade em virtude de customização, modificação,
                complementação, incremento ou funcionalidade realizada no programa a pedido do CLIENTE,
                sendo que, quaisquer dos novos elementos produzidos incorporar-se-ão ao(s)
                Software(s)/Sistema(s) da DELTA FIRE e a esta tão somente pertencerá. A LICENCIADA
                reconhece expressamente, por meio da adesão ao presente Termo, que o
                Software/Programa/Sistema/Módulos/Interfaces/Esquemas/Fluxos, assim como os logotipos,
                marcas, insígnias, símbolos, sinais distintivos, manuais, documentação técnica associada e
                quaisquer outros materiais correlatos ao Software, constituem, conforme o caso, direitos
                autorais, segredos comerciais e/ou licenças de propriedade da LICENCIANTE, sendo tais direitos
                e demais dispositivos específicos acima identificados, protegidos pela legislação nacional e
                internacional vigente aplicável à propriedade intelectual e industrial e aos direitos autorais,
                especialmente, no Brasil, pelo disposto nas Leis nº 9.609 e 9.610 de 1.998, sendo crime sua
                infração.
            </p>
            <p>
                <strong>7. DA ABRANGÊNCIA:</strong> O licenciamento do direito de uso de software de que trata este contrato,
                se refere exclusivamente à LICENCIADA e às cópias do mesmo, devidamente licenciadas e
                personalizadas dos módulos executáveis do Programa, que serão instaladas (ou conferido
                acesso, em caso de SISTEMAS em nuvem) unicamente nos equipamentos de processamento de
                dados próprios da LICENCIADA, situados na sede desta ou em suas filiais, agências ou sucursais,
                prévia e devidamente identificadas como tal e assim consideradas pela CONTRATADA, na forma
                do TERMO DE ADESÃO ELETRÔNICO respectivo.
                Fica expressamente vedado à CONTRATANTE, em relação ao objeto desta, ceder, doar, alugar,
                vender, arrendar, emprestar, reproduzir, modificar, adaptar, traduzir, disponibilizar ao acesso
                de terceiros diretamente ou via on-line, acesso remoto ou de outra forma de transmissão de
                dados existentes ou mesmo que venha a ser criado, oferecer em garantia ou penhor; alienar ou
                transferir, total ou parcial a qualquer título de forma onerosa ou gratuita; decompilar, mudar a
                engenharia (reengenharia), enfim, dar qualquer outra destinação ao
                SOFTWARE/PROGRAMA/SISTEMA E SEUS MÓDULOS, integral ou parte dele que não seja a
                simples e específica utilização nos moldes ora contratados expressamente. Entende-se como
                terceiros qualquer pessoa física ou jurídica que não seja exclusivamente a CONTRATANTE já
                prévia e anteriormente qualificada.
            </p>
            <p>
                <strong>8. DA NÃO EXCLUSIVIDADE:</strong> As partes contratantes declaram expressamente, por meio do
                presente instrumento, que a LICENCIANTE fica livre e desimpedida para, como melhor lhe
                aprouver, promover o licenciamento do Software/Programa e/ou seus Módulos a terceiros, bem
                como para a cessão, locação, venda ou alienação integral ou parcial do mesmo a qualquer outro
                interessado, pela própria LICENCIANTE ou por terceiros, independentemente de anuência,
                aceitação, autorização ou mesmo de qualquer outra forma de intervenção prévia ou posterior
                da LICENCIADA, seja ela tácita ou expressa, não mantendo qualquer relação de vínculo ou
                controle, direto ou difuso, extracontratual, a LICENCIADA, face ao produto ora contratado e de
                propriedade exclusiva da LICENCIANTE.
            </p>
            <p>
                <strong>9. DAS RESPONSABILIDADES DA LICENCIADA</strong> É de exclusiva responsabilidade do CLIENTE, nos
                casos de licença onerosa, efetuar todos os pagamentos citados na nota fiscal e/ou proposta
                comercial e/ou ordem de compra, nas respectivas datas dos seus vencimentos, na forma
                pactuada. Qualquer atraso por parte da LICENCIADA, em qualquer dos pagamentos previstos no
                pedido ou, ainda, em nota fiscal, seja ele referente às parcelas de adesão/instalação,
                treinamento, suporte, interfaceamentos ou serviços correlatos do SOFTWARE/SISTEMA ou
                referente à licença do mesmo, por período superior a 10 (dez) dias corridos, contados a partir
                do vencimento da parcela inadimplida, importará na adoção imediata de todas as providências
                que a lei lhe assegura para a satisfação de seu crédito, sem prejuízo das perdas e danos
                correspondentes e decorrentes de tal impontualidade, juros de mora, correção monetária,
                honorários advocatícios e custas judiciais caso necessários, aplicando-se as penalidades
                descritas neste instrumento e/ou em lei. PARA ESTE FIM, DECLARA-SE QUE OS ATOS DE
                PROTESTO E INCLUSÃO DE NOME/CNPJ NOS CADASTROS RESTRITIVOS DE CRÉDITO, na forma
                da Lei, serão realizados quando constituído em mora o CLIENTE, o que se dá, no presente caso,
                pelo inadimplemento da obrigação em seu termo. Manter a supervisão, administração e
                controle do correto e adequado uso do programa designando pessoal capacitado para a
                operação devida do mesmo, nos termos do treinamento oferecido, sob pena de isenção de
                qualquer responsabilidade da LICENCIANTE em caso de erro na operação ou na operação em
                desconformidade com as orientações e treinamentos repassados pela LICENCIANTE no ato da
                instalação. A LICENCIADA se obriga a manter, em suas instalações, sede e unidades vinculadas,
                sob pena de rescisão do presente contrato – à escolha da DELTA FIRE - por inobservância de
                regra técnica imprescindível a efetivação do que ora se pactua, microcomputadores,
                equipamentos de informática e periféricos, dentro das ESPECIFICAÇÕES E CONFIGURAÇÕES
                MÍNIMAS estabelecidas, de forma a não só viabilizar o perfeito funcionamento, mas também, o
                melhor desempenho do produto e dos serviços ora contratados. A LICENCIADA se obriga a
                informar à LICENCIANTE, dentro do menor lapso possível, qualquer anomalia ocorrida no
                decorrer do processamento de informações, diretas ou indiretas, bem como no funcionamento
                do PROGRAMA. O uso do programa de forma diversa da expressamente estipulada no presente
                contrato, à divulgação do todo ou em partes, do programa e da sua documentação (manuais,
                descrição técnica, etc.), bem como qualquer alteração no programa procedida sem o prévio e
                expresso consentimento da LICENCIANTE, enseja o autor de tal ato a todas as sanções previstas
                no nosso ordenamento jurídico bem como, e ainda, à reparação pelas perdas, danos e lucros
                cessantes decorrentes de tal ilícito, não afastando a aplicação concomitante das penalidades
                previstas no contrato de licenciamento de uso do SOFTWARE/SISTEMA. É DE
                RESPONSABILIDADE EXCLUSIVA DA LICENCIADA A CORRETA INSERÇÃO DOS DADOS NO SISTEMA
                E SEUS BANCOS DE DADOS, ISENTANDO-SE EXPRESSAMENTE A LICENCIANTE DE QUALQUER
                RESPONSABILIDADE, DIRETA, INDIRETA OU DECORRENCIAL NA OCORRÊNCIA DE DANO, AINDA.
            </p>
            <p>
                <strong>10. DAS RESPONSABILIDADES DA LICENCIANTE:</strong> Garantir à LICENCIADA o correto
                funcionamento técnico do SOFTWARE, responsabilizando-se pela perfeita funcionalidade e
                operacionalidade do mesmo, detectando e/ou corrigindo erros de operação, configuração,
                programação e instalação inicial do SOFTWARE/SISTEMA, verificado pela manutenção
                remota/local do sistema ou indicados pela LICENCIADA. A LICENCIANTE está isenta de qualquer
                responsabilidade pela ocorrência de erros, inoperâncias ou demais inconsistências de
                programação e execução do SISTEMA caso estas resultem de erros na operação do sistema, dos
                periféricos dos equipamentos da LICENCIADA ou mesmo de alterações, modificações ou
                quaisquer outras intervenções no sistema, procedidas pela LICENCIADA ou por seus funcionários
                e ou prepostos sem a devida, prévia, expressa e monitorada autorização da LICENCIANTE.
                Prover, à LICENCIADA, a assistência técnica remota, relativa à operação do SISTEMA, elucidando
                as consultas feitas pela pessoa indicada expressamente pela LICENCIADA para tais contatos,
                sobre a operação do programa, assessorando-a tanto para a otimização no uso dos recursos do
                mesmo quanto para o esclarecimento de eventuais dúvidas. As consultas poderão ser
                procedidas de forma variada: telefônica, eletrônica e escrita, e deverão ser encaminhadas ao
                departamento técnico específico da LICENCIANTE que dará aos questionamentos apresentados,
                no menor lapso possível o retorno apropriado. As consultas telefônicas e eletrônicas serão
                atendidas dentro do horário comercial, em dias úteis, sendo o horário compreendido entre
                08:00 e 11h45min e de 12h45min às 17h48min, vinculados ao horário de Brasília-DF. O suporte
                técnico telefônico será prestado pela LICENCIANTE nos dias e horários descritos acima, exceto
                nos feriados nacionais, estaduais e municipais tomando como base a localidade onde se
                encontra a LICENCIADA, visando dirimir dúvidas referentes ao funcionamento do Programa.
                Disponibilizar, à LICENCIADA, as novas versões do SISTEMA, que futuramente venham a ser
                desenvolvidas, tanto para otimização do sistema como para atendimento especificamente à
                legislação em vigor caso esta venha a ser alterada, incrementada ou suprimida, acompanhadas
                das instruções e orientações técnicas necessárias para o seu uso e perfeito funcionamento, sem
                ônus adicional. A atualização acontecerá quando houver mudança de legislação que exija
                alteração no SISTEMA, novos aperfeiçoamentos técnicos, atualizações de manuais do SISTEMA
                em meio eletrônico ou por solicitações da CONTRATANTE desde que previamente aprovadas
                pela LICENCIANTE. Cabe, exclusivamente à LICENCIANTE, decidir sobre as mudanças
                tecnológicas do SISTEMA que julgar necessárias, sejam estas por força da Legislação Brasileira
                vigente ou melhoria do desempenho do Programa. Manter e providenciar para que seja
                mantido, por seus funcionários, prepostos, credenciados e representantes, o mais absoluto
                sigilo quanto às informações da LICENCIADA, seus clientes, rotinas, características,
                metodologias, etc., a que tiver acesso por ocasião da instalação e/ou manutenção do Programa,
                bem como usá-las estritamente no desempenho das atividades previstas neste contrato, sob
                pena de responsabilidade por uso indevido.
            </p>
            <p>
                <strong>11. CONDIÇÕES GERAIS:</strong> Para os casos de customização, quando houver, o licenciamento do
                direito de uso de qualquer funcionalidade adicional do programa, já existente ou que a
                LICENCIANTE venha a desenvolver com especificações exclusivas para a LICENCIADA,
                denominados simplesmente de CUSTOMIZAÇÃO, além daqueles contratados neste ato pela
                LICENCIADA, se for o caso, será objeto de nova e específica negociação, prazos e condições
                técnicas e físicas para sua implementação, independentemente de sua contratação ao final do
                5
                Documento controlado: TLES-JUR- REV0 – 12/08/2022
                DELTA FIRE Ltda. R. Pinheiro Machado, 3271 Caxias do Sul/RS, CEP 95020-172 - Fone: 54 3204-4000
                www.deltafire.com.br
                desenvolvimento por parte da LICENCIADA, posto que os custos de tal projeto serão de
                responsabilidade da LICENCIADA. As CUSTOMIZAÇÕES, mesmo que procedidas em
                complemento à outra já contratada, procedida e instalada no sistema da LICENCIADA, serão
                objetos de novas negociações de preços e prazos para implementação. Toda e qualquer
                CUSTOMIZAÇÃO será precedida, necessariamente, de análise de viabilidade (devidamente
                orçada e aprovada) e, quando viável, deverá ser, igualmente, orçada e aprovada. Os custos e
                despesas de quaisquer outros treinamentos ou reciclagens de operadores do SISTEMA, além do
                previsto, quando solicitados expressamente pela LICENCIADA, serão acordados previamente
                entre as partes e pagos exclusiva e integralmente pela LICENCIADA, nos termos do previsto
                neste instrumento e/ou em aditivo próprio. Qualquer alteração na configuração original dos
                equipamentos de processamento de dados da LICENCIADA que possa ser causa de interferência
                ou conflito na operacionalidade do programa ou no ambiente operacional dos mesmos
                equipamentos, nos quais o programa e módulos objetos do PROGRAMA foram instalados,
                mesmo que se mantenham dentro das especificações mínimas elencadas e delimitadas
                expressamente nos anexos do presente instrumento, deve ser prévia e formalmente
                comunicada à LICENCIANTE, sob pena de responsabilização direta e exclusiva da LICENCIADA
                diante da ocorrência de inoperância, erro, descontinuidade ou desconfiguração do programa,
                isentando, com isso, de qualquer responsabilidade a LICENCIANTE, na ocorrência de tais
                episódios. O funcionamento imperfeito do programa, ocasionado por operação indevida ou fora
                das especificações, por falhas no ambiente operacional ou por qualquer forma de "vírus" que
                venha a ser detectada, ou ainda, por defeitos técnicos de rede ou ocorridos com os
                equipamentos (hardwares e periféricos), ou ainda de qualquer outra forma de erro que não
                mantenha vinculação com a programação original do software, é de inteira e exclusiva
                responsabilidade da LICENCIADA, não cabendo qualquer responsabilidade à LICENCIANTE sobre
                tais episódios, seus desdobramentos ou mesmo os danos, perdas e demais prejuízos deles
                decorrentes. O funcionamento imperfeito dos equipamentos de processamento de dados de
                propriedade ou alocado pela LICENCIADA, em sua sede ou unidades remotas, ocorrido por
                estarem fora das especificações mínimas citadas neste instrumento e/ou contrato, por
                instalações indevidas ou mesmo de má qualidade, é de única e exclusiva responsabilidade da
                LICENCIADA e ensejarão a cobrança, nos termos do presente instrumento, caso haja
                necessidade de reparo, reinstalação ou manutenção a ser realizada nas dependências da
                LICENCIADA.
            </p>
            <p>
                <strong>12. DA EXCLUSÃO DA RESPONSABILIDADE:</strong> As obrigações assumidas pela LICENCIANTE, neste
                termo, não incluem, nem pressupõem, em hipótese alguma, qualquer indenização, seja a que
                título for, por dano ou cessação de lucros, caso ocorra disfunção do programa. A LICENCIADA
                declara expressamente reconhecer, bem como ACEITAR que: a) o estado da técnica bem como
                da atual tecnologia de desenvolvimento de software disponível, não permite o desenvolvimento
                de sistemas totalmente isentos de vícios ou de eventuais defeitos/inconformidades de
                operacionalidade e que, em razão de tal condição, a LICENCIANTE não poderá garantir, como de
                fato não garante, que o Programa operará ininterruptamente ou livre de quaisquer eventuais
                vícios ou defeitos e; b) a responsabilidade na LICENCIANTE limita-se: às características descritas
                nos manuais e demais documentos que acompanham o programa bem como nas eventuais
                alterações ou mesmo customizações solicitadas expressamente pela LICENCIADA, procedidas
                nos termos e condições previstos claramente no presente instrumento (aprovadas e contratadas
                expressamente) e desenvolvidas exclusivamente pela LICENCIANTE; c) O Programa base ou seus
                MÓDULOS, quando houver, não foram desenvolvidos sob encomenda da LICENCIADA, mas sim
                6
                Documento controlado: TLES-JUR- REV0 – 12/08/2022
                DELTA FIRE Ltda. R. Pinheiro Machado, 3271 Caxias do Sul/RS, CEP 95020-172 - Fone: 54 3204-4000
                www.deltafire.com.br
                para uso de uma gama indefinível de clientes, razão pela qual a CONTRATADA não pode garantir
                que o mesmo atenderá integralmente todas as necessidades técnicas da LICENCIADA,
                excetuando-se o caso de customizações específicas contratadas expressamente para tal fim,
                quando o caso permitir, as quais terão seu desenvolvimento (de ferramentas e funções) de
                forma pontual e dirigida, ressalvando-se, contudo, a não exclusividade da LICENCIADA em
                relação ao desenvolvimento de tais customizações, sendo assegurado à LICENCIANTE a livre
                comercialização/cessão de tais ferramentas, após seu desenvolvimento. Estabelecem e pactuam
                expressamente as partes contratantes que, nenhuma informação ou orientação verbal ou
                escrita, dada pela LICENCIANTE, seus prepostos, distribuidores, agentes ou assemelhados; por
                qualquer meio de comunicação existente ou que venha a ser criado, terá o condão de criar nova
                garantia ou mesmo de estender a abrangência e os efeitos do presente instrumento que,
                juntamente com seus anexos e eventuais aditivos (os quais o comporão para todos os efeitos),
                será o único documento válido para reger a relação ora contratada e, neste sentido, a
                LICENCIADA não poderá embasar-se em tais informações/orientações não previstas em
                contrato para poder exigir, questionar ou mesmo reivindicar qualquer outra forma de relação
                não prevista formalmente entre as partes.
            </p>
            <p>
                <strong>13.DAS GARANTIAS:</strong> As garantias estipuladas não abrangem danos ou prejuízos advindos de
                defeitos ou erros decorrentes de negligência, imprudência ou imperícia do usuário, seus
                empregados ou prepostos, assim como, problemas provenientes de falha de equipamento, de
                "caso fortuito" ou "força maior", contemplados pelo art. 393 do Código Civil Brasileiro. 8.1.1 Tais
                garantias também não contemplam a ocorrência de interrupções de ordem técnica referentes
                à impossibilidade de conexão e de transmissão de dados entre as partes, decorrentes de falhas
                na prestação de serviços por parte das concessionárias e empresas de telecomunicação
                contratadas e que servem, seja à contratante, seja à contratada, eximindo esta última de toda
                e qualquer responsabilidade pela ocorrência de tais interrupções na transmissão de dados,
                independente do sentido de fluxo destes, bem como do lapso de duração das mesmas, de forma
                que compete a tais concessionárias e empresas respectivas a exclusiva responsabilidade por
                eventuais falhas na prestação de seus serviços bem como dos prejuízos e lucros cessantes
                decorrentes destes episódios caso ocorram.
            </p>
            <p>
                <strong>14.PREÇOS E CONDIÇÕES COMERCIAIS:</strong> Para os softwares licenciados de forma onerosa, os
                preços e condições comerciais serão aqueles previstos na NOTA FISCAL e/ou PROPOSTA
                COMERCIAL e/ou ainda, na ORDEM DE COMPRA e/ou PEDIDO. No caso de divergência de valores
                entre os documentos citados, prevalecerá os valores e a negociação descrita na NOTA FISCAL de
                venda e/ou serviço. Neste valor, poderão ser incluídos outros valores em função de
                necessidades especiais solicitadas pela LICENCIADA, bem como serviços e usuários adicionais
                contratados quando o seu objeto for software hospedado em nuvem. A remessa da NOTA
                FISCAL por parte da LICENCIANTE é de exclusiva responsabilidade da LICENCIANTE. Sobre
                qualquer pagamento efetuado após a respectiva data de vencimento, incidirá multa penal de
                2% (dois por cento) e 0,033 % de juros ao dia (1% a.m. pro rata dies). O não pagamento do título
                emitido, decorridos 10 (dez) dias, ensejará o encaminhamento do mesmo ao cartório de
                protesto competente e inscrição em bancos de dados de inadimplentes sem necessidade de
                prévio aviso neste sentido, bem como a incidência de todas as despesas e encargos decorrentes
                de tal medida. Para os licenciamentos onerosos, os valores contratados serão reajustados
                anualmente no mês de aniversário do contrato de acordo com a maior variação entre os índices
                IPCA/IBGE ou IGPM/IBGE, e em sua falta, será adotado o INPC/IBGE. Caso haja, no curso da
                relação contratual, independente do lapso transcorrido, a contratação de novo(s) módulo(s)
                7
                Documento controlado: TLES-JUR- REV0 – 12/08/2022
                DELTA FIRE Ltda. R. Pinheiro Machado, 3271 Caxias do Sul/RS, CEP 95020-172 - Fone: 54 3204-4000
                www.deltafire.com.br
                acessório(s) ao SISTEMA principal, fica estabelecido expressamente por meio do presente que o
                índice de REAJUSTE ANUAL também será aplicado, em idêntica forma e data em relação ao(s)
                módulo(s) posteriormente contratado(s), incidindo, assim, na mesma data de reajuste, um único
                índice tanto para o principal quanto para o(s) acessório(s) posteriormente contratado(s),
                compondo, para efeito de reajuste anual, a partir de então, um único valor. Caso ocorra atraso
                no pagamento de qualquer parcela por mais de 10 (dez) dias corridos, independente das demais
                medidas previstas na legislação civil vigente, a LICENCIANTE procederá ao bloqueio do Programa
                até que a pendência seja sanada, ou seja, o respectivo pagamento em atraso ou, a seu critério,
                poderá rescindir, imediata e unilateralmente o presente contrato por inobservância de seus
                preceitos por parte da LICENCIADA, nos termos do presente termo, imputando-lhe, neste caso,
                todos os ônus rescisórios, sem a necessidade da observância do prazo aqui previsto.
            </p>
            <p>
                <strong>15.DA DURAÇÃO:</strong> O presente contrato tem FIDELIZAÇÃO CONFORME O PRAZO OPTADO NA
                NOTA FISCAL ou PROPOSTA COMERCIAL e/ou ainda, na ORDEM DE COMPRA ou PEDIDO,
                podendo ser resilido somente após o transcurso deste lapso inicial de carência.
            </p>
            <p>
                <strong>16. DA RESCISÃO:</strong> O não cumprimento, por qualquer das partes, de qualquer uma das cláusulas
                e condições aqui estabelecidas, dará à outra parte, em qualquer tempo, o pleno direito de
                rescindir o presente contrato independentemente de interpelação ou notificação judicial ou
                extrajudicial. Qualquer uma das partes poderá rescindir imotivadamente o presente contrato,
                após o transcurso do lapso de carência, caso contratada, devendo para tanto apresentar sua
                intenção por qualquer meio admitido, preferencialmente por carta e com aviso de recebimento
                - AR, de toda forma, com uma antecedência mínima de 30 (trinta) dias para o efetivo termo do
                contrato. A partir do momento da formalização do pedido de rescisão do contrato, a
                CONTRATANTE deverá proceder, nos próximos 30 dias subsequentes, as suas expensas e sob
                sua exclusiva responsabilidade a cópia de todos os seus dados lançados no Programa, a fim de
                evitar que os mesmos sejam perdidos após o encerramento do contrato e a descontinuidade do
                SISTEMA em seus servidores. Caso não seja procedida, pela contratante, a cópia de seus dados
                lançados no SISTEMA e, encerrado definitivamente o contrato, não consiga mais acessá-los em
                razão da descontinuidade do SISTEMA, estará a LICENCIANTE absolutamente isenta de qualquer
                responsabilidade por tais dados e acesso aos mesmos, exceto, quando tecnicamente viável ou
                possível, mediante pagamento do valor correspondente pelo serviço. Caso ainda a LICENCIADA
                tenha interesse em acessar seus dados no SISTEMA já descontinuado, esta deverá proceder ao
                contato diretamente com o Departamento Comercial da CONTRATADA para que sejam
                estabelecidas e efetivamente firmadas novas condições comerciais e valores para que seja
                contratado, então, o serviço específico de acesso extemporâneo ao SISTEMA já descontinuado,
                CONDICIONADA, TAL CONTRATAÇÃO, À VIABILIDADE OPERACIONAL A SER VERIFICADA
                EXCLUSIVAMENTE PELA CONTRATADA. Uma vez firmado o presente instrumento, surgirá para
                as partes dele signatárias a obrigação de honrá-lo e fielmente cumpri-lo, haja vista a detonação,
                já a partir de sua assinatura, de uma série de medidas técnicas para sua efetivação.
            </p>
            <p>
                <strong>17. DA PRORROGAÇÃO DO CONTRATO:</strong> Os Softwares gratuitos licenciados pela DELTA FIRE
                terão prazo indeterminado, não havendo data prevista para seu término ou prorrogação. Para
                os Softwares a título oneroso, licenciados pela DELTA FIRE, decorrido o prazo inicial da
                fidelização, haverá a prorrogação automática do presente contrato, passando o mesmo a viger
                por prazo indeterminado podendo, entretanto, ser rescindido nos termos e moldes previstos no
                presente. Os valores que as partes utilizarão como base para a negociação do novo valor da
                licença para efeito de vigência do contrato por prazo indeterminado, bem como de correção
                anual dos valores a serem pagos, são aqueles descritos no item 14 do presente instrumento,
                8
                Documento controlado: TLES-JUR- REV0 – 12/08/2022
                DELTA FIRE Ltda. R. Pinheiro Machado, 3271 Caxias do Sul/RS, CEP 95020-172 - Fone: 54 3204-4000
                www.deltafire.com.br
                bem como seus respectivos e posteriores aditivos contratuais, caso haja, reajustados a cada 12
                (doze) meses com base na maior variação entre os índices IPCA/IBGE ou IGPM/IBGE ou outro
                que o substitua, calculado no período compreendido nos doze meses contados a partir da
                assinatura deste contrato tal como previsto expressamente neste Termo.
            </p>
            <p>
                <strong>18.DA LEGITIMIDADE:</strong> As partes, por meio de seus representantes legais, signatários do
                presente instrumento, declaram sob as penas da Lei, estarem legitimados e autorizados a
                proceder à contratação nos moldes em que ora é procedida, bem como assumir obrigação em
                nome de suas representadas, responsabilizando-se pessoal, civil, criminal e
                administrativamente por todas as declarações e compromissos ora pactuados e expressos no
                presente instrumento. O presente Termo de Adesão, acompanhado da PROPOSTA COMERCIAL
                e/ou da NOTA FISCAL e/ou PEDIDO e/ou ORDEM DE COMPRA, tem força de título executivo
                extrajudicial, nos termos do art. 784, III do CPC, mesmo em cobrança amigável, sem prejuízo do
                pagamento de honorários advocatícios ao patrono da parte vitimada pela ação da outra, custeio
                de despesas judiciais, administrativas, cartorárias e outras demandadas e necessárias a tanto,
                quando for o caso.
            </p>
            <p>
                <strong>19. DAS QUESTÕES TRABALHISTAS/PREVIDENCIÁRIAS:</strong> Fica estabelecido que nenhum vínculo
                empregatício ou de qualquer natureza existirá reciprocamente, entre a LICENCIADA e os
                prepostos da LICENCIANTE e desta para com os prepostos daquela que, para todos os efeitos,
                cada qual será considerada única empregadora daqueles a seu serviço ficando a esta exclusiva
                empregadora, afeta todas as despesas inclusive os encargos sociais, trabalhistas, securitários
                bem como, quaisquer outros aplicáveis a espécie. É de integral responsabilidade de cada uma
                das partes anuentes os ônus decorrentes de quaisquer reclamações trabalhistas ou qualquer
                outro ato de natureza administrativa e/ou judicial, inclusive provenientes de acidentes de
                trabalho para seus respectivos funcionários, colaboradores e/ou prepostos, permanentes ou
                eventuais que constituem mão de obra encarregada da execução dos serviços objeto deste
                contrato, seja a que título for e a que tempo decorrer, respondendo integralmente pelo
                pagamento de indenizações, multas, verbas trabalhistas/previdenciárias, fiscais, honorários
                advocatícios, custas processuais e demais encargos existentes, caso sejam acionadas
                indevidamente, ficando cada uma das partes, expressa e reciprocamente autorizadas a
                procederem ao bloqueio/compensação/cobrança de eventuais valores suportados pela parte
                onerada indevidamente em razão de tais demandas ou incidentes.
            </p>
            <p>
                <strong>20. SEGURANÇA E PRIVACIDADE:</strong> A LICENCIANTE se compromete a aplicar as medidas técnicas
                de segurança aptas a proteger as informações de acessos não autorizados e eventuais incidentes
                de segurança correspondentes a tais dados. No entanto, a LICENCIANTE se exime de
                responsabilidade por culpa exclusiva de terceiros, como em caso de invasão por hackers ou
                crackers, bem como por culpa exclusiva da LICENCIADA, como no caso em que esta possibilita o
                acesso ou transfere seus dados a terceiros. A LICENCIANTE se compromete, ainda, a comunicar
                a LICENCIADA, em prazo adequado, acerca da ocorrência de qualquer incidente de segurança
                que apresente risco potencial a seus direitos e liberdades pessoais. Para acessar a Política de
                Privacidade e os Termo de Uso da LICENCIANTE, acesse https://www.deltafire.com.br/politica
            </p>
            <p>
                <strong>21. DO FORO:</strong> É aplicável a este contrato o foro da Comarca de CAXIAS DO SUL/RS, para dirimir
                as questões porventura oriundas da execução e/ou interpretação das cláusulas e condições
                deste contrato.

            </p>
            <p>
                <strong>22. DAS CONTRATAÇÕES INTERNACIONAIS:</strong> Em caso de licenciamento a clientes sediados fora
                do território brasileiro (Brasil) entende, aceita e anui, a LICENCIADA, que o presente contrato
                vigerá sobre as disposições da Lei Brasileira, sendo esta aplicada sobre qualquer outra, por mais
                9
                Documento controlado: TLES-JUR- REV0 – 12/08/2022
                DELTA FIRE Ltda. R. Pinheiro Machado, 3271 Caxias do Sul/RS, CEP 95020-172 - Fone: 54 3204-4000
                www.deltafire.com.br
                especial que seja. Concorda e anui, a LICENCIADA, ainda, com o foro de eleição do contrato e,
                caso descumpridas as obrigações por parte da LICENCIADA, poderá, a LICENCIANTE, optar pelo
                foro de domicilio ou execução do contrato, o que lhe convier, em especial para as medidas de
                cobrança, quando houver e de proteção dos direitos de propriedade intelectual e industrial. Os
                pagamentos dos objetos licenciados a título oneroso serão realizados de forma eletrônica via
                plataforma eletrônica de pagamentos informada à LICENCIADA no ato da contratação, em
                moeda corrente da localidade de execução do contrato, sendo emitidos os documentos fiscais
                respectivos a pedido da LICENCIADA (invoice), devendo este informar os dados de cadastro e
                registro das faturas à LICENCIANTE.
            </p>
        </>
    )
}