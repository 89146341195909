import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router"
import IconButton from '@mui/material/IconButton'
import { Container, Button, Grid, Typography, Checkbox, FormControlLabel, FormHelperText, Snackbar } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import { Person, VpnKey, Email } from '@mui/icons-material'
import validator from 'validator'

const useStyles = makeStyles({

})

export default function EditUser(props) {
    const classes = useStyles();
    const [user, setUser] = useState(localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : {})
    const [name, setName] = useState()
    const [password, setPassword] = useState("")
    const [nameError, setNameError] = useState(false)

    const [passwordError, setPasswordError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [passwordErrorText, setPasswordErrorText] = useState("")

    const [nameErrorText, setNameErrorText] = useState("")

    const [confirmation, setConfirmation] = useState("")
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
    const [confirmationError, setConfirmationError] = useState(false)
    const [passwordConfirmationText, setPasswordConfirmationText] = useState("")

    const [email, setEmail] = useState(props.user.email === "" ? "" : props.user.email)
    const [emailError, setEmailError] = useState(false)
    const [emailErrorText, setEmailErrorText] = useState("")

    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")

    const [userRolesList, setUserRolesList] = useState(props.user.role)

    const history = useHistory()

    const [disabled, setDisabled] = useState()
    const { upload, editar, alarmes, relatorio, usuarios, admin, revenda, delta } = userRolesList

    const handleRoles = (event) => {
        setUserRolesList({
            ...userRolesList,
            [event.target.name]: event.target.checked,
        })
    }

    useEffect(() => {
        setName(props.user.dS_NOME)
        setDisabled(props.user.role.notEditable)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (name.length < 1) {
            setNameError(true)
            setNameErrorText("Insira um novo nome para o usuário Administrador")
        } else {
            setNameError(false)
            setNameErrorText("")
        }

        if (password.length < 8 && password.length > 0) {
            setPasswordError(true)
            setPasswordErrorText("Sua senha deve conter no mínimo 8 caracteres")
        } else {
            setPasswordError(false)
            setPasswordErrorText("")
        }

        if (confirmation !== password) {
            setConfirmationError(true)
            setPasswordConfirmationText("As senhas devem ser iguais")
        } else {
            setConfirmationError(false)
            setPasswordConfirmationText("")
        }

        if (email != null) {
            if (validator.isEmail(email)) {
                setEmailError(false)
                setEmailErrorText("")

            } else {
                setEmailError(true)
                setEmailErrorText("insira um email válido")
            }
        }


        if (passwordError === false && name && confirmation === password && emailError === false) {
            fetch(`${process.env.REACT_APP_URL}/user/edit`, {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({
                    "ID": props.user.id,
                    "EMAIL": email,
                    "DS_NOME": name,
                    "SENHA": password,
                    "ROLE": userRolesList,
                    "ID_EMPRESA": props.user.iD_EMPRESA,
                    "ID_INTRANET": props.user.iD_INTRANET,
                    "DS_CNPJ": props.user.dS_CNPJ
                })
            })
                .then(response => response.json())
                .then(data => {
                    setMessage(data.message)
                    setOpen(!open)
                })
        }
    }

    const handleDelete = () => {
        fetch(`${process.env.REACT_APP_URL}/user/delete?userId=${props.user.id}`, {
            method: "GET",
            headers: { "content-type": "application/json" }
        }).then(response => response.json())
            .then(data => {
                props.removeUser(data.user)
            })
    }

    const setVisible = () => {
        setShowPassword(!showPassword)
    }

    const setConfirmationVisible = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation)
    }

    const handleSnackBar = () => {
        setOpen(!open)
    }

    const validateEmail = (e) => {
        if (validator.isEmail(e)) {
            setEmailError(false)
            setEmailErrorText("")

        } else {
            setEmailError(true)
            setEmailErrorText("insira um email válido")
        }
        setEmail(e)
    }

    return (
        <Container>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleSnackBar}
                message={message}
            />
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={12} lg={12}>
                        <InputLabel htmlFor="usern-name">Nome do usuário</InputLabel>
                        <OutlinedInput
                            autoFocus={true}
                            id="usern-name"
                            onChange={(e) => setName(e.target.value)}
                            variant="outlined"
                            color="secondary"
                            required
                            value={name}
                            error={nameError}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Person />
                                </InputAdornment>
                            }
                        ></OutlinedInput>
                        <FormHelperText id="user-name">{nameErrorText}</FormHelperText>
                    </Grid>

                    {props.user.email != null ?
                        <Grid item xs={12} md={12} lg={12}>
                            <OutlinedInput
                                className={classes.key}
                                id="email-field"
                                onChange={(e) => validateEmail(e.target.value)}
                                variant="outlined"
                                color="secondary"
                                required
                                type="email"
                                value={email}
                                placeholder="Insira um email válido para este usuário"
                                error={emailError}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Email />
                                    </InputAdornment>
                                }
                            ></OutlinedInput>
                            <FormHelperText id="email-field">{emailErrorText}</FormHelperText>
                        </Grid>
                        : null}

                    <Grid item xs={12} md={12} lg={12}>
                        <InputLabel htmlFor="outlined-adornment-password">Nova senha</InputLabel>
                        <OutlinedInput
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            id="password"
                            type={showPassword ? "text" : "password"}
                            error={passwordError}
                            value={password}

                            startAdornment={
                                <InputAdornment position="start">
                                    <VpnKey />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={setVisible}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText id="password">Deixe em branco caso não queira trocar de senha</FormHelperText>
                        <FormHelperText id="password">{passwordErrorText}</FormHelperText>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <InputLabel htmlFor="outlined-adornment-password">Confirme a nova senha</InputLabel>
                        <OutlinedInput
                            required
                            onChange={(e) => setConfirmation(e.target.value)}
                            id="confirmation"
                            type={showPasswordConfirmation ? "text" : "password"}
                            error={confirmationError}
                            value={confirmation}
                            startAdornment={
                                <InputAdornment position="start">
                                    <VpnKey />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={setConfirmationVisible}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText id="password">{passwordConfirmationText}</FormHelperText>
                    </Grid>

                    {/* {userNotFound == false ? null : <Grid item xs={12} md={12} lg={12}><Typography variant='body2' color='#d32f2f'>Usuário e/ou senha incorretos</Typography></Grid>} */}

                </Grid>

                <Grid sx={{ marginTop: "20px", marginBottom: "20px" }} container spacing={4}>
                    <Grid justifyContent="center" alignItems="center" item xs={12} md={12} lg={12}>
                        <Typography sx={{ fontSize: '12px' }}>Editar permissões.</Typography>
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <FormControlLabel
                            control={
                                <Checkbox label="Top" disabled={disabled} checked={upload} onChange={handleRoles} name="upload" />
                            }
                            label="Upload rom"
                        />
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <FormControlLabel
                            control={
                                <Checkbox label="Top" disabled={disabled} checked={usuarios} onChange={handleRoles} name="usuarios" />
                            }
                            label="Criar usuários"
                        />
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <FormControlLabel
                            control={
                                <Checkbox label="Top" disabled={disabled} checked={alarmes} onChange={handleRoles} name="alarmes" />
                            }
                            label="Gerar alarmes"
                        />
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <FormControlLabel
                            control={
                                <Checkbox label="Top" disabled={disabled} checked={relatorio} onChange={handleRoles} name="relatorio" />
                            }
                            label="Gerar relatorio"
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <FormControlLabel
                            control={
                                <Checkbox label="Top" disabled={disabled} checked={editar} onChange={handleRoles} name="editar" />
                            }
                            label="Editar grupos / amplificadores"
                        />
                    </Grid>
                </Grid>
                <Button onClick={handleSubmit} sx={{ mr: "20px" }} variant="contained" color="secondary">Salvar</Button>
                <Button onClick={handleDelete} variant="contained" disabled={disabled} color="error">Excluir</Button>
            </form>
        </Container>
    );
}