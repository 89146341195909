import { useState, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { styled, useTheme } from "@mui/material/styles"
import { Box, Typography } from '@mui/material'

const useStyles = makeStyles((theme) => {
    return {
        footerLicence: {
            backgroundColor: "#30D253",
            color: "#121312",
        },
        footerLicenceWarning: {
            backgroundColor: "#F42F3E",
            color: "#fffffff",
        }
    }
})

const FooterBar = styled('footer', {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: "1",
    alignItems: "center",
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        [theme.breakpoints.up('sm')]: {

            width: `calc(100% - 240px)`,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function Footer(props) {
    const buildVersion = useState(process.env.REACT_APP_BUILD_VERSION)

    return (
        <FooterBar 
            open={props.open}
        >
            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between', padding: '0.5rem' }}>
                <Typography textAlign="center" variant="body2">Central: {props.serial}</Typography>
                {/* <Typography textAlign="center" variant="body2">Tipo da Licença: {licence}</Typography> */}
                {/* {licence === "Free" ? null :
                    <Typography textAlign="center" variant="body2">{days < 0 ? 'Licença Expirada' : days + ' dias restantes'}</Typography>
                } */}

                {process.env.REACT_APP_MODE === 'desktop' ? 
                    <Typography textAlign="end" variant="body2">v{buildVersion}</Typography>
                    :
                    null
                }

            </Box>
        </FooterBar>
    )
}