import { useEffect, useState } from 'react'
import { Checkbox } from '@material-ui/core'
import { Modal, Box, Typography, FormControlLabel, Container, Button, Card } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles"
import UserTermText from './UserTermText'
import TechnicianTermsText from './TechnicianTermsText'
import RessaleTermsText from './RessaleTermsText'
import MultiUserTermsText from './MultiUserTermsText'
import LogoDelta from '../assets/logoDelta.png'

const useStyles = makeStyles({
    userTermsCard: {
        position: 'absolute',
        overflow: 'scroll',
        display: 'blco'
    },
    fonts: {
        color: "#000000"
    }
})

export default function TermsModal(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [checked, setChecked] = useState(false)

    const acceptTerm = () => {
        props.setAcceptTerm(true)
        setOpen(false)
    }

    const declineTerm = () => {
        props.setAcceptTerm(false)
        setChecked(false)
        setOpen(false)
    }

    const handleChange = () => {
        setChecked(true)
        setOpen(true)
    }

    return (
        <>
            <FormControlLabel sx={{ color: "#ffffff" }} control={<Checkbox
                style={{ color: "#ffffff" }}
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />} label="Li e aceito os termos e condições de uso" />
            <Modal
                open={open}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                        setOpen(true)
                    } else {
                        setOpen(false)
                    }
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown={true}
                className={classes.userTermsCard}
            >
                <Container sx={{ padding: "1rem" }}>
                    <Card sx={{ padding: "1rem" }}>
                        <Box textAlign='center' sx={{ padding: '2rem' }}><img width={300} height={120} src={LogoDelta} alt={"Delta Fire"} className={classes.logoDeltaHome} /></Box>
                        <Box textAlign='center'>
                            <Typography variant="h5">TERMO DE LICENCIAMENTO ELETRÔNICO PARA USO DE SOFTWARES DELTA FIRE E SERVIÇOS CORRELATOS</Typography>
                        </Box>

                        <Box sx={{ padding: "1rem" }}>
                            <Typography variant="body1">
                                {props.technician
                                    ?
                                    <TechnicianTermsText />
                                    :
                                    props.ressale
                                        ?
                                        <RessaleTermsText />
                                        :
                                        props.multiUser
                                            ?
                                            <MultiUserTermsText />
                                            :
                                            <UserTermText />
                                }
                            </Typography>
                        </Box>

                        <Box sx={{
                            display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'
                        }}>
                            <Button variant='contained' color='error' onClick={declineTerm}>Recusar</Button>
                            <Button variant='contained' color='success' onClick={acceptTerm}>Aceitar</Button>
                        </Box>
                    </Card>
                </Container>
            </Modal>
        </>
    )
}