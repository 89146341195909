import { useState, useEffect } from 'react'
import { Container, Card, Grid, Box, Typography, styled, Paper } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles({
    ContainerUserLicence: {

    },
    userLicenceCard: {
        padding: "1rem",
        textAlign: "center",
        marginTop: "1rem"
    }
})

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    height: 'auto',
    width: '100%'
}));

export default function UserLicences(props) {
    const classes = useStyles()
    const [licences, setLicences] = useState([])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/licences/client/licences?serialNumber=${props.serialKeys}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            }
        }).then(res => res.json())
            .then(data => {
                setLicences(data)
                var userQuantity = data.filter(item => item.tipo_licenca === 'web')[0].quantidade_usuarios
                props.setWebUsers(userQuantity)
            }).catch((error) => { })
    }, [])

    return (
        <>            
            <center>
                <label><h4>Licenças Encontradas</h4></label>
            </center>

            <ItemHeader>
                <Grid container spacing={3}>
                    <Grid item xs={3} md={3} lg={3}>
                        <Typography variant="body2">Tipo de Licença</Typography>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                        <Typography variant="body2">Chave</Typography>
                    </Grid>
                    <Grid item xs={3} md={3} lg={3}>
                        <Typography variant="body2">Validade</Typography>
                    </Grid>
                    <Grid item xs={2} md={2} lg={2}>
                        <Typography variant="body2">Status</Typography>
                    </Grid>
                </Grid>
            </ItemHeader>

            {licences.map(item => {
                return <Card className={classes.userLicenceCard} key={item.chave}>
                    <Grid container spacing={3}>
                        <Grid item xs={3} md={3} lg={3}>
                            <Typography variant="body2">{item.tipo_licenca == "desktop" ? "PRO Local" : item.tipo_licenca}</Typography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <Typography variant="body2">{item.chave}</Typography>
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                            <Typography variant="body2">{new Date(item.expiracao_licenca).toLocaleDateString()}</Typography>
                        </Grid>
                        <Grid item xs={2} md={2} lg={2}>
                            <Typography variant="body2">{item.chave_utilizada ? "Ativada" : "Não Ativada"}</Typography>
                        </Grid>
                    </Grid>
                </Card>
            })}
        </>
    )
}