import { useState, useEffect, forwardRef } from "react"
import { Key, Close, Add, Cloud, Email } from '@mui/icons-material'
import { Grid, Button, Card, CardHeader, CardContent, IconButton, Tooltip, Snackbar, InputLabel, OutlinedInput, InputAdornment, FormHelperText, Typography, ButtonGroup } from "@mui/material"
import { makeStyles } from "@mui/styles"
import SelectUser from "./SelectUser"
import MuiAlert from "@mui/material/Alert"
import { useSnackbar } from "../providers/SnackbarProvider";

const useStyles = makeStyles({
    card: {
        padding: "1rem",
        marginBottom: "1rem"
    },
    key: {
        width: "20rem",
        marginBottom: "3rem"
    }
})

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function WebLicences(props) {
    const classes = useStyles()

    const { openSnackbar } = useSnackbar();

    const [userList, setUserList] = useState([])
    const [edit, setEdit] = useState(false)
    const [openUserList, setOpenUserList] = useState(false)
    const [secretError, setSecretError] = useState(false)
    const [secret, setSecret] = useState()
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [refreshToken, setRefreshToken] = useState(0)
    const [listSavedEmails, setListSavedEmails] = useState([])
    const [mainWebEmail, setMainWebEmail] = useState("");
    const [mainWebUser, setMainWebUser] = useState({"id": null, "name": null, "email": null, "model": null, "position": null});
    const [mainWebUserLocked, setMainWebUserLocked ] = useState(null);
    const [webLicense, setWebLicense] = useState({ "tipo_licenca": "", "expiracao_licenca": "", "chave": "", "chave_utilizada": null });
    const [webUsers, setWebUsers] = useState([]);
    const [isActivation, setIsActivation] = useState(true);

    const keyLength = (e) => {
        if (e.length >= 24) {
            setSecret(e)
            setOpenUserList(true)
            setListSavedEmails(props.listSavedEmails)
        }
    }

    const changeMainWebUser = async () => {
        try 
        {
            props.handleOpenModal(true)

            let user = {
                "dS_CNPJ": mainWebUser.model.dS_CNPJ,
                "dS_NOME": mainWebUser.model.dS_NOME,
                "email": mainWebUser.email,
                "iD_EMPRESA": mainWebUser.model.iD_EMPRESA,
                "iD_INTRANET": mainWebUser.model.iD_INTRANET,
                "id": mainWebUser.model.id,
                "role": mainWebUser.model.role,
                "senha": "",
                "booL_MASTER": true
            };

            const response = await fetch(`${process.env.REACT_APP_URL}/licences/activate/web?key=${secret}&serialNumber=${props.systemData.serialKeys}`, {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify([user])
            });

            if(!response.ok)
            {
                throw Error("Não foi salvar as alterações!");
            }

            const resData = await response.json();
            
            console.log("🚀 ~ changeMainWebUser ~ resData:", resData)

            if(!!resData)
            {
                props.handleOpenModal(false);

                switch (resData.message)
                {
                    case "success":
                        openSnackbar("Sua licença WEB foi ativada com sucesso","success");
                        break;
                    case "fail":
                        openSnackbar(resData.error,"error");
                        break;
                    case "duplicated":
                        openSnackbar(resData.error,"error");
                        break;
                    case "limitError":
                        openSnackbar("Limite de usuários atingido","error");
                        break;
                    case "Used":
                        openSnackbar("Esta chave já está em uso. Adquira uma nova chave.","error");
                        break;
                    case "Error":
                        openSnackbar("Erro ao ativar licença. Verifique se a chave foi inserida corretamente","error");
                        break;
                    case "NotFound":
                        openSnackbar("Chave não encontrada.","error");
                        break;
                    case "Invalid":
                        openSnackbar("Chave inválida.","error");
                        break;
                    default:
                        if (!!resData.error)
                        {
                            openSnackbar(resData.error,"error");
                        }
                        else
                        {
                            openSnackbar("Sua licença WEB foi ativada com sucesso","success");
                        }
                        break;
                }
                setRefreshToken(Math.random);
            }
        }
        catch (error)
        {
            console.error("🚀 ~ changeMainWebUser ~ error:", error)
        }
        finally 
        {
            props.updateUserEmail([mainWebUser])
        }
    }

    const renewLicense = (list) => {
        //props.updateUserEmail(list)
        props.handleOpenModal(true)
        var userList = []
        setListSavedEmails(list)
        listSavedEmails.map(item => {

            /*if (item.email != null) {
                var user = {
                    "dS_CNPJ": item.model.dS_CNPJ,
                    "dS_NOME": item.model.dS_NOME,
                    "email": item.email,
                    "iD_EMPRESA": item.model.iD_EMPRESA,
                    "iD_INTRANET": item.model.iD_INTRANET,
                    "id": item.model.id,
                    "role": item.model.role,
                    "senha": ""
                }
            }*/
            userList.push(item)
        })
        fetch(`${process.env.REACT_APP_URL}/licences/renew/web?key=${secret}&serialNumber=${props.systemData.serialKeys}`, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(userList)
        })
            .then(response => response.json())
            .then(data => {
                props.handleOpenModal(false)

                if (data.message.toLowerCase() === "success") 
                {
                    openSnackbar("Sua licença WEB foi renovada com sucesso","success");
                }
                if (data.message.toLowerCase() === "fail") {
                    openSnackbar(data.error,"error");
                }
                if (data.message.toLowerCase() === "duplicated") 
                {
                    openSnackbar(data.error,"error");
                }
                if (data.message.toLowerCase() === "limiterror") 
                {
                    openSnackbar(data.error,"error");
                }
                if (data.message.toLowerCase() === "used") 
                {
                    openSnackbar("Esta chave já está em uso. Adquira uma nova chave.","error");
                }
                if (data.message.toLowerCase() === "error")
                {
                    openSnackbar("Erro ao ativar licença. Verifique se a chave foi inserida corretamente","error");
                }
                if (data.message.toLowerCase() === "notfound") 
                {
                    openSnackbar("Chave não encontrada.","error");
                }
                if (data.message.toLowerCase() === "invalid") 
                {
                    openSnackbar("Chave inválida.","error");
                }

                setRefreshToken(Math.random)
            })
            .catch((error) => { })
    }

    const toggleMainWebUser = (user, pos) => {
        let received = {"id": null, "name": null, "email": null, "model": null, "position": null};

        received.id = user.id;
        received.name = user.dS_NOME;
        received.email = mainWebEmail;
        received.model = user;
        received.position = pos;

        console.log("🚀 ~ toggleMainWebUser ~ received:", received);

        setMainWebUser(received);
    }

    const checkWebLicense = async () => {
        try
        {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/licences/client/licences?serialNumber=${JSON.parse(localStorage.getItem('system')).serialKeys}`,{
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            });

            if (!request.ok)
            {
                throw new Error("Não foi possível obter as licenças deste Serial");
            }

            const response = await request.json();
            console.log("🚀 ~ checkWebLicense ~ response:", response)

            response.forEach(license => {
                if (license.tipo_licenca === "web")
                {
                    setWebLicense(license);
                    setMainWebUserLocked(license.chave_utilizada);
                }
            });
        }
        catch (error)
        {
            console.log("🚀 ~ checkWebLicense ~ error:", error)
        }
    }

    useEffect(() => {
        console.log("🚀 ~ WebLicences ~ props.userList:", props.userList)
        setUserList(props.userList);
     }, [props.userList])

    useEffect(() => {
        setSecret("")
        setOpenUserList(false)
    }, [refreshToken])

    useEffect(()=>{
        if (props.webUsersList.length > 0)
        {
            setIsActivation(false);
        }
    },[props.webUsersList])

    return (
        <>
            {/* <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(!open)}
            >
                <Alert severity={severity} sx={{ width: '100%' }}>{message}</Alert>
            </Snackbar> */}
            <Card className={classes.card}>
                <CardHeader
                    avatar={<Cloud color="primary" />}
                    title={isActivation ? "Ativar Licença Web" : "Renovar Licença Web"}
                />
                {isActivation ?
                    /// ATIVAÇÃO DA CHAVE WEB \\\
                    <>
                        {props.systemData.errorMessage ?
                            <Typography variant="body1" color="error">* Nenhuma central cadastrada.</Typography>
                            :
                            <>
                                <CardContent>
                                    <div class="form-group">
                                        <InputLabel htmlFor="secret-key">Insira sua Chave</InputLabel>
                                        <OutlinedInput
                                            className={classes.key}
                                            autoFocus={true}
                                            id="secret-key"
                                            onChange={(e) => keyLength(e.target.value)}
                                            variant="outlined"
                                            color="secondary"
                                            value={secret}
                                            fullWidth={true}
                                            required
                                            error={secretError}
                                            style={{"marginBottom": "25px"}}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Key />
                                                </InputAdornment>
                                            }
                                        ></OutlinedInput>
                                        <FormHelperText id="secret-key">{secretError}</FormHelperText>

                                    </div>
                                    <div class="form-group">
                                        <InputLabel htmlFor="">E-mail do Usuário Web Master</InputLabel>
                                        <OutlinedInput
                                            className={classes.key}
                                            type="email"
                                            id="email-field"
                                            variant="outlined"
                                            color="secondary"
                                            fullWidth={true}
                                            placeholder="Insira um email válido para este usuário"
                                            disabled={mainWebUserLocked}
                                            required
                                            onChange={(e) => setMainWebEmail(e.target.value)}
                                            //error={user.emailError}
                                            style={{"marginBottom": "25px"}}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Email />
                                                </InputAdornment>
                                            }
                                        />
                                        {/* <FormHelperText id="email-field">{user.emailErrorText}</FormHelperText> */}
                                    </div>
                                    <div class="form-group mb-5">
                                        <InputLabel>Selecione o Usuário que será o Web Master</InputLabel>
                                        <ButtonGroup
                                            size="large"
                                            variant="contained"
                                            orientation="vertical"
                                            fullWidth={true}
                                            disabled={mainWebUserLocked}
                                            aria-label="Vertical button group"
                                            style={{"border": "1px solid #929292"}}
                                        >
                                            {props.userList.map((user, i) => (
                                                <Button 
                                                    key={user.id}
                                                    fullWidth={true}
                                                    color={!!mainWebUser && mainWebUser.id === user.id ? "secondary" : "default" }
                                                    style={{"justifyContent": "flex-start"}}
                                                    onClick={() => toggleMainWebUser(user, i) }
                                                >
                                                    {user.dS_NOME}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </div>
                                    
                                    <div class="form-group">
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            fullWidth={true}
                                            onClick={() => (changeMainWebUser())}
                                        >
                                            Ativar Licença
                                        </Button>
                                    </div>


                                    {/* {openUserList ?
                                        <SelectUser refreshToken={refreshToken} webUsers={props.webUsers} edit={edit} receiveCheckedUserList={receiveCheckedUserList} userList={props.userList} />
                                        : null
                                    } */}
                                </CardContent>
                            </>
                        }
                    </>
                    : 
                    /// RENOVAÇÃO DA CHAVE WEB \\\
                    <>
                        {
                            props.systemData.errorMessage ?
                                <Typography variant="body1" color="error">* Nenhuma central cadastrada.</Typography>
                                :
                                <>
                                    <CardContent>
                                        <InputLabel htmlFor="secret-key">Insira sua Chave</InputLabel>
                                        <OutlinedInput
                                            className={classes.key}
                                            id="secret-key"
                                            onChange={(e) => keyLength(e.target.value)}
                                            variant="outlined"
                                            color="secondary"
                                            value={secret}
                                            fullWidth={true}
                                            required
                                            error={secretError}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Key />
                                                </InputAdornment>
                                            }
                                        ></OutlinedInput>
                                        <FormHelperText id="secret-key">{secretError}</FormHelperText>
                                        <Grid item xs={12} md={12} lg={12} >
                                            <Button
                                                disabled={secret.length < 30}
                                                variant="contained"
                                                color="secondary"
                                                fullWidth={true}
                                                onClick={() => (renewLicense(userList))}
                                            >
                                                Renovar Licença
                                            </Button>
                                        </Grid>
                                    </CardContent>
                                </>
                        }
                    </>
                }
            </Card>
        </>
    )
}