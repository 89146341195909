import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ModalUserData(props) {
  const [open, setOpen] = useState(false);
  const handleClose = () => { return };
  const close = () => props.setOpen(false)
  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {props.modalError ?
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Algo deu errado. Verifique os dados informados e tente novamente
            </Typography>
            :
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Envie os dados abaixo para o usuário
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>Nome: <strong>{props.name}</strong></Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>Email: <strong>{props.email}</strong></Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>Senha: <strong>{props.password}</strong></Typography>
            </>
          }

          <Button
            color="secondary"
            variant="contained"
            onClick={close}
          >Fechar</Button>
        </Box>
      </Modal>
    </div>
  );
}