import { useEffect } from "react"
import { Grid, Box } from "@mui/material"
import DoughnutChart from "./DoughnutChart"
import DoughnutChartData from "./data/DoughnutChartData"

function DoughnutDashboard({ data }) {

    return (
        <>
            <DoughnutChart
                chart={data}
                backgroundColor="#FFFFFF"
            />
        </>
    )
}

export default DoughnutDashboard