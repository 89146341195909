import { useEffect, useState } from 'react'
import getDaysInMonth from "date-fns/getDaysInMonth"

const SetData = (origin, menuItens, month, year, row, token) => {
    const [menuItens2, setItens2] = useState([{ 'id_central': menuItens }])
    const [requestData, setRequestData] = useState()
    const [daysWithEvents, setDaysWithEvents] = useState()
    const [lastDayWithEvent, setLastDayWithEvent] = useState()
    const [daysList, setDaysList] = useState()
    const [labels, setDaysInMonth] = useState([])
    const [shot, setShot] = useState({})
    const [SDatasetsTemplate, setTemplate] = useState({
        label: "",
        data: [],
        showLine: true,
        borderColor: "",
        backgroundColor: ""
    })

    useEffect(() => {
        if (row != null && requestData !== undefined) {
            if (row.fK_OCORRENCIA != null) {
                if (row.fK_OCORRENCIA.includes("DISPARO")) {
                    var date = (row.dT_EVENTO.split("T"))[0]
                    var day = (date.split("-"))[2]
                    var month = (date.split("-"))[1]
                    var year = (date.split("-"))[0]

                    var alarmTemplate = {
                        central: row.fK_CENTRAL,
                        day: parseInt(day),
                        month: parseInt(month),
                        year: parseInt(year),
                        occurrence: row.fK_OCORRENCIA
                    }

                    requestData.push(alarmTemplate)

                    setTemplate({
                        label: "",
                        data: [],
                        showLine: true,
                        borderColor: "",
                        backgroundColor: ""
                    })

                    // cria lista contendo apenas seire das centrais
                    var menuList = []
                    origin == "client" ? menuList = menuItens2 : menuList = menuItens
                    var menuItensList = menuList.map(item => item.id_central)

                    //cria lista contendo eventos que estão contidos na lista de series de central
                    var requestCopy = requestData.filter(item => {
                        return menuItensList.includes(item.central) ? item : null
                    })

                    var daysObject = []

                    daysWithEvents.map(day => daysObject.push({ day: day, occurrences: 0 }))

                    //cria objeto com os dias e os events ocorridos no dia
                    daysObject.map(item => requestCopy.filter(requestItem => requestItem.day == item.day ? item.occurrences = item.occurrences + 1 : 0))

                    if (daysObject.length > 0) {

                        SDatasetsTemplate.label = "DISPAROS"

                        // para cada dia verifica se o dia possui evento e alimenta o dia com o total de eventos
                        for (var x = 1; x <= daysList; x++) {
                            if (!(x > lastDayWithEvent)) {
                                if (daysWithEvents.includes(x)) {
                                    daysObject.map(item => {
                                        if (item.day == x) {
                                            SDatasetsTemplate.data.push(item.occurrences)
                                        }
                                    })
                                } else {
                                    SDatasetsTemplate.data.push(0)
                                }
                            }
                        }

                        setShot({
                            labels: labels,
                            datasets: SDatasetsTemplate
                        })
                    }
                }
            }
        }
    }, [row])

    useEffect(() => {
        getData().then(events => {
            if (events != undefined) {
                setRequestData(events)

                // lista com os dias que possuem evento
                var daysWithE = [...new Set(events.map(item => item.day))]
                setDaysWithEvents(Array.from(daysWithE))

                // variável contendo o último dia com evento
                var lastDay = Math.max(...daysWithE)
                setLastDayWithEvent(Math.max(...daysWithE))

                // variavel contendo o total de dias no mês
                var totalDays = getDaysInMonth(year, month)
                setDaysList(getDaysInMonth(year, month))

                setTemplate({
                    label: "",
                    data: [],
                    showLine: true,
                    borderColor: "",
                    backgroundColor: ""
                })

                // cria lista contendo apenas seire das centrais
                var menuList = []
                origin == "client" ? menuList = menuItens2 : menuList = menuItens
                var menuItensList = menuList.map(item => item.id_central)

                //cria lista contendo eventos que estão contidos na lista de series de central
                var requestCopy = events.filter(item => {
                    return menuItensList.includes(item.central) ? item : null
                })

                var daysObject = []

                daysWithE.map(day => daysObject.push({ day: day, occurrences: 0 }))

                //cria objeto com os dias e os events ocorridos no dia
                daysObject.map(item => requestCopy.filter(requestItem => requestItem.day == item.day ? item.occurrences = item.occurrences + 1 : 0))

                if (daysObject.length > 0) {

                    SDatasetsTemplate.label = "DISPAROS"

                    // para cada dia verifica se o dia possui evento e alimenta o dia com o total de eventos
                    for (var x = 1; x <= totalDays; x++) {
                        if (!(x > lastDay)) {
                            if (daysWithE.includes(x)) {
                                daysObject.map(item => {
                                    if (item.day == x) {
                                        SDatasetsTemplate.data.push(item.occurrences)
                                    }
                                })
                            } else {
                                SDatasetsTemplate.data.push(0)
                            }
                        }
                    }

                    setShot({
                        labels: labels,
                        datasets: SDatasetsTemplate
                    })
                }
            }
        })
    }, [menuItens])

    async function getData() {
        const response = await fetch(`${process.env.REACT_APP_URL}/events/relatory/all?occurrence=DISPARO`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Expose-Headers': '*',
                'Authorization': 'Bearer ' + token
            }
        })

        const events = await response.json()
        return events
    }
    return {
        shot: shot
    }
}

export default SetData