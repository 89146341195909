import React, { useState, useEffect, useCallback } from 'react'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import AlarmCard from './AlarmCard'
import EventCard from './EventCard'
import { Grid, Container, Tooltip, Button, Box } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles"
import { styled } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import Alarm from '../assets/Alarm.mp3'
import Beep from '../assets/Beep.mp3'
import EventsFilterModal from './EventsFilterModal'
import PanelCommands from './PanelCommands'

const useStyles = makeStyles((theme) => {
    
    return {
        gridAlarmBackdrop: {
            padding: "3rem"
        }
    }
})

const GeneralAlarmButton = styled(IconButton)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        backgroundColor: '#ca373c',
        width: '10vw',
        height: '6vh',
        borderRadius: 100,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#a12b2f'
        }
    },
    [theme.breakpoints.down('md')]: {
        backgroundColor: '#ca373c',
        width: '15vw',
        height: '6vh',
        borderRadius: 100,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#a12b2f'
        }
    }
}))

const GeneralResetButton = styled(IconButton)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        backgroundColor: '#00e676',
        width: '10vw',
        height: '6vh',
        borderRadius: 100,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#2e7d32'
        }
    },
    [theme.breakpoints.down('md')]: {
        backgroundColor: '#00e676',
        width: '15vw',
        height: '6vh',
        borderRadius: 100,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#2e7d32'
        }
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
});

export default function AlarmBackdrop(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [alarms, setAlarms] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [disbledEvents, setDisabledEvents] = useState([]);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [alarmAudio] = useState(new Audio(Alarm));
    const [beepAudio] = useState(new Audio(Beep));

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const generalReset = (command) => {
        props.generatedCommand({
            "groupValue": 'general',
            "type": command
        })
        setButtonsDisabled(true)
        if (command === 'DESL') {
            setDisabled(true)
        }
        fetch(`${process.env.REACT_APP_URL}/command/send`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify({
                "userId": props.companyId,
                "groupValue": `${props.serialNumber}0`,
                "groupName": "Geral",
                "command": command,
                "origin": "guardiao"
            })
        })
            .then(res => res.json())
            .then(data => {
                setButtonsDisabled(false)
                if (command === "DESL") {
                    setAlarms([])
                    props.clearState()
                    setOpen(false)
                }

                if (process.env.REACT_APP_MODE === 'desktop') {
                    fetch(`${process.env.REACT_APP_API_URL}/Events/receive`, {
                        method: 'POST',
                        headers: {
                            "content-type": "application/json",
                            "Authorization": "Bearer " + props.token
                        },
                        body: JSON.stringify({
                            "id": data.id,
                            "type": "alarme",
                            "dT_EVENTO": data.dT_ALARME,
                            "fK_CENTRAL": data.fK_CENTRAL,
                            "dS_ORIGEM_ALARME": "Guardião",
                            "fK_OCORRENCIA": data.dS_DESCRICAO_ALARME,
                            "fK_GRUPO": data.fK_CENTRAL + "0",
                        })
                    }).then().catch((error) => { setButtonsDisabled(false) })
                }
            }).catch(() => { setButtonsDisabled(false) })

    }

    const removeGroup = (group) => {
        const filteredItems = alarms.filter(item => item.fK_GRUPO !== group)
        setAlarms(filteredItems)
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    useEffect(() => {
        async function clear() {
            await delay(1000)
            setAlarms([])
            setOpen(false)
            setDisabled(true)
        }
        clear()
    }, [props.clearBackdrop])

    useEffect(() => {
        if (typeof props.rows === undefined) 
        {

        } 
        else 
        {
            if (props.rows) 
            {
                if (props.rows.length === 0) {
                    setAlarms([])
                    setDisabled(true)
                }

                if (props.serial === props.rows.fK_CENTRAL) {
                    var isDisabled = disbledEvents.find(item => item.dS_DESCRICAO === props.rows.fK_OCORRENCIA && item.desabilitado === true)

                    if (isDisabled === undefined) {
                        setAlarms(alarms => [...alarms, props.rows])

                        if (props.rows.fK_OCORRENCIA === "DESL") {
                            setAlarms([])
                            props.clearState()
                            setAlarms(alarms => [...alarms, props.rows])
                        }
                        if (props.rows.fK_OCORRENCIA === "BATERIA BAIXA" ||
                            props.rows.fK_OCORRENCIA === "REMOVIDO" ||
                            props.rows.fK_OCORRENCIA === "CONTROLE AMBIENTAL" ||
                            props.rows.fK_OCORRENCIA === "SEM REDE AC"
                        ) {
                            beepAudio.play()
                        }

                        if (props.rows.fK_OCORRENCIA === "DESL") {
                            alarmAudio.pause()
                        }
                        if (props.rows.fK_OCORRENCIA !== "DESL" &&
                            props.rows.fK_OCORRENCIA !== "BATERIA BAIXA" &&
                            props.rows.fK_OCORRENCIA !== "REMOVIDO" &&
                            props.rows.fK_OCORRENCIA !== "CONTROLE AMBIENTAL" &&
                            props.rows.fK_OCORRENCIA !== "SEM REDE AC"
                        ) {
                            alarmAudio.play()
                        }

                        if (alarms.length > 99) 
                        {
                            alarms.shift()
                        }
                        if (!open) 
                        {
                            setOpen(true)
                        }
                        if (disabled) 
                        {
                            setDisabled(false)
                        }
                        if (props.rows.fK_OCORRENCIA === "DESL") 
                        {
                            setDisabled(true)
                        }
                    }
                }
            }
        }
    }, [props.rows])

    return (
        <>
            <button type="button" class="header-action" data-action="system-alerts" disabled={disabled} onClick={handleClickOpen}>
                <i class="fa fa-exclamation-triangle fa-lg" aria-hidden="true" style={{"marginTop": "2px"}}></i>
            </button>
            
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}>
                    
                <AppBar sx={{ position: 'absolute' }}>
                    <Toolbar>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            position="center">
                            <CloseIcon />
                        </IconButton>
                        {process.env.REACT_APP_MODE === 'desktop' &&
                            <Grid justifyContent='space-around' alignItems='center' container spacing={3} paddingTop={"24px"}>
                                <PanelCommands 
                                    generatedCommand={props.generatedCommand} 
                                    user={props.companyId} 
                                    serialNumber={props.serial}
                                    token={props.token}
                                    modemStatus={props.modemStatus}
                                    occurrenceController={props.occurrenceController}
                                    setOccurrenceController={props.setOccurrenceController}
                                />
                                {/* <Grid item xs={3} md={2} lg={2} marginLeft={30}>
                                    <GeneralResetButton disabled={buttonsDisabled} color="inherit" onClick={() => generalReset("DESL")}>
                                        <Typography variant="delta2">Restaurar Sistema</Typography>
                                    </GeneralResetButton>
                                </Grid>
                                {props.userRole.alarmes === true ?
                                    <>
                                        <Grid item xs={3} md={2} lg={2}>
                                            <GeneralAlarmButton disabled={buttonsDisabled} color="inherit" onClick={() => generalReset("LIGA")}>
                                                <Typography variant="delta2">Alarme de Abandono</Typography>
                                            </GeneralAlarmButton>
                                        </Grid>
                                        <Grid item xs={3} md={2} lg={2}>
                                            <GeneralAlarmButton disabled={buttonsDisabled} color="inherit" onClick={() => generalReset("PANI")}>
                                                <Typography variant="delta2">Alarme de Brigada</Typography>
                                            </GeneralAlarmButton>
                                        </Grid>
                                        <Grid item xs={3} md={2} lg={2}>
                                            <GeneralAlarmButton disabled={buttonsDisabled} color="inherit" onClick={() => generalReset("SILE")}>
                                                <Typography variant="delta2">Silenciar Alarme</Typography>
                                            </GeneralAlarmButton>
                                        </Grid>
                                        <Grid item xs={1} md={1} lg={1} marginLeft={10}>
                                            <EventsFilterModal setDisabledEvents={setDisabledEvents} token={props.token} />
                                        </Grid>
                                    </>
                                    : null} */}
                            </Grid>
                        }
                    </Toolbar>
                </AppBar>
                < br/>
                <Grid container spacing={2} className={classes.gridAlarmBackdrop}>
                    {alarms.length > 0
                        ? alarms.slice(0).reverse().map(alarm => (
                            (
                                alarm.type === "alarme"
                                ? 
                                <Grid key={alarm.id} item xs={12} md={6} lg={4}>
                                    <AlarmCard token={props.token} serialNumber={props.serial} setAlarms={setAlarms} row={alarm} />
                                </Grid>
                                : 
                                <Grid key={alarm.id} item xs={12} md={6} lg={4}>
                                    <EventCard userRole={props.userRole} userId={props.userId} token={props.token} serialNumber={props.serial} removeGroup={removeGroup} row={alarm} />
                                </Grid>
                            )
                        ))
                        : null
                    }
                </Grid>
            </Dialog>
        </>
    );
}