import React, { useState, useEffect } from "react"
import { Grid, styled, Container, Stack, Paper, Typography } from '@mui/material'
import GenerateCommand from "../components/GenerateCommand"
import makeStyles from "@mui/styles/makeStyles"
import { TouchApp } from '@mui/icons-material'

const useStyles = makeStyles({
    pageCommand: {
        minHeight: "100vh"
    },
    stack: {
        maxWidth: "100%"
    }
})

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '80vw',
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    width: '80vw',
    minHeight: '30px'
}));

export default function Commands(props) {
    const classes = useStyles()
    const [groups, setGroups] = useState([])
    const [commands, setCommands] = useState([])
    const [lastAlarm, setLastAlarm] = useState({})

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/group/all`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify({
                "serialNumber": props.serial,
            })
        })
        .then(res => res.json())
        .then(data => setGroups(data))
    }, [])

    // useEffect(() => {
    //     fetch(`${process.env.REACT_APP_URL}/command/last?serialNumber=${props.serial}`, { 
    //         method: 'GET', 
    //         headers: {
    //             "content-type": "application/json",
    //             "authorization": "Bearer " + props.token
    //     }, })
    //     .then(response => response.json())
    //     .then(data => setLastAlarm(data))
    // })

    const newColor = (row) => {
        if (row.dS_DESCRICAO_ALARME === "DESL") {
            return "#00B33C"
        }
        if (row.dS_DESCRICAO_ALARME === "PANI") {
            return "#F58634"
        }
        if (row.dS_DESCRICAO_ALARME === "SILE") {
            return "#FFEA00"
        }
        if (row.dS_DESCRICAO_ALARME === "LIGA") {
            return "#CA373C"
        }
    }


    return (
        <Container className={classes.pageCommand}>
            <GenerateCommand 
                generatedCommand={props.generatedCommand} 
                user={props.user} 
                companyId={props.companyId}
                serialNumber={props.serial} 
                token={props.token} 
                groupList={groups}
                occurrenceController={props.occurrenceController}
                setOccurrenceController={props.setOccurrenceController}
                modemStatus={props.modemStatus}
            />
        </Container>
    )
}