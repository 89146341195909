import { BorderColor } from "@mui/icons-material";

function configs(labels, datasets) {
  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          data: datasets.data,
          backgroundColor: [
            '#FFEA00',
            '#ffd600',
            '#e53935',
            '#b71c1c'
          ],
          holeSize: 0.1,
          hoverOffset: 4,
        }
      ]
    },
    options: {
      borderWidth: 0,
      responsive: true,
      plugins: {
        legend: {
          position: 'top'
        },
        title: {
          display: false,
          text: 'Chart.js Doughnut Chart'
        }
      }
    },
  }
};

export default configs;