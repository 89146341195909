import React, { useState, useEffect } from "react"
import { Container, Box, LinearProgress } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import WebLogin from "../components/WebLogin"
import LogoDelta from '../assets/logoDelta.png'
import LogoGuardiao from '../assets/logoPequeno.png'
import LogoIntegrador from '../assets/logo-integrador-v2-v.png'
import LogoTecnico from '../assets/tecnico.png'
import LogoGuardiaoVertical from '../assets/logo-guardiao-2-v-v2.png'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            backgroundColor: '#212121',
            width: '100%',
            alignContent: 'center',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                marginTop: '5rem',
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"item1 item1 item2 item2" "item1 item1 item3 item3"`,
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '2rem',
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 3,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"item2 item2 item2 item2" "item1 item1 item1 item1" "item3 item3 item3 item3"`,
            }
        },
        pageHome: {
            [theme.breakpoints.up('sm')]: {

            },
            [theme.breakpoints.down('sm')]: {

            }
        },
        pageHomeLeft: {
            [theme.breakpoints.up('sm')]: {

            },
            [theme.breakpoints.down('sm')]: {

            }
        },
        logoDeltaHome: {
            //justifyItems: "center"
            objectFit: "contain",
            objectPosition: "center",
        },
        imgDiv: {
            display: "flex",
            justifyContent: "center"
        },
        item1: {
            gridArea: "item1",
            margin: "auto"
        },
        item2: {
            gridArea: "item2",
            margin: "auto"
        },
        item3: {
            gridArea: "item3",
            margin: "auto",
            marginTop: "3rem"
        }
    }
})


export default function WebHomePage(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Container>
            {isLoading && 
                <LinearProgress color="secondary" style={{position: "fixed", top: "0", left: "0", width: "100%", zIndex: "1201"}} /> 
            }
            <Box className={classes.container}>
                <Box className={classes.item1}><WebLogin populateData={props.populateData} data={props} setIsLoading={setIsLoading} /></Box>
                <Box className={classes.item2}><img width={350} height={150} src={props.theme === '#f44336' ? LogoIntegrador : props.theme === '#ff9800' ? LogoTecnico : LogoGuardiaoVertical} alt={"Logo Guardião"} className={classes.logoDeltaHome} /></Box>
                <Box className={classes.item3}><img width={120} height={50} src={LogoDelta} alt={"Delta Fire"} className={classes.logoDeltaHome} /></Box>
            </Box>
        </Container>
    )
}