import React, { useState, useEffect } from "react"
import { Router} from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import makeStyles from "@mui/styles/makeStyles"
import { Grid } from "@mui/material"

const useStyles = makeStyles({

    grid: {
        maxWidth: "100%"
    },
    textField: {
        width: '15vw'
    }
})

export default function AmplifierList(props) {
    const [amplifier, setAmplifier] = useState({})
    const [location, setLocation] = useState("")

    useEffect(() => {
        setAmplifier(props.amplifier)
        setLocation(props.amplifier.fK_VL_CADASTR_GRUPO.slice(12) + " - " + props.amplifier.dS_LOCALIZACAO)
    }, [props.amplifier])

    // useEffect(() => {
    //     console.log("🚀 ~ useEffect ~ amplifier:", amplifier)
    //     console.log("🚀 ~ useEffect ~ amplifier.dS_STATUS:", `${amplifier.dS_DL} - ${amplifier.dS_STATUS}`)
    // },[amplifier])

    return (
        <Grid alignItems="center" container spacing={3} style={{"width": "100%", "display": "flex", "alignItems": "center", "justifyContent": "center", "marginTop": 0,"marginLeft": 0}}>
            <Grid item key={1} xs={1} md={1} lg={1} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                {amplifier.dS_STATUS === "online" || amplifier.dS_STATUS === "online/sincronizado" ? <Router sx={{ color: "#34C759" }} /> : amplifier.dS_STATUS === "buscando" || amplifier.dS_STATUS === "online/sincronizando" ? <Router sx={{ color: "#5ACBFA" }} /> : <Router sx={{ color: "#FF3B30" }} />}
            </Grid>

            <Grid item key={2} xs={2} md={2} lg={2} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                <Typography variant="delta">{String(amplifier.dS_DL).toUpperCase()}</Typography>                    
            </Grid>

            <Grid item key={3} xs={2} md={2} lg={2} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                <Typography variant="delta">{amplifier.dS_STATUS === "online" || amplifier.dS_STATUS === "online/sincronizado"  ? "online/sincronizado" : amplifier.dS_STATUS === "buscando" || amplifier.dS_STATUS === "online/sincronizando" ? "online/sincronizando" : "offline"}</Typography>                    
            </Grid>

            <Grid item key={4} xs={4} md={4} lg={4} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                <Typography variant="delta">{location.split(" - ")[1] === "" ? location.split(" - ")[0] + " - Sem Nome Registrado" : location}</Typography>                    
            </Grid>

            <Grid item key={5} xs={3} md={3} lg={3} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                <Typography variant="delta">{amplifier.dS_NOME == null ? "Sem Nome Registrado" : amplifier.dS_NOME}</Typography>                    
            </Grid>
        </Grid>
    )
}