import React, { useState } from 'react'
import { Typography, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Warning } from "@mui/icons-material"

const useStyles = makeStyles((theme) => {
    return {
        containerEventItem: {
            alignItems: 'center',
            //justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"eventItem1 eventItem2 eventItem3 eventItem4 eventItem5 eventItem6 eventItem7"`,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"eventItem1 eventItem2 eventItem3" 
                "eventItem4 eventItem5 eventItem6"
                "item7 . ."`,
            }
        },
        eventItem1: {
            gridArea: "eventItem1",
        },
        eventItem2: {
            gridArea: "eventItem2",
        },
        eventItem3: {
            gridArea: "eventItem3",
        },
        eventItem4: {
            gridArea: "eventItem4",
        },
        eventItem5: {
            gridArea: "eventItem5",
        },
        eventItem6: {
            gridArea: "eventItem6",
        }
    }
})

export default function EventAlertRemote(data) {
    const [row, setRow] = useState({})
    const classes = useStyles()
    useState(() => {
        setRow(data.row)
    }, [])

    const newColor = (row) => {
        if (row.dS_DESCRICAO_EVENTO === "Sub Central") {
            if (row.fK_OCORRENCIA === "ALARME DE RETORNO" ||
                row.fK_OCORRENCIA === "ALARME EXTRA" ||
                row.fK_OCORRENCIA === "ALARME VISUAL") {
                return "#FFEA00"
            }
            if (row.fK_OCORRENCIA === "SISTEMA RESTAURADO") {
                return "#00B33C";
            }
            if (row.fK_OCORRENCIA === "ALARME DE BRIGADA") {
                return "#F58634";
            }
            if (row.fK_OCORRENCIA === "ALARME DE ABANDONO") {
                return "#CA373C";
            }
        }
        if (row.fK_OCORRENCIA === "BATERIA BAIXA" ||
            row.fK_OCORRENCIA === "SINALIZAÇAO VISUAL ATIVA" ||
            row.fK_OCORRENCIA === "REMOVIDO" ||
            row.fK_OCORRENCIA === "SILENCIAR ALARME" ||
            row.fK_OCORRENCIA === "CONTROLE AMBIENTAL" ||
            row.fK_OCORRENCIA === "SEM REDE AC"
        ) {
            return "#FFEA00"
        } else {
            return "#CA373C"
        }
    }

    return (
        <div>

            <Box className={classes.containerEventItem}>
                <Box className={classes.eventItem1}><Warning sx={{ color: newColor(row) }} /></Box>
                <Box className={classes.eventItem2}><Typography variant="delta">{row.fK_CENTRAL}</Typography></Box>
                <Box className={classes.eventItem3}><Typography variant="delta">{row.fK_OCORRENCIA}</Typography></Box>
                <Box className={classes.eventItem4}><Typography variant="delta">{row.dS_DESCRICAO_EVENTO}</Typography></Box>
                <Box className={classes.eventItem5}><Typography variant="delta">{row.dS_ID_PERIFERICO === undefined ? row.iD_PERIFERICO : row.dS_ID_PERIFERICO}</Typography></Box>
                <Box className={classes.eventItem6}><Typography variant="delta">{row.dS_LOCAL_EVENTO}</Typography></Box>
                <Box className={classes.eventItem7}><Typography variant="delta">{new Date(row.dT_EVENTO).toLocaleString().replace(',', '')}</Typography></Box>
            </Box>
        </div>
    )
}