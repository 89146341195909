import { useEffct, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Line } from "react-chartjs-2"
import { Chart, registerables } from "chart.js"
import { Card, Divider, Icon, Box, Typography } from "@mui/material"
import configs from "./configs"
import { purple, green, grey, lightBlue, orange, red } from '@mui/material/colors'
Chart.register(...registerables)

const useStyles = makeStyles((theme) => {
    return {
        bg: {
            backgroundColor: green,
            padding: '1rem'
        }
    }
})

function LineChart({ darkMode, chart, backgroundColor }) {
    const classes = useStyles()
    const { data, options } = configs(chart.labels || [], chart.datasets || {}, darkMode)
    return (
        <Box sx={{ bgcolor: backgroundColor }}>
            <Line data={chart} options={options} />
        </Box>
    )
}

export default LineChart