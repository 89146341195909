import React, { useState, useEffect } from 'react'
import { Container, Typography, TextField, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    fieldOccurrences: {
        width: '100%'
    }
})

export default function Occurrences(props) {
    const classes = useStyles()
    const [occurrences, setOccurrences] = useState([])
    const [currency, setCurrency] = useState("Todos")

    const handleChange = (event) => {
        setCurrency(event.target.value)
        props.setOccurrence(event.target.value)
    }

    async function populateData() {
        const response = await fetch(`${process.env.REACT_APP_URL}/occurrence/all`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization" : "Bearer "+props.token
              }
        })

        const json = await response.json()
        return json
    }

    useEffect(() => {
        populateData().then(json => {
            setOccurrences(json)
            setOccurrences(occurrences => [...occurrences, { id: 0, dS_DESCRICAO: "Todos" }].reverse())
        })
    }, [])

    useEffect(() => {
        if (occurrences.length > 0)
        {
            props.setControlLoading(props.controlLoading.map(item => {
                if (item.type === "occurrence") 
                {
                    return { ...item, status: true };
                }
                return item;
            }));
        }
    },[occurrences]);


    return (
        <>
            <TextField
                id="outlined-select-currency"
                select
                label="Tipo de Ocorrência"
                value={currency}
                onChange={handleChange}
                className={classes.fieldOccurrences}
            >
                {occurrences.map((option) => (
                    <MenuItem key={option.id} value={option.dS_DESCRICAO}>
                        {option.dS_DESCRICAO}
                    </MenuItem>
                ))}
            </TextField>
        </>
    )
}