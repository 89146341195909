import { useState, useEffect } from "react"
import { Grid, Container, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { Card, CardHeader, CardActions, CardContent, Snackbar } from "@mui/material"
import LineDashboard from "../dashboard/charts/LineChart/LineDashboard"
import DoughnutDashboard from "../dashboard/charts/DoughnutChart/DoughnutDasboard"
import BateryData from "../dashboard/charts/data/BateryData"
import RemovedData from "../dashboard/charts/data/RemovedData"
import ShotData from "../dashboard/charts/data/ShotData"
import AlarmData from "../dashboard/charts/data/AlarmData"
import DoughnutData from "../dashboard/charts/data/DoughnutData"
import Calendar from "../dashboard/charts/data/Calendar"
import { format } from "date-fns"

const useStyles = makeStyles({
    gridDashboard: {
        // marginBottom: '1rem',
        // width: '100%',
        // maxWidth: '100%',
        minHeight: '100vh'
    }
})

export default function Dashboards(props) {
    const classes = useStyles()
    const bateryColor = "#FFEA00"
    const removedColor = "#ffd600"
    const shotColor = "#e53935"
    const alarmColor = "#b71c1c"
    const [refresh, setRefresh] = useState(1)
    const [open, setOpen] = useState(false)
    const [refreshDate, setRefreshDate] = useState(format(Date.now(), 'dd-MM-yyyy HH:mm:ss'))
    const { labels, monthName, month, year } = Calendar()
    const { batery } = BateryData(props.origin, props.menuItens, month, year, props.rows)
    const { removed } = RemovedData(props.origin, props.menuItens, month, year, props.rows)
    const { shot } = ShotData(props.origin, props.menuItens, month, year, props.rows)
    const { alarm } = AlarmData(props.origin, props.menuItens, month, year, props.rows)

    useEffect(() => {
        setOpen(true)
        setRefreshDate(format(Date.now(), 'dd-MM-yyyy - HH:mm:ss'))
    }, [props.rows])


    const closeSnackBar = () => {
        setOpen(false)
    }

    const data = {
        labels: labels,
        datasets: []
    }

    const bateryData = {
        labels: labels,
        datasets: []
    }

    const removedData = {
        labels: labels,
        datasets: []
    }

    const shotData = {
        labels: labels,
        datasets: []
    }

    const alarmData = {
        labels: labels,
        datasets: []
    }

    const generalAlarmData = {
        labels: labels,
        datasets: []
    }

    if (batery.datasets !== undefined) {
        batery.datasets.borderColor = bateryColor
        batery.datasets.backgroundColor = bateryColor
        bateryData.datasets.push(batery.datasets)
        data.datasets.push(batery.datasets)
    }

    if (removed.datasets !== undefined) {
        removed.datasets.borderColor = removedColor
        removed.datasets.backgroundColor = removedColor
        removedData.datasets.push(removed.datasets)
        data.datasets.push(removed.datasets)
    }

    if (shot.datasets !== undefined) {
        shot.datasets.borderColor = shotColor
        shot.datasets.backgroundColor = shotColor
        shotData.datasets.push(shot.datasets)
        data.datasets.push(shot.datasets)
    }

    if (alarm.datasets !== undefined) {
        alarm.datasets.borderColor = alarmColor
        alarm.datasets.backgroundColor = alarmColor
        alarmData.datasets.push(alarm.datasets)
        data.datasets.push(alarm.datasets)
        generalAlarmData.datasets = alarm.general
    }

    var doughnutData = {}
    const { doughnut } = DoughnutData(batery.datasets !== undefined ? batery.datasets.data : 0,
        removed.datasets !== undefined ? removed.datasets.data : 0,
        shot.datasets !== undefined ? shot.datasets.data : 0,
        alarm.datasets !== undefined ? alarm.datasets.data : 0)
    doughnutData = doughnut
    if (alarm.datasets !== undefined && shot.datasets !== undefined && removed.datasets !== undefined && batery.datasets !== undefined) {

    }

    return (
        <main class="container">
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={closeSnackBar}
                message="Dashboard Atualizado!" />

            <Grid justifyContent='space-between' container spacing={1}>

                <Grid item xs={12} md={12} lg={12}>
                    <center>
                        <label><h3>Dashboards</h3></label>
                    </center>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body2" color='#616161'><span style={{ color: "#616161" }}>Dados atualizados em: </span> {refreshDate}</Typography>
                </Grid>
            </Grid>

            <br />

            <Grid sx={{ marginBottom: '1rem' }} container spacing={0}>
                <Grid item xs={12} md={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={'Eventos'}
                            subheader={monthName}
                        />
                        <CardContent>
                            <LineDashboard darkMode={props.darkMode} data={data} token={props.token} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid container sx={{ marginBottom: '1rem' }} spacing={2}>

                <Grid item xs={12} md={6} lg={6}>
                    <Card>
                        <CardHeader
                            title={'Acionamentos'}
                            subheader={monthName}
                        />
                        <CardContent>
                            <LineDashboard darkMode={props.darkMode} data={shotData} token={props.token} />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Card>
                        <CardHeader
                            title={'Bateria Baixa'}
                            subheader={monthName}
                        />
                        <CardContent>
                            <LineDashboard darkMode={props.darkMode} data={bateryData} token={props.token} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid sx={{ marginBottom: '1rem' }} container spacing={2}>

                <Grid item xs={12} md={6} lg={6}>
                    <Card>
                        <CardHeader
                            title={'Comandos de Alarmes'}
                            subheader={monthName}
                        />
                        <CardContent>
                            <LineDashboard darkMode={props.darkMode} data={generalAlarmData} token={props.token} />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Card>
                        <CardHeader
                            title={'Removido'}
                            subheader={monthName}
                        />
                        <CardContent>
                            <LineDashboard darkMode={props.darkMode} data={removedData} token={props.token} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={'Resumo'}
                            subheader={monthName}
                        />
                        <CardContent>
                            <DoughnutDashboard data={doughnutData} token={props.token} />
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
        </main>
    )
}