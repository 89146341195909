import React, { useState, useEffect } from "react"
import { Router } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import makeStyles from "@mui/styles/makeStyles"
import { Grid } from "@mui/material"
import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'

const useStyles = makeStyles({

    grid: {
        maxWidth: "100%"
    },
    textField: {
        width: '15vw'
    }
})

export default function AmplifierStatusList(props) {
    const [amplifier, setAmplifier] = useState({})
    const [status, setStatus] = useState({})

    useEffect(() => {
        if (typeof props.amplifier !== null) {
            setAmplifier(props.amplifier)
            setStatus(props.status)
        }
    }, [amplifier])

    return (
        <div>
            <Grid alignItems="center" container spacing={3} style={{"width": "100%", "display": "flex", "alignItems": "center", "justifyContent": "center", "marginTop": 0,"marginLeft": 0}}>
                <Grid item key={2} xs={2} md={2} lg={2} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                    <Typography variant="delta">{String(amplifier.dS_DL).toUpperCase()}</Typography>
                </Grid>

                <Grid item key={3} xs={2} md={2} lg={2} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                    <Typography variant="delta">{amplifier.nome}</Typography>
                </Grid>

                <Grid item key={4} xs={2} md={2} lg={2} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                    <Typography variant="delta">{amplifier.grupo}</Typography>
                </Grid>

                <Grid item key={5} xs={3} md={3} lg={3} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                    {typeof status !== 'undefined' ? <Typography variant="delta">{amplifier.status}</Typography> : null}
                </Grid>

                <Grid item key={6} xs={3} md={3} lg={3} style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "paddingTop": "5px", "paddingBottom": "5px", "paddingLeft": 0}}>
                    {typeof status !== 'undefined' ? <Typography variant="delta">{new Date(amplifier.data).toLocaleString().replace(",", "")}</Typography> : null}
                </Grid>
            </Grid>
        </div>
    )
}