import { useState, useEffect, forwardRef } from "react";
import { Typography, Card, CardHeader, CardContent, Grid, InputLabel, Tooltip, IconButton, OutlinedInput, InputAdornment, Snackbar, Button, FormControlLabel, Checkbox } from "@mui/material";
import { AlternateEmail, Add, Remove } from '@mui/icons-material'
import makeStyles from "@mui/styles/makeStyles"
import EmailCard from "./EmailCard";
import SmtpServerConfig from "./SmtpServerConfig";
import MuiAlert from "@mui/material/Alert"

const useStyles = makeStyles({
    card: {
        padding: "1rem"
    }
})

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function EmailSettings(props) {
    const classes = useStyles();
    const [openCreate, setOpenCreate] = useState(false)
    const [email, setEmail] = useState("")
    const [emailList, setEmailList] = useState([])
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/email/get`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                // "Authorization": "Bearer " + props.token
            }
        })
            .then(res => {
                if (res.status === 404) {

                } else {
                    res.json()
                        .then(data => setEmailList(data))
                }
            })
    }, [])

    const [emailRoleList, setEmailRoleList] = useState({
        acionamentos: false,
        disparos: false,
        removidos: false,
        bateria: false,
        alarmes: false,
        reset: false
    })

    const { acionamentos, disparos, removidos, bateria, alarmes, reset } = emailRoleList

    const addEmail = () => {
        var tempEmailList = [...emailList]
        var createdEmail = {
            name: email,
            acionamentos: emailRoleList.acionamentos,
            disparos: emailRoleList.disparos,
            removidos: emailRoleList.removidos,
            bateria: emailRoleList.bateria,
            alarmes: emailRoleList.alarmes,
            reset: emailRoleList.reset
        }

        fetch(`${process.env.REACT_APP_URL}/email/post`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                // "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify({
                name: createdEmail.name,
                acionamentos: createdEmail.acionamentos,
                disparos: createdEmail.disparos,
                removidos: createdEmail.removidos,
                bateria: createdEmail.bateria,
                alarmes: createdEmail.alarmes,
                reset: createdEmail.reset
            })
        })
            .then(res => {


                if (res.status === 400) {
                    res.json()
                        .then(data => {
                            setSeverity("error")
                            setMessage(data.message)
                            setOpen(true)
                        })
                } else {
                    res.json()
                        .then(data => {
                            tempEmailList.push(data)
                            setEmailList(tempEmailList)
                            setEmail("")
                            setEmailRoleList({})
                        })

                }
            })


    }

    const editEmail = (email) => {
        var tempEmailList = [...emailList]
        tempEmailList.filter(item => {
            if (item.name === email.name) {
                item.name = email.name
                item.acionamentos = email.acionamentos
                item.disparos = email.disparos
                item.removidos = email.removidos
                item.bateria = email.bateria
                item.alarmes = email.alarmes
                item.reset = email.reset
            }
        })
        fetch(`${process.env.REACT_APP_URL}/email/put`, {
            method: 'PUT',
            headers: {
                "content-type": "application/json",
                // "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify({
                id: email.id,
                name: email.name,
                acionamentos: email.acionamentos,
                disparos: email.disparos,
                removidos: email.removidos,
                bateria: email.bateria,
                alarmes: email.alarmes,
                reset: email.reset
            })
        }).then(res => {
            if (res.status === 400) {
                // snackbar
            } else {
                setEmailList(tempEmailList)
            }
        })
    }

    const deleteEmail = (email) => {
        fetch(`${process.env.REACT_APP_URL}/email/delete?id=${email.id}`, {
            method: 'DELETE',
            headers: {
                "content-type": "application/json",
                // "Authorization": "Bearer " + props.token
            }
        }).then(res => {
            if (res.status === 404) {
                // snackbar
            } else {
                var tempEmailList = [...emailList].filter(item => item.name !== email.name)
                setEmailList(tempEmailList)
            }
        })

    }

    const handleRoles = (event) => {
        setEmailRoleList({
            ...emailRoleList,
            [event.target.name]: event.target.checked,
        })
    }

    const handleSnackBar = () => {
        setOpen(!open)
    }

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleSnackBar}>
                <Alert severity={severity} sx={{ width: '100%' }}>{message}</Alert>
            </Snackbar>

            <SmtpServerConfig />

            <Card className={classes.card}>
                <CardHeader
                    avatar={<AlternateEmail color="primary" />}
                    action={!openCreate
                        ?
                        <Tooltip title="Criar">
                            <IconButton onClick={() => setOpenCreate(!openCreate)}>
                                <Add />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="Fechar">
                            <IconButton onClick={() => setOpenCreate(!openCreate)}>
                                <Remove />
                            </IconButton>
                        </Tooltip>}
                    title={"Adicionar e-mail"}
                />
                {openCreate ?
                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid item xs={4} md={4} lg={4}>
                                <InputLabel htmlFor="usern-name">E-mail</InputLabel>
                                <OutlinedInput
                                    autoFocus={true}
                                    id="usern-name"
                                    onChange={(e) => setEmail(e.target.value)}
                                    variant="outlined"
                                    color="secondary"
                                    required
                                    value={email}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <AlternateEmail />
                                        </InputAdornment>
                                    }
                                ></OutlinedInput>

                            </Grid>

                        </Grid>

                        <Grid sx={{ marginTop: "20px", marginBottom: "20px" }} container spacing={4}>
                            <Grid justifyContent="center" alignItems="center" item xs={12} md={12} lg={12}>
                                <Typography variant='h6'>Notificações</Typography>
                                <Typography sx={{ fontSize: '12px' }}>Selecione as informações que este usuário receberá por e-mail.</Typography>
                            </Grid>

                            <Grid item xs={2} md={2} lg={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={acionamentos} onChange={handleRoles} name="acionamentos" />
                                    }
                                    label="Acionamentos"
                                />
                            </Grid>

                            <Grid item xs={2} md={2} lg={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={disparos} onChange={handleRoles} name="disparos" />
                                    }
                                    label="Disparos"
                                />
                            </Grid>

                            <Grid item xs={2} md={2} lg={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={reset} onChange={handleRoles} name="reset" />
                                    }
                                    label="Restaurar Central"
                                />
                            </Grid>

                            <Grid item xs={2} md={2} lg={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={alarmes} onChange={handleRoles} name="alarmes" />
                                    }
                                    label="Alarmes"
                                />
                            </Grid>

                            <Grid item xs={2} md={2} lg={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={removidos} onChange={handleRoles} name="removidos" />
                                    }
                                    label="Equip. Removidos"
                                />
                            </Grid>

                            <Grid item xs={4} md={4} lg={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={bateria} onChange={handleRoles} name="bateria" />
                                    }
                                    label="Bateria Baixas"
                                />
                            </Grid>
                        </Grid>

                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={addEmail}
                        >
                            Criar
                        </Button>
                    </CardContent>
                    : null}
            </Card>

            <Grid container spacing={3}>
                {emailList.map(email => (
                    <Grid key={email.name} item xs={12} md={12} lg={4}>
                        <EmailCard key={email.name} editEmail={editEmail} deleteEmail={deleteEmail} email={email} />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}