import { Typography, Card, Box } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { Alert } from "@mui/material"
import { styled } from "@mui/material/styles"
import bannerGuardiao from "../assets/bannerGuardiao.jpg"

const useStyles = makeStyles({
    pageUNA: {
        minHeight: "100vh",
        margin: "auto",
        textAlign: "center"
    },
    cardUNA: {
        margin: 'auto',
        padding: '4rem',
        width: '750px',
        marginTop: '20vh'
    }
})

const ImageWrapper = styled('img')(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        maxWidth: '700px',
        maxHeight: '300px',
        marginTop: '20vh'
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: '80vw',
        maxHeight: '20vh',
        marginTop: '20vh'
    }
}));

export default function UserNotAllowed(props) {
    const classes = useStyles()
    return (
        <Box className={classes.pageUNA}>
            {props.userRole.light == true
                ? <ImageWrapper src={bannerGuardiao} alt={"Banner Guardião"} />
                : props.userRole.usuarios === false ? <Card className={classes.cardUNA}><Alert severity="warning"> <Typography>Usuário Sem Permissão!</Typography> </Alert></Card> : null}
        </Box>
    )
}