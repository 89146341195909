import React, { useState, useEffect } from 'react'
import { Container, Grid, Button, Box, Typography, Stack } from '@mui/material'
import Calendar from '../components/Calendar'
import Occurrences from '../components/Occurrences'
import Description from '../components/Description'
import Local from '../components/Local'
import makeStyles from "@mui/styles/makeStyles"
import format from 'date-fns/format'
import EventAlertRemote from '../components/EventAlertRemote'
import Pagination from '@mui/material/Pagination'
import ptBR from 'date-fns/locale/pt-BR'
import { styled } from '@mui/material/styles'
import { Warning } from "@mui/icons-material"
import PdfViewer from '../providers/PdfViewer';
import Drawer from '../includes/Drawer';
import { ReactComponent as IconPDF } from '../assets/icons/icon_file_pdf.svg'

const useStyles = makeStyles((theme) => {
    return {
        containerEventRelatory: {
            alignItems: 'center',
            //justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"eventRelatoryItem1 eventRelatoryItem2 eventRelatoryItem3 eventRelatoryItem4 eventRelatoryItem5 eventRelatoryItem6 eventRelatoryItem7"`,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"eventRelatoryItem1 eventRelatoryItem2 eventRelatoryItem3" 
                "eventRelatoryItem4 eventRelatoryItem5 eventRelatoryItem6"
                "eventRelatoryItem7 . ."`,
            }
        },
        eventRelatoryItem1: {
            gridArea: "eventRelatoryItem1",
        },
        eventRelatoryItem2: {
            gridArea: "eventRelatoryItem2",
        },
        eventRelatoryItem3: {
            gridArea: "eventRelatoryItem3",
        },
        eventRelatoryItem4: {
            gridArea: "eventRelatoryItem4",
        },
        eventRelatoryItem5: {
            gridArea: "eventRelatoryItem5",
        },
        eventRelatoryItem6: {
            gridArea: "eventRelatoryItem6",
        },
        eventRelatoryItem7: {
            gridArea: "eventRelatoryItem7",
        },
        pageEvent: {
            minHeight: "100vh",
            paddingTop: "4rem"
        },
        buttonEvent: {
            marginTop: '3rem',
            marginBottom: '3rem'
        }
    }
})

const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 15,
    width: '100%'
}));

const ItemHeader = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    width: '100%',
    minHeight: '30px'
}));

const NewGrid = styled(Grid)(({ theme }) => ({
    paddingTop: "10px"
    //padding: theme.spacing(3)
}))

export default function EventRelatory(props) {
    const classes = useStyles()
    const [firstDate, setFirstDate] = useState(format(new Date(), 'MM/dd/yyyy'), { locale: ptBR })
    const [secondDate, setSecondDate] = useState(format(new Date(), 'MM/dd/yyyy'), { locale: ptBR })
    const [occurrence, setOccurrence] = useState('Todos')
    const [description, setDescription] = useState('Todos')
    const [local, setLocal] = useState('Todos')
    const [result, setResult] = useState([])
    const [pagination, setPagination] = useState({})
    const [page, setPage] = useState(1)
    const [menuItens, setMenuItens] = useState("")
    const [relatoryName, setRelatoryName] = useState("")
    const [groupValue, setGroupValue] = useState("")
    const [show, setShow] = useState(false);
    const [controlLoading, setControlLoading] = useState([{"type": "occurrence", "status": false},{"type": "description", "status": false},{"type": "local", "status": false}]);

    const changeGroupValue = (value) => {
        setGroupValue(value)
    }

    const gerarPdf = async () => {
        let actionUrl = `${process.env.REACT_APP_URL}/relatory/event?pageNumber=1&pageSize=100&firstDate=${firstDate}&secondDate=${secondDate}&user=${props.user.dS_NOME}&menuItens=${menuItens}${description === 'Todos' ? '' : '&description=' + description}${occurrence === 'Todos' ? '' : '&occurrence=' + occurrence}${groupValue === '0000000000000' ? '' : '&local=' + groupValue}`;

        if (process.env.REACT_APP_MODE === "desktop") {
            PdfViewer.Download(actionUrl, "GET", props.token, "relatorio-eventos", props.setIsLoading);
        } else {
            if (props.user.role.delta === true || props.user.role.revenda === true) {
                actionUrl = `${process.env.REACT_APP_URL}/relatory/integrator/event?pageNumber=1&pageSize=100&firstDate=${firstDate}&secondDate=${secondDate}&user=${props.user.dS_NOME}&menuItens=${menuItens}${description === 'Todos' ? '' : '&description=' + description}${occurrence === 'Todos' ? '' : '&occurrence=' + occurrence}${groupValue === '0000000000000' ? '' : '&local=' + groupValue}`;
                PdfViewer.PrintAsync(actionUrl, "GET", props.token, props.setIsLoading);
            } else {
                PdfViewer.PrintAsync(actionUrl, "GET", props.token, props.setIsLoading);
            }
        }
    }

    const request = async() => {
        props.setIsLoading(true);

        fetch(`${process.env.REACT_APP_URL}/events/relatory?pageNumber=1&pageSize=100&firstDate=${firstDate}&secondDate=${secondDate}&menuItens=${menuItens}&name${relatoryName}${description === 'Todos' ? '' : '&description=' + description}${occurrence === 'Todos' ? '' : '&occurrence=' + occurrence}${groupValue === '0000000000000' ? '' : '&local=' + groupValue}`,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Expose-Headers': '*',
                "Authorization": "Bearer " + props.token
            }
        })
        .then(response => (
            setPagination(JSON.parse(response.headers.get("X-Pagination"))),
            response.json()
        ))
        .then(data => {
            setShow(true);
            setResult(data)
            props.setIsLoading(false);
        })
        .catch(e => {
            console.log("🚀 ~ request ~ e:", e)
            props.setIsLoading(false);
        })
    }

    const pageChange = (event, value) => {
        props.setIsLoading(true);
        setPage(value)

        fetch(`${process.env.REACT_APP_URL}/events/relatory?pageNumber=${value}&pageSize=100&firstDate=${firstDate}&secondDate=${secondDate}&menuItens=${menuItens}${description === 'Todos' ? '' : '&description=' + description}${occurrence === 'Todos' ? '' : '&occurrence=' + occurrence}${groupValue === '0000000000000' ? '' : '&local=' + groupValue}`,
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Expose-Headers': '*',
                    "Authorization": "Bearer " + props.token
                }
            })
            .then(response => (
                setPagination(JSON.parse(response.headers.get("X-Pagination"))),
                response.json()
            ))
            .then(data => {
                setResult(data)
                props.setIsLoading(false);
            })
    }

    const handleScriptLoad = () => {
        // Lógica adicional após o carregamento do script, se necessário
      };

    useEffect(() => {
        setResult([])
        if (props.serial === undefined) 
        {
            setMenuItens(Array.from(new Set(
                props.menuItens.map(item => {
                    return item.id_central;
                })
            )))
        } 
        else 
        {
            setMenuItens(props.serial)
        }
    }, [props.menuItens])

    useEffect(() => {
        let count = 0;
        
        controlLoading.forEach(item => {
            if (!!item.status)
            {
                count++;
            } else if (props.user.role.delta === true || props.user.role.revenda === true)
            {
                count++;
            }
        });

        if (count === controlLoading.length)
        {
            props.setIsLoading(false);
        }
        else
        {
            props.setIsLoading(true);
        }
    }, [controlLoading]);

    return (
        <main class="container">

            <center>
                <h3 class="mb-5">Relatório de Eventos</h3>
            </center>
            
            <div class="filter">
                <div class="filter__column">
                    <Calendar label={"Data Inicial"} setDate={setFirstDate} />
                </div>
                <div class="filter__column">
                    <Calendar label={"Data Final"} setDate={setSecondDate} />
                </div>
                <div class="filter__column">
                    <Occurrences token={props.token} setOccurrence={setOccurrence} controlLoading={controlLoading} setControlLoading={setControlLoading} />
                </div>
                {props.user.role.delta === true || props.user.role.revenda === true 
                ? 
                null 
                :
                <div class="filter__column">
                    <Description serial={props.serial} token={props.token} setLocal={setDescription} controlLoading={controlLoading} setControlLoading={setControlLoading} />
                </div>
                }
                {props.user.role.delta === true || props.user.role.revenda === true 
                ? 
                null 
                :
                <div class="filter__column">
                    <Local serial={props.serial} token={props.token} changeGroupValue={changeGroupValue} setLocal={setLocal} controlLoading={controlLoading} setControlLoading={setControlLoading} />
                </div>
                }
            </div>

            <div class="buttons-container buttons-container--right mb-5">
                <button type="button" class="button button--md button--primary" title="Buscar" onClick={request} disabled={props.isLoading}>
                    <span class="button__label">Buscar</span>
                </button>
                <button type="button" class="button button--transparent" title="Gerar PDF" onClick={gerarPdf} disabled={props.isLoading}>
                    <IconPDF className="button__icon df-i df-i-red df-i-2xl"/>
                </button>
            </div>

            {!!show &&
                <>
                    <Stack alignItems="center" spacing={2}>
                        <ItemHeader item key={0} xs={12} md={12} lg={12}>
                            <Box className={classes.containerEventRelatory}>
                                <Box className={classes.eventRelatoryItem1}><Warning /></Box>
                                <Box className={classes.eventRelatoryItem2}><Typography variant="body2">Central</Typography></Box>
                                <Box className={classes.eventRelatoryItem3}><Typography variant="body2">Ocorrência</Typography></Box>
                                <Box className={classes.eventRelatoryItem4}><Typography variant="body2">Descrição</Typography></Box>
                                <Box className={classes.eventRelatoryItem5}><Typography variant="body2">I.D.</Typography></Box>
                                <Box className={classes.eventRelatoryItem6}><Typography variant="body2">Local</Typography></Box>
                                <Box className={classes.eventRelatoryItem7}><Typography variant="body2">Data</Typography></Box>
                            </Box>
                        </ItemHeader>

                        {result.length > 0 ? result.map(row => {
                            return <Item item key={row.id} xs={12} md={12} lg={12}>
                                <EventAlertRemote key={row.id} row={row} />
                            </Item>
                        }) : <Typography>Nenhum resultado encontrado!</Typography>}
                    </Stack>

                    <Container sx={{ p: 2 }}>
                        <Pagination
                            count={pagination.TotalPages}
                            onChange={pageChange}
                            page={page}
                        />
                    </Container>
                </>
            }

            {/* <Drawer drawerId='event-relatory-pdf' className='drawer--bottom-full'>
                <Drawer.Header>
                    <h4>Relatório para impressão</h4>
                </Drawer.Header>
                <Drawer.Body>

                </Drawer.Body>
            </Drawer> */}
        </main>
    )
}