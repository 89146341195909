import React, { useState, useEffect } from 'react'
import { Container, Grid, Button, Box, TextField, Typography, Autocomplete, Stack } from '@mui/material'
import RegisterItem from "../components/RegisterItem"
import makeStyles from "@mui/styles/makeStyles"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { styled } from '@mui/material/styles'
import RelatoryNameField from '../components/RelatoryNameField'
import PdfViewer from '../providers/PdfViewer'
import Drawer from '../includes/Drawer'
import { ReactComponent as IconPDF } from '../assets/icons/icon_file_pdf.svg'

const useStyles = makeStyles((theme) => {
    return {
        pageRefisterRelatory: {
            minHeight: "100vh",
            paddingTop: "4rem"
        },
        buttonRegisterRelatory: {
            marginTop: '3rem',
            marginBottom: '3rem'
        }
    }
})

const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 15,
    width: '100%'
}));

const ItemHeader = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    height: 'auto',
    width: '100%'
}));


export default function RegisterRelatory(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(true)
    const [descriptionList, setDescriptionList] = useState(["Todos"])
    const [memoryList, setMemoryList] = useState(["Todos"])
    const [localList, setLocalList] = useState(["Todos"])
    const [serieList, setSerieList] = useState(["Todos"])
    const [groupList, setGroupList] = useState(["Todos"])
    const [registers, setRegisters] = useState(["Todos"])
    const [result, setResult] = useState([])
    const [memory, setmemory] = useState("")
    const [description, setDescription] = useState("")
    const [local, setLocal] = useState("")
    const [serie, setSerie] = useState("")
    const [group, setGroup] = useState("")
    const [relatoryName, setRelatoryName] = useState("")
    const [show, setShow] = useState(false);

    const changeRelatoryName = (newName) => {
        setRelatoryName(newName)
    }

    const request = async () => {
        props.setIsLoading(true);

        if (!open) 
        { 
            setOpen(true) 
        }

        var response = await fetch(`${process.env.REACT_APP_URL}/register/register?serialNumber=${props.serial}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Expose-Headers': '*',
                'Authorization': 'Bearer ' + props.token
            }
        })

        var data = await response.json()

        setRegisters(data)

        var uniqueDescription = Array.from(new Set(data.map(register => register.dS_DESCRICAO)))
            .map(description => {
                return {
                    label: description
                }
            })

        var uniqueMem = Array.from(new Set(data.map(register => register.vL_MEM)))
            .map(mem => {
                return {
                    label: mem
                }
            })

        var uniqueLocalization = Array.from(new Set(data.map(register => register.dS_LOCALIZACAO)))
            .map(localization => {
                return {
                    label: localization
                }
            })

        var uniqueSerie = Array.from(new Set(data.map(register => register.dS_NUM_SERIE)))
            .map(serie => {
                return {
                    label: serie
                }
            })

        //TODO - Trazer o nome dos grupos para serem exibidos no Select
        var uniqueGroup = Array.from(new Set(data.map(register => register.fK_GRUPO.slice(12) )))
            .map(group => {
                return {
                    label: group
                }
            })

        setDescriptionList(descriptionList.concat(uniqueDescription))

        setMemoryList(memoryList.concat(uniqueMem))

        setLocalList(localList.concat(uniqueLocalization))

        setSerieList(serieList.concat(uniqueSerie))

        setGroupList(groupList.concat(uniqueGroup))

        props.setIsLoading(false);
    }

    const gerarPdf = async () => {
        let filterParameters = {
            vL_MEM: memory === "Todos" ? "" : memory,
            dS_NUM_SERIE: serie === "Todos" ? "" : serie,
            dS_DESCRICAO: description === "Todos" ? "" : description,
            dS_LOCALIZACAO: local === "Todos" ? "" : local,
            fK_GRUPO: group === "Todos" ? "" : group
        }

        let actionUrl = `${process.env.REACT_APP_URL}/relatory/register?FK_CENTRAL=${props.serial}&VL_MEM=${filterParameters.vL_MEM}&DS_NUM_SERIE=${filterParameters.dS_NUM_SERIE}&DS_DESCRICAO=${filterParameters.dS_DESCRICAO}&DS_LOCALIZACAO=${filterParameters.dS_LOCALIZACAO}&FK_GRUPO=${filterParameters.fK_GRUPO}&user=${props.user.dS_NOME}&name${relatoryName}`;

        if (process.env.REACT_APP_MODE === "desktop") {
            PdfViewer.Download(actionUrl, "GET", props.token, "relatorio-perifericos", props.setIsLoading);
        } else {
            PdfViewer.PrintAsync(actionUrl, "GET", props.token, props.setIsLoading);
        }
    }

    const filter = () => {
        var filterParameters = {
            vL_MEM: memory === "Todos" ? "" : memory,
            dS_NUM_SERIE: serie === "Todos" ? "" : serie,
            dS_DESCRICAO: description === "Todos" ? "" : description,
            dS_LOCALIZACAO: local === "Todos" ? "" : local,
            fK_GRUPO: group === "Todos" ? "" : group
        }

        if (filterParameters.vL_MEM === "" &&
            filterParameters.dS_NUM_SERIE === "" &&
            filterParameters.dS_DESCRICAO === "" &&
            filterParameters.dS_LOCALIZACAO === "" &&
            filterParameters.fK_GRUPO === "") 
        {
            setShow(true);
            setResult(registers)
        } 
        else 
        {
            props.setIsLoading(true);
            fetch(`${process.env.REACT_APP_URL}/register/relatory?FK_CENTRAL=${props.serial}&VL_MEM=${filterParameters.vL_MEM}&DS_NUM_SERIE=${filterParameters.dS_NUM_SERIE}&DS_DESCRICAO=${filterParameters.dS_DESCRICAO}&DS_LOCALIZACAO=${filterParameters.dS_LOCALIZACAO}&FK_GRUPO=${filterParameters.fK_GRUPO}`,
                {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Access-Control-Expose-Headers': '*',
                        "Authorization": "Bearer " + props.token
                    }
                })
                .then(response => response.json())
                .then(data => {
                    setShow(true);
                    setResult(data);
                    props.setIsLoading(false);
                })
        }
    }

    const header = {
        id: 1,
        vL_MEM: "Posição memória",
        dS_NUM_SERIE: "Número de série",
        dS_DESCRICAO: "Periférico",
        fK_GRUPO: "000000000000Grupo",
        dS_LOCALIZACAO: "Local"
    }

    useEffect(() => {
        request()
    }, [])

    return (
        <div class="container">
            <center>
                <h3 class="mb-5">Relatório de Periféricos Cadastrados</h3>
            </center>

            <div class="filter">
                {/* <div class="filter__column filter__column--fw">
                    <RelatoryNameField changeRelatoryName={changeRelatoryName} />
                </div> */}
                <div class="filter__column">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={memoryList}
                        defaultValue={"Todos"}
                        sx={{ width: "100%", mx: 'auto' }}
                        onChange={(event) => setmemory(event.target.innerText === undefined ? "" : event.target.innerText)}
                        renderInput={(params) => <TextField {...params} label="Posição de Memória" />}
                    />
                </div>
                <div class="filter__column">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        defaultValue={"Todos"}
                        options={descriptionList}
                        sx={{ width: "100%", mx: 'auto' }}
                        onChange={(event) => setDescription(event.target.innerText === undefined ? "" : event.target.innerText)}
                        renderInput={(params) => <TextField {...params} label="Periférico" />}
                    />
                </div>
                <div class="filter__column">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={localList}
                        defaultValue={"Todos"}
                        sx={{ width: "100%", mx: 'auto' }}
                        onChange={(event) => setLocal(event.target.innerText === undefined ? "" : event.target.innerText)}
                        renderInput={(params) => <TextField {...params} label="Local" />}
                    />
                </div>
                <div class="filter__column">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={serieList}
                        defaultValue={"Todos"}
                        sx={{ width: "100%", mx: 'auto' }}
                        onChange={(event) => setSerie(event.target.innerText === undefined ? "" : event.target.innerText)}
                        renderInput={(params) => <TextField {...params} label="Número de Série" />}
                    />
                </div>
                <div class="filter__column">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={groupList}
                        defaultValue={"Todos"}
                        sx={{ width: "100%", mx: 'auto' }}
                        onChange={(event) => setGroup(event.target.innerText === undefined ? "" : event.target.innerText)}
                        renderInput={(params) => <TextField {...params} label="Grupo" />}
                    />
                </div>
            </div>

            <div class="buttons-container buttons-container--right mb-5">
                <button type="button" class="button button--md button--primary" title="Buscar" onClick={filter} disabled={props.isLoading}>
                    <span class="button__label">Buscar</span>
                </button>
                <button type="button" class="button button--transparent" title="Gerar PDF" onClick={gerarPdf} disabled={props.isLoading}>
                    <IconPDF className="button__icon df-i df-i-red df-i-2xl"/>
                </button>
            </div>

            {!!show &&
                <Stack sx={{ maxWidth: "100%" }} alignItems="center" spacing={2}>
                    <ItemHeader item key={header.id} xs={12} md={12} lg={12}>
                        <RegisterItem register={header} />
                    </ItemHeader>

                    {result.length > 0 ? result.map(row => {
                        return <Item item key={row.id} xs={12} md={12} lg={12} sx={{ backgroundColor: '#FFFFFFF' }}> <RegisterItem key={row.dS_NUM_SERIE} register={row} /> </Item>
                    }) : <Typography>Nenhum resultado encontrado!</Typography>}
                </Stack>
            }
            {/* <Container sx={{ p: 2 }}>
                <Pagination
                    count={pagination.TotalPages}
                    onChange={pageChange}
                    page={page}
                />
            </Container> */}
        </div>
    )
}