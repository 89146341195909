import React, { useState, useEffect } from "react"
import { Box, Container, Typography, Button, styled, Grid } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { Person, Engineering } from "@mui/icons-material"
import LogoGuardiao from '../assets/logoPequeno.png'
import { useHistory, useLocation } from "react-router"

const useStyles = makeStyles((theme) => {
    return {
        operationContainerLight: {
            backgroundColor: "#000",
            width: '100%',
            height: '100% !important',
            marginTop: '5rem'
        },
        fonts: {
            color: "#ffffff"
        }
    }
})

const AlarmButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        backgroundColor: 'secondary',
        width: '30vw',
        height: '30vh',
        color: 'primary',
        border: '1px solid',
        padding: "2rem",
        '&:hover': {
            backgroundColor: '#2196f3',
            color: '#FFFFFF'
        }
    },
    [theme.breakpoints.down('sm')]: {
        backgroundColor: '#F58634',
        width: '10vw',
        height: '10vh',
        borderRadius: 100,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#b71c1c'
        }
    }
}))

export default function OperationMode(props) {
    const classes = useStyles()
    const history = useHistory()

    const firstLogin = () => {
        history.push("/primeiro+login")
    }

    const technicianFirstLogin = () => {
        history.push("/cadastro+tecnico")
    }

    return (
        <>
            <Box sx={{ marginTop: '1rem', marginLeft: '1rem' }}><img width={120} height={120} src={LogoGuardiao} alt={"Guardião 2.0"} className={classes.logoDeltaHome} /></Box>
            <Container className={classes.operationContainer}>
                <Box textAlign="center" sx={{ width: '100%' }}>

                    <Typography className={classes.fonts} variant="h4">Selecione quem será o Usuário deste Guardião 2.0:</Typography>
                </Box>

                <Grid sx={{ marginTop: '3rem' }} container spacing={5} textAlign='center'>
                    <Grid item sm={6} md={6} lg={6}>
                        <AlarmButton onClick={firstLogin}><Person className={classes.fonts} fontSize="large" /><Typography className={classes.fonts} variant="delta">Usuário Final (Cliente)</Typography></AlarmButton>
                    </Grid>

                    <Grid item sm={6} md={6} lg={6}>
                        <AlarmButton onClick={technicianFirstLogin}><Engineering className={classes.fonts} fontSize="large" /><Typography className={classes.fonts} variant="delta">Usuário Técnico (Integrador)</Typography></AlarmButton>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}