import React, { useState, useEffect } from "react"
import { Button, Typography, Modal, Badge, Stack, Paper, styled, Box } from "@mui/material"
import { FormatListBulleted, Cancel } from "@mui/icons-material"
import RegisterItem from "./RegisterItem"
import { Grid } from "@mui/material"
import { Container } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles"
import { IconButton } from "@mui/material"
import Drawer from "../includes/Drawer"

const useStyles = makeStyles((theme) => {
    return {
        modalRegister: {
            position: 'absolute',
            top: '10%',
            left: '10%',
            overflow: 'scroll',
            height: '100%',
            display: 'block'
        },
        containerRegister: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        },
        containerRegisterItemHeader: {
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"registerItem1 registerItem2 registerItem3 registerItem4 registerItem5 registerItem6"`,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"registerItem1 registerItem2 registerItem3 registerItem5" 
                ". registerItem4 registerItem6 ."`,
            }
        },
        closeRegister: {
            color: '#f44336',
            //padding: '2rem',
            marginLeft: '100%',
        },
        messageRegister: {
            borderRadius: 10,
            width: "30vw",
            height: "15vh",
            position: "absolute",
            backgroundColor: "#FFFFFF",
            padding: "2rem"
        },
    }
})

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#5E5E5E",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
    marginTop: "0px !important",
    marginBottom: "2px !important"
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: "#B4B4B4",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#FFF',
    width: '100%',
    marginBottom: "10px !important"
}));

const header = {
    id: 1,
    vL_MEM: "Posição Memória",
    dS_NUM_SERIE: "Número de Série",
    dS_DESCRICAO: "Periférico",
    fK_GRUPO: "000000000000Grupo",
    dS_LOCALIZACAO: "Localização"
}

export default function RegisterModal({data, index, title}) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [registers, setRegisters] = useState([])
    const [length, setLength] = useState()
    var [registerCounter, setCommandCouner] = useState(1)

    const handleOpen = () => {
        setOpen(!open)
    }

    useEffect(() => {
        if(!!data)
        {
            setRegisters(data);
            setLength(data.length);
        }
    }, [data])

    return (
        <div>
            <label for={`drawer-equipaments-group-${index}`} className="button button--xs mx-auto">
                <Badge
                    badgeContent={length > 0 ? length : 0}
                    color="secondary"
                    showZero={true}
                >
                    <i class="df-i df-i-2xl df-i-peripheral df-i-white"></i>
                </Badge>
            </label>

            <Drawer className="drawer--bottom-full" drawerId={`drawer-equipaments-group-${index}`}>
                <Drawer.Header>
                    <h3>{title}</h3>
                </Drawer.Header>
                <Drawer.Body>
                    <Container>
                        <Stack className={classes.stack} alignItems="center" spacing={2}>
                            <ItemHeader item key={header.id} xs={12} md={6} lg={4}>
                                <Box className={classes.containerRegisterItemHeader}>
                                    <Box className={classes.commandModalItem1}><FormatListBulleted /></Box>
                                    <Box className={classes.commandModalItem2}><Typography variant="body2">Posição Memória</Typography></Box>
                                    <Box className={classes.commandModalItem3}><Typography variant="body2">Número de Série</Typography></Box>
                                    <Box className={classes.commandModalItem4}><Typography variant="body2">Periférico</Typography></Box>
                                    <Box className={classes.commandModalItem5}><Typography variant="body2">Grupo</Typography></Box>
                                    <Box className={classes.commandModalItem6}><Typography variant="body2">Local</Typography></Box>
                                </Box>
                            </ItemHeader>
                            {length > 0 ?
                                registers.map(r => (
                                    <Item item key={r.id} xs={12} md={6} lg={4}>
                                        <RegisterItem register={r} />
                                    </Item>
                                ))
                                : 
                                <h4 style={{textAlign: "center"}}>Este grupo não possui Equipamentos.</h4>
                            }
                        </Stack>
                    </Container>
                </Drawer.Body>
            </Drawer>
        </div>

    )
}