import { useState, useEffect, forwardRef } from 'react'
import { Card, CardHeader, CardContent, CardActions, Button, Snackbar, OutlinedInput, InputAdornment, styled, Paper, Grid, Typography } from '@mui/material'
import { Computer, Key } from "@mui/icons-material"
import makeStyles from "@mui/styles/makeStyles"
import MuiAlert from "@mui/material/Alert"

const useStyles = makeStyles({
    terminalLicenceKey: {
        width: "20rem",
        marginBottom: "0rem"
    },
    terminalLicenceCard: {
        padding: "1rem",
        marginTop: "1rem",
        marginBottom: "1rem"
    },
    terminalListLicenceCard: {
        padding: "1rem",
        textAlign: "center",
        marginTop: "1rem",
        marginBottom: "1rem"
    }
})

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    height: 'auto',
    width: '100%'
}));

export default function TerminalFireLicence(props) {
    const classes = useStyles()
    const [activateButton, setActivateButton] = useState(true)
    const [key, setKey] = useState("")
    const [severity, setSeverity] = useState("")
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [licences, setLicences] = useState([])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/licences/terminal/web/all?serialNumber=${props.systemData.serialKeys}`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            },
        }).then(response => response.json())
            .then(data => setLicences(data))
    }, [])

    const keyLength = (e) => {
        if (e.length >= 10) {
            setKey(e)
            setActivateButton(false)
        } else {
            setActivateButton(true)
        }
    }

    const activateLicence = () => {
        fetch(`${process.env.REACT_APP_URL}/licences/activate/terminal?key=${key}&serialNumber=${props.systemData.serialKeys}`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.message === "Success") {
                    setSeverity("success")
                    setOpen(true)
                    setMessage("Sua Licença foi ativada com sucesso!")
                    props.setActive(1)
                }
                if (data.message === "Used") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Esta chave já está em uso. Adquira uma nova chave.")
                }
                if (data.message === "Error") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Erro ao ativar Licença!")
                }
                if (data.message === "NotFound") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Chave não encontrada!")
                }
                if (data.message === "Invalid") {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Chave Inválida!")
                }
            })
    }

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(!open)}>

                <Alert severity={severity} sx={{ width: '100%' }}>{message}</Alert>
                
            </Snackbar>

            <Card className={classes.terminalLicenceCard}>
                <center>
                    <label><h4>Ativação da Licença</h4></label>
                </center>

                <CardContent>
                    <OutlinedInput
                        className={classes.terminalLicenceKey}
                        id="secret-key"
                        onChange={(e) => keyLength(e.target.value)}
                        variant="outlined"
                        color="secondary"
                        fullWidth={true}
                        required
                        //error={secretError}
                        startAdornment={
                            <InputAdornment position="start">
                                <Key />
                            </InputAdornment>
                        }
                    ></OutlinedInput>
                </CardContent>

                <CardActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth={true}
                        disabled={activateButton}
                        onClick={activateLicence}>
                            Ativar licença
                        </Button>

                </CardActions>
            </Card>

            <ItemHeader>
                <Grid container spacing={3}>
                    <Grid item xs={4} md={4} lg={4}>
                        <Typography variant="body2">Tipo de Licença</Typography>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                        <Typography variant="body2">Chave</Typography>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                        <Typography variant="body2">Status</Typography>
                    </Grid>
                </Grid>
            </ItemHeader>
            {licences.map(item => {
                return <Card className={classes.terminalListLicenceCard} key={item.chave}>
                    <Grid container spacing={3}>
                        <Grid item xs={4} md={4} lg={4}>
                            <Typography variant="body2">Terminal</Typography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <Typography variant="body2">{item.chave}</Typography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <Typography variant="body2">{item.chave_utilizada ? "Ativada" : "Não Ativada"}</Typography>
                        </Grid>
                    </Grid>
                </Card>
            })}
        </>
    )
}