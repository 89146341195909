import React, { createContext, useContext, useState, useCallback } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const SnackbarContext = createContext();

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }) => {
    const maxSnackbars = 3;
    const [snackbars, setSnackbars] = useState([]);

    const openSnackbar = useCallback((message, severity = 'success') => {
        setSnackbars((prev) => {
        // Ensure we do not exceed the maximum number of snackbars
        if (prev.length >= maxSnackbars) {
            prev.shift(); // Remove the oldest snackbar
        }
        return [...prev, { id: uuidv4(), message, severity }];
        });
    }, []);

    const closeSnackbar = useCallback((id) => () => {
        setSnackbars((prev) => prev.filter((snackbar) => snackbar.id !== id));
    }, []);

    return (
        <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
            {children}
            {snackbars.map((snackbar) => (
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    key={snackbar.id}
                    open={true}
                    autoHideDuration={6000}
                    onClose={closeSnackbar(snackbar.id)}
                >
                    <Alert onClose={closeSnackbar(snackbar.id)} variant='filled' severity={snackbar.severity}  sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            ))}
        </SnackbarContext.Provider>
    );
};