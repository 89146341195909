import React, { useState, useEffect } from 'react'
import { Container, Button, Grid, Typography, Checkbox, FormControlLabel, Snackbar } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles({

})

export default function EditEmail(props) {
    const classes = useStyles();
    const [emailRoleList, setEmailRoleList] = useState({
        acionamentos: props.email.acionamentos,
        disparos: props.email.disparos,
        removidos: props.email.removidos,
        bateria: props.email.bateria,
        alarmes: props.email.alarmes,
        reset: props.email.reset
    })


    const [disabled, setDisabled] = useState()
    const { acionamentos, disparos, removidos, bateria, alarmes, reset } = emailRoleList

    const handleRoles = (event) => {
        setEmailRoleList({
            ...emailRoleList,
            [event.target.name]: event.target.checked,
        })
    }

    const handleUpdate = (e) => {
        props.email.acionamentos = emailRoleList.acionamentos
        props.email.disparos = emailRoleList.disparos
        props.email.removidos = emailRoleList.removidos
        props.email.bateria = emailRoleList.bateria
        props.email.alarmes = emailRoleList.alarmes
        props.email.reset = emailRoleList.reset

        props.editEmail(props.email)
    }

    const handleDelete = () => {
        props.deleteEmail(props.email)
    }

    return (
        <Container>
            <Grid sx={{ marginTop: "20px", marginBottom: "20px" }} container spacing={4}>
                <Grid justifyContent="center" alignItems="center" item xs={12} md={12} lg={12}>
                    <Typography sx={{ fontSize: '12px' }}>Editar eventos a ser notificado.</Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <FormControlLabel
                        control={
                            <Checkbox label="Top" disabled={disabled} checked={acionamentos} onChange={handleRoles} name="acionamentos" />
                        }
                        label="Acionamentos"
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <FormControlLabel
                        control={
                            <Checkbox label="Top" disabled={disabled} checked={disparos} onChange={handleRoles} name="disparos" />
                        }
                        label="Disparos"
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <FormControlLabel
                        control={
                            <Checkbox label="Top" disabled={disabled} checked={removidos} onChange={handleRoles} name="removidos" />
                        }
                        label="Removidos"
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <FormControlLabel
                        control={
                            <Checkbox label="Top" disabled={disabled} checked={bateria} onChange={handleRoles} name="bateria" />
                        }
                        label="Bateria"
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <FormControlLabel
                        control={
                            <Checkbox label="Top" disabled={disabled} checked={alarmes} onChange={handleRoles} name="alarmes" />
                        }
                        label="Alarmes"
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <FormControlLabel
                        control={
                            <Checkbox label="Top" disabled={disabled} checked={reset} onChange={handleRoles} name="reset" />
                        }
                        label="Restaurar central"
                    />
                </Grid>
            </Grid>
            <Button onClick={handleUpdate} sx={{ mr: "20px" }} variant="contained" color="secondary">Salvar</Button>
            <Button onClick={handleDelete} variant="contained" disabled={disabled} color="error">Excluir</Button>
        </Container>
    );
}