import { useState, useEffect } from 'react'
import { Card, Grid, Typography, styled, Paper } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles({
    ContainerUserLicence: {

    },
    techLineLicence: {
        padding: "1rem",
        textAlign: "center",
        marginTop: "1rem",
        marginBottom: "1rem"
    }
})

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    height: 'auto',
    width: '100%'
}));

export default function TechLicence(props) {
    const classes = useStyles()
    const [licences, setLicences] = useState([])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/licences/tech/licences?licence=${props.licence}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            }
        }).then(res => res.json())
            .then(data => setLicences(data)).catch((error) => { })
    }, [])

    return (
        <>
            <ItemHeader>
                <Grid container spacing={3}>
                    <Grid item xs={3} md={3} lg={3}>
                        <Typography variant="body2">Tipo de licença</Typography>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                        <Typography variant="body2">Chave</Typography>
                    </Grid>
                    <Grid item xs={3} md={3} lg={3}>
                        <Typography variant="body2">Validade</Typography>
                    </Grid>
                    <Grid item xs={2} md={2} lg={2}>
                        <Typography variant="body2">Status</Typography>
                    </Grid>
                </Grid>
            </ItemHeader>
            {licences.map(item => {
                return <Card className={classes.techLineLicence} key={item.chave}>
                    <Grid container spacing={3}>
                        <Grid item xs={3} md={3} lg={3}>
                            <Typography variant="body2">Licença {item.tipo_licenca == "desktop" ? "PRO Local" : item.tipo_licenca}</Typography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <Typography variant="body2">Chave {item.chave}</Typography>
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                            <Typography variant="body2">Validade {new Date(item.expiracao_licenca).toLocaleDateString()}</Typography>
                        </Grid>
                        <Grid item xs={2} md={2} lg={2}>
                            <Typography variant="body2">{item.chave_utilizada ? "ativa" : "não ativa"}</Typography>
                        </Grid>
                    </Grid>
                </Card>
            })}
        </>
    )
}