import { Card, CardHeader, CardContent, Typography, Button, CardActions, Snackbar, OutlinedInput, InputAdornment } from "@mui/material"
import { Computer, Key } from '@mui/icons-material'
import { useState, forwardRef } from "react"
import { makeStyles } from "@mui/styles"
import { useHistory } from "react-router-dom"
import MuiAlert from "@mui/material/Alert"

const useStyles = makeStyles({
    card: {
        padding: "1rem",
        marginTop: "1rem",
        marginBottom: "1rem"
    },
    key: {
        width: "20rem",
        marginBottom: "3rem"
    }
})

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function FreeLicence(props) {
    const classes = useStyles()
    const history = useHistory()
    const [key, setKey] = useState()
    const [activeButton, setActiveButton] = useState(true)
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    const keyLength = (e) => {
        if (e.length >= 24) {
            setKey(e)
            setActiveButton(false)
        }
    }

    const ActivateLicence = (e) => {
        fetch(`${process.env.REACT_APP_URL}/user/free?userId=${props.user.id}&companyId=${props.user.iD_EMPRESA}&key=${key}`, {
            method: "GET",
            headers: { "content-type": "application/json" }
        }).then(response => response.json())
            .then(data => {
                if (data.message === "success") {
                    setSeverity("success")
                    setOpen(true)
                    setMessage("Licença ativa com sucesso")

                    fetch(`${process.env.REACT_APP_URL}/UserFetchData/get?id=${props.companyId}&serialConnected=${props.serialConnected}&portName=${sessionStorage.getItem("serialPortNumber")}`, {
                        method: 'GET',
                        headers: {
                            "content-type": "application/json",
                            "Authorization": "Bearer " + props.token
                        }
                    })
                        .then(res => res.json())
                        .then(data => {
                            props.setSystemData(data)
                            props.hubConnection(data.serialKeys)
                            sessionStorage.setItem("serialConnected", "conected")
                        })

                    history.push("/")
                } else {
                    setSeverity("error")
                    setOpen(true)
                    setMessage("Erro ao ativar licença")
                }
            })
    }

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(!open)}
            >
                <Alert severity={severity} sx={{ width: '100%' }}>{message}</Alert>
            </Snackbar>
            <Card className={classes.card}>
                <CardHeader
                    avatar={<Computer color="primary" />}
                    title={"Ativar licença Free"}
                />
                {props.fileUploaded === false
                    ?
                    <Typography variant="body1" color="error">* Nenhuma central cadastrada.</Typography>
                    :
                    <>
                        <CardContent>
                            {/* <InputLabel htmlFor="secret-key">Insira sua chave</InputLabel> */}
                            <OutlinedInput
                                className={classes.key}
                                autoFocus={true}
                                id="secret-key"
                                onChange={(e) => keyLength(e.target.value)}
                                variant="outlined"
                                color="secondary"

                                required
                                //error={secretError}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Key />
                                    </InputAdornment>
                                }
                            ></OutlinedInput>
                            {/* <FormHelperText id="secret-key">{secretError}</FormHelperText> */}
                        </CardContent>
                        <CardActions>
                            <Button
                                disabled={activeButton}
                                variant="contained"
                                color="secondary"
                                onClick={() => (ActivateLicence())}>Ativar Licença</Button>
                        </CardActions>
                    </>
                }

            </Card >
        </>
    )
}