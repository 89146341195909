import { useState, useEffect } from 'react'
import { Modal, IconButton, Button, Tooltip, Card, CardHeader, Checkbox, styled, FormControlLabel, Typography, CardActions, Grid } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import CloseIcon from '@mui/icons-material/Close'

const FilterCard = styled(Card)(({ theme }) => ({
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 400,
    overflow: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: 10,

}))

export default function EventsFilterModal(props) {
    const [open, setOpen] = useState(false)
    const [checked, setChecked] = useState(false)
    const [eventList, setEventList] = useState([])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/occurrence/all`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            }
        }).then(response => response.json())
            .then(data => {
                setEventList(Array.from(new Set(data.filter(item => item.id === 5))))
                props.setDisabledEvents(Array.from(new Set(data.filter(item => item.id === 5))))
            })
    }, [])

    const handleCheckBox = (id, event) => {
        var eventListAux = [...eventList]
        eventListAux.find(item => item.id === id).desabilitado = event.target.checked
        setEventList(eventListAux)
    }

    const openModal = () => {
        setOpen(true)
    }

    const closeModal = () => {
        setOpen(false)
    }

    const sendRequest = () => {
        var occurrence = Array.from(new Set(eventList))
        fetch(`${process.env.REACT_APP_URL}/occurrence/enable`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify(eventList)
        }).then(() => {
            props.setDisabledEvents(eventList)
            closeModal()
        })
    }

    return (
        <>
            <Tooltip title='Filtrar pop-ups'>
                <IconButton onClick={openModal} variant="contained" color='secondary' sx={{ boxShadow: 2 }}>
                    <FilterAltIcon />
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={openModal}
            >
                <FilterCard>
                    <CardHeader
                        action={<IconButton variant='contained' onClick={closeModal}>
                            <Tooltip title="sair sem salvar">
                                <CloseIcon />
                            </Tooltip>
                        </IconButton>}
                        title={<Typography variant="delta">Selecione os eventos que deseja desativar</Typography>}
                    />
                    <Grid container spacing={1}>
                        {eventList.map(item => (
                            <Grid item xs={12} md={12} lg={12} key={item.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox label="Top" checked={item.desabilitado} onChange={(event) => handleCheckBox(item.id, event)} name="events" />
                                    }
                                    label={item.dS_DESCRICAO}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <CardActions>
                        <Button variant="contained" color="secondary" onClick={sendRequest}>Salvar</Button>
                    </CardActions>
                </FilterCard>
            </Modal>
        </>
    )
}