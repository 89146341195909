import React, { useState, useEffect } from "react"
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import makeStyles from "@mui/styles/makeStyles"
import { Box } from "@mui/material"
import { Warning } from "@mui/icons-material"

const useStyles = makeStyles((theme) => {
    return {
        eventCard: {
            height: '40vh',
            display: 'flex',
            flexDirection: 'column',
        },
        actionsEvent: {
            justifyContent: "center"
        }
    }
})

export default function EventMobileItem(props) {
    const classes = useStyles()

    useEffect(() => { }, [])

    const newColor = () => {
        if (props.row.dS_DESCRICAO_EVENTO === "Sub Central") {
            if (props.row.fK_OCORRENCIA === "ALARME DE RETORNO" ||
                props.row.fK_OCORRENCIA === "ALARME EXTRA" ||
                props.row.fK_OCORRENCIA === "ALARME VISUAL") {
                return "#FFEA00"
            }
            if (props.row.fK_OCORRENCIA === "SISTEMA RESTAURADO") {
                return "#00B33C";
            }
            if (props.row.fK_OCORRENCIA === "ALARME DE BRIGADA") {
                return "#F58634";
            }
            if (props.row.fK_OCORRENCIA === "ALARME DE ABANDONO") {
                return "#CA373C";
            }
        }
        if (props.row.fK_OCORRENCIA === "BATERIA BAIXA" ||
            props.row.fK_OCORRENCIA === "SINALIZAÇAO VISUAL ATIVA" ||
            props.row.fK_OCORRENCIA === "REMOVIDO" ||
            props.row.fK_OCORRENCIA === "SILENCIAR ALARME") {
            return "#FFEA00"
        } else {
            return "#CA373C"
        }
    }

    return (
        <div>
            <Card className={classes.eventCard} elevation={1} >
                <CardHeader
                    avatar={""}
                    action={""}
                    title={"GRUPO"}
                    subheader={<Typography variant="body2">{props.row.ds_GRUPO == null ? props.row.fK_GRUPO.slice(12) : props.row.ds_GRUPO}</Typography>}
                />
                <CardContent>
                    <Box textAlign="center">
                        <Warning sx={{ color: newColor, fontSize: 40 }} />
                        <Typography variant='h6'>{props.row.dS_DESCRICAO_EVENTO}</Typography>
                        <Typography variant="delta2">{props.row.dS_ID_PERIFERICO ? props.row.dS_ID_PERIFERICO : props.row.iD_PERIFERICO}</Typography>
                        <Typography variant='h5'>{props.row.fK_OCORRENCIA}</Typography>
                        <Typography variant='h6'>{props.row.dS_LOCAL_EVENTO}</Typography>
                    </Box>
                </CardContent>
                <CardActions sx={{ marginTop: 'auto' }}>
                    <Box textAlign="center" sx={{ width: "100%" }}>
                        <Typography variant='body1'>
                            {new Date(props.row.dT_EVENTO).toLocaleString().replace(',', '')}
                        </Typography>
                    </Box>
                </CardActions>
            </Card>
        </div>
    )
}