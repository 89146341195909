import { useState, useEffect } from "react";
import { Card, CardHeader, CardContent, Grid, Tooltip, IconButton, Button, InputLabel, OutlinedInput, InputAdornment } from "@mui/material";
import { Mail, Add, Remove, RoomPreferences, Delete, Close, AlternateEmail, VpnKey } from '@mui/icons-material'
import makeStyles from "@mui/styles/makeStyles"
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'

const useStyles = makeStyles({
    card: {
        padding: "1rem",
        marginBottom: "1rem"
    }
})

export default function SmtpServerConfig(props) {
    const classes = useStyles();
    const [openCreate, setOpenCreate] = useState(false)
    const [host, setHost] = useState("")
    const [port, setPort] = useState("")
    const [openEdit, setOpenEdit] = useState(false)
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [server, setServer] = useState({
        id: "",
        host: "",
        port: "",
        email: ""
    })

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/server/get`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                // "Authorization": "Bearer " + props.token
            }
        })
            .then(res => {
                if (res.status === 404) {

                } else {
                    res.json()
                        .then(data => {
                            var tempServer = {
                                id: 0,
                                host: "",
                                port: 0,
                                email: ""
                            }
                            tempServer.id = data.id
                            tempServer.host = data.host
                            tempServer.port = data.port
                            tempServer.email = data.email
                            setServer(tempServer)
                        })
                }
            })
    }, [])



    const addServer = () => {
        fetch(`${process.env.REACT_APP_URL}/server/post`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                // "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify({
                host: host,
                port: port,
                email: email,
                password: password
            })
        })
            .then(res => {
                if (res.status === 400) {
                    res.json()
                        .then(data => {
                            // setSeverity("error")
                            // setMessage(data.message)
                            // setOpen(true)
                        })
                } else {
                    res.json()
                        .then(data => {
                            setServer({
                                id: data.id,
                                host: data.host,
                                port: data.port,
                                email: data.email
                            })
                        })

                }
            })
    }

    const deleteServer = () => {
        fetch(`${process.env.REACT_APP_URL}/server/delete?id=${server.id}`, {
            method: 'DELETE',
            headers: {
                "content-type": "application/json",
                // "Authorization": "Bearer " + props.token
            }
        }).then(res => {
            if (res.status === 404) {
                // snackbar
            } else {
                setServer({
                    id: "",
                    host: "",
                    port: "",
                    email: ""
                })
            }
        })
    }

    const setVisible = () => {
        setShowPassword(!showPassword)
    }

    return (
        <>
            {server.host === ""
                ?

                <Card className={classes.card}>
                    <CardHeader
                        avatar={<Mail color="primary" />}
                        action={!openCreate
                            ?
                            <Tooltip title="Criar">
                                <IconButton onClick={() => setOpenCreate(!openCreate)}>
                                    <Add />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title="Fechar">
                                <IconButton onClick={() => setOpenCreate(!openCreate)}>
                                    <Remove />
                                </IconButton>
                            </Tooltip>}
                        title={"Adicionar servidor SMTP"}
                    />

                    {openCreate ?
                        <CardContent>
                            <Grid container spacing={4}>
                                <Grid item xs={4} md={4} lg={4}>
                                    <InputLabel htmlFor="usern-name">Servidor SMTP</InputLabel>
                                    <OutlinedInput
                                        autoFocus={true}
                                        id="usern-name"
                                        onChange={(e) => setHost(e.target.value.trim())}
                                        variant="outlined"
                                        color="secondary"
                                        required
                                        value={host}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Mail />
                                            </InputAdornment>
                                        }
                                    ></OutlinedInput>

                                </Grid>

                                <Grid item xs={4} md={4} lg={4}>
                                    <InputLabel htmlFor="outlined-adornment-password">Porta</InputLabel>
                                    <OutlinedInput
                                        required
                                        onChange={(e) => setPort(e.target.value.trim())}
                                        id="password"
                                        type={"text"}
                                        value={port}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <RoomPreferences />
                                            </InputAdornment>
                                        }
                                    />

                                </Grid>
                            </Grid>

                            <Grid sx={{ marginTop: "0.5rem" }} container spacing={4}>
                                <Grid item xs={4} md={4} lg={4}>
                                    <InputLabel htmlFor="usern-name">E-mail</InputLabel>
                                    <OutlinedInput
                                        id="usern-name"
                                        onChange={(e) => setEmail(e.target.value.trim())}
                                        variant="outlined"
                                        color="secondary"
                                        required
                                        value={email}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <AlternateEmail />
                                            </InputAdornment>
                                        }
                                    ></OutlinedInput>

                                </Grid>

                                <Grid item xs={4} md={4} lg={4}>
                                    <InputLabel htmlFor="outlined-adornment-password">Senha do e-mail</InputLabel>
                                    <OutlinedInput
                                        required
                                        onChange={(e) => setPassword(e.target.value)}
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <VpnKey />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={setVisible}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />

                                </Grid>
                            </Grid>

                            <Button
                                sx={{ marginTop: "2rem" }}
                                color="secondary"
                                variant="contained"
                                onClick={addServer}
                            >
                                Criar
                            </Button>
                        </CardContent>
                        : null}
                </Card>


                :
                <Card className={classes.card}>
                    <CardHeader
                        avatar={<Mail color="primary" />}
                        action={!openEdit
                            ?
                            <Tooltip title="Criar">
                                <IconButton onClick={() => setOpenEdit(!openEdit)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title="Fechar">
                                <IconButton onClick={() => setOpenEdit(!openEdit)}>
                                    <Close />
                                </IconButton>
                            </Tooltip>}
                        title={"Informações servidor SMTP"}
                    />

                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid item xs={4} md={4} lg={4}>
                                <InputLabel htmlFor="usern-name">Host</InputLabel>
                                <OutlinedInput
                                    disabled={true}
                                    autoFocus={true}
                                    id="usern-name"
                                    onChange={(e) => setHost(e.target.value.trim())}
                                    variant="outlined"
                                    color="secondary"
                                    required
                                    value={server.host}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Mail />
                                        </InputAdornment>
                                    }
                                ></OutlinedInput>

                            </Grid>

                            <Grid item xs={4} md={4} lg={4}>
                                <InputLabel htmlFor="outlined-adornment-password">Porta</InputLabel>
                                <OutlinedInput
                                    disabled={true}
                                    onChange={(e) => setPort(e.target.value.trim())}
                                    id="password"
                                    type={"text"}
                                    value={server.port}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <RoomPreferences />
                                        </InputAdornment>
                                    }
                                />

                            </Grid>

                            <Grid item xs={4} md={4} lg={4}>
                                <InputLabel htmlFor="usern-name">E-mail</InputLabel>
                                <OutlinedInput
                                    disabled={true}
                                    id="usern-name"
                                    onChange={(e) => setEmail(e.target.value.trim)}
                                    variant="outlined"
                                    color="secondary"
                                    required
                                    value={server.email}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <AlternateEmail />
                                        </InputAdornment>
                                    }
                                ></OutlinedInput>

                            </Grid>
                        </Grid>

                        {openEdit ?
                            <Button
                                sx={{ marginTop: "2rem" }}
                                color="error"
                                variant="contained"
                                onClick={deleteServer}
                            >
                                Excluir
                            </Button>
                            : null}

                    </CardContent>
                </Card>
            }
        </>

    )
}