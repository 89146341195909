import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from "react-router"
import IconButton from '@mui/material/IconButton'
import { Container, Card, CardHeader, CardContent, Tooltip, Button, Grid, Typography, Checkbox, FormControl, FormLabel, FormGroup, FormControlLabel, FormHelperText, Snackbar } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import { Person, VpnKey, Add, Remove } from '@mui/icons-material'
import { cnpjMask } from './CnpjMask'
import ApartmentIcon from '@mui/icons-material/Apartment'

const useStyles = makeStyles({
    card: {
        padding: "1rem"
    }
})

export default function CreateUser(props) {
    const classes = useStyles();
    const [user, setUser] = useState(localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : {})
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const [nameError, setNameError] = useState(false)

    const [passwordError, setPasswordError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [passwordErrorText, setPasswordErrorText] = useState("")

    const [nameErrorText, setNameErrorText] = useState("")

    const [confirmation, setConfirmation] = useState("")
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
    const [confirmationError, setConfirmationError] = useState(false)
    const [passwordConfirmationText, setPasswordConfirmationText] = useState("")

    const [openCreate, setOpenCreate] = useState(false)

    const [userNotFound, setUserNotFound] = useState(false)

    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")

    const [userRolesList, setUserRolesList] = useState({
        upload: false,
        editar: false,
        alarmes: false,
        relatorio: false,
        usuarios: false,
        admin: true,
        revenda: false,
        delta: false
    })

    const history = useHistory()

    const { upload, editar, alarmes, relatorio, usuarios, admin, revenda, delta } = userRolesList

    const handleRoles = (event) => {
        setUserRolesList({
            ...userRolesList,
            [event.target.name]: event.target.checked,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (name.length < 1) {
            setNameError(true)
            setNameErrorText("Insira um novo nome para o usuário Administrador")
        } else {
            setNameError(false)
            setNameErrorText("")
        }

        if (password.length < 8) {
            setPasswordError(true)
            setPasswordErrorText("Sua senha deve conter no mínimo 8 caracteres")
        } else {
            setPasswordError(false)
            setPasswordErrorText("")
        }

        if (confirmation != password) {
            setConfirmationError(true)
            setPasswordConfirmationText("As senhas devem ser iguais")
        } else {
            setConfirmationError(false)
            setPasswordConfirmationText("")

        }

        if (password.length >= 8 && name && confirmation === password) {
            fetch(`${process.env.REACT_APP_URL}/user/create`, {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({
                    "DS_NOME": name,
                    "SENHA": password,
                    "ROLE": userRolesList,
                    "ID_EMPRESA": props.user.iD_EMPRESA,
                    "ID_INTRANET": 0,
                    "DS_CNPJ": props.user.dS_CNPJ
                })
            }).then(response => response.json()).then(data => {
                if (data.message === "success") {
                    setMessage("Usuário criado com sucesso.")
                    setOpen(true)

                    setUserRolesList({
                        ...userRolesList,
                        upload: false,
                        editar: false,
                        alarmes: false,
                        relatorio: false,
                        usuarios: false,
                        admin: false
                    })

                    props.addUser(data.user.user)
                    setName("")
                    setPassword("")
                    setConfirmation("")
                }
            })
        }
    }

    const setVisible = () => {
        setShowPassword(!showPassword)
    }

    const setConfirmationVisible = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation)
    }

    const handleSnackBar = () => {
        setOpen(!open)
    }

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleSnackBar}
                message={message}
            />
            <Card className={classes.card}>
                <CardHeader
                    avatar={<Person color="primary" />}
                    action={!openCreate
                        ?
                        <Tooltip title="Criar">
                            <IconButton onClick={() => setOpenCreate(!openCreate)}>
                                <Add />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="Fechar">
                            <IconButton onClick={() => setOpenCreate(!openCreate)}>
                                <Remove />
                            </IconButton>
                        </Tooltip>}
                    title={"Criar usuário"}
                //subheader={ }
                />
                {openCreate ?
                    <CardContent>
                        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <Grid container spacing={4}>
                                <Grid item xs={4} md={4} lg={4}>
                                    <InputLabel htmlFor="usern-name">Nome do usuário</InputLabel>
                                    <OutlinedInput
                                        autoFocus={true}
                                        id="usern-name"
                                        onChange={(e) => setName(e.target.value)}
                                        variant="outlined"
                                        color="secondary"
                                        required
                                        value={name}
                                        error={nameError}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Person />
                                            </InputAdornment>
                                        }
                                    ></OutlinedInput>
                                    <FormHelperText id="user-name">{nameErrorText}</FormHelperText>
                                </Grid>

                                <Grid item xs={4} md={4} lg={4}>
                                    <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                                    <OutlinedInput
                                        required
                                        onChange={(e) => setPassword(e.target.value)}
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        error={passwordError}
                                        value={password}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <VpnKey />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={setVisible}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="password">{passwordErrorText}</FormHelperText>
                                </Grid>

                                <Grid item xs={4} md={4} lg={4}>
                                    <InputLabel htmlFor="outlined-adornment-password">Confirme a senha</InputLabel>
                                    <OutlinedInput
                                        required
                                        onChange={(e) => setConfirmation(e.target.value)}
                                        id="confirmation"
                                        type={showPasswordConfirmation ? "text" : "password"}
                                        error={confirmationError}
                                        value={confirmation}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <VpnKey />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={setConfirmationVisible}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="password">{passwordConfirmationText}</FormHelperText>
                                </Grid>

                                {/* {userNotFound == false ? null : <Grid item xs={12} md={12} lg={12}><Typography variant='body2' color='#d32f2f'>Usuário e/ou senha incorretos</Typography></Grid>} */}

                            </Grid>

                            <Grid sx={{ marginTop: "20px", marginBottom: "20px" }} container spacing={4}>
                                <Grid justifyContent="center" alignItems="center" item xs={12} md={12} lg={12}>
                                    <Typography variant='h6'>Premissões de usuário</Typography>
                                    <Typography sx={{ fontSize: '12px' }}>Selecione as opções que deseja habilitar para este usuário.</Typography>
                                </Grid>

                                <Grid item xs={2} md={2} lg={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={upload} onChange={handleRoles} name="upload" />
                                        }
                                        label="Upload rom"
                                    />
                                </Grid>

                                <Grid item xs={2} md={2} lg={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={usuarios} onChange={handleRoles} name="usuarios" />
                                        }
                                        label="Criar usuários"
                                    />
                                </Grid>

                                <Grid item xs={2} md={2} lg={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={alarmes} onChange={handleRoles} name="alarmes" />
                                        }
                                        label="Gerar alarmes"
                                    />
                                </Grid>

                                <Grid item xs={2} md={2} lg={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={relatorio} onChange={handleRoles} name="relatorio" />
                                        }
                                        label="Gerar relatorio"
                                    />
                                </Grid>

                                <Grid item xs={4} md={4} lg={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={editar} onChange={handleRoles} name="editar" />
                                        }
                                        label="Editar grupos / amplificadores"
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                            >
                                Criar
                            </Button>
                        </form>
                    </CardContent>
                    : null}
            </Card>
        </>
    );
}