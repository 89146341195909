import React, { useState, useEffect } from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Warning } from "@mui/icons-material"

const useStyles = makeStyles((theme) => {
    return {
        containerEventItem: {
            alignItems: 'center',
            //justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"eventItem2 eventItem3 eventItem4 eventItem5 eventItem6 eventItem7"`,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"eventItem2 eventItem3 eventItem4" 
                "eventItem5 eventItem6 eventItem7"`,
            }
        },
        eventItem1: {
            gridArea: "eventItem1",
        },
        eventItem2: {
            gridArea: "eventItem2",
        },
        eventItem3: {
            gridArea: "eventItem3",
        },
        eventItem4: {
            gridArea: "eventItem4",
        },
        eventItem5: {
            gridArea: "eventItem5",
        },
        eventItem6: {
            gridArea: "eventItem6",
        }
    }
})

export default function EventAlertRemotePanel(data) {
    const [row, setRow] = useState({})
    const classes = useStyles()
    useState(() => {
        setRow(data.row)
    }, [])

    const newColor = (row) => {
        if (row.fK_OCORRENCIA === "BATERIA BAIXA" ||
            row.fK_OCORRENCIA === "SINALIZAÇAO VISUAL ATIVA" ||
            row.fK_OCORRENCIA === "REMOVIDO" ||
            row.fK_OCORRENCIA === "SILENCIAR ALARME" ||
            row.fK_OCORRENCIA === "CONTROLE AMBIENTAL" ||
            row.fK_OCORRENCIA === "SEM REDE AC"
        ) {
            return "#FFEA00"
        } else {
            return "#CA373C"
        }
    }

    return (
        <div>
            <Grid alignItems="center" container spacing={3}>
                <Grid item key={1} xs={1} md={1} lg={1}>
                    <Warning sx={{ color: newColor(row) }} />
                </Grid>

                <Grid item key={2} xs={11} md={11} lg={11}>
                    <Box className={classes.containerEventItem}>
                        <Box className={classes.eventItem2}><Typography variant="delta">{row.fK_CENTRAL}</Typography></Box>
                        <Box className={classes.eventItem3}><Typography variant="delta">{row.fK_OCORRENCIA}</Typography></Box>
                        <Box className={classes.eventItem4}><Typography variant="delta">{row.dS_DESCRICAO_EVENTO}</Typography></Box>
                        <Box className={classes.eventItem5}><Typography variant="delta">{row.dS_ID_PERIFERICO === undefined ? row.iD_PERIFERICO : row.dS_ID_PERIFERICO}</Typography></Box>
                        <Box className={classes.eventItem6}><Typography variant="delta">{row.dS_LOCAL_EVENTO}</Typography></Box>
                        <Box className={classes.eventItem7}><Typography variant="delta">{new Date(row.dT_EVENTO).toLocaleString().replace(',', '')}</Typography></Box>
                    </Box>
                </Grid>
            </Grid>

        </div>
    )
}