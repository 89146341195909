import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useLocation } from "react-router"
import IconButton from '@mui/material/IconButton'
import { Container, InputLabel, MenuItem, FormControl, Select, SelectChangeEvent, Button, Grid, Typography, Box } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import { Balance, Person, VpnKey } from '@mui/icons-material'
import { FormHelperText } from '@mui/material'

const useStyles = makeStyles((theme) => {
    return {
        text: {
            [theme.breakpoints.up('sm')]: {},
            [theme.breakpoints.down('sm')]: {
                textAlign: "left"
            }
        }
    }
})

export default function Login(props) {
    const classes = useStyles();
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const [nameError, setNameError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [nameErrorText, setNameErrorText] = useState("")
    const [passwordErrorText, setPasswordErrorText] = useState("")
    const [userNotFound, setUserNotFound] = useState(false)
    const [portList, setPortList] = useState([])
    const [port, setPort] = useState("")
    const [portUsed, setPortUsed] = useState("")
    const [portError, setPortError] = useState(false)
    const history = useHistory()
    const [appVerify, setAppVerify] = useState(false)

    const [valorSelecionado, setValorSelecionado] = useState("")

    const GetPorts = useCallback(async () => {
        let flag = false;
        let serialPorts = [];

        try
        {
            const response = await fetch(`${process.env.REACT_APP_URL}/account/ports`, {
                method: "GET",
                headers: { "content-type": "application/json" },
            });

            if (!response.ok) {
                throw Error("Não foi possível adquirir as Portas COM");
            }

            const res = await response.json();

            if (!!res)
            {
                console.log("🚀 ~ GetPorts ~ res:", res)
                flag = true;
                serialPorts = res;
                setPortList(res);
            }
        }
        catch (error)
        {
            console.log("GetPorts.Error => ", error);
        }
        finally
        {
            if (!!flag)
            {
                console.log("🚀 ~ GetConnectedPort ~ serialPorts:", serialPorts)
                GetConnectedPort(serialPorts);
            }
        }
    },[])

    const GetConnectedPort = useCallback(async (serialPorts) => {
        try
        {
            const response = await fetch(`${process.env.REACT_APP_URL}/account/ports/com`, {
                method: "GET",
                headers: { "content-type": "application/json" },
            });

            if (!response.ok)
            {
                throw Error("Não foi possível obter informações de Porta COM previamente selecionada!");
            }

            const res = await response.json();
            
            if(res != null &&  serialPorts.length > 0)
            {
                console.log("Porta encontrada => Lista | Index", serialPorts, res)
                setPortUsed(res)
                setValorSelecionado(serialPorts[res])
            }
            else 
            {
                console.warn("Valor de serialPorts[res] não está definido.", serialPorts, res);
            }
        }
        catch (error)
        {
            console.log("GetConnectedPort.Error => ",error);
        }
    },[])

    const connectionState = (e) => {
        setPort(e)
        sessionStorage.setItem("serialPortNumber", e)

        setValorSelecionado(e)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        props.setIsLoading(true);

        if (name === '') {
            setNameError(true)
            setNameErrorText("Insira o Nome de Usuário")
            props.setIsLoading(false);
        } else {
            setNameError(false)
            setNameErrorText("")
        }
        if (password === '') {
            setPasswordError(true)
            setPasswordErrorText("Insira a Senha")
            props.setIsLoading(false);
        } else {
            setPasswordError(false)
            setPasswordErrorText("")
        }

        if (valorSelecionado === " ")
        {
            setPortError(true)
            props.setIsLoading(false);
        } 
        else
        {
            setPortError(false)
        }

        if (name && password) {
            const uri = `${process.env.REACT_APP_URL}/account/login?ds_NOME=${name}&sENHA=${password}`
            const encoded = encodeURI(uri)
            fetch(encoded, {
                method: "GET",
                headers: { "content-type": "application/json" },
            })
                .then(res => {
                    if (res.status === 404) {
                        setUserNotFound(true)
                        props.setIsLoading(false);
                    } else {
                        res.json()
                            .then(data => {
                                if (data.message) {
                                    props.token.setToken(data.message)
                                    props.setIsLoading(false);
                                } else {
                                    setPort(valorSelecionado)
                                    sessionStorage.setItem("serialPortNumber", valorSelecionado)
                                    props.data.setPort(valorSelecionado)
                                    props.data.setToken(data.token)
                                    props.data.setUserData(data.user)
                                    props.data.populateData(data.user, data.token)
                                    localStorage.setItem("user", JSON.stringify(data.user))
                                    localStorage.setItem("token", data.token)


                                    if (data.user.iD_EMPRESA === "00000000-0000-0000-0000-000000000000") {

                                        if (valorSelecionado != " ") {
                                            sessionStorage.setItem("serialConnected", "conected")
                                        }

                                        history.push("/modo+operacao")
                                    }
                                    else if (data.user.role.revenda === true || data.user.role.delta === true || data.user.role.multiCentral === true) {
                                        history.push("/painel")
                                    } else {

                                        if (valorSelecionado != " ") {
                                            sessionStorage.setItem("serialConnected", "conected")
                                        }

                                        history.push("/central")
                                    }

                                }
                            })
                    }
                })
                .catch(err => console.log(err))
        }
    }

    const setVisible = () => {
        setShowPassword(!showPassword)
    }

    useEffect(() => {
        GetPorts();
    }, [GetPorts])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/account/app/verify`, {
            method: "GET",
            headers: { "content-type": "application/json" },
        })
            .then(res => res.json())
            .then(data => {
                setAppVerify(data)
            })
    })

    return (
        
        <Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit} color="#212121">
                <Container color="#212121">

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12} className={classes.text}>
                            <Typography variant='h3' color="#929292">Login</Typography>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} className={classes.text}>
                            <InputLabel sx={{ color: "#929292" }} htmlFor="usern-name">Usuário:</InputLabel>
                            <OutlinedInput
                                fullWidth={true}
                                autoFocus={true}
                                id="usern-name"
                                onChange={(e) => setName(e.target.value)}
                                variant="outlined"
                                color="secondary"
                                required
                                error={nameError}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Person />
                                    </InputAdornment>
                                }
                            ></OutlinedInput>
                            <FormHelperText id="user-name">{nameErrorText}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} className={classes.text}>
                            <InputLabel sx={{ color: "#929292" }} htmlFor="outlined-adornment-password">Senha:</InputLabel>
                            <OutlinedInput
                                fullWidth={true}
                                required
                                onChange={(e) => setPassword(e.target.value)}
                                id="password"
                                type={showPassword ? "text" : "password"}
                                error={passwordError}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <VpnKey />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={setVisible}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText id="password">{passwordErrorText}</FormHelperText>
                        </Grid>

                        {userNotFound === false ? null : <Grid item xs={12} md={12} lg={12}><Typography variant='body2' color='#d32f2f'>Usuário e/ou Senha Incorretos!</Typography></Grid>}

                        {sessionStorage.getItem("serialConnected") === null
                            ? <Grid item xs={12} md={12} lg={12}>
                                <InputLabel sx={{ color: "#929292" }} htmlFor="outlined-adornment-comPort" className={classes.text}>Escolha uma Porta COM:</InputLabel>
                                <FormControl fullWidth>

                                    {/*<InputLabel id="demo-simple-select-label">Portas COM Encontradas</InputLabel>*/}

                                    {portUsed !== " " ?

                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={valorSelecionado}
                                            onChange={(event) => { if(!!event.target.value) connectionState(event.target.value) }}>

                                            {portList.length > 0 
                                            ? 
                                            portList.map((item, index) => (
                                                <MenuItem key={index} value={item}>{item}</MenuItem>
                                            ))
                                            : null}
                                        </Select>

                                        :

                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={port}
                                            onChange={(event) => { if(!!event.target.value) connectionState(event.target.value) }}>
                                            {portList.length > 0 
                                            ? 
                                            portList.map((item, index) => (
                                                <MenuItem key={index} value={item}>{item}</MenuItem>
                                            ))
                                            : null}
                                        </Select>
                                    }
                                </FormControl>
                            </Grid>
                            : null
                        }

                        <Grid item xs={12} md={12} lg={12} className={classes.text} marginTop={"5px"}>
                            <Button
                                fullWidth={true}
                                type="submit"
                                color="secondary"
                                variant="contained"
                            >
                                Entrar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </Box>
    
    );                    
}
