import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import makeStyles from "@mui/styles/makeStyles"
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import { Container, Typography } from '@mui/material'
import EventAlertRemote from '../components/EventAlertRemote'
import EventAlertRemotePanel from '../components/EventAlarmRemotePanel'
import CommandItemRemote from "../components/CommandItemRemote"

const useStyles = makeStyles({
    pageEventMaster: {
        minHeight: "100vh",
    },
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 15
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    height: '50px',
}));

const EventsMaster = (props) => {
    const history = useHistory()
    const classes = useStyles(props)
    const [rows, setRows] = useState([])
    const [clients, setClients] = useState([])

    const populateData = () => {
        var menuItensList = Array.from(new Set(
            props.menuItens.filter((este, i) => props.menuItens.indexOf(este) === i).map(item => {
                return item.id_central;
            })
        ))
        setClients(menuItensList)
        fetch(`${process.env.REACT_APP_URL}/userfetchdata/all?menuItens=${menuItensList}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            }
        })
            .then(response => response.json())
            .then(data => setRows(data))
    }

    useEffect(() => {
        if (props.menuItens.length > 0) {
            populateData()
        }
    }, [props.menuItens])

    useEffect(() => {
        setClients(props.clients)
        setRows((currentRows) => [...currentRows, props.streaming]);
        if (rows.length > 99) {
            rows.shift()
        }
    }, [props.streaming])

    return (
        <Container className={classes.pageEventMaster}>
            <Box sx={{ flexGrow: 1, marginLeft: 'auto', marginRight: 'auto' }}>
                <ItemHeader key={0} xs={12} md={12} lg={12}>
                    <Typography varian="h4">Monitoramento de Eventos e Alarmes</Typography>
                </ItemHeader>
                {
                    rows.slice(0).reverse().map(row => {
                        if (row) {
                            return row.type === "alarme"
                                ?
                                <Item key={row.id} xs={12} md={12} lg={12} >
                                    <CommandItemRemote key={row.id} commandData={row} />
                                </Item>
                                :
                                <Item key={row.id} xs={12} md={12} lg={12}>
                                    <EventAlertRemotePanel key={row.id} row={row} />
                                </Item>
                        }
                    })
                }
            </Box>
        </Container>
    );
}

export default EventsMaster;