import React, { useState, useEffect } from 'react'
import { Container, Grid, Button, Box, TextField, MenuItem, Typography, styled, Stack, Paper } from '@mui/material'
import Calendar from '../components/Calendar'
import Local from '../components/Local'
import makeStyles from "@mui/styles/makeStyles"
import format from 'date-fns/format'
import Pagination from '@mui/material/Pagination'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import ptBR from 'date-fns/locale/pt-BR'
import CommandItem from "../components/CommandItem"
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import RelatoryNameField from '../components/RelatoryNameField'
import PdfViewer from '../providers/PdfViewer'
import Drawer from '../includes/Drawer'
import { ReactComponent as IconPDF } from '../assets/icons/icon_file_pdf.svg'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: 15,
    width: '100%'
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    height: 'auto',
    width: '100%'
}));

const useStyles = makeStyles((theme) => {
    return {
        pageAlarm: {
            minHeight: "100vh",
            paddingTop: "4rem"
        },
        buttonRelatory: {
            marginTop: '3rem',
            marginBottom: '3rem'
        },
        fieldRelatory: {
            width: "100%"
        },
        commandRelatoryItem: {
            alignItems: 'center',
            [theme.breakpoints.up('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"commandRelatoryItem1 commandRelatoryItem2 commandRelatoryItem3 commandRelatoryItem4 commandRelatoryItem5 commandRelatoryItem6"`,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"commandRelatoryItem1 commandRelatoryItem2 commandRelatoryItem3 commandRelatoryItem3" 
                "commandRelatoryItem4 commandRelatoryItem5 commandRelatoryItem6 commandRelatoryItem6"`,
            }
        },
        commandRelatoryItem1: {
            gridArea: "commandRelatoryItem1",
        },
        commandRelatoryItem2: {
            gridArea: "commandRelatoryItem2",
        },
        commandRelatoryItem3: {
            gridArea: "commandRelatoryItem3",
        },
        commandRelatoryItem4: {
            gridArea: "commandRelatoryItem4",
        },
        commandRelatoryItem5: {
            gridArea: "commandRelatoryItem5",
        },
        commandRelatoryItem6: {
            gridArea: "commandRelatoryItem6",
        }
    }
})

const alarms = [
    {
        id: 1,
        type: "Todos",
        name: "Todos"
    },
    {
        id: 2,
        type: "DESL",
        name: "Sistema Restaurado"
    },
    {
        id: 3,
        type: "LIGA",
        name: "Alarme de Abandono"
    },
    {
        id: 4,
        type: "PANI",
        name: "Alarme de Brigada"
    },
    {
        id: 5,
        type: "SILE",
        name: "Silenciar Alarme"
    }
]

export default function AlarmRelatory(props) {
    const classes = useStyles()
    const [currency, setCurrency] = useState("Todos")
    const [firstDate, setFirstDate] = useState(format(new Date(), 'MM/dd/yyyy'), { locale: ptBR })
    const [secondDate, setSecondDate] = useState(format(new Date(), 'MM/dd/yyyy'), { locale: ptBR })
    const [alarmOrigin, setOccurrence] = useState('Todos')
    const [alarmDescription, setDescription] = useState('Todos')
    const [alarmLocal, setLocal] = useState('Todos')
    const [result, setResult] = useState([])
    const [pagination, setPagination] = useState({})
    const [page, setPage] = useState(1)
    const [menuItens, setMenuItens] = useState("")
    const [relatoryName, setRelatoryName] = useState("")
    const [groupValue, setGroupValue] = useState("")
    const [show, setShow] = useState(false);
    const [controlLoading, setControlLoading] = useState([{"type": "local", "status": false}]);

    const changeGroupValue = (value) => {
        setGroupValue(value)
    }

    const gerarPdf = () => {   
        let actionUrl = `${process.env.REACT_APP_URL}/relatory/alarm?pageNumber=1&pageSize=60&alarmFirstDate=${firstDate}&alarmSecondDate=${secondDate}&menuItens=${menuItens}&user=${props.user.dS_NOME}&name${relatoryName}${alarmDescription === 'Todos' ? '' : '&alarmDescription=' + alarmDescription}${groupValue === '' ? '' : '&alarmGroup=' + groupValue}`

        if (process.env.REACT_APP_MODE === "desktop") {
            PdfViewer.Download(actionUrl, "GET", props.token, "relatorio-alarmes", props.setIsLoading);
        } else {
            if (props.user.role.delta === true || props.user.role.revenda === true)
            {
                actionUrl = `${process.env.REACT_APP_URL}/relatory/integrator/alarm?pageNumber=1&pageSize=60&alarmFirstDate=${firstDate}&alarmSecondDate=${secondDate}&menuItens=${menuItens}&user=${props.user.dS_NOME}&name${relatoryName}${alarmDescription === 'Todos' ? '' : '&alarmDescription=' + alarmDescription}${groupValue === '' ? '' : '&alarmGroup=' + groupValue}`
                PdfViewer.PrintAsync(actionUrl, "GET", props.token, props.setIsLoading);
            } else
            {
                PdfViewer.PrintAsync(actionUrl, "GET", props.token, props.setIsLoading);
            }
        }
    }

    const handleChange = (event) => {
        setCurrency(event.target.value)
        setDescription(event.target.value)
    }

    async function request() {
        props.setIsLoading(true);

        if (groupValue === '0000000000000') {
            setGroupValue('')
        }

        fetch(`${process.env.REACT_APP_URL}/command/relatory?pageNumber=1&pageSize=100&alarmFirstDate=${firstDate}&alarmSecondDate=${secondDate}&menuItens=${menuItens}${alarmDescription === 'Todos' ? '' : '&alarmDescription=' + alarmDescription}${groupValue === '' ? '' : '&alarmGroup=' + groupValue}`,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Expose-Headers': '*',
                "Authorization": "Bearer " + props.token
            }
        })
        .then(response => (
            setPagination(JSON.parse(response.headers.get("X-Pagination"))),
            response.json()
        ))
        .then(data => {
            setShow(true);
            setResult(data)
            props.setIsLoading(false);
        })
    }

    const pageChange = (event, value) => {
        props.setIsLoading(true);
        setPage(value)

        fetch(`${process.env.REACT_APP_URL}/command/relatory?pageNumber=${value}&pageSize=100&alarmFirstDate=${firstDate}&alarmSecondDate=${secondDate}&menuItens=${menuItens}${alarmDescription === 'Todos' ? '' : '&alarmDescription=' + alarmDescription}${groupValue === '0000000000000' ? '' : '&alarmGroup=' + groupValue}`,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Expose-Headers': '*',
                "Authorization": "Bearer " + props.token
            }
        })
        .then(response => (
            setPagination(JSON.parse(response.headers.get("X-Pagination"))),
            response.json()
        ))
        .then(data => {
            setResult(data)
            props.setIsLoading(false);
        })
    }

    useEffect(() => {
        setResult([])

        if (props.serial === undefined) {
            setMenuItens(Array.from(new Set(
                props.menuItens.map(item => {
                    return item.id_central;
                })
            )))
        } else {
            setMenuItens(props.serial)
        }
    }, [props.menuItens])

    useEffect(() => {
        let count = 0;
        
        controlLoading.forEach(item => {
            if (!!item.status)
            {
                count++;
            } else if (props.user.role.delta === true || props.user.role.revenda === true)
            {
                count++;
            }
        });

        if (count === controlLoading.length)
        {
            props.setIsLoading(false);
        }
        else
        {
            props.setIsLoading(true);
        }
    }, [controlLoading]);

    return (
        <div class="container">

            <center>
                <h3 class="mb-5">Relatório de Comandos de Alarmes</h3>
            </center>

            <div class="filter">
                {/* <div class="filter__column filter__column--fw">
                    <RelatoryNameField changeRelatoryName={changeRelatoryName} />
                </div> */}
                <div class="filter__column">
                    <Calendar label={"Data Inicial"} setDate={setFirstDate} />
                </div>
                <div class="filter__column">
                    <Calendar label={"Data Final"} setDate={setSecondDate} />
                </div>
                <div class="filter__column">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Comandos de Alarmes"
                        value={currency}
                        onChange={handleChange}
                        className={classes.fieldRelatory}
                    >
                        {alarms.map((option) => (
                            <MenuItem key={option.id} value={option.type}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                {props.user.role.delta === true || props.user.role.revenda === true 
                ? 
                null 
                :
                <div class="filter__column">
                    <Local serial={props.serial} token={props.token} changeGroupValue={changeGroupValue} setLocal={setLocal} controlLoading={controlLoading} setControlLoading={setControlLoading} />
                </div>
                }
            </div>

            <div class="buttons-container buttons-container--right mb-5">
            <button type="button" class="button button--md button--primary" title="Buscar" onClick={request} disabled={props.isLoading}>
                    <span class="button__label">Buscar</span>
                </button>
                <button type="button" class="button button--transparent" title="Gerar PDF" onClick={gerarPdf} disabled={props.isLoading}>
                    <IconPDF className="button__icon df-i df-i-red df-i-2xl"/>
                </button>
            </div>

            {!!show &&
                <>
                    <Box sx={{ flexGrow: 1 }}>
                        <Stack className={classes.stack} alignItems="center" spacing={2}>
                            <ItemHeader key={0} xs={12} md={6} lg={4}>
                                <Box className={classes.commandRelatoryItem}>
                                    <Box className={classes.commandRelatoryItem1}><VolumeUpIcon /></Box>
                                    <Box className={classes.commandRelatoryItem2}><Typography variant="body2">Central</Typography></Box>
                                    <Box className={classes.commandRelatoryItem3}><Typography variant="body2">Comando</Typography></Box>
                                    <Box className={classes.commandRelatoryItem4}><Typography variant="body2">Local</Typography></Box>
                                    <Box className={classes.commandRelatoryItem5}><Typography variant="body2">Origem</Typography></Box>
                                    <Box className={classes.commandRelatoryItem6}><Typography variant="body2">Data</Typography></Box>
                                </Box>
                            </ItemHeader>
                            {result.length > 0 ? result.map(row => {
                                return <Item key={row.id} xs={12} md={6} lg={4}>
                                    <CommandItem key={row.id} commandData={row} />
                                </Item>

                            }) : <Typography>Nenhum resultado encontrado!</Typography>}
                        </Stack>
                    </Box>
                    <Container sx={{ p: 2 }}>
                        <Pagination
                            count={pagination.TotalPages}
                            onChange={pageChange}
                            page={page}
                        />
                    </Container>
                </>
            }
        </div>
    )
}