import { useState, useEffect } from "react";
import { Button, Select, Card, CardHeader, CardContent, MenuItem, Box, Tooltip, IconButton, InputLabel, OutlinedInput } from "@mui/material";
import { Edit, Save, Storage, Folder } from '@mui/icons-material';
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
    card: {
        padding: "1rem",
        marginTop: "1rem",
        marginBottom: "1rem"
    },
    box: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: 1,
        gridTemplateRows: 'auto',
        gridTemplateAreas: `"item1 item2" 
                "item3 item4"`,
    },
    item1: {
        gridArea: "item1",
    },
    item2: {
        gridArea: "item2",
    },
    item3: {
        gridArea: "item3",
        width: "90%"
    },
    item4: {
        gridArea: "item4",
        width: "50%"
    }
})

const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0]

export default function DatabaseBackup({ onButtonClick, token }) {
    const classes = useStyles()
    const [folder, setFolder] = useState('')
    const [settings, setSettings] = useState()
    const [edit, setEdit] = useState(false)
    const [hour, setHour] = useState(0)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/database/get`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + token
            }
        }).then(res => res.json()).then(data => {
            setSettings(data)
            setHour(data.horaBackup)
        })
    }, [])

    const handleClick = (event) => {
        setEdit(!edit)

        fetch(`${process.env.REACT_APP_URL}/database/settings/hour`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify({
                "horaBackup": hour
            })
        })
    }

    const handleChange = (event) => {
        setHour(event.target.value);
    };

    const inputChange = () => {
        fetch(`${process.env.REACT_APP_URL}/database/settings/path`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + token
            }
        }).then(res => res.json()).then(data => {
            setSettings(data)
            setHour(data.horaBackup)
        })
    }
    return (
        <>
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <Storage color="primary" />
                    }
                    title="Backup do Banco de Dados"
                    action={
                        edit ?
                            <Tooltip title="Salvar">
                                <IconButton onClick={() => handleClick()}>
                                    <Save />
                                </IconButton>
                            </Tooltip>

                            : <Tooltip title="Editar">
                                <IconButton onClick={() => setEdit(!edit)}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>

                    }
                />
                <CardContent>
                    {settings !== undefined ?
                        <Box className={classes.box}>
                            <InputLabel className={classes.item1} htmlFor="path">Pasta do Arquivo:</InputLabel>

                            <Box className={classes.item3}>
                                <OutlinedInput
                                    sx={{ width: "70%" }}
                                    required
                                    onChange={inputChange}
                                    id="path"
                                    value={settings.caminho === null ? "Nenhuma Pasta Selecionada" : settings.caminho}
                                    disabled={true}
                                />
                                <IconButton disabled={!edit}>
                                    <Tooltip onClick={inputChange} title="Selecionar Pasta">
                                        <Folder />
                                    </Tooltip>
                                </IconButton>
                            </Box>


                            <InputLabel className={classes.item2} htmlFor="select-hour">Horário para o Backup:</InputLabel>

                            <Select
                                className={classes.item4}
                                labelId="demo-simple-select-label"
                                id="select-hour"
                                value={hour}
                                onChange={handleChange}
                                disabled={!edit}
                            >
                                {hours.map(item => {
                                    return <MenuItem value={item}>{item}:00</MenuItem>
                                })}
                            </Select>
                        </Box>
                        : null}
                </CardContent>
            </Card>
        </>
    )
}