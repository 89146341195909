import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({ children, className = '', modalId = ''}) => {
    let header = null, body = null, footer = null;

    React.Children.forEach(children, child => {
		if (child.type === Modal.Header) {
			header = child;
		} else if (child.type === Modal.Body) {
			body = child;
		} else if (child.type === Modal.Footer) {
			footer = child;
		}
	});

    return (
		<>
			<input type="checkbox" id={modalId} data-control="modal"/>
			<div className={`modal ${className}`}>
				<label for={modalId} className="modal__outarea"></label>
				<label for={modalId} className="modal__close">
					<i class="fa fa-times fa-lg" aria-hidden="true"></i>
				</label>
				{header && <div className="modal__header">{header}</div>}
				{body && <div className="modal__body">{body}</div>}
				{footer && <div className="modal__footer">{footer}</div>}
			</div>
		</>
    );
}

Modal.propTypes = {
    children: PropTypes.node.isRequired,
};

// Subcomponentes
Modal.Header = ({ children }) => <>{children}</>;
Modal.Body = ({ children }) => <>{children}</>;
Modal.Footer = ({ children }) => <>{children}</>;

export default Modal;
