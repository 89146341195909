import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router"
import IconButton from '@mui/material/IconButton'
import { Container, InputLabel, Button, Grid, Typography, Box } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import { Person, VpnKey } from '@mui/icons-material'
import { FormHelperText } from '@mui/material'

const useStyles = makeStyles((theme) => {
    return {
        text: {
            [theme.breakpoints.up('sm')]: {},
            [theme.breakpoints.down('sm')]: {
                textAlign: "center"
            }
        }
    }
})

export default function WebLogin(props) {
    const classes = useStyles();
    const [email, setName] = useState("")
    const [password, setPassword] = useState("")
    const [nameError, setNameError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [nameErrorText, setNameErrorText] = useState("")
    const [passwordErrorText, setPasswordErrorText] = useState("")
    const [userNotFound, setUserNotFound] = useState(false)
    const history = useHistory()


    const handleSubmit = (e) => {
        e.preventDefault()
        props.setIsLoading(true);

        if (email === '') {
            setNameError(true)
            setNameErrorText("Insira o Email")
            props.setIsLoading(false);
        } 
        else 
        {
            setNameError(false)
            setNameErrorText("")
        }

        if (password === '') {
            setPasswordError(true)
            setPasswordErrorText("Insira a Senha")
            props.setIsLoading(false);
        } 
        else 
        {
            setPasswordError(false)
            setPasswordErrorText("")
        }

        if (email && password) {
            const uri = `${process.env.REACT_APP_URL}/account/login/web?email=${email}&sENHA=${password}`
            const encoded = encodeURI(uri)
            fetch(encoded, {
                //mode: 'cors',
                method: "GET",
                //credentials: "include",
                headers: { "content-type": "application/json", 'Access-Control-Allow-Origin': '*' },
            })
                .then(res => {
                    if (res.status === 404) {
                        setUserNotFound(true)
                        props.setIsLoading(false);
                    } else {
                        res.json()
                            .then(data => {
                                if (data.message) {
                                    props.token.setToken(data.message)
                                    props.setIsLoading(false);
                                } else {
                                    //props.data.setPort(port)
                                    props.data.setToken(data.token)
                                    props.data.setUserData(data.user)
                                    props.data.populateData(data.user, data.token)
                                    localStorage.setItem("user", JSON.stringify(data.user))
                                    localStorage.setItem("token", data.token)
                                    if (data.user.iD_EMPRESA === "00000000-0000-0000-0000-000000000000") {
                                        history.push("/primeiro+login")
                                    }
                                    else if (data.user.role.revenda === true || data.user.role.delta === true || data.user.role.multiCentral === true) {
                                        history.push("/painel")
                                    } else {
                                        sessionStorage.setItem("serialConnected", "conected")
                                        history.push("/central")
                                    }
                                }
                            })
                    }
                })
                .catch(err => console.log(err))
        }
    }

    const setVisible = () => {
        setShowPassword(!showPassword)
    }


    return (
        <Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Container color="#212121">

                    <Grid container spacing={2}>

                        <Grid item xs={12} md={12} lg={12} className={classes.text}>
                            <Typography variant='h3' color='#929292'>Login</Typography>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} className={classes.text}>
                            <InputLabel sx={{ color: "#929292" }} htmlFor="usern-email">E-mail:</InputLabel>
                            <OutlinedInput
                                fullWidth={true}
                                autoFocus={true}
                                id="usern-email"
                                onChange={(e) => setName(e.target.value)}
                                variant="outlined"
                                color="secondary"
                                placeholder="Email"
                                required
                                error={nameError}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Person />
                                    </InputAdornment>
                                }
                            ></OutlinedInput>
                            <FormHelperText id="user-email">{nameErrorText}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} className={classes.text}>
                            <InputLabel sx={{ color: "#929292" }} htmlFor="outlined-adornment-password">Senha:</InputLabel>
                            <OutlinedInput
                                fullWidth={true}
                                required
                                onChange={(e) => setPassword(e.target.value)}
                                id="password"
                                type={showPassword ? "text" : "password"}
                                error={passwordError}
                                placeholder="Senha"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <VpnKey />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={setVisible}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText id="password">{passwordErrorText}</FormHelperText>
                        </Grid>

                        {userNotFound == false ? null : <Grid item xs={12} md={12} lg={12}><Typography variant='body2' color='#d32f2f'>E-mail e/ou Senha Incorretos!</Typography></Grid>}

                        <Grid item xs={12} md={12} lg={12} className={classes.text} marginTop={"5px"}>
                            <Button
                                fullWidth={true}
                                type="submit"
                                color="secondary"
                                variant="contained"
                            >
                                Entrar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </Box>
    );
}