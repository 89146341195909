import { useState, useEffect } from "react"
import { Card, CardHeader, CardContent, CardActions, Typography, Container, Box, IconButton, Tooltip, Button } from "@mui/material"
import { Close, Edit, Person } from '@mui/icons-material'
import makeStyles from "@mui/styles/makeStyles"
import ViewWebUser from "./ViewWebUser"
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'

export default function UserCardWeb(props) {
    const [edit, setEdit] = useState(false)

    return (
        <Card sx={{marginTop: "1rem", marginBottom: "1rem", padding: "1rem"}}>
            <CardHeader
                avatar={<Person color="primary" />}
                action={edit
                    ?
                    <Tooltip title="Fechar dados">
                        <IconButton onClick={() => setEdit(!edit)}>
                            <VisibilityOff />
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title="Ver dados">
                        <IconButton onClick={() => setEdit(!edit)}>
                            <Visibility />
                        </IconButton>
                    </Tooltip>}
                title={props.user.dS_NOME}
            //subheader={ }
            />
            {edit ?
                <>
                    <CardContent>
                        <ViewWebUser removeUser={props.removeUser} user={props.user} />
                    </CardContent>
                </>
                : null}
        </Card>
    )
}