import { useState, useEffect, useCallback, useMemo } from "react";
import differenceInDays from 'date-fns/differenceInDays'
import Helper from "../providers/Helpers";
//import { error } from "console";

const CompareArrays = (array1, array2) => {
    if (array1.length !== array2.length) {
        return false;
    }
    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
            return false;
        }
    }
    return true;
};

const licenceName = (licenceType) => {
    if (licenceType === "d") {
        return "PRO Local"
    }
    if (licenceType === "f") {
        return "Free"
    }
    if (licenceType === "t") {
        return "Técnico"
    }
    if (licenceType === "r") {
        return "Integrador"
    }
    if (licenceType === "w") {
        return "Web"
    }
    if (licenceType === "m") {
        return "Master"
    }
}

export default function SystemData(props) {
    const memoModemStatus = useMemo(() => props.modemStatus, [props.modemStatus]);
    const memoToken = useMemo(() => props.token, [props.token]);
    const [desktopStatus, setDesktopStatus] = useState("não informado");
    const [panelStatus, setPanelStatus] = useState("não informado");
    const [coordinator, setCoordinator] = useState({});
    const [onlineAmplifiers, setOnlineAmplifiers] = useState([]);
    const [amplifiers, setAmplifiers] = useState([]);
    const [events, setEvents] = useState([]);
    const [alarms, setAlarms] = useState([]);
    const [panelMode, setPanelMode] = useState(props.panelMode);
    const [licenceElements, setLicenceElements] = useState([]);
    const [licenceValidation, setLicenceValidation] = useState(true);
    const [days, setDays] = useState("");
    const [licence, setLicence] = useState("");
    const [network, setNetwork] = useState({});

    const CheckPanelStatus = (lastTimeUpdate) => {
        let last = new Date(lastTimeUpdate);
        let current = new Date();
        let minutes = Math.floor((current.getTime() - last.getTime()) / 60000);

        if (minutes > 10)
        {
            setPanelStatus("offline");
        } 
        else
        {
            setPanelStatus("online");
        }
    }

    const CheckLicenseStatus = useCallback(async () => {
        let now = false;
        
        try
        {
            if (!!sessionStorage.getItem("serial"))
            {
                const response = await fetch(`${process.env.REACT_APP_URL}/central/licence?serialNumber=${sessionStorage.getItem("serial")}`, {
                    method: 'GET',
                    headers: { 
                        "content-type": "application/json",
                        "authorization": `Bearer ${props.token}`
                    }
                });
    
                if (!response.ok)
                {
                    throw new Error("Não foi possível buscar dados de Licenças");
                }
    
                const res = await response.json();
    
                if (res.licenceElements != null)
                {
                    setLicenceElements(res.licenceElements)
    
                    let startdate = new Date();
                    let endDateElements = res.licenceElements[2].split("-");
                    let endDate = new Date(endDateElements[2], endDateElements[1] - 1, endDateElements[0]);
                    let difInDays = differenceInDays(endDate, startdate);
        
                    if (difInDays <= 30)
                    {
                        setLicenceValidation(false);
                    }
        
                    setDays(difInDays);
                    setLicence(licenceName(res.licenceElements[0]));
                    localStorage.setItem("licenseType",licenceName(res.licenceElements[0]));
                }
            }
            else
            {
                now = true;
                throw new Error("O Serial não foi informado. Nova tentativa será realizada!");
            }
        }
        catch (error)
        {
            console.log("🚀 ~ CheckLicenseStatus ~ error:", error)
        }
        finally
        {
            if (!now)
            {
                setTimeout(CheckLicenseStatus,43200000);
            }
            else
            {
                setTimeout(CheckLicenseStatus,2000);
            }
        }
        return () => { console.log("Finish CheckLicenseStatus"); }
    },[]);

    const GetDailyAlarms = useCallback(async () => {
        try
        {
            if(!!sessionStorage.getItem("serial"))
            {
                const response = await fetch(`${process.env.REACT_APP_URL}/command/all`, {
                    method: 'POST',
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer " + memoToken
                    },
                    body: JSON.stringify({
                        "serialNumber": sessionStorage.getItem("serial"),
                    })
                });
    
                if (!response.ok)
                {
                    throw new Error("Não foi possível buscar os Alarmes do Dia");
                }
    
                const res = await response.json();
    
                setAlarms(res);
            }
            else
            {
                throw new Error("O Serial não foi informado. Nova tentativa será realizada!");
            }
        }
        catch (error)
        {
            console.log("🚀 ~ GetDailyAlarms ~ error:", error)
        }
        finally
        {
            setTimeout(GetDailyAlarms, 5000);
        }

        return () => { console.log("Finish GetDailyAlarms"); }
    },[]);
    
    const GetDailyEvents = useCallback(async () => {
        try
        {
            if (!!sessionStorage.getItem("serial"))
            {
                const response = await fetch(`${process.env.REACT_APP_URL}/events/all?serialNumber=${sessionStorage.getItem("serial")}`, {
                    method: "GET",
                    headers: { 
                        "content-type": "application/json",
                        "authorization": `Bearer ${memoToken}`
                    }
                });
    
                if (!response.ok)
                {
                    throw new Error("Não foi possível buscar os Eventos diários");
                }
    
                const res = await response.json();
    
                setEvents(res);
            }
            else
            {
                throw new Error("O Serial não foi informado. Nova tentativa será realizada!");
            }
        }
        catch (error)
        {
            console.log("🚀 ~ GetDailyEvents ~ error:", error);
        }
        finally 
        {
            setTimeout(GetDailyEvents, 5000);
        }

        return () => { console.log("Finish GetDailyEvents"); }
    },[]);

    const GetDesktopStatusBySerial = useCallback(async () => {
        try
        {
            if (!!sessionStorage.getItem("serial"))
            {
                const response = await fetch(`${process.env.REACT_APP_URL}/panel/last?centralSerial=${sessionStorage.getItem("serial")}`, {
                    method: "GET",
                    headers: { 
                        "content-type": "application/json",
                        "authorization": `Bearer ${memoToken}`
                    }
                });
    
                if (!response.ok)
                {
                    throw new Error("Falha ao buscar dados do Modo de Operação");
                }
    
                const res = await response.json();
    
                setPanelMode(res.status);
            }
            else
            {
                throw new Error("O Serial não foi informado. Nova tentativa será realizada!");
            }
        }
        catch (error)
        {
            console.log("🚀 ~ GetDesktopStatusBySerial ~ error:", error)
        }
        finally
        {
            setTimeout(GetDesktopStatusBySerial,10000);
        }

        return () => { console.log("Finish GetDesktopStatusBySerial"); }
    },[]);

    const GetCoordinator = useCallback(() => {
        let data = JSON.parse(sessionStorage.getItem("coordinator"));

        if (!!data)
        {
            //setPanelStatus(data.panelStatus);
            if (data.panelStatus === "online")
            {
                CheckPanelStatus(data.coordinator.dT_AMPLIFICADOR);
            }
            else
            {
                setPanelStatus("offline");
            }

            setCoordinator(data.coordinator);
        }

        setTimeout(GetCoordinator, 10000);
    },[]);
    
    const GetAmplifiersByCentralSerial = useCallback(async () => {
        if(!!props.modemConnected)
        {
            try
            {
                if (!!sessionStorage.getItem("serial"))
                {
                    const response = await fetch(`${process.env.REACT_APP_URL}/amplifier/all`, {
                        method: "POST",
                        headers: { 
                            "content-type": "application/json",
                            "authorization": `Bearer ${memoToken}`
                        },
                        body: JSON.stringify({ "serialNumber": sessionStorage.getItem("serial") })
                    });
    
                    if (!response.ok)
                    {
                        throw new Error("Falha ao buscar dados de amplificadores");
                    }
    
                    const res = await response.json();
    
                    setAmplifiers(res.filter(amplifier => amplifier.dS_NOME !== "Modem" && amplifier.dS_NOME !== "Central"));
                    setOnlineAmplifiers(res.filter(amplifier => (amplifier.dS_STATUS === "online" || amplifier.dS_STATUS === "buscando") && amplifier.dS_NOME !== "Modem" && amplifier.ds_NOME !== "Central"));
                }
                else
                {
                    throw new Error("O Serial não foi informado. Nova tentativa será realizada!");
                }
            }
            catch (error)
            {
                console.log("🚀 ~ GetAmplifiersByCentralSerial ~ error:", error)
            }
            finally
            {
                setTimeout(GetAmplifiersByCentralSerial,2000);
            }

            return () => { console.log("Finish GetAmplifiersByCentralSerial"); }
        }
    },[]);

    const NetworkStatus = useCallback(() => {
        let amplifiers = JSON.parse(sessionStorage.getItem("amplifiers"));

        if(!!amplifiers)
        {
            let amplifiersOnline = []
            let onlineCount = []
            let percentage = 0;
        
            let response = {
                status: "",
                total: 0,
                online: 0,
                working: 0
            };
        
            amplifiers.online.forEach(amplifier => {
                amplifiersOnline.push(amplifier)
            });
        
            onlineCount = amplifiersOnline.filter(amplifier => {
                return (amplifier.dS_STATUS === "online" || amplifier.dS_STATUS === "buscando") && amplifier.dS_NOME !== "Central" ? amplifier : null
            });
        
            percentage = (onlineCount.length * 100) / amplifiers.all.length;
        
            response.total = amplifiers.all.length;
            response.online = onlineCount.length;
            response.working = percentage;
        
            switch(true)
            {
                case (response.working > 0 && response.working <= 19):
                    response.status = "Baixa";
                    break;
                case (response.working > 19 && response.working <= 49):
                    response.status = "Média";
                    break;
                case (response.working > 49 && response.working <= 79):
                    response.status = "Alta";
                    break;
                case (response.working > 79):
                    response.status = "Excelente";
                    break;
                case (response.online === 0 && response.total > 0):
                    response.status = "Necessita Atenção";
                    Helper.Notification('ATENÇÃO, Sinal Baixo!\n\nAcesse a página de Rede e verifique os Amplificadores.', "Rede");
                    break;
                default:
                    response.status = "Sem amplificadores";
                    break;
            }
        
            setNetwork(response);
        }
    },[]);

    const CheckDesktopStatusFromWeb = useCallback(async () => {
        if(process.env.REACT_APP_MODE === "web")
        {
            try
            {
                if (!!sessionStorage.getItem("serial"))
                {
                    const response = await fetch(`${process.env.REACT_APP_URL}/localstatus/get-by-serial?serialNumber=${sessionStorage.getItem("serial")}`, {
                        method: "GET",
                        headers: { 
                            "content-type": "application/json",
                            "authorization": `Bearer ${memoToken}`
                        }
                    });
        
                    if (!response.ok)
                    {
                        throw new Error("Falha ao buscar dados do Modo de Operação");
                    }
        
                    const res = await response.json();
                    
                    if (res.isOpen)
                    {
                        setDesktopStatus("online");
                    }
                    else
                    {
                        setDesktopStatus("offline");
                    }

                    console.log("🚀 ~ CheckDesktopStatusFromWeb ~ res:", res)
                }
                else
                {
                    throw new Error("O Serial não foi informado. Nova tentativa será realizada!");
                }
            }
            catch (error)
            {
                console.log("🚀 ~ GetDesktopStatusBySerial ~ error:", error)
            }
            finally
            {
                setTimeout(CheckDesktopStatusFromWeb,10000);
            }

            return () => { console.log("Finish CheckDesktopStatusFromWeb"); }
        }
        else
        {
            return () => { console.log("Ambiente Desktop identificado, pulando CheckDesktopStatusFromWeb"); }
        }
    },[])

    useEffect(() => {
        document.querySelector('.header-action[data-action="refresh-system"]').click();
    },[]);
    
    useEffect(CheckLicenseStatus, [CheckLicenseStatus]);
    useEffect(CheckDesktopStatusFromWeb, [CheckDesktopStatusFromWeb]);
    useEffect(GetDesktopStatusBySerial, [GetDesktopStatusBySerial]);
    useEffect(GetCoordinator,[GetCoordinator, amplifiers]);
    //useEffect(GetAmplifiersByCentralSerial,[GetAmplifiersByCentralSerial]);
    useEffect(GetDailyEvents, [GetDailyEvents]);
    useEffect(GetDailyAlarms, [GetDailyAlarms]);

    useEffect(() => {
        setPanelMode(props.panelMode);
    }, [props.panelMode]);

    useEffect(() => {
        if (typeof props.rows !== 'undefined') {
            if(props.rows !== null){
                if (props.rows.type === 'alarme') {
                    var auxAlarms = [...alarms]
                    auxAlarms.push(props.rows)
                    setAlarms(auxAlarms)
                }

                if (props.rows.type === 'evento') {
                    var auxEvents = [...events]
                    auxEvents.push(props.rows)
                    setEvents(auxEvents)
                }
            }
        }
    }, [props.rows]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (coordinator.dS_DL === props.amplifierChange.amplifier) 
            {
                //setPanelStatus(props.amplifierChange.status)
            } 
            else 
            {
                const updatedAmplifiers = amplifiers.map(amplifier => {
                    if (amplifier.dS_DL === props.amplifierChange.amplifier) {
                        return {...amplifier, dS_STATUS: props.amplifierChange.status};
                    }
                    return amplifier;
                });

                if (!CompareArrays(updatedAmplifiers, amplifiers)) {
                    setAmplifiers(updatedAmplifiers);
                }
            }        
        }, 10000);

        return () => clearInterval(interval);
    }, [props.amplifierChange, amplifiers, coordinator]);

    useEffect(() => {
        var amplifierExists = amplifiers.find(amplifier => amplifier.dS_NUMSERIE === props.newAmplifier.dS_NUMSERIE)

        if (typeof amplifierExists === 'undefined') 
        {
            var updateAmplifierList = [...amplifiers]
            updateAmplifierList.push(props.newAmplifier)
            setAmplifiers(updateAmplifierList)
        }
    }, [props.newAmplifier, amplifiers]);

    useEffect(() => {
        NetworkStatus();

        const interval = setInterval(NetworkStatus, 2000);

        return () => clearInterval(interval);
    }, [NetworkStatus, props.modemStatus])

    return (
        <main class="container info-box-container mt-5">

            <div class="info-box info-box--curved info-box--full">
                <div class="info-box__header">
                    <h4 class="info-box__title">Número de Série da Central</h4>
                </div>
                <div class="info-box__body">
                    {
                        props.systemData.name !== undefined ?
                        <>
                            <p class="h1 mb-1 mt-2">
                                {props.systemData.serialKeys}
                            </p>

                            <p class="h5 mt-0 mb-2">
                                {props.systemData.name}
                            </p>
                        </>
                        :
                        <span class="h2">{props.systemData.serialKeys}</span>
                    }
                </div>
                <div class="info-box__footer"></div>
            </div>

            <div class="info-box info-box--curved">
                <div class="info-box__header">
                    <h4 class="info-box__title">Status da Central</h4>
                </div>
                <div class="info-box__body">
                    <i className={panelStatus === 'não informado' ? "fa fa-tablet fa-4x color-disabled" : panelStatus === 'online' ? 'fa fa-tablet fa-4x color-success' : 'fa fa-tablet fa-4x color-danger'} aria-hidden="true"></i>
                </div>
                <div class="info-box__footer">
                    <span>
                        {panelStatus !== undefined ? panelStatus : "NÃO INFORMADO"}
                    </span>
                </div>
            </div>

            <div class="info-box info-box--curved">
                <div class="info-box__header">
                    <h4 class="info-box__title">Modo de Operação</h4>
                </div>
                <div class="info-box__body">
                    {panelMode === "NÃO INFORMADO"
                    ? <i class="fa fa-cogs fa-4x color-disabled" aria-hidden="true"></i>
                    : panelMode === "MODO NORMAL"
                    ? <i class="fa fa-cogs fa-4x color-success" aria-hidden="true"></i>
                    : panelMode === "MODO PARCIAL"
                    ? <i class="fa fa-cogs fa-4x color-warning" aria-hidden="true"></i>
                    : panelMode.includes("SOFT SPARE PANEL") 
                    ? <i class="fa fa-cogs fa-4x color-info" aria-hidden="true"></i>
                    : <i class="fa fa-cogs fa-4x color-danger" aria-hidden="true"></i>
                    }
                </div>
                <div class="info-box__footer">
                    <span>{panelMode}</span>
                </div>
            </div>

            {process.env.REACT_APP_MODE === 'desktop'
            ?
            <div class="info-box info-box--curved">
                <div class="info-box__header">
                    <h4 class="info-box__title">Modem USB</h4>
                </div>
                <div class="info-box__body">
                    <i className={!!props.modemStatus.status ? 'fa fa-usb fa-4x fa-rotate-270 color-success' : 'fa fa-usb fa-4x fa-rotate-270 color-danger'} aria-hidden="true"></i>
                </div>
                <div class="info-box__footer">
                    <span style={{color: props.systemData.serialPortError ? "#FF3B30;" : "inheret"}}>
                        {!props.modemStatus.status
                        ? "NÃO ENCONTRADO"
                        : props.systemData.serialPortError
                        ? `ERRO CONEXÃO - ${sessionStorage.getItem("serialPortNumber")}`
                        : `Conectado - ${sessionStorage.getItem("serialPortNumber")}` }
                    </span>
                </div>
            </div>
            :
            <div class="info-box info-box--curved">
                <div class="info-box__header">
                    <h4 class="info-box__title">Guardião Desktop</h4>
                </div>
                <div class="info-box__body">
                    {desktopStatus === "não informado" && props.modemConnected === "não informado"
                    ? <i className={"fa fa-laptop fa-4x color-disabled"} aria-hidden="true"></i>
                    : desktopStatus === "online" && !!props.modemConnected
                    ? <i className={"fa fa-laptop fa-4x color-success"} aria-hidden="true"></i>
                    : desktopStatus === "online" && !props.modemConnected
                    ? <i className={"fa fa-laptop fa-4x color-warning"} aria-hidden="true"></i>
                    : <i className={"fa fa-laptop fa-4x color-danger"} aria-hidden="true"></i>
                    }
                </div>
                <div class="info-box__footer">
                    <span>
                        {
                        desktopStatus === "não informado" && props.modemConnected === "não informado"
                        ? 
                        `Não informado`
                        :
                        desktopStatus === "online" && !props.modemConnected
                        ?
                        `${desktopStatus} - Sem Modem`
                        :
                        desktopStatus
                        }
                    </span>
                </div>
            </div>
            }

            <div class="info-box info-box--curved" data-action="network-health" data-tippy data-tippy-content={`Amplificadores: ${network.online} de ${network.total}`} title={`Amplificadores: ${network.online} de ${network.total}`}>
                <div class="info-box__header">
                    <h4 class="info-box__title">Rede de Amplificadores</h4>
                </div>
                <div class="info-box__body">
                    <i className={network.working >= 50 ? "fa fa-wifi fa-4x color-success" : network.working <= 49 && network.working >= 19 ? "fa fa-wifi fa-4x color-warning" :  network.working > 0 ? "fa fa-wifi fa-4x color-danger" : "fa fa-wifi fa-4x color-disabled"} aria-hidden="true"></i>
                </div>
                <div class="info-box__footer">
                    <span>
                        {!!network.status ? network.status : "Não informado"}
                    </span>
                </div>
            </div>

            <div class="info-box info-box--curved">
                <div class="info-box__header">
                    <h4 class="info-box__title">Eventos</h4>
                </div>
                <div class="info-box__body">
                    <span class="h1 color-warning">{events.length}</span>
                </div>
                <div class="info-box__footer">
                    <span>Hoje</span>
                </div>
            </div>

            <div class="info-box info-box--curved">
                <div class="info-box__header">
                    <h4 class="info-box__title">Alarmes</h4>
                </div>
                <div class="info-box__body">
                    <span class="h1 color-danger">
                        {alarms.length}
                    </span>
                </div>
                <div class="info-box__footer">
                    <span>Hoje</span>
                </div>
            </div>

            <div class="info-box info-box--curved">
                <div class="info-box__header">
                    <h4 class="info-box__title">Versão</h4>
                </div>
                <div class="info-box__body">
                    <span class="h1 color-info">
                        { process.env.REACT_APP_BUILD_VERSION }
                    </span>
                </div>
                <div class="info-box__footer">
                    <span>
                        { process.env.REACT_APP_BUILD_TYPE }
                    </span>
                </div>
            </div>

            <div class="info-box info-box--curved">
                <div class="info-box__header">
                    <h4 class="info-box__title">Tipo da Licença</h4>
                </div>
                <div class="info-box__body">
                    <span className={days > 30 ? "h2 color-info" : days > 7 && days <= 30 ? "h2 color-warning" : "h2 color-danger"}>
                        { licence }
                    </span>
                </div>
                <div class="info-box__footer">
                    {days > 0
                    ?
                    <span>Ativa</span>
                    :
                    <span>Expirada</span>
                    }
                </div>
            </div>

            <div class="info-box info-box--curved" data-action="expiration-license">
                <div class="info-box__header">
                    <h4 class="info-box__title">Expira em</h4>
                </div>
                <div class="info-box__body">
                    <span className={days > 30 ? "h2 color-info" : days > 7 && days <= 30 ? "h2 color-warning" : "h2 color-danger"}>
                        {days}
                    </span>
                </div>
                <div class="info-box__footer">
                    <span>dias</span>
                </div>
            </div>

        </main>
    )
}